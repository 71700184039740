import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiUpload } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react'
import {
  decode as decodeBase64,
  encode as encodeBase64,
} from '@stablelib/base64'

import { SubmissionPatchDto, SubmissionStatus } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import Input from '~components/Input'
import { ModalCloseButton } from '~components/Modal'

import { getMappedErrorMessage, validateImageSize } from '~pages/Agency/utils'
import { ADMIN_FORM_ENDPOINT } from '~features/admin-form/common/AdminViewFormService'

import { useAgencyRejectedReasons } from './queries'

type StatusChangeModalProps = {
  submissionId?: string
  formId?: string
  agencyId?: string
  clickedButton: string
  setModalIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  formName?: string
  submittedAt?: string
  submittedBy?: string
  paymentAmount?: number
  lastPaymentTransactionId?: string
  onStatusChange: any
}

export const StatusChangeModal = ({
  clickedButton,
  submissionId,
  formId,
  agencyId,
  isOpen,
  setModalIsOpen,
  formName,
  submittedAt,
  submittedBy,
  paymentAmount,
  lastPaymentTransactionId,
  onStatusChange,
}: StatusChangeModalProps): JSX.Element => {
  const fileUploadRef = useRef<HTMLInputElement | null>(null)
  const navigate = useNavigate()
  const agencyIdString = agencyId ? `agencyId=${agencyId}` : ''
  const { data } = useAgencyRejectedReasons(agencyIdString)
  const [currentAttachment, setCurrentAttachment] = useState<string>('')
  const [continueModal, setContinueModal] = useState<boolean>(false)
  const [cancelModal, setCancelModal] = useState<boolean>(false)
  const [requestData, setRequestData] = useState({})
  const [message, setMessage] = useState<string>('')
  const toast = useToast()

  type DropdownType = {
    value: string
    label: string
  }

  const agencyReasonDropdownData: DropdownType[] = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      agencyReasonDropdownData.push({
        value: value?.reason,
        label: value?.reason,
      })
    })

    agencyReasonDropdownData.sort((a, b) => a.label.localeCompare(b.label))
  }

  mapDataToDropDown()

  const mainFormValues = useForm({
    defaultValues: {
      reason: '',
      note: '',
      attachment: '',
    },
  })

  const convertBase64 = (file: File): Promise<string | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        if (fileReader.result === null) {
          throw new Error()
        }
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  //Upload attachment
  const handleFileSelect = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (target.files?.[0] === undefined) return
      const file = target.files?.[0]
      mainFormValues.clearErrors()
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (fileUploadRef.current) {
        fileUploadRef.current.value = ''
      }

      const isImageValid = validateImageSize(file, 2000000)

      if (isImageValid === false) {
        return mainFormValues.setError(
          'attachment',
          {
            type: 'invalidFile',
            message: 'Selected file has to be less than 2MB',
          },
          { shouldFocus: true },
        )
      }
      const encodedAttachmentFiles = await convertBase64(file)
      if (typeof encodedAttachmentFiles !== 'string') {
        toast({
          description: 'Please upload again',
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
        return
      }
      // const encodedAttachment = encodedAttachmentFiles.split(',')[1]
      setCurrentAttachment(encodedAttachmentFiles)

      mainFormValues.setValue(
        'attachment',
        file ? file.name : 'Attached file',
        {
          shouldValidate: true,
        },
      )
    },
    [toast, mainFormValues],
  )

  const callApi = (requestBody: SubmissionPatchDto, successToast?: string) => {
    ApiService.patch(
      `/${ADMIN_FORM_ENDPOINT}/${formId}/submissions/${submissionId}`,
      requestBody,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          // setIsLoading(false)
          toast({
            description: successToast
              ? successToast
              : 'Submission status changed',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          })
          onStatusChange()
          setModalIsOpen(false)
          window.location.reload() // Force page reload
        }
      })
      .catch((error) => {
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const onSubmit = (data: any) => {
    if (clickedButton === 'Reject') {
      const requestBody = {
        status: SubmissionStatus.Rejected,
        rejectedReason: data.reason,
        rejectedNote: data.note,
        rejectedAttachment: currentAttachment,
      }
      setRequestData(requestBody)
      setMessage('Form rejected successfully')
    } else if (clickedButton === 'Complete') {
      const requestBody = {
        status: SubmissionStatus.Completed,
        completedNote: data.note,
        completedAttachment: currentAttachment,
      }
      setRequestData(requestBody)
      setMessage('Form completed successfully')
    } else if (clickedButton === 'Accept') {
      const requestBody = {
        status: SubmissionStatus.InProgress,
        acceptedNote: data.note,
      }
      setRequestData(requestBody)
      setMessage('Form accepted successfully')
    } else if (clickedButton === 'Refunded') {
      const requestBody = {
        paymentRefunded: true,
        paymentRefundedAmount: paymentAmount,
        paymentRefundedNote: data.note,
      }
      setRequestData(requestBody)
      setMessage('Payment refunded successfully')
    }
    setContinueModal(true)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setModalIsOpen(false)
          mainFormValues.reset({})
          setCurrentAttachment('')
          navigate(-1)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={mainFormValues.handleSubmit(onSubmit)}>
            <ModalCloseButton />
            <ModalHeader>{clickedButton} Details</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                <Box height="70px">
                  <FormLabel htmlFor="formName" mb="8px">
                    Form Name
                  </FormLabel>
                  <Input
                    defaultValue={formName}
                    placeholder={'Form Name'}
                    id="formName"
                    disabled={true}
                  />
                </Box>
                <Box height="70px">
                  <FormLabel htmlFor="submissionId" mb="8px">
                    Submission ID
                  </FormLabel>
                  <Input
                    defaultValue={submissionId}
                    placeholder={'Submission ID'}
                    id="submissionId"
                    disabled={true}
                  />
                </Box>
                <Box height="70px">
                  <FormLabel htmlFor="submittedAt" mb="8px">
                    Submitted at
                  </FormLabel>
                  <Input
                    defaultValue={submittedAt}
                    placeholder={'Submitted At'}
                    id="submittedAt"
                    disabled={true}
                  />
                </Box>
                <Box height="70px">
                  <FormLabel htmlFor="submittedBy" mb="8px">
                    Submitted by
                  </FormLabel>
                  <Input
                    defaultValue={submittedBy}
                    placeholder={'Submitted By'}
                    id="submittedBy"
                    disabled={true}
                  />
                </Box>
                {clickedButton === 'Refunded' ? (
                  <>
                    {' '}
                    <Box height="70px">
                      <FormLabel htmlFor="paymentAmount" mb="8px">
                        Payment Amount (LKR)
                      </FormLabel>
                      <Input
                        defaultValue={
                          paymentAmount
                            ? typeof paymentAmount === 'number'
                              ? paymentAmount?.toFixed(2).toString()
                              : paymentAmount
                            : ''
                        }
                        placeholder={'Payment Amount'}
                        id="paymentAmount"
                        disabled={true}
                      />
                    </Box>
                    <Box height="70px">
                      <FormLabel htmlFor="paymentTransactionId" mb="8px">
                        Payment Transaction ID
                      </FormLabel>
                      <Input
                        defaultValue={lastPaymentTransactionId}
                        placeholder={'Payment Transaction ID'}
                        id="paymentTransactionId"
                        disabled={true}
                      />
                    </Box>
                  </>
                ) : null}

                {clickedButton === 'Reject' ? (
                  <>
                    <Box height="70px">
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.reason
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="reason" mb="8px">
                          Reject Reason*
                        </ChakraFormLabel>
                        <Controller
                          name="reason"
                          control={mainFormValues.control}
                          render={({ field }) => (
                            <SingleSelect
                              {...field}
                              items={agencyReasonDropdownData}
                            />
                          )}
                          rules={{ required: 'Reject reason is required' }}
                        />
                        {mainFormValues.formState.errors.reason && (
                          <FormErrorMessage>
                            {mainFormValues.formState.errors.reason.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </>
                ) : null}
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.note ? true : false
                    }
                  >
                    <FormLabel htmlFor="note" mb="8px">
                      Note
                    </FormLabel>
                    <Input
                      placeholder={'Note'}
                      id="note"
                      maxLength={100}
                      {...mainFormValues.register('note', {
                        maxLength: {
                          value: 100,
                          message: 'Max length is 100',
                        },
                      })}
                    />
                    {mainFormValues.formState.errors.note && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.note.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                {clickedButton === 'Reject' || clickedButton === 'Complete' ? (
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        mainFormValues?.formState?.errors?.attachment
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="attachment" mb="8px">
                        Upload Attachment
                      </FormLabel>
                      <Stack direction="row" spacing="0.5rem">
                        <Input
                          placeholder={'Upload Attachment'}
                          id="attachment"
                          maxLength={100}
                          {...mainFormValues.register('attachment', {
                            // pattern: {
                            //   value: SECRET_KEY_REGEX,
                            //   message: 'Invalid Encryption Key',
                            // },
                            maxLength: {
                              value: 100,
                              message: 'Max length is 100',
                            },
                          })}
                        />
                        <IconButton
                          // isDisabled={isLoading}
                          variant="outline"
                          aria-label="Attachments"
                          icon={<BiUpload />}
                          onClick={() => fileUploadRef.current?.click()}
                        />
                      </Stack>
                      {mainFormValues.formState.errors.attachment && (
                        <FormErrorMessage>
                          {mainFormValues.formState.errors.attachment.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <Input
                      name="secretKeyFile"
                      type="file"
                      ref={fileUploadRef}
                      accept="image/png, image/jpg, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={handleFileSelect}
                      display="none"
                    />
                  </Box>
                ) : null}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button type="submit">Continue</Button>
                <Button
                  onClick={() => {
                    setCancelModal(true)
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={continueModal}
        onClose={() => {
          setContinueModal(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            Are you sure you want to continue?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                onClick={() => {
                  callApi(requestData, message)
                  setContinueModal(false)
                  setModalIsOpen(false)
                  mainFormValues.reset({})
                  setCurrentAttachment('')
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setContinueModal(false)
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={cancelModal}
        onClose={() => {
          setCancelModal(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            Are you sure you want to cancel?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                onClick={() => {
                  setModalIsOpen(false)
                  mainFormValues.reset({})
                  setCurrentAttachment('')
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setCancelModal(false)
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
