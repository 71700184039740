import { Merge, Opaque } from 'type-fest'
import { z } from 'zod'
import { NotificationType } from './agency_notification'

export type GeneralNotificationId = Opaque<string, 'GeneralNotificationId'>
export const GeneralNotificationId =
  z.string() as unknown as z.Schema<GeneralNotificationId>

// enum NotificationType {
//   FS = 'Form Submission',
//   FA = 'Form Accepted',
//   FR = 'Form Rejected',
//   FC = 'Form Completed',
//   PF = 'Payment Failed',
// }

export type GeneralNotificationType = {
  code: NotificationType
  name: string
}

export type GeneralNotificationLastModifiedBy = {
  userCategoryId: string
  userId: string
}

/**
 * Typing for individual agency notification
 */
export type GeneralNotificationBase = {
  // _id: AgencyNotificationId
  notificationType: GeneralNotificationType
  notification?: string
  created?: Date
  lastModified?: Date
  lastModifiedBy: GeneralNotificationLastModifiedBy
}

export type GeneralNotificationDto = Merge<
  GeneralNotificationBase,
  { created?: Date; lastModified?: Date }
>
