import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiDownload } from 'react-icons/bi'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import simplur from 'simplur'

import {
  ADMINFORM_ROUTE,
  ADMINFORM_SUBMISSIONS_SUBROUTE,
} from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { DateInput } from '~components/DatePicker/DateInput'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

import { ADMIN_FORM_ENDPOINT } from '~features/admin-form/common/AdminViewFormService'

import { useFormResponses } from '../../../queries'
import { useStorageResponsesContext } from '../StorageResponsesContext'
import useDecryptionWorkers, {
  DownloadEncryptedParams,
} from '../useDecryptionWorkers'

import { DownloadButton } from './DownloadButton'

export const UnlockedResponses = ({
  secretKey,
}: {
  secretKey?: string
}): JSX.Element => {
  type SubmissionsTableType = {
    rowNumber?: number
    _id?: string
    createdDate?: string
    status?: string
  }

  const fromObjectToQueryString = (obj: any): string => {
    let queryString = ''

    Object.keys(obj).forEach((key) => {
      queryString = queryString + `${key}=${obj[key]}&`
    })

    if (queryString.substr(queryString.length - 1) === '&') {
      queryString = queryString.substring(0, queryString.length - 1)
    }
    return queryString
  }

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const { downloadEncryptedResponses } = useDecryptionWorkers()
  const { downloadParams } = useStorageResponsesContext()

  const toast = useToast()
  const { formId } = useParams()
  const navigate = useNavigate()
  const { data: { count } = {} } = useFormResponses()
  const [isLoading, setIsLoading] = useState(false)
  const [submissionsTable, setSubmissionsTable] = useState<
    SubmissionsTableType[]
  >([])
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [queryObject, setQueryObject] = useState<string>('status=UnAttended')
  const [totalCount, setTotalCount] = useState<number>(0)

  const prettifiedResponsesCount = useMemo(() => {
    if (!count) return
    return simplur` ${[count]}response[|s] to date`
  }, [count])

  const mapDataToTable = (array: any[]) => {
    const currentTableData: SubmissionsTableType[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        _id: value?._id,
        serialNumber: value?.serialNumber ? value?.serialNumber : '-',
        createdDate: formatDate(value?.created),
        status: value?.status,
      }
      currentTableData.push(obj)
    })
    setSubmissionsTable(currentTableData)
  }
  localStorage.removeItem('refresh')

  const callAPI = (query = '') => {
    setIsLoading(true)
    ApiService.get(
      `${ADMIN_FORM_ENDPOINT}/${formId}/submissions/manage?page_size=${rowsPerPage}&page=${currentPage}&${query}`,
    )
      .then((data) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.submissions)) {
            mapDataToTable(data?.data?.submissions)
          }
          setTotalCount(data?.data?.meta?.totalItems)
        } else {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setTotalCount(0)
        mapDataToTable([])
      })
  }

  useEffect(() => {
    setCurrentPage(currentPage)
    callAPI(queryObject)
  }, [currentPage, rowsPerPage])

  const defaultValues = {
    id: '',
    status: 'UnAttended',
    startDate: '',
    endDate: '',
  }

  const formValues = useForm({
    defaultValues: defaultValues,
  })

  const currentStatus = formValues.watch('status')
  const currentStartDate = formValues.watch('startDate')
  const currentEndDate = formValues.watch('endDate')
  const currentSubmissionId = formValues.watch('id')

  const onSubmit = (data: any) => {
    setCurrentPage(1)
    if (data.startDate !== '' || data.endDate !== '') {
      if (new Date(data?.startDate) > new Date(data?.endDate)) {
        formValues.setError(
          'endDate',
          {
            type: 'focus',
            message: 'End date should be greater than start Date',
          },
          { shouldFocus: true },
        )
        return
      }
    }
    if (data.startDate !== '' && data.endDate === '') {
      formValues.setError(
        'endDate',
        {
          type: 'focus',
          message: 'End date cannot be empty if start date is entered',
        },
        { shouldFocus: true },
      )
      return
    }
    if (data.endDate !== '' && data.startDate === '') {
      formValues.setError(
        'startDate',
        {
          type: 'focus',
          message: 'Start date cannot be empty if end date is entered',
        },
        { shouldFocus: true },
      )
      return
    }
    if (data?.status === '' || data?.status === 'ALL') {
      delete data['status']
    }
    if (data?.id === '') {
      delete data['id']
    }
    if (data?.startDate === '') {
      delete data['startDate']
    }
    if (data?.endDate === '') {
      delete data['endDate']
    }

    const queryData = fromObjectToQueryString(data)
    setQueryObject(queryData)
    callAPI(queryData)
  }

  const handleExportCsvMutation = useMutation(
    (params: DownloadEncryptedParams) => downloadEncryptedResponses(params),
    // TODO: add error and success handling
  )

  const handleExportCsvNoAttachments = useCallback(() => {
    if (!downloadParams) return
    return handleExportCsvMutation.mutate({
      ...downloadParams,
      downloadAttachments: false,
    })
  }, [downloadParams, handleExportCsvMutation])

  const handleExportCsvWithAttachments = useCallback(() => {
    if (!downloadParams) return
    return handleExportCsvMutation.mutate({
      ...downloadParams,
      downloadAttachments: true,
    })
  }, [downloadParams, handleExportCsvMutation])

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('status=UnAttended')
    callAPI('status=UnAttended')
    formValues.reset(defaultValues)
  }

  return (
    <Flex flexDir="column" h="100%">
      <Grid
        mb="1rem"
        alignItems="end"
        color="secondary.500"
        gridTemplateColumns={{ base: 'auto', md: 'auto 1fr' }}
        gridGap={{ base: '0.5rem', md: '1.5rem' }}
        gridTemplateAreas={{
          base: "'submissions submissions' 'export'",
          md: "'submissions export'",
        }}
      >
        <Box gridArea="submissions">
          <Text textStyle="h4">
            <Text as="span" color="primary.500">
              {count?.toLocaleString()}
            </Text>
            {prettifiedResponsesCount}
          </Text>
        </Box>
        {/* <DownloadButton /> */}
        <Box gridArea="export" justifySelf="flex-end">
          <ButtonGroup variant="outline" spacing="6">
            <Button
              variant="solid"
              colorScheme="primary"
              leftIcon={<BiDownload fontSize="1.5rem" />}
              onClick={handleExportCsvNoAttachments}
            >
              Export
            </Button>
            <Button
              variant="solid"
              colorScheme="primary"
              leftIcon={<BiDownload fontSize="1.5rem" />}
              onClick={handleExportCsvWithAttachments}
            >
              Export with Attachments
            </Button>
          </ButtonGroup>
        </Box>
      </Grid>
      <form onSubmit={formValues.handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
          <Box height="70px">
            <FormControl
              isInvalid={
                formValues?.formState?.errors?.startDate ? true : false
              }
            >
              <FormLabel>Submitted From</FormLabel>
              <Controller
                control={formValues.control}
                name={'startDate'}
                rules={{
                  validate: {
                    // GET IT?
                    validDate: (val) => {
                      if (!val) return
                      const dateVal = new Date(val)
                      if (isNaN(dateVal.getTime())) {
                        return 'Please enter a valid date'
                      }
                      return true
                    },
                  },
                }}
                render={({ field }) => (
                  <DateInput excludeFuture={true} {...field} />
                )}
              />
              {formValues.formState.errors.startDate && (
                <FormErrorMessage>
                  {formValues.formState.errors.startDate.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box height="70px">
            <FormControl
              isInvalid={formValues?.formState?.errors?.endDate ? true : false}
            >
              <FormLabel>Submitted To</FormLabel>
              <Controller
                control={formValues.control}
                name={'endDate'}
                rules={{
                  validate: {
                    // GET IT?
                    validDate: (val) => {
                      if (!val) return
                      const dateVal = new Date(val)
                      const fromDateVal = new Date(currentStartDate)
                      if (isNaN(dateVal.getTime())) {
                        return 'Please enter a valid date'
                      } else if (dateVal < fromDateVal) {
                        return 'To date cannot be lesser than From date.'
                      }
                      return true
                    },
                  },
                }}
                render={({ field }) => (
                  <DateInput excludeFuture={true} {...field} />
                )}
              />
              {formValues.formState.errors.endDate && (
                <FormErrorMessage>
                  {formValues.formState.errors.endDate.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box height="70px">
            <FormControl
              isInvalid={formValues?.formState?.errors?.id ? true : false}
            >
              <FormLabel htmlFor="id" mb="8px">
                Submission ID
              </FormLabel>
              <Input
                placeholder={'Submission ID'}
                id="id"
                maxLength={24}
                {...formValues.register('id', {
                  maxLength: { value: 24, message: 'Max lenght is 24' },
                })}
              />
              {formValues.formState.errors.id && (
                <FormErrorMessage>
                  {formValues.formState.errors.id.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <Box height="70px">
            <FormControl
              isInvalid={formValues?.formState?.errors?.status ? true : false}
            >
              <FormLabel htmlFor="status" mb="8px">
                Status
              </FormLabel>
              <Controller
                name="status"
                control={formValues.control}
                render={({ field }) => (
                  <SingleSelect
                    {...field}
                    items={[
                      {
                        value: 'ALL',
                        label: 'All',
                      },
                      {
                        value: 'UnAttended',
                        label: 'Unattended',
                      },
                      {
                        value: 'Inprogress',
                        label: 'In progress',
                      },
                      {
                        value: 'Rejected',
                        label: 'Rejected',
                      },
                      {
                        value: 'Completed',
                        label: 'Completed',
                      },
                      {
                        value: 'PaymentPending',
                        label: 'Payment Pending',
                      },
                      {
                        value: 'PaymentFailed',
                        label: 'Payment Failed',
                      },
                    ]}
                  />
                )}
              />
              {formValues.formState.errors.status && (
                <FormErrorMessage>
                  {formValues.formState.errors.status.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>
        </SimpleGrid>
        <Center>
          <Box height="60px" mt={20}>
            <ButtonGroup variant="outline" spacing="6">
              <Button
                type="submit"
                disabled={
                  currentStatus === '' &&
                  currentStartDate === '' &&
                  currentEndDate === '' &&
                  currentSubmissionId === ''
                }
              >
                Search
              </Button>
              <Button type="reset" onClick={resetForm}>
                Reset
              </Button>
            </ButtonGroup>
          </Box>
        </Center>
      </form>
      <Box bg={'gray.200'} p={5}>
        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
          <Box bg={'gray.200'} p={5}></Box>
        </SimpleGrid>
        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="70px">
          <DataTable
            heading="Submissions"
            rowValues={submissionsTable ? submissionsTable : []}
            cols={[
              {
                Header: '#',
                accessor: 'rowNumber',
                disableSortBy: true,
                maxWidth: 100,
                minWidth: 100,
              },
              {
                Header: 'Submission ID',
                accessor: '_id',
                disableSortBy: true,
                maxWidth: 350,
                minWidth: 190,
              },
              {
                Header: 'Serial Number',
                accessor: 'serialNumber',
                disableSortBy: true,
                maxWidth: 100,
                width: 200,
                minWidth: 90,
              },
              {
                Header: 'Time',
                accessor: 'createdDate',
                disableSortBy: true,
                maxWidth: 400,
                minWidth: 190,
              },
              {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
                maxWidth: 300,
                minWidth: 170,
              },
              {
                Header: 'Action',
                Cell: (props: any) => (
                  <ButtonGroup variant="outline" spacing="1" padding={2}>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${formId}/submission/${props?.row?.values?._id}`,
                          { state: { currentSecretKey: secretKey } },
                        )
                      }
                    >
                      View
                    </Button>
                  </ButtonGroup>
                ),
              },
            ]}
          />
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : null}
          {submissionsTable.length === 0 ? (
            <Center>No results found</Center>
          ) : null}
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
            <Grid templateColumns="repeat(4, 1fr)" gap={6}>
              <GridItem w="100%" h="10" colSpan={2} pt={2}>
                Rows per page:
              </GridItem>
              <GridItem w="100%" h="10" colSpan={1}>
                <SingleSelect
                  value={rowsPerPage}
                  onChange={(value) => {
                    setCurrentPage(1)
                    setRowsPerPage(value)
                  }}
                  name={'rowsPerPage'}
                  isClearable={false}
                  items={[
                    {
                      value: '5',
                      label: '5',
                    },
                    {
                      value: '10',
                      label: '10',
                    },
                    {
                      value: '25',
                      label: '25',
                    },
                    {
                      value: '50',
                      label: '50',
                    },
                    {
                      value: '100',
                      label: '100',
                    },
                  ]}
                />
              </GridItem>
            </Grid>
            <Pagination
              currentPage={currentPage}
              pageSize={parseInt(rowsPerPage, 10)}
              totalCount={totalCount}
              onPageChange={setCurrentPage}
            />
          </SimpleGrid>
        </SimpleGrid>
      </Box>
    </Flex>
  )
}
