import { ConversationDto, ConversationWithOptionsDto } from '~shared/types'

import { ApiService } from './ApiService'

const CONVERSATIONS_ENDPOINT = '/conversations'
const PUBLIC_ENDPOINT = '/public'

/**
 * Retrieves a lis of conversations
 * @returns when Conversations available
 * @throws error on non 2xx response
 */
export const getConversations = (): Promise<ConversationDto[]> => {
  return ApiService.get(`${CONVERSATIONS_ENDPOINT}`).then(({ data }) => data)
}

/**
 * Retrieves a lis of conversations for a submission Id
 * @returns when Conversations available
 * @throws error on non 2xx response
 */
export const getConversationsBySubmissionId = (
  submissionId: string,
): Promise<ConversationDto[]> => {
  return ApiService.get(
    `${CONVERSATIONS_ENDPOINT}/submissions/${submissionId}`,
  ).then(({ data }) => data)
}

/**
 * Create a new Conversation
 * @param conversation the parameters for new Conversation
 * @returns when Conversation is created
 * @throws error on non 2xx response
 */
export const createConversation = (
  conversation: ConversationWithOptionsDto,
): Promise<ConversationDto> => {
  return ApiService.post(`${CONVERSATIONS_ENDPOINT}`, {
    conversation,
  }).then(({ data }) => data)
}

/**
 * Create a new Conversation
 * @param conversationWithOptions the parameters for new Conversation
 * @returns when Conversation is created. Receive list of conversations for the submission
 * @throws error on non 2xx response
 */
export const createConversationBySubmission = (
  conversationWithOptions: ConversationWithOptionsDto,
): Promise<ConversationDto[]> => {
  return ApiService.post(`${CONVERSATIONS_ENDPOINT}/submissions`, {
    conversationWithOptions,
  }).then(({ data }) => data)
}

// For citizen users

/**
 * Create a new Conversation
 * @param conversationWithOptions the parameters for new Conversation
 * @returns when Conversation is created. Receive list of conversations for the submission
 * @throws error on non 2xx response
 */
export const createConversationBySubmissionForCitizen = (
  conversationWithOptions: ConversationWithOptionsDto,
): Promise<ConversationDto[]> => {
  return ApiService.post(
    `${PUBLIC_ENDPOINT}${CONVERSATIONS_ENDPOINT}/submissions`,
    {
      conversationWithOptions,
    },
  ).then(({ data }) => data)
}
