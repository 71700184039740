import validator from 'validator'

import { HttpError } from '~services/ApiService'

export const fromObjectToQueryString = (obj: any): string => {
  let queryString = ''

  Object.keys(obj).forEach((key) => {
    queryString = queryString + `${key}=${obj[key]}&`
  })

  if (queryString.substr(queryString.length - 1) === '&') {
    queryString = queryString.substring(0, queryString.length - 1)
  }
  return queryString
}

export const getMappedErrorMessage = (error: Error): string => {
  // check if error is an instance of HttpError to be able to access status code of error
  if (error instanceof HttpError) {
    const errorMessage = `Error - ${error.message}`
    return errorMessage
  }
  // if error is not of type HttpError return the error message encapsulated in Error object
  return 'Something went wrong!'
}

export const tableTemplate = `<div id="pdf-container">
<style>
table {
  border-collapse: collapse;
  width: 100%;
  padding-bottom: 20px;
}

th, td {
  // border: 1px solid #C4C4C4;
  padding-top: -1px;
  padding-bottom: 10px;
  padding-left: 5px;
  text-align: left;
  font-size: 12px;
  word-wrap: break-word;
}

th {
  background-color: #f2f2f2;
}

.component-container {
  break-inside: avoid;
}

@media print {
  .component-container {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}

</style>
  <table>
    <thead>
      <tr class="component-container">
        <% _.forEach(finalOuput.columnTitles, function(title, index) { %>
          <th <% if (finalOuput.columnWidths && finalOuput.columnWidths[index]) { %> style="max-width: <%= finalOuput.columnWidths[index] %>;" <% } %> ><%- title %></th>
        <% }); %>
      </tr>
    </thead>
    <tbody>
      <% _.forEach(finalOuput.answerArray, function(row) { %>
        <tr class="component-container">
          <% _.forEach(row, function(cell, index) { %>
            <td <% if (finalOuput.columnWidths && finalOuput.columnWidths[index]) { %> style="max-width: <%= finalOuput.columnWidths[index] %>;" <% } %> ><%- cell %></td>
          <% }); %>
        </tr>
      <% }); %>
    </tbody>
  </table>
</div>`
