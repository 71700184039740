import { UseControllerProps } from 'react-hook-form'
import validator from 'validator'

const MAX_EMAIL_LENGTH = 30

const MAX_TITLE_LENGTH = 200
const MIN_TITLE_LENGTH = 4

export const FORM_TITLE_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Form name is required',
  minLength: {
    value: MIN_TITLE_LENGTH,
    message: `Form name must be at least ${MIN_TITLE_LENGTH} characters`,
  },
  maxLength: {
    value: MAX_TITLE_LENGTH,
    message: `Form name must be at most ${MAX_TITLE_LENGTH} characters`,
  },
  // pattern: {
  //   value: /^[a-zA-Z0-9_\-./() &`;'"]*$/,
  //   message: 'Form name cannot contain special characters',
  // },
  validate: {
    trimMinLength: (value: string) => {
      return (
        value.trim().length >= MIN_TITLE_LENGTH ||
        `Form name must be at least ${MIN_TITLE_LENGTH} characters`
      )
    },
  },
}

export const FORM_DESCRIPTION_VALIDATION_RULES: UseControllerProps['rules'] = {
  maxLength: {
    value: 500,
    message: `Form description must be at most 500 characters`,
  },
}

export const createAdminEmailValidationTransform = () => {
  const transform = {
    // Combine and display all emails in a single string in the input field.
    input: (value: string[]) => value.join(','),
    // Convert joined email string into an array of emails.
    output: (value: string) =>
      value
        .replace(/\s/g, '')
        .split(',')
        .map((v) => v.trim()),
  }

  const rules: UseControllerProps['rules'] = {
    validate: {
      required: (emails: string[]) => {
        return (
          emails.filter(Boolean).length > 0 ||
          'You must at least enter one email to receive responses'
        )
      },
      valid: (emails: string[]) => {
        return (
          emails.filter(Boolean).every((e) => validator.isEmail(e)) ||
          'Please enter valid email(s) (e.g. me@example.com) separated by commas.'
        )
      },
      duplicate: (emails: string[]) => {
        return (
          new Set(emails).size === emails.length ||
          'Please remove duplicate emails.'
        )
      },
      maxLength: (emails: string[]) => {
        return (
          emails.length <= MAX_EMAIL_LENGTH ||
          'Please limit number of emails to 30.'
        )
      },
    },
  }

  return {
    rules,
    transform,
  }
}

export const SEQUENCE_NUMBER_MAX_LENGTH_RULES: UseControllerProps['rules'] = {
  required: 'Sequence Number Minimum Character Length is required',
  minLength: {
    value: 1,
    message: `Sequence Number Minimum Character Length must be at least 1 character`,
  },
  maxLength: {
    value: 2,
    message: `Sequence Number Minimum Character Length must be at most 2 characters`,
  },
  pattern: {
    value: /^[0-9]*$/,
    message: 'Sequence Number Minimum Character Length should be numeric',
  },
  validate: {
    rangeOfNumber: (value: string) => {
      const condition = parseFloat(value) < 1 || parseFloat(value) > 10
      return (
        !condition ||
        'Sequence Number Minimum Character Length must be between 1 and 10'
      )
    },
  },
}
