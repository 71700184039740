import { useQuery, UseQueryResult } from 'react-query'

import { TitleDto } from '~shared/types'

import { ApiService } from '~services/ApiService'

export const getTitles = async (): Promise<TitleDto[]> =>
  ApiService.get('/public/title').then(({ data }) => data?.titles)

const titleUserPublicKeys = {
  base: ['titleUserPublic'],
}

export const useTitleUserPublic = (): UseQueryResult<TitleDto[], unknown> => {
  return useQuery<TitleDto[]>(titleUserPublicKeys.base, () => getTitles(), {
    // 5 minutes.
    staleTime: 300000,
  })
}
