import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input/index'
import { DataTable } from '~components/Table'

export const ManageRejectReasonsPage = (): JSX.Element | null => {
  type RejectReasonTable = {
    _id?: string
    id?: number
    reason: string
  }

  type ReasonState = {
    agencyReason: {
      agency: {
        agencyId: string
        agencyName: string
      }
      reasonType: {
        code: string
        name: string
      }
      lastModifiedBy: {
        userCategoryCode: string
        userCategoryName: string
        userId: string
        userName: string
      }
      _id: string
      agencyId: string
      reason: string
      created: string
      lastModified: string
      __v: number
    }
  }

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [rejectReason, setRejectReason] = useState<RejectReasonTable[]>([])
  const [createReasonModal, setCreateReasonModal] = useState<boolean>(false)
  const [updateReasonModal, setUpdateReasonModal] = useState<boolean>(false)
  const [deleteReasonModal, setDeleteReasonModal] = useState<boolean>(false)
  const [deletingReasonId, setDeletingReasonId] = useState<number | null>(null)
  const [updatingReasonId, setUpdatingReasonId] = useState<number | null>(null)
  const toast = useToast()

  const rejectReasonFormDefault = {
    reason: '',
  }

  const createReasonFormValues = useForm({
    defaultValues: rejectReasonFormDefault,
  })

  const updateReasonFormValues = useForm({
    defaultValues: rejectReasonFormDefault,
  })

  const [userResult, setUserResult] = useState({
    _id: '',
    username: '',
    title: '',
    gender: '',
    firstName: '',
    lastName: '',
    identificationType: '',
    nicNo: '',
    contact: '',
    email: '',
    userCategory: {
      code: '',
      name: '',
    },
    agency: {
      emailDomain: [],
      phoneNo: [],
      email: [],
      _id: '',
      shortName: '',
      fullName: '',
      logo: '',
      contactPerson: [],
    },
    userRole: {
      code: '',
      name: '',
    },
    createdBy: {
      userCategoryCode: '',
      userCategoryName: '',
      userId: '',
      userName: '',
    },
    lastModifiedBy: {
      userId: '',
      userName: '',
      userCategoryCode: '',
      userCategoryName: '',
    },
    createdAt: '',
    lastModifiedAt: '',
    _v: 0,
    lastAccessed: '',
  })

  async function getAgency() {
    ApiService.get(`/user/session-user`)
      .then((data: any) => {
        if (data?.status === 200) {
          setUserResult(data.data) //result.agency
          setIsLoaded(true)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getAgency()
  }, [])

  const [reasonResult, setReasonResult] = useState<ReasonState[]>([])

  async function getReasons() {
    ApiService.get(`/agency-reasons?reasonType=FR`)
      .then((data: any) => {
        if (data?.status === 200) {
          setReasonResult((reasonResult) => [...reasonResult, data.json])
          mapDataToTable(data.data)
          setIsLoaded(true)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapDataToTable = (array: any[]) => {
    const reasonData: RejectReasonTable[] = []
    array.map((value, index) => {
      const obj = {
        _id: value?._id,
        id: index + 1,
        reason: value?.reason,
      }
      reasonData.push(obj)
    })
    setRejectReason(reasonData)
  }

  useEffect(() => {
    const response = getReasons()
  }, [])

  const onSubmitCreateRejectReason = (data: any) => {
    const agencyReason = {
      agencyId: userResult.agency._id,
      agency: {
        agencyId: userResult.agency._id,
        agencyName: userResult.agency.fullName,
      },
      reasonType: {
        code: 'FR',
        name: 'Form Reject',
      },
      reason: data?.reason,
      lastModifiedBy: {
        userCategoryCode: userResult.userCategory.code,
        userCategoryName: userResult.userCategory.name,
        userId: userResult._id,
        userName: userResult.username,
      },
    }

    const requestBody = {
      agencyReason: agencyReason,
    }

    ApiService.post(`/agency-reasons`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          toast({
            title: '',
            description: 'Agency Reason Created',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          getReasons()
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: error.message,
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })

    setCreateReasonModal(false)
    createReasonFormValues.reset({})
  }

  useEffect(() => {
    if (deletingReasonId !== null) {
      setDeleteReasonModal(true)
    }
  }, [deletingReasonId])

  useEffect(() => {
    if (updatingReasonId !== null) {
      setUpdateReasonModal(true)
      updateReasonFormValues.setValue(
        'reason',
        rejectReason[updatingReasonId ?? 0]?.reason,
      )
    }
  }, [updatingReasonId])

  const onSubmitUpdateRejectReason = (data: any) => {
    const reasonId = rejectReason[updatingReasonId ?? 0]._id

    const agencyReason = {
      agencyId: userResult.agency._id,
      agency: {
        agencyId: userResult.agency._id,
        agencyName: userResult.agency.fullName,
      },
      reasonType: {
        code: 'FR',
        name: 'Form Reject',
      },
      reason: data?.reason,
      lastModifiedBy: {
        userCategoryCode: userResult.userCategory.code,
        userCategoryName: userResult.userCategory.name,
        userId: userResult._id,
        userName: userResult.username,
      },
    }

    const requestBody = {
      agencyReason: agencyReason,
    }

    ApiService.put(`/agency-reasons/${reasonId}`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          toast({
            title: '',
            description: 'Agency Reason Updated',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          getReasons()
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: error.message,
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })

    setUpdateReasonModal(false)
    updateReasonFormValues.reset({})
    setUpdatingReasonId(null)
  }

  const onSubmitDeleteRejectReason = () => {
    const reasonId = rejectReason[deletingReasonId ?? 0]._id

    ApiService.delete<void>(`/agency-reasons/${reasonId}`).then((data: any) => {
      if (data.status === 200) {
        toast({
          title: '',
          description: 'Agency Reason has been deleted',
          duration: 5000,
          isClosable: true,
          status: 'success',
          position: 'top-right',
        })
        getReasons()
      }
    })
  }

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Manage Reject Reasons
          </Heading>
        </Box>
        <Box bg={'white'} p={10} borderRadius="12px" my={5}>
          <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="70px">
            <Box></Box>
            <Box></Box>
            <Flex justify="right">
              <Button onClick={() => setCreateReasonModal(true)}>
                Add Reason
              </Button>
            </Flex>
          </SimpleGrid>
          <DataTable
            heading="Reject Reasons"
            rowValues={rejectReason ? rejectReason : []}
            cols={[
              {
                Header: 'No.',
                accessor: 'id',
                disableSortBy: true,
                maxWidth: 100,
                minWidth: 80,
                width: 80,
              },
              {
                Header: 'Reason',
                accessor: 'reason',
                disableSortBy: true,
                maxWidth: 400,
                minWidth: 140,
              },
              {
                Header: 'Action',
                Cell: (props: any) => (
                  <ButtonGroup variant="outline" spacing="1" padding={2}>
                    <Button
                      onClick={() => {
                        setUpdatingReasonId(props.row.id)
                        setUpdateReasonModal(true)
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => {
                        setDeletingReasonId(props.row.id)
                        setDeleteReasonModal(true)
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                ),
              },
            ]}
          />
        </Box>
      </Box>

      {createReasonModal ? (
        <Modal
          isOpen={createReasonModal}
          onClose={() => {
            setCreateReasonModal(false)
            createReasonFormValues.reset({})
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={createReasonFormValues.handleSubmit(
                onSubmitCreateRejectReason,
              )}
            >
              <ModalCloseButton />
              <ModalHeader>Add Reason</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        createReasonFormValues?.formState?.errors?.reason
                          ? true
                          : false
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="reason" mb="8px">
                        Reason*
                      </FormLabel>
                      <Input
                        placeholder={'Reason'}
                        id="reason"
                        maxLength={100}
                        {...createReasonFormValues.register('reason', {
                          required: 'Reason is Required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {createReasonFormValues.formState.errors.reason && (
                        <FormErrorMessage>
                          {
                            createReasonFormValues.formState.errors.reason
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button type="submit">Save</Button>
                  <Button
                    onClick={() => {
                      setCreateReasonModal(false)
                      createReasonFormValues.reset({})
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      ) : null}
      {deleteReasonModal ? (
        <Modal
          isOpen={deleteReasonModal}
          onClose={() => {
            setDeleteReasonModal(false)
            setDeletingReasonId(null)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to delete?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (deletingReasonId !== null) {
                      onSubmitDeleteRejectReason()
                      setDeletingReasonId(null)
                      setDeleteReasonModal(false)
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setDeleteReasonModal(false)
                    setDeletingReasonId(null)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
      {updateReasonModal ? (
        <Modal
          isOpen={updateReasonModal}
          onClose={() => {
            setUpdateReasonModal(false)
            updateReasonFormValues.reset({})
            setUpdatingReasonId(null)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={updateReasonFormValues.handleSubmit(
                onSubmitUpdateRejectReason,
              )}
            >
              <ModalCloseButton />
              <ModalHeader>Update Reason</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        updateReasonFormValues?.formState?.errors?.reason
                          ? true
                          : false
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="reason" mb="8px">
                        Reason*
                      </FormLabel>
                      <Input
                        placeholder={'Reason'}
                        id="reason"
                        maxLength={100}
                        {...updateReasonFormValues.register('reason', {
                          required: 'Reason is Required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {updateReasonFormValues.formState.errors.reason && (
                        <FormErrorMessage>
                          {
                            updateReasonFormValues.formState.errors.reason
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button type="submit">Update</Button>
                  <Button
                    onClick={() => {
                      setUpdateReasonModal(false)
                      updateReasonFormValues.reset({})
                      setUpdatingReasonId(null)
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
