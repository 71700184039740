// TODO: Add correct PASSWORD_POLICY_ROUTE path, or move it to constants/externalLinks accordingly
export const LANDING_ROUTE = '/'
// export const ROOT_ROUTE = '/'
// export const LOGIN_ROUTE = '/welcome'
export const ROOT_ROUTE = '/workspace'
export const DASHBOARD_ROUTE = '/dashboard'
export const AGENCY_DASHBOARD_ROUTE = '/agent/dashboard'
export const LOGIN_ROUTE = '/welcome'
export const IAM_GROUP_ROUTE_WSO2 = '/iam'
export const LOGIN_ROUTE_IAS_WSO2 = 'signin'
export const LOGOUT_ROUTE_IAS_WSO2 = 'signout'
export const LOGIN_FIRST_TIME = 'first'
export const FORGOT_USERNAME_PASSWORD = 'forgot'

export const TOU_ROUTE = '/terms'
export const TOC_ROUTE = '/terms-and-conditions'
export const UM_ROUTE = '/user-manual'
export const PRIVACY_POLICY_ROUTE = '/privacy'
export const PASSWORD_POLICY_ROUTE = '/password'

// Cannot use regex match in react-router@6, which means we need to validate
// the regex in PublicFormPage.
export const PUBLICFORM_ROUTE = '/:formId'
export const VIEW_FORM_ROUTE = '/:formId/preview'
export const FORMID_REGEX = /^([a-fA-F0-9]{24})$/

export const ADMINFORM_ROUTE = '/agent/form'
/** Build tab has no subroute, its the index admin form route. */
export const ADMINFORM_BUILD_SUBROUTE = ''
export const ADMINFORM_SETTINGS_SUBROUTE = 'settings'
export const ADMINFORM_RESULTS_SUBROUTE = 'results'
export const ADMINFORM_SUBMISSIONS_SUBROUTE = 'submissions'

/** Responses tab has no subroute, its the index results route. */
export const RESULTS_RESPONSES_SUBROUTE = ''
export const RESULTS_FEEDBACK_SUBROUTE = 'feedback'

export const ITCA_SETTINGS = 'settings'
export const ITCA_TITLE_SETTINGS = 'settings/titles'
export const ITCA_FORM_MASTER_DATA = 'settings/master-data'
export const ITCA_FORM_MASTER_DATA_ADD_UPDATE =
  '/settings/master-data/add-update'

/** Agency pages. */
export const AGENCY_CREATE = '/agency/create'
export const CREATE_USER_ADMIN = '/user/admin/create'
export const AGENCY_VIEW = '/agency/view'
export const AGENCY_UPDATE = '/agency/update'
export const AGENCY_MANAGE = '/agency'

/** View Citizen pages */
export const VIEW_CITIZEN = 'view-citizen'

/** User pages */
export const VIEW_USER_PROFILE = '/view-user-profile'
export const UPDATE_USER_PROFILE = '/update-user-profile'

/** Activity Log page */
export const ACTIVITY_LOG = 'activity-log'

/** Reject Reason pages */
export const MANAGE_REJECT_REASONS = '/manage-reject-reasons'

/** Templates pages */
export const MANAGE_TEMPLATES = 'agent/templates'
export const VIEW_TEMPLATES = 'agent/templates/view'
export const VIEW_FORMS_PREVIEW = '/agent/forms/view'
export const AGENT_MANAGE_FORMS = '/agent/forms'

/** Notification */
export const NOTIFICATION = '/notification'
export const UPDATE_NOTIFICAION = '/notification/update'

/** User Manage pages */
export const USER_CREATE = '/user/create'
export const VIEW_USER = '/user/view'
export const USER_MANAGE = '/user'
export const USER_UPDATE = '/user/update'

/** Agency User Manage pages */
export const AGENCY_USER_CREATE = '/agent/user/create'
export const AGENCY_VIEW_USER = '/agent/user/view'
export const AGENCY_USER_MANAGE = '/agent/user'
export const AGENCY_USER_UPDATE = '/agent/user/update'

/* citizen routes */
export const CITIZEN_LANDING_ROUTE = '/'
export const CITIZEN_ROOT_ROUTE = '/citizen/dashboard'
export const CITIZEN_LOGIN_ROUTE = '/welcome/public'
export const CITIZEN_IAM_GROUP_ROUTE_WSO2 = '/public/iam'
export const CITIZEN_LOGIN_ROUTE_IAS_WSO2 = 'signin'
export const CITIZEN_LOGOUT_ROUTE_IAS_WSO2 = 'signout'
export const CITIZEN_USER_ONBOARD = 'profile'
export const CITIZEN_FORGOT_USERNAME_PASSWORD = 'forgot'
export const CITIZEN_PAYMENT_PROCESSING_PAGE = '/process-payment'
export const CITIZEN_PAYMENT_PAY_AGAIN_PAGE =
  '/:formId/submission/:submissionId/pay-again'

/* submission routes */
export const VIEW_SUBMISSION = ':formId/submission/:submissionId'
export const VIEW_SUBMISSION_AGENCY = '/report/:formId/submission/:submissionId'
export const CITIZEN_DASHBOARD_ROUTE = '/citizen/dashboard'
export const CITIZEN_VIEW_USER_PROFILE = '/citizen/view-user-profile'
export const CITIZEN_UPDATE_USER_PROFILE = '/citizen/update-user-profile'
export const CITIZEN_VIEW_GOVERMENT_AGENCY = '/citizen/view-goverment-agency'
export const CITIZEN_ONBOARDING_SCREEN = 'citizen/onboarding-screen'
export const VIEW_SUBMISSION_CITIZEN =
  '/citizen/:formId/submission/:submissionId'
/** Report pages */
export const ADMIN_PROGRESS_REPORT = 'reports/admin-progress-reports'
export const AGENCY_PROGRESS_REPORT = 'reports/agency-progress-reports'
export const ADMIN_ARCHIVED_REPORT = 'reports/admin-archived-reports'
export const AGENCY_ARCHIVED_REPORT = 'reports/agency-archived-reports'
export const ADMIN_UNPUBLISHED_REPORT = 'reports/admin-unpublished-reports'
export const AGENCY_UNPUBLISHED_REPORT = 'reports/agency-unpublished-reports'
export const AGENCY_RECONCILlIATION_REPORT =
  'reports/agency-reconciliation-reports'

/** Conversation routes */
export const CONVERSATION_MESSAGE_TYPES =
  '/settings/manage-conversation-message-types'

export const VIEW_AGENCY_DETAILS = '/agent/view-agency'
export const UPDATE_AGENCY_DETAILS = '/agent/update-agency'
