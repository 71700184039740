import { z } from 'zod'
import { Opaque } from 'type-fest'

import { UserDto } from './user'

export type MasterDataId = Opaque<string, 'MasterDataId'>
export const MasterDataId = z.string() as unknown as z.Schema<MasterDataId>

export enum MasterDataCategory {
  OTHER = 'OTHER',
  DISTRICT = 'DISTRICT',
  PROVINCE = 'PROVINCE',
  COUNTRY = 'COUNTRY',
  CITY = 'CITY',
}

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const MasterDataBase = z.object({
  masterType: z.string(),
  option: z.string(),
  category: z.nativeEnum(MasterDataCategory),
  parent: MasterDataId.optional(),
  orderIndex: z.number().optional(),
  createdAt: z.date(),
  lastModifiedAt: z.date(),
  createdBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(), // TODO: change to DTO shape
  }),
  lastModifiedBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(),
  }),
})
export type MasterDataBase = z.infer<typeof MasterDataBase>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const MasterDataOption = z.object({
  option: z.string(),
  parent: MasterDataId.optional(),
})
export type MasterDataOption = z.infer<typeof MasterDataOption>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const MasterDataTypeBase = z.object({
  masterType: z.string(),
  category: z.nativeEnum(MasterDataCategory),
  options: z.array(MasterDataOption),
})
export type MasterDataTypeBase = z.infer<typeof MasterDataTypeBase>

// Convert to serialized versions.
export const MasterDataDto = MasterDataBase.extend({
  _id: MasterDataId,
  parent: MasterDataBase.extend({
    _id: MasterDataId,
  }),
  createdBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
  lastModifiedBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
})
export type MasterDataDto = z.infer<typeof MasterDataDto>
