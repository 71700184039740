import { memo, useEffect, useRef, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Skeleton,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { jsPDF } from 'jspdf'
import { isArray } from 'lodash'
import moment from 'moment-timezone'
import { useRecoilState, useRecoilValue } from 'recoil'

import { SubmissionStatus } from '~shared/types'

import { useToast } from '~hooks/useToast'
import Button from '~components/Button'

import { LanguageFilter } from '../../../../../atom'

import { CancelModal } from './CancelModal'
import { ConversationMessageModel } from './ConversationMessageModel'
import { useMutateDownloadAttachments } from './mutations'
import {
  useIndividualSubmission,
  useSubmissionConversations,
  useSubmissionPayments,
} from './queries'

export const IndividualResponseCitizenPage = (): JSX.Element => {
  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    })
    return day + ' ' + month + ' ' + year + ', ' + time
  }

  const { formId, submissionId } = useParams()
  if (!formId) throw new Error('Missing formId')
  if (!submissionId) throw new Error('Missing submissionId')
  const navigate = useNavigate()

  const { data, isLoading, isError } = useIndividualSubmission()

  const submissionPayments = useSubmissionPayments()
  const submissionConversations = useSubmissionConversations(submissionId)
  const conversations = isArray(submissionConversations?.data)
    ? submissionConversations?.data
    : []

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  const [signatureImage, setSignatureImage] = useState<string>('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)

  console.log(ref.current)
  const exportPdf = () => {
    const doc = new jsPDF('p', 'pt')
    savePdf(doc)
  }
  const savePdf = (doc: any) => {
    doc.html(ref.current, {
      callback: function (doc: any) {
        doc.save('view-submission.pdf')
      },
    })
  }

  useEffect(() => {
    if (data?.signature) {
      setSignatureImage(data.signature?.value)
    }
  }, [data])

  const locale: any = useRecoilValue(LanguageFilter)

  const messages: any = {
    en: {
      responseID: 'Response ID:',
      timestamp: 'Timestamp:',
      attachments: 'Attachments:',
      formSubmissionDetails: 'Form Submission Details',
      submissionID: 'Submission ID:',
      serialNumber: 'Serial Number:',
      createdAts: 'Created at:',
      submittedAt: 'Submitted at:',
      submissionStatus: 'Submission Status:',
      govAgencyName: 'Gov. Agency Name:',
      formName: 'Form Name:',
      formDescription: 'Form Description:',
      formLanguage: 'Form Language:',
      acceptedAt: 'Accepted at:',
      acceptedNote: 'Accepted Note:',
      completedAt: 'Completed at:',
      completedNote: 'Completed Note:',
      rejectedAt: 'Rejected at:',
      rejectedReason: 'Rejected Reason:',
      rejectedNote: 'Rejected Note:',
      completedAttachment: 'Completed Attachment:',
      rejectedAttachment: 'Rejected Attachment:',
      paymentDetails: 'Payment Details',
      noPayments: 'No payment details to show',
      paymentAt: 'Payment at:',
      paymentStatus: 'Payment Status:',
      paymentAmount: 'Payment Amount (LKR):',
      more: 'More',
      paymentType: 'Payment Type:',
      paymentTransaction: 'Payment Transaction ID:',
      paymentRequest: 'Payment Transaction Request:',
      paymentRefunded: 'Payment Refunded:',
      paymentRefundedAt: 'Payment Refunded at:',
      paymentRefundedNote: 'Payment Refunded Note:',
      conversation: 'Conversation',
      messageType: 'Message Type:',
      message: 'Message:',
      viewSubmission: 'View Form Submission',
      cancel: 'Cancel',
      inquiry: 'Inquiry:',
      repayment: 'Retry Payment',
      archivedDate: 'Archived Date:',
      cancelConfirmationMessage: 'Are you sure you want to cancel?',
      cancelHeaderMessage: 'Confirm',
      cancelYesMessage: 'Yes',
      cancelNoMessage: 'No',
      signature: 'Signature:',
      signDate: 'Signed Date:',
    },
    si: {
      responseID: 'ප්‍රතිචාර ID:',
      timestamp: 'වේලා මුද්රාව:',
      attachments: 'ඇමුණුම්:',
      formSubmissionDetails: 'පෝරමය ඉදිරිපත් කිරීමේ විස්තර',
      submissionID: 'ඉදිරිපත් කිරීමේ ID:',
      serialNumber: 'අනු අංකය:',
      createdAts: 'දී නිර්මාණය කරන ලදී:',
      submittedAt: 'දී ඉදිරිපත් කරන ලදී:',
      submissionStatus: 'ඉදිරිපත් කිරීමේ තත්ත්වය:',
      govAgencyName: 'රජයේ නියෝජිතායතනයේ නම:',
      formName: 'පෝරමයේ නම:',
      formDescription: 'පෝරමය විස්තරය:',
      formLanguage: 'ආකෘති භාෂාව:',
      acceptedAt: 'දී පිළිගත්තා:',
      acceptedNote: 'පිළිගත් සටහන:',
      completedAt: 'දී නිම කරන ලදී:',
      completedNote: 'සම්පූර්ණ කළ සටහන:',
      rejectedAt: 'දී ප්රතික්ෂේප කරන ලදී:',
      rejectedReason: 'ප්‍රතික්ෂේප කළ හේතුව:',
      rejectedNote: 'ප්‍රතික්ෂේප කළ සටහන:',
      completedAttachment: 'සම්පූර්ණ කළ ඇමුණුම්:',
      rejectedAttachment: 'ප්‍රතික්ෂේප කළ ඇමුණුම්:',
      paymentDetails: 'ගෙවීම් තොරතුරු:',
      noPayments: 'පෙන්වීම සදහා ගෙවීම් තොරතුරු  නොමැත',
      paymentAt: 'දී ගෙවීම:',
      paymentStatus: 'ගෙවීම් තත්ත්වය:',
      paymentAmount: 'ගෙවීම් මුදල (LKR):',
      more: 'තව',
      paymentType: 'ගෙවීම් වර්ගය:',
      paymentTransaction: 'ගෙවීම් ගනුදෙනු හැඳුනුම්පත:',
      paymentRequest: 'ගෙවීම් ගනුදෙනු ඉල්ලීම:',
      paymentRefunded: 'ගෙවීම ආපසු ගෙවන ලදී:',
      paymentRefundedAt: 'ගෙවීම ආපසු ගෙවන ලදී:',
      paymentRefundedNote: 'ආපසු ගෙවීමේ  සටහන:',
      conversation: 'සංවාදය',
      messageType: 'පණිවිඩ වර්ගය:',
      message: 'පණිවුඩය:',
      viewSubmission: 'පෝරමය ඉදිරිපත් කිරීම',
      cancel: 'අවලංගු කරන්න',
      inquiry: 'විමසීම:',
      repayment: 'නැවත ගෙවීමට උත්සාහ කරන්න',
      archivedDate: 'සංරක්ෂිත දිනය:',
      cancelConfirmationMessage: 'ඔබට අවලංගු කිරීමට අවශ්‍ය බව විශ්වාසද?',
      cancelHeaderMessage: 'තහවුරු කරන්න',
      cancelYesMessage: 'ඔව්',
      cancelNoMessage: 'නැත',
      signature: 'අත්සන:',
      signDate: 'අත්සන් කළ දිනය:',
    },
    ta: {
      responseID: 'பதில் ஐடி:',
      timestamp: 'நேர முத்திரை:',
      attachments: 'இணைப்புகள்:',
      formSubmissionDetails: 'படிவம் சமர்ப்பிப்பு விவரங்கள்',
      submissionID: 'சமர்ப்பிப்பு ஐடி:',
      serialNumber: 'தொடர் எண்:',
      createdAts: 'இல் உருவாக்கப்பட்டது:',
      submittedAt: 'இல் சமர்ப்பிக்கப்பட்டது:',
      submissionStatus: 'சமர்ப்பிப்பு நிலை:',
      govAgencyName: 'அரசு ஏஜென்சி பெயர்:',
      formName: 'படிவத்தின் பெயர்:',
      formDescription: 'படிவம் விளக்கம்:',
      formLanguage: 'வடிவ மொழி:',
      acceptedAt: 'இல் ஏற்றுக்கொள்ளப்பட்டது:',
      acceptedNote: 'ஏற்றுக்கொள்ளப்பட்ட குறிப்பு:',
      completedAt: 'மணிக்கு முடிக்கப்பட்டது:',
      completedNote: 'பூர்த்தி செய்யப்பட்ட குறிப்பு:',
      rejectedAt: 'இல் நிராகரிக்கப்பட்டது:',
      rejectedReason: 'நிராகரிக்கப்பட்ட காரணம்:',
      rejectedNote: 'நிராகரிக்கப்பட்ட குறிப்பு:',
      completedAttachment: 'பூர்த்தி செய்யப்பட்ட இணைப்புக்கள்:',
      rejectedAttachment: 'நிராகரிக்கப்பட்ட இணைப்பு:',
      paymentDetails: 'கட்டண விவரங்கள்',
      noPayments: 'கட்டண விவரங்கள் காட்டுவதற்கு இல்லை',
      paymentAt: 'இல் பணம் செலுத்துதல்:',
      paymentStatus: 'கட்டண நிலை:',
      paymentAmount: 'கட்டணத் தொகை (LKR):',
      more: 'மேலும்',
      paymentType: 'கட்டணம் வகை:',
      paymentTransaction: 'கட்டண பரிவர்த்தனை ஐடி:',
      paymentRequest: 'கட்டண பரிவர்த்தனை கோரிக்கை:',
      paymentRefunded: 'பணம் திருப்பியளிக்கப்பட்டது:',
      paymentRefundedAt: 'பணம் திருப்பியளிக்கப்பட்டது:',
      paymentRefundedNote: 'திரும்பப் பெறப்பட்ட குறிப்பு:',
      conversation: 'உரையாடல்',
      messageType: 'செய்தி வகை:',
      message: 'செய்தி:',
      viewSubmission: 'படிவம் சமர்ப்பிப்பு',
      cancel: 'ரத்து செய்',
      inquiry: 'விசாரணை:',
      repayment: 'கட்டணம் செலுத்த மீண்டும் முயற்சிக்க',
      archivedDate: 'காப்பகப்படுத்தப்பட்ட தேதி:',
      cancelConfirmationMessage: 'நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா?',
      cancelHeaderMessage: 'உறுதி',
      cancelYesMessage: 'ஆம்',
      cancelNoMessage: 'இல்லை',
      signature: 'கையெழுத்து:',
      signDate: 'கையொப்பமிட்ட தேதி:',
    },
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    // <Flex flexDir="column" marginTop={{ base: '-1.5rem', md: '-3rem' }}>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Flex flexDir="column">
        {/* <IndividualResponseNavbar /> */}
        <Box height="40px">
          <Heading as="h4" size="md">
            <FormattedMessage
              id="viewSubmission"
              values={{ locale }}
            ></FormattedMessage>
          </Heading>
        </Box>

        <Stack
          px={{ md: '1.75rem', lg: '2rem' }}
          spacing={{ base: '1.5rem', md: '2.5rem' }}
          ref={ref}
        >
          <Stack
            bg="primary.100"
            p="1.5rem"
            sx={{
              fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
            }}
          >
            <Text textStyle="h4">
              <FormattedMessage
                id="formSubmissionDetails"
                values={{ locale }}
              ></FormattedMessage>
            </Text>

            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="serialNumber"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.serialNumber ?? '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="createdAts"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.submissionTime ?? '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="submittedAt"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.submissionTime ?? '-'}
              </Skeleton>
            </Stack>

            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="submissionStatus"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.submissionStatus
                  ? `${data?.submissionStatus} (${
                      data?.submissionStatus === SubmissionStatus.Completed
                        ? data?.completedAt
                          ? formatDate(data.completedAt.toString())
                          : '-'
                        : data?.submissionStatus === SubmissionStatus.Rejected
                        ? data?.rejectedAt
                          ? formatDate(data?.rejectedAt.toString())
                          : '-'
                        : data?.submissionStatus === SubmissionStatus.InProgress
                        ? data?.acceptedAt
                          ? formatDate(data?.acceptedAt.toString())
                          : '-'
                        : data?.submissionTime
                    })`
                  : '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="govAgencyName"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.agencyName ?? '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="formName"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.formName ?? '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="formDescription"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.formDescription ?? '-'}
              </Skeleton>
            </Stack>
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="formLanguage"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.formLanguage ?? '-'}
              </Skeleton>
            </Stack>

            {data?.submissionStatus === 'Inprogress' ? (
              <>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="acceptedAt"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.acceptedAt
                      ? formatDate(data.acceptedAt.toString())
                      : '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="acceptedNote"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.acceptedNote ?? '-'}
                  </Skeleton>
                </Stack>
              </>
            ) : null}

            {data?.submissionStatus === 'Completed' ? (
              <>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="acceptedAt"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.acceptedAt
                      ? formatDate(data.acceptedAt.toString())
                      : '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="acceptedNote"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.acceptedNote ?? '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="completedAt"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.completedAt
                      ? formatDate(data.completedAt.toString())
                      : '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="completedNote"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.completedNote ?? '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="completedAttachment"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.completedAttachment ? (
                      <Link
                        href={`${data.completedAttachment}`}
                        download="Completed Attachment"
                      >
                        Click to download
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Skeleton>
                </Stack>
              </>
            ) : null}

            {data?.submissionStatus === 'Rejected' ? (
              <>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="rejectedAt"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.rejectedAt
                      ? formatDate(data?.rejectedAt.toString())
                      : '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="rejectedReason"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.rejectedReason ?? '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="rejectedNote"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.rejectedNote ?? '-'}
                  </Skeleton>
                </Stack>
                <Stack
                  spacing={{ base: '0', md: '0.5rem' }}
                  direction={{ base: 'column', md: 'row' }}
                >
                  <Text as="span" textStyle="subhead-1">
                    <FormattedMessage
                      id="rejectedAttachment"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    {data?.rejectedAttachment ? (
                      <Link
                        href={`${data.rejectedAttachment}`}
                        download="Rejected Attachment"
                      >
                        Click to download
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Skeleton>
                </Stack>
              </>
            ) : null}

            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Text as="span" textStyle="subhead-1">
                <FormattedMessage
                  id="archivedDate"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>
              <Skeleton isLoaded={!isLoading && !isError}>
                {data?.archivedDate
                  ? formatDate(data.archivedDate.toString())
                  : '-'}
              </Skeleton>
            </Stack>

            {data?.paymentRequired ? (
              <>
                <Stack
                  bg="primary.100"
                  p="1.5rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Text textStyle="h4">
                    <FormattedMessage
                      id="paymentDetails"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>

                  {submissionPayments?.data?.length === 0 ? (
                    <Text>
                      <FormattedMessage
                        id="noPayments"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                  ) : null}

                  {submissionPayments?.data?.map((item, index) => (
                    <>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentAt"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>
                          {item?.created
                            ? formatDate(item.created.toString())
                            : '-'}
                        </Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentStatus"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentStatus}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentAmount"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentAmount}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentType"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentType?.paymentTypeName}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentTransaction"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentTxnId}</Text>
                      </Stack>

                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              <FormattedMessage
                                id="more"
                                values={{ locale }}
                              ></FormattedMessage>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                <FormattedMessage
                                  id="paymentRequest"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Text>
                              <Text>{item?.paymentTxnRequest}</Text>
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Divider p="1.5rem" />
                    </>
                  ))}
                </Stack>

                <Stack
                  bg="primary.100"
                  p="1.5rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefunded"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefunded ? 'Yes' : 'No'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefundedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedAt
                        ? formatDate(data.paymentRefundedAt)
                        : 'N/A'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefundedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedNote ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                </Stack>
              </>
            ) : null}
          </Stack>

          {signatureImage !== '' ? (
            <Stack bg="primary.100" p="1.5rem" mt={10}>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="signature"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Center
                  bg={'none'}
                  // h="154px"
                  // w="424px"
                  maxW={{ base: '100%', md: '424px' }}
                  maxH={{ base: '160px', md: 'auto' }}
                  borderWidth="1px"
                  borderColor="gray.400"
                  borderStyle="dashed"
                  borderRadius="12px"
                >
                  <Image
                    // h="150px"
                    // w="420px"
                    // m={'2px'}
                    maxW={{ base: '100%', md: '420px' }}
                    maxH={{ base: '150px', md: 'auto' }}
                    objectFit="contain"
                    src={signatureImage}
                  />
                </Center>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="signDate"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submissionTime ?? 'N/A'}
                </Skeleton>
              </Stack>
            </Stack>
          ) : null}

          <Stack bg="primary.100" p="1.5rem">
            <Text textStyle="h4">
              <FormattedMessage
                id="conversation"
                values={{ locale }}
              ></FormattedMessage>
            </Text>
            {/* Load conversation history */}
            {conversations.map((conversation, i) => {
              return (
                <Box key={i}>
                  <SimpleGrid
                    columns={{ sm: 1, md: 4 }}
                    spacing="5px"
                    pt={i === 0 ? '10px' : '40px'}
                  >
                    <GridItem>
                      <Text
                        as="span"
                        textStyle="subhead-1"
                        style={{ fontWeight: 700 }}
                      >
                        {conversation?.conversationType?.code === 'CQ' ? (
                          <FormattedMessage
                            id="inquiry"
                            values={{ locale }}
                          ></FormattedMessage>
                        ) : (
                          <FormattedMessage
                            id="message"
                            values={{ locale }}
                          ></FormattedMessage>
                        )}
                      </Text>
                    </GridItem>
                    <GridItem>
                      {conversation?.conversationType?.code === 'CQ'
                        ? conversation?.senderPublicUser?.name
                        : conversation?.agency?.name}
                    </GridItem>
                    {/* By assuming the server time is in Sri Lankan time zone */}
                    <GridItem>
                      {moment(conversation?.createdAt).format('DD/MM/YY')}
                    </GridItem>
                    <GridItem>
                      {moment(conversation?.createdAt).format('HH:mm')}
                    </GridItem>
                  </SimpleGrid>
                  <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                    <GridItem>
                      <Text as="span" textStyle="subhead-1">
                        <FormattedMessage
                          id="messageType"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Text>
                    </GridItem>
                    <GridItem colSpan={3}>
                      {conversation?.message?.type}
                    </GridItem>
                  </SimpleGrid>
                  <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                    <GridItem>
                      <Text as="span" textStyle="subhead-1">
                        <FormattedMessage
                          id="message"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Text>
                    </GridItem>
                    <GridItem colSpan={3}>
                      {conversation?.message?.content}
                    </GridItem>
                  </SimpleGrid>
                </Box>
              )
            })}
            {/* Only show for relevant status and collaborator */}
            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt="10px">
              <GridItem colSpan={3}></GridItem>
              <GridItem>
                <ConversationMessageModel
                  formId={formId}
                  agencyId={data?.agencyId}
                  agencyName={data?.agencyName}
                  submissionId={submissionId}
                  formCollaborators={'formCollaborators'}
                  submissionStatus={data?.submissionStatus}
                  isFormArchived={data?.isFormArchived}
                />
              </GridItem>
            </SimpleGrid>
          </Stack>
          <Center>
            <ButtonGroup>
              <Button
                onClick={() => {
                  setIsCancelModalOpen(true)
                }}
              >
                <FormattedMessage
                  id="cancel"
                  values={{ locale }}
                ></FormattedMessage>
              </Button>
              {submissionPayments?.data ? (
                (data?.submissionStatus === 'PaymentFailed' &&
                  submissionPayments?.data[0]?.paymentStatus === 'Failed') ||
                (data?.submissionStatus === 'PaymentPending' &&
                  !submissionPayments?.data[0]?.paymentStatus) ? (
                  <Button
                    onClick={() => {
                      navigate(
                        `/${formId}/submission/${submissionId}/pay-again`,
                      )
                    }}
                  >
                    <FormattedMessage
                      id="repayment"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                ) : null
              ) : null}
            </ButtonGroup>
          </Center>
        </Stack>
        {isCancelModalOpen ? (
          <CancelModal
            isOpen={isCancelModalOpen}
            onClose={() => {
              setIsCancelModalOpen(false)
            }}
            onYesClick={async () => {
              navigate('/citizen/dashboard')
            }}
            onNoClick={() => {
              setIsCancelModalOpen(false)
            }}
            confirmationMessage={
              <FormattedMessage
                id="cancelConfirmationMessage"
                values={{ locale }}
              ></FormattedMessage>
            }
            headerMessage={
              <FormattedMessage
                id="cancelHeaderMessage"
                values={{ locale }}
              ></FormattedMessage>
            }
            yesButton={
              <FormattedMessage
                id="cancelYesMessage"
                values={{ locale }}
              ></FormattedMessage>
            }
            noButton={
              <FormattedMessage
                id="cancelNoMessage"
                values={{ locale }}
              ></FormattedMessage>
            }
          />
        ) : null}
      </Flex>
    </IntlProvider>
  )
}
