import { Merge, Opaque } from 'type-fest'
import { z } from 'zod'
import { AgencyDto } from './agency'
import { UserDto } from './user'

export type AgencyNotificationId = Opaque<string, 'AgencyNotificationId'>
export const AgencyNotificationId =
  z.string() as unknown as z.Schema<AgencyNotificationId>

export enum NotificationType {
  FS = 'Submitted',
  FA = 'Accepted',
  FR = 'Rejected',
  FC = 'Completed',
  PF = 'Payment Failed',
  PS = 'Payment Success',
  FSS = 'Form Submission',
  FAA = 'Form Accepted',
  FRR = 'Form Rejected',
  FCC = 'Form Completed',
}

export type AgencyNotificationAgency = {
  agencyId: AgencyDto['_id']
  agencyName: string
}

export type AgencyNotificationNotificationType = {
  code: NotificationType
  name: string
}

export type AgencyNotificationEmailNotification = {
  subject?: string
  body?: string
}

export type AgencyNotificationLastModifiedBy = {
  userCategoryCode: string
  userCategoryName: string
  userId: UserDto['_id']
  userName: string
}

/**
 * Typing for individual agency notification
 */
export type AgencyNotificationBase = {
  // _id: AgencyNotificationId
  agencyId: AgencyDto['_id']
  agency: AgencyNotificationAgency
  notificationType: AgencyNotificationNotificationType
  appendNotification?: string
  emailNotification?: AgencyNotificationEmailNotification
  smsNotification?: string
  smsNotificationEnable: boolean
  emailNotificationEnable: boolean
  created?: Date
  lastModified?: Date
  lastModifiedBy: AgencyNotificationLastModifiedBy
}

export type AgencyNotificationDto = Merge<
  AgencyNotificationBase,
  { created?: Date; lastModified?: Date }
>
