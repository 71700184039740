import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { isEmpty, isString, template } from 'lodash'

import { VIEW_SUBMISSION_CITIZEN } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { DateInput } from '~components/DatePicker/DateInput'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

import { tableTemplate } from '~pages/AdminReports/utils'

import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const AgencyProgressReport = () => {
  type progressReportTable = {
    rowNumber: number
    _formId: string
    formTitle: string
    receivedCount: number
    unattendedCount: number
    inProgressCount: number
    rejectedCount: number
    completedCount: number
  }
  type progressReportSubmissionTable = {
    rowNumber: number
    submissionID: string
    serialNumber: string
    submittedAt: string
    submittedBy: string
    status: string
    statusAt: string
  }

  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [IsSubmissionLoading, setIsSubmissionLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSubmissionPage, setCurrentSubmissionPage] = useState(1)
  const [selectedId, setSelectedId] = useState<string>('')
  const [selectedName, setSelectedName] = useState<string>('')
  const [submissionCurrentPage, setSubmissionCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [rowsSubmissionPerPage, setRowsSubmissionPerPage] = useState('25')
  const [queryObject, setQueryObject] = useState<string>('')
  const [queryObjectMore, setQueryObjectMore] = useState<string>('')
  const [progressReportCount, setProgressReportCount] = useState<number>(0)
  const [progressReportSubmissionCount, setProgressReportSubmissionCount] =
    useState<number>(0)
  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const [progressReportTableData, setProgressReportTableData] = useState<
    progressReportTable[]
  >([])
  const [allProgressReportTableData, setAllProgressReportTableData] = useState<
    progressReportTable[]
  >([])

  const [
    progressReportSubmissionTableData,
    setProgressReportSubmissionTableData,
  ] = useState<progressReportSubmissionTable[]>([])
  const [
    allProgressReportSubmissionTableData,
    setAllProgressReportSubmissionTableData,
  ] = useState<progressReportSubmissionTable[]>([])

  const [formDropdownData, setFormDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [finalTemplateObj, setFinalTemplateObj] = useState<any>()

  // set values for more area
  const setMoreValue = (selectedId: string, selectedName: string) => {
    setSelectedId(selectedId)
    setSelectedName(selectedName)
  }

  // dropdown api call
  const formNameDropDown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value?._id,
        value: value?._id,
        label: value?.title,
      }
      currentAgencyData.push(obj)
    })
    setFormDropdownData(currentAgencyData)
  }

  const callDropDown = () => {
    ApiService.get(`/report/allforms?agencyId=${loggedUserAgencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            formNameDropDown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
      }

      if (loggedUserDetails?._doc?.agency) {
        setLoggedUserAgencyId(loggedUserDetails?._doc?.agency)
      }
    }
  }, [])

  useEffect(() => {
    if (loggedUserAgencyId) {
      callDropDown()
    }
  }, [loggedUserAgencyId])

  // forms funtions
  const mainFormValues = useForm({
    defaultValues: {
      formId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const progressReportSubmissionValues = useForm({
    defaultValues: {
      status: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const fromDateMain = mainFormValues.watch('createdFrom')
  const fromDateMore = progressReportSubmissionValues.watch('submittedFrom')

  const mapDataToTable = (array: any[]) => {
    const progressReportData: progressReportTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        formTitle: value?.formTitle.toLowerCase(),
        formTitleStr: value?.formTitle,
        receivedCount: value?.receivedCount,
        unattendedCount: value?.unattendedCount,
        inProgressCount: value?.inProgressCount,
        rejectedCount: value?.rejectedCount,
        completedCount: value?.completedCount,
        _formId: value?.formId,
      }
      progressReportData.push(obj)
    })
    setProgressReportTableData(progressReportData)
  }

  const mapDataToTableAll = (array: any[]) => {
    const progressReportData: progressReportTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * 0 + index + 1,
        formTitle: value?.formTitle,
        receivedCount: value?.receivedCount,
        agencyId: value?.agencyId,
        governmentAgency: value?.agencyName,
        unattendedCount: value?.unattendedCount,
        inProgressCount: value?.inProgressCount,
        rejectedCount: value?.rejectedCount,
        completedCount: value?.completedCount,
        _formId: value?.formId,
      }
      progressReportData.push(obj)
    })
    setAllProgressReportTableData(progressReportData)

    const columns = [
      'No',
      'Form Name',
      'Received Count',
      'Unattended Count',
      'In Progress Count',
      'Rejected Count',
      'Completed Count',
    ]
    const finalAnswerArray: any[] = []
    progressReportData.forEach(function (item: any) {
      console.log('item', item)
      const tempArray = []
      tempArray.push(item?.rowNumber)
      tempArray.push(item?.formTitle)
      tempArray.push(item?.receivedCount)
      tempArray.push(item?.unattendedCount)
      tempArray.push(item?.inProgressCount)
      tempArray.push(item?.rejectedCount)
      tempArray.push(item?.completedCount)
      finalAnswerArray.push(tempArray)
    })

    const finalObj = {
      answerArray: finalAnswerArray,
      columnTitles: columns,
    }

    console.log('finalObj', finalObj)
    setFinalTemplateObj(finalObj)
  }

  const mapDataToSubmissionTable = (array: any[]) => {
    const progressReportSubmissionData: progressReportSubmissionTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber:
          parseInt(rowsSubmissionPerPage) * (currentSubmissionPage - 1) +
          index +
          1,
        formId: value?.formId,
        submissionID: value?.submissionId,
        serialNumber: value?.serialNumber,
        submittedAt: format(new Date(value?.submittedAt), 'dd/LLL/y h:mm a'),
        submittedAtStr: value?.submittedAt,
        submittedBy: value?.submittedBy,
        lastModifiedBy: value?.lastModifiedBy,
        status: value?.status,
        statusAt: format(new Date(value?.statusAt), 'dd/LLL/y h:mm a'),
        statusAtStr: value?.statusAt,
      }
      progressReportSubmissionData.push(obj)
    })
    setProgressReportSubmissionTableData(progressReportSubmissionData)
  }

  const mapDataToSubmissionTableAll = (array: any[]) => {
    const progressReportSubmissionData: progressReportSubmissionTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsSubmissionPerPage) * 0 + index + 1,
        submissionID: value?.submissionId,
        serialNumber: value?.serialNumber,
        submittedAt: format(new Date(value?.submittedAt), 'dd/LLL/y h:mm a'),
        submittedBy: value?.submittedBy,
        lastModifiedBy: value?.lastModifiedBy,
        status: value?.status,
        statusAt: format(new Date(value?.statusAt), 'dd/LLL/y h:mm a'),
      }
      progressReportSubmissionData.push(obj)
    })
    setAllProgressReportSubmissionTableData(progressReportSubmissionData)
  }

  const callApi = (query = '') => {
    setIsLoading(true)
    ApiService.get(
      `/progress-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.filteredForms)) {
            mapDataToTable(data?.data?.filteredForms)
            setProgressReportCount(data?.data?.meta?.totalItems)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const callApiAll = (query = '') => {
    setIsLoading(true)
    ApiService.get(
      `/progress-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          if (Array.isArray(data?.data?.filteredForms)) {
            mapDataToTableAll(data?.data?.filteredForms)
          }
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const submissionCallApi = (query = '') => {
    setIsSubmissionLoading(true)
    ApiService.get(
      `progress-reports/submissions/${selectedId}?${query}&page_size=${rowsSubmissionPerPage}&page=${currentSubmissionPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsSubmissionLoading(false)
          if (Array.isArray(data?.data?.submissions)) {
            mapDataToSubmissionTable(data?.data?.submissions)
            setProgressReportSubmissionCount(data?.data?.meta?.totalItems)
          }
        } else {
          setIsSubmissionLoading(false)
        }
      })
      .catch((error) => {
        setIsSubmissionLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const submissionCallApiAll = (query = '') => {
    setIsSubmissionLoading(true)
    ApiService.get(
      `progress-reports/submissions/${selectedId}?${query}&page_size=${rowsSubmissionPerPage}&page=${currentSubmissionPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          if (Array.isArray(data?.data?.submissions)) {
            mapDataToSubmissionTableAll(data?.data?.submissions)
          }
        }
      })
      .catch((error) => {
        setIsSubmissionLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
    callApiAll(queryObject)
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    callApiAll(queryObject)
  }, [])

  useEffect(() => {
    setSubmissionCurrentPage(submissionCurrentPage)
    if (selectedId) {
      submissionCallApi(queryObjectMore)
      submissionCallApiAll(queryObjectMore)
    }
  }, [currentSubmissionPage, rowsSubmissionPerPage, selectedId])

  useEffect(() => {
    if (selectedId) {
      submissionCallApiAll(queryObjectMore)
    }
  }, [selectedId])
  localStorage.removeItem('refresh')

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.formId === '') {
      delete data['formId']
    }
    if (data?.createdFrom === '') {
      delete data['createdFrom']
    }
    if (data?.createdTo === '') {
      delete data['createdTo']
    }
    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
    callApiAll(queryString)
  }

  const onProgressReportSubmission = (data: any) => {
    setCurrentPage(1)
    if (data?.status === '') {
      delete data['status']
    }
    if (data?.submittedFrom === '') {
      delete data['submittedFrom']
    }
    if (data?.submittedTo === '') {
      delete data['submittedTo']
    }
    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObjectMore(queryString)
    submissionCallApi(queryString)
    submissionCallApiAll(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    callApiAll()
    setSelectedId('')
    setSelectedName('')
    mainFormValues.reset({
      formId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  const resetSubmissionForm = () => {
    setCurrentSubmissionPage(1)
    setQueryObjectMore('')
    submissionCallApi()
    submissionCallApiAll()
    progressReportSubmissionValues.reset({
      status: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  const exportPdf = async () => {
    const content = document.getElementById('divToPrint')
    if (content !== null) {
      content.style.display = 'block'

      const options = {
        margin: 6,
        filename: `Progress_Report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
      }

      await html2pdf(content, options)

      content.style.display = 'none'
    }
  }

  const exportPdfMore = () => {
    const doc = new jsPDF('p', 'pt')
    autoTable(doc, {
      body: allProgressReportSubmissionTableData,
      columns: [
        { header: 'No', dataKey: 'rowNumber' },
        { header: 'Serial Number', dataKey: 'serialNumber' },
        { header: 'Submitted at', dataKey: 'submittedAt' },
        { header: 'Submitted by', dataKey: 'submittedBy' },
        { header: 'Last Modified by', dataKey: 'lastModifiedBy' },
        { header: 'Status', dataKey: 'status' },
        {
          header: 'Status at',
          dataKey: 'statusAt',
        },
      ],
    })
    doc.save('Progress_More_Report.pdf')
  }

  const headers = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Form Name', key: 'formTitle' },
    { label: 'Received Count', key: 'receivedCount' },
    { label: 'Unattended Count', key: 'unattendedCount' },
    { label: 'In Progress Count', key: 'inProgressCount' },
    { label: 'Rejected Count', key: 'rejectedCount' },
    { label: 'Completed Count', key: 'completedCount' },
  ]
  const headersMore = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Submitted at', key: 'submittedAt' },
    { label: 'Submitted by', key: 'submittedBy' },
    { label: 'Last Modified by', key: 'lastModifiedBy' },
    { label: 'Status', key: 'status' },
    { label: 'Status at', key: 'statusAt' },
  ]

  const csvReport = {
    data: allProgressReportTableData,
    headers: headers,
    filename: 'Progress_Report.csv',
  }

  const csvReportMore = {
    data: allProgressReportSubmissionTableData,
    headers: headersMore,
    filename: 'Progress_More_Report.csv',
  }

  const boxHeight = `${selectedName.length / 2 + 40}px`

  const compiledTemplate = template(tableTemplate)

  const printResult = finalTemplateObj
    ? compiledTemplate({ finalOuput: finalTemplateObj })
    : ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [progressReportTableData])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box>
          <Box height="40px">
            <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
              Progress Report
            </Heading>
          </Box>
          <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.formId ? true : false
                  }
                >
                  <FormLabel htmlFor="formId" mb="8px">
                    Form Name
                  </FormLabel>
                  <Controller
                    name="formId"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={formDropdownData ? formDropdownData : []}
                      />
                    )}
                  />
                  {mainFormValues.formState.errors.formId && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.formId.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px"></Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.createdFrom
                      ? true
                      : false
                  }
                >
                  <ChakraFormLabel htmlFor="createdFrom" mb="8px">
                    Form Created From
                  </ChakraFormLabel>
                  <Controller
                    control={mainFormValues.control}
                    name={'createdFrom'}
                    rules={{
                      validate: {
                        validDate: (val) => {
                          if (!val) return
                          const dateVal = new Date(val)
                          if (isNaN(dateVal.getTime())) {
                            return 'Please enter a valid date'
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field }) => (
                      <DateInput excludeFuture={true} {...field} />
                    )}
                  />
                  {mainFormValues.formState.errors.createdFrom && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.createdFrom.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.createdTo ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="createdTo" mb="8px">
                    Form Created To
                  </ChakraFormLabel>
                  <Controller
                    control={mainFormValues.control}
                    name={'createdTo'}
                    rules={{
                      validate: {
                        validDate: (val) => {
                          if (!val) return
                          const dateVal = new Date(val)
                          const fromDate = new Date(fromDateMain)
                          if (isNaN(dateVal.getTime())) {
                            return 'Please enter a valid date'
                          } else if (dateVal < fromDate) {
                            return 'To date cannot be lesser than From date.'
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field }) => (
                      <DateInput excludeFuture={true} {...field} />
                    )}
                  />
                  {mainFormValues.formState.errors.createdTo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.createdTo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="10px"></Box>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
              <Box height="80px">
                <Center>
                  <ButtonGroup variant="outline" spacing="6">
                    <Button type="submit">Search</Button>
                    <Button type="reset" onClick={resetForm}>
                      Reset
                    </Button>
                  </ButtonGroup>
                </Center>
              </Box>
            </SimpleGrid>
          </form>
        </Box>

        <Box display="none" id="divToPrint">
          <div dangerouslySetInnerHTML={{ __html: printResult }} />
        </Box>

        <Box
          bg={'white'}
          px={10}
          py={5}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid
            columns={{ sm: 1, md: 1 }}
            spacing="10px"
            overflowX="auto"
          >
            <DataTable
              heading=""
              rowValues={progressReportTableData ? progressReportTableData : []}
              currentPage={currentPage}
              pageSize={parseInt(rowsPerPage)}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 300,
                  minWidth: 100,
                },
                {
                  Header: 'Form Name',
                  accessor: 'formTitle',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.formTitleStr}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },

                {
                  Header: 'Received Count',
                  accessor: 'receivedCount',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <Text textAlign="center" width="100%">
                      {props?.row?.original?.receivedCount}
                    </Text>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Unattended Count',
                  accessor: 'unattendedCount',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <Text textAlign="center" width="100%">
                      {props?.row?.original?.unattendedCount}
                    </Text>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'In Progress Count',
                  accessor: 'inProgressCount',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <Text textAlign="center" width="100%">
                      {props?.row?.original?.inProgressCount}
                    </Text>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Rejected Count',
                  accessor: 'rejectedCount',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <Text textAlign="center" width="100%">
                      {props?.row?.original?.rejectedCount}
                    </Text>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Completed Count',
                  accessor: 'completedCount',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <Text textAlign="center" width="100%">
                      {props?.row?.original?.completedCount}
                    </Text>
                  ),
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Action',
                  Cell: (props: any) => (
                    <ButtonGroup
                      w="100%"
                      justifyContent="center"
                      variant="outline"
                      spacing="1"
                      padding={2}
                    >
                      <Button
                        onClick={() =>
                          setMoreValue(
                            `${props?.row?.original?._formId}`,
                            `${props?.row?.original?.formTitle}`,
                          )
                        }
                      >
                        More
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {progressReportTableData?.length === 0 ? (
              <Center>No results found</Center>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <div>
                Rows per page:
                <SingleSelect
                  value={rowsPerPage}
                  onChange={(value) => {
                    setCurrentPage(1)
                    setRowsPerPage(value)
                  }}
                  name={'rowsPerPage'}
                  isClearable={false}
                  items={[
                    {
                      value: '5',
                      label: '5',
                    },
                    {
                      value: '10',
                      label: '10',
                    },
                    {
                      value: '25',
                      label: '25',
                    },
                    {
                      value: '50',
                      label: '50',
                    },
                    {
                      value: '100',
                      label: '100',
                    },
                  ]}
                />
              </div>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={progressReportCount} //from BE the total number of record should also be returned.
                onPageChange={setCurrentPage}
              />
            </SimpleGrid>
            <Flex mb={10}>
              <Spacer />
              <ButtonGroup variant="outline" spacing="6">
                <Button>
                  <CSVLink {...csvReport}>Download CSV</CSVLink>
                </Button>
                <Button onClick={exportPdf}>Download PDF</Button>
              </ButtonGroup>
            </Flex>
          </SimpleGrid>
        </Box>
        {selectedId ? (
          <Box bg={'gray.50'} p={10}>
            <Box height="40px">
              <Heading as="h4" size="sm">
                More Details
              </Heading>
            </Box>

            <SimpleGrid
              style={{ marginTop: 15 }}
              columns={{ sm: 1, md: 2 }}
              spacing="40px"
            >
              <Box height={boxHeight}>
                <HStack>
                  <Text as="b"> Form Name:</Text>
                  <Text>{selectedName}</Text>
                </HStack>
              </Box>
            </SimpleGrid>
            <form
              onSubmit={progressReportSubmissionValues.handleSubmit(
                onProgressReportSubmission,
              )}
            >
              <SimpleGrid
                style={{ marginTop: 15 }}
                columns={{ sm: 1, md: 2 }}
                spacing="40px"
              >
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      progressReportSubmissionValues?.formState?.errors
                        ?.submittedFrom
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="submittedFrom" mb="8px">
                      Submitted From
                    </ChakraFormLabel>
                    <Controller
                      control={progressReportSubmissionValues.control}
                      name={'submittedFrom'}
                      rules={{
                        validate: {
                          validDate: (val) => {
                            if (!val) return
                            const dateVal = new Date(val)
                            if (isNaN(dateVal.getTime())) {
                              return 'Please enter a valid date'
                            }
                            return true
                          },
                        },
                      }}
                      render={({ field }) => (
                        <DateInput excludeFuture={true} {...field} />
                      )}
                    />
                    {progressReportSubmissionValues.formState.errors
                      .submittedFrom && (
                      <FormErrorMessage>
                        {
                          progressReportSubmissionValues.formState.errors
                            .submittedFrom.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      progressReportSubmissionValues?.formState?.errors
                        ?.submittedTo
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="submittedTo" mb="8px">
                      Submitted To
                    </ChakraFormLabel>
                    <Controller
                      control={progressReportSubmissionValues.control}
                      name={'submittedTo'}
                      rules={{
                        validate: {
                          validDate: (val) => {
                            if (!val) return
                            const dateVal = new Date(val)
                            const fromDate = new Date(fromDateMore)
                            if (isNaN(dateVal.getTime())) {
                              return 'Please enter a valid date'
                            } else if (dateVal < fromDate) {
                              return 'To date cannot be lesser than From date.'
                            }
                            return true
                          },
                        },
                      }}
                      render={({ field }) => (
                        <DateInput excludeFuture={true} {...field} />
                      )}
                    />
                    {progressReportSubmissionValues.formState.errors
                      .submittedTo && (
                      <FormErrorMessage>
                        {
                          progressReportSubmissionValues.formState.errors
                            .submittedTo.message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      progressReportSubmissionValues?.formState?.errors?.status
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="status" mb="8px">
                      Status
                    </FormLabel>
                    <Controller
                      name="status"
                      control={progressReportSubmissionValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'Completed',
                              label: 'Completed',
                            },
                            // {
                            //   value: 'Draft',
                            //   label: 'Draft',
                            // },
                            {
                              value: 'Inprogress',
                              label: 'Inprogress',
                            },
                            // {
                            //   value: 'PaymentPending',
                            //   label: 'Payment Inprogress',
                            // },
                            {
                              value: 'Rejected',
                              label: 'Rejected',
                            },
                            {
                              value: 'UnAttended',
                              label: 'UnAttended',
                            },
                          ]}
                        />
                      )}
                    />
                    {progressReportSubmissionValues.formState.errors.status && (
                      <FormErrorMessage>
                        {
                          progressReportSubmissionValues.formState.errors.status
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>{' '}
                <Box height="80px"></Box> <Box height="10px"></Box>
              </SimpleGrid>{' '}
              <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                <Box height="80px">
                  <Center>
                    <ButtonGroup variant="outline" spacing="6">
                      <Button type="submit">Search</Button>
                      <Button type="reset" onClick={resetSubmissionForm}>
                        Reset
                      </Button>
                    </ButtonGroup>
                  </Center>
                </Box>
              </SimpleGrid>
            </form>

            <Box
              bg={'gray.200'}
              pl={5}
              pr={5}
              pb={5}
              overflowX="auto"
              maxWidth="100Vw"
            >
              <SimpleGrid
                columns={{ sm: 1, md: 1 }}
                spacing="10px"
                overflowX="auto"
              >
                <Box bg={'gray.200'} p={5}></Box>
              </SimpleGrid>
              <SimpleGrid columns={{ sm: 1, md: 1 }} mt={-100} spacing="70px">
                <DataTable
                  heading=""
                  rowValues={
                    progressReportSubmissionTableData
                      ? progressReportSubmissionTableData
                      : []
                  }
                  currentPage={currentSubmissionPage}
                  pageSize={parseInt(rowsSubmissionPerPage)}
                  cols={[
                    {
                      Header: 'No',
                      accessor: 'rowNumber',
                      disableSortBy: true,
                      maxWidth: 300,
                      minWidth: 100,
                    },
                    {
                      Header: 'Serial Number',
                      accessor: 'serialNumber',
                      disableSortBy: false,
                      maxWidth: 400,
                      minWidth: 190,
                    },
                    {
                      Header: 'Submitted at',
                      accessor: 'submittedAtStr',
                      disableSortBy: false,
                      Cell: (props: any) => (
                        <div>{props?.row?.original?.submittedAt}</div>
                      ),
                      maxWidth: 400,
                      minWidth: 190,
                    },
                    {
                      Header: 'Submitted by',
                      accessor: 'submittedBy',
                      disableSortBy: false,
                      maxWidth: 400,
                      minWidth: 190,
                    },
                    {
                      Header: 'Last Modified by',
                      accessor: 'lastModifiedBy',
                      disableSortBy: false,
                      maxWidth: 400,
                      minWidth: 190,
                    },
                    {
                      Header: 'Status',
                      accessor: 'status', //not returned
                      disableSortBy: false,
                      maxWidth: 300,
                      minWidth: 170,
                    },
                    {
                      Header: 'Status at',
                      accessor: 'statusAtStr', //not returned
                      disableSortBy: false,
                      Cell: (props: any) => (
                        <div>{props?.row?.original?.statusAt}</div>
                      ),
                      maxWidth: 300,
                      minWidth: 170,
                    },
                    {
                      Header: () => (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          Action
                        </div>
                      ),
                      accessor: 'action',
                      Cell: (props: any) => (
                        <ButtonGroup
                          variant="outline"
                          spacing="1"
                          padding={2}
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Button
                            variant="solid"
                            colorScheme="primary"
                            as={Link}
                            to={`/report/${selectedId}/submission/${props?.row?.original?.submissionID}`}
                          >
                            View Submission
                          </Button>
                        </ButtonGroup>
                      ),
                    },
                  ]}
                />
                {IsSubmissionLoading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : null}
                {progressReportSubmissionTableData?.length === 0 ? (
                  <Center>No results found</Center>
                ) : null}
                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                  <div>
                    Rows per page:
                    <SingleSelect
                      value={rowsSubmissionPerPage}
                      onChange={(value) => {
                        setCurrentSubmissionPage(1)
                        setRowsSubmissionPerPage(value)
                      }}
                      name={'rowsSubmissionPerPage'}
                      isClearable={false}
                      items={[
                        {
                          value: '5',
                          label: '5',
                        },
                        {
                          value: '10',
                          label: '10',
                        },
                        {
                          value: '25',
                          label: '25',
                        },
                        {
                          value: '50',
                          label: '50',
                        },
                        {
                          value: '100',
                          label: '100',
                        },
                      ]}
                    />
                  </div>
                  <Pagination
                    currentPage={currentSubmissionPage}
                    pageSize={parseInt(rowsSubmissionPerPage, 10)}
                    totalCount={progressReportSubmissionCount} //from BE the total number of record should also be returned.
                    onPageChange={setCurrentSubmissionPage}
                  />
                </SimpleGrid>{' '}
                <Flex>
                  <Spacer />
                  <ButtonGroup variant="outline" spacing="6">
                    <Button>
                      <CSVLink {...csvReportMore}>Download CSV</CSVLink>
                    </Button>
                    <Button onClick={exportPdfMore}>Download PDF</Button>
                  </ButtonGroup>
                </Flex>
              </SimpleGrid>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
