import React, { ReactNode, ReactText, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { IconType } from 'react-icons'
import {
  FiActivity,
  FiChevronDown,
  FiFile,
  FiHome,
  FiMenu,
  FiServer,
  FiSettings,
  FiUsers,
} from 'react-icons/fi'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  toast,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { isBoolean, isEmpty, isString } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import SlLogo from '~assets/images/sl-logo.jpg'
import DGFLogo from '~assets/svgs/brand/dgf-logo.svg'
import { useUser } from '~contexts/userContext'
import { LOGGED_IN_KEY } from '~constants/localStorage'
import {
  AGENCY_MANAGE_PERMISSION,
  ROOT_ROUTE_PERMISSION,
  SETTINGS_MANAGE_PERMISSION,
} from '~constants/permission'
import {
  AGENCY_MANAGE,
  CITIZEN_ONBOARDING_SCREEN,
  CITIZEN_VIEW_USER_PROFILE,
  ROOT_ROUTE,
  UM_ROUTE,
} from '~constants/routes'
import { useLocalStorage } from '~hooks/useLocalStorage'
import { ApiService } from '~services/ApiService'
import { getWso2LogoutSettings, logout } from '~services/CitizenAuthService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'

import { LanguageFilter } from '../../../atom.js'

interface LinkItemProps {
  name: string
  icon: IconType
  code: string
  url: string
  permission: string
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Dashboard',
    icon: FiHome,
    code: 'workspace',
    url: ROOT_ROUTE,
    permission: ROOT_ROUTE_PERMISSION,
  },
  {
    name: 'Agencies',
    icon: FiServer,
    code: 'agency',
    url: AGENCY_MANAGE,
    permission: AGENCY_MANAGE_PERMISSION,
  },
  {
    name: 'Onboarding Screen',
    icon: FiFile,
    code: 'onboardingScreen',
    url: CITIZEN_ONBOARDING_SCREEN,
    permission: AGENCY_MANAGE_PERMISSION,
  },
]

export const CitizenLayout = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const navigate = useNavigate()
  return (
    <Box minH="100vh" bg={useColorModeValue('#ECEFF1', '#ECEFF1')}>
      {/* <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      /> */}
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer> */}
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 0 }} p="4">
        {children}
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  let current = 'workspace'
  current = window.location.pathname.split('/')[1]

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'white')}
      borderRight="1px"
      borderRightColor={useColorModeValue('primary.200', 'primary.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          current={current}
          code={link.code}
          url={link.url}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  code: string
  icon: IconType
  children: ReactText
  current: string
  url: string
}
const NavItem = ({
  code,
  icon,
  children,
  current,
  url,
  ...rest
}: NavItemProps) => {
  return (
    <Link
      href={url}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      {code === current ? (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          background="primary.1000"
          color="white"
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      ) : (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          color="primary.20"
          _hover={{
            bg: 'primary.1000',
            color: 'white',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </Link>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const [, setIsAuthenticated] = useLocalStorage<boolean>(LOGGED_IN_KEY)
  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user)._doc : {}
  const location = useLocation()
  const tempLanguage = location?.state?.tempLanguage
  const [language, setSelectedLanguage] = useRecoilState(LanguageFilter)
  const navigate = useNavigate()
  const [displayName, setDisplayName] = useState<string>('')
  const [userRole, setUserRole] = useState<string>('')
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const locale: any = useRecoilValue(LanguageFilter)

  useEffect(
    () => () => {
      window.history.replaceState({}, '')
    },
    [],
  )

  useEffect(() => {
    if (tempLanguage) {
      setSelectedLanguage(tempLanguage)
      localStorage.setItem('tempLanguage', tempLanguage)
    }
  }, [locale])

  const messages: any = {
    en: {
      profile: 'Profile',
      help: 'Help',
      signOut: 'Sign Out',
    },
    si: {
      profile: 'පැතිකඩ',
      help: 'උදව්',
      signOut: 'පිටවීම',
    },
    ta: {
      profile: 'சுயவிவரம்',
      help: 'உதவி',
      signOut: 'வெளியேறு',
    },
  }
  const makeLogoutWithWso2 = async () => {
    try {
      // Get logout options
      const result = await getWso2LogoutSettings()
      if (
        result &&
        isString(result.endpoint) &&
        !isEmpty(result.endpoint) &&
        isString(result.wso2UserIdToken) &&
        !isEmpty(result.wso2UserIdToken) &&
        isString(result.logoutRedirectUrl) &&
        !isEmpty(result.logoutRedirectUrl) &&
        isBoolean(result.clientLogoutOnly) &&
        result.clientLogoutOnly === false &&
        isBoolean(result.logoutWithExtraTab)
      ) {
        const logoutUrl = `${result.endpoint}/oidc/logout?id_token_hint=${result.wso2UserIdToken}&post_logout_redirect_uri=${result.logoutRedirectUrl}`
        if (result.logoutWithExtraTab === true) {
          // Open the logout in a separate tab
          window.open(logoutUrl, '_blank', 'noopener,noreferrer')
        } else {
          window.location.href = logoutUrl
        }
      }
      // Malformed url, perform client logout
    } catch (error) {
      // Error for settings. Ignore
    } finally {
      // Perform client logout
      try {
        await logout()
      } catch (error) {
        // If logout failed from backend. Cookies may not have cleared. Ignore
      }
      setIsAuthenticated(false)
      localStorage.removeItem('tempLanguage')
    }
  }

  useEffect(() => {
    getAvatar()
  })

  async function getAvatar() {
    ApiService.get(`/public/users/${userData._id}`)
      .then((data: any) => {
        console.log(data?.data)
        if (data?.status === 200) {
          setAvatarUrl(data?.data?.user?.profPicture)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setDisplayName(
      `${userData?.title}` +
        '. ' +
        `${userData?.firstName}` +
        ' ' +
        `${userData?.lastName}`,
    )
    setUserRole(`${userData?.userRole?.name}`)
  }, [userData])

  useEffect(() => {
    const prevLanguage = localStorage.getItem('tempLanguage')
    if (prevLanguage) {
      setSelectedLanguage(prevLanguage)
    } else {
      const preferredLang =
        userData?.preferredLanguage == 'English'
          ? 'en'
          : userData?.preferredLanguage == 'Sinhala'
          ? 'si'
          : userData?.preferredLanguage == 'Tamil'
          ? 'ta'
          : ''
      setSelectedLanguage(preferredLang)
      localStorage.setItem('tempLanguage', preferredLang)
    }
  }, [])

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Flex
        ml={{ base: 0, md: 0 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'white')}
        borderBottomWidth="1px"
        borderBottomColor={'#ECEFF1'}
        justifyContent={{ base: 'space-between' }}
        {...rest}
      >
        <Flex alignItems="center" mx="4">
          <Image h="16" src={DGFLogo} />
        </Flex>
        <Box height="40px" display={{ base: 'none', md: 'none', lg: 'flex' }}>
          <Heading as="h3" size="md" mt="2" color="#2B2B2B">
            DIGITAL GOVERNMENT FORMS
          </Heading>
        </Box>

        <HStack spacing={{ base: '0', md: '6' }} borderRadius="5px">
          <SingleSelect
            name={'languageSelect'}
            value={language ? language : 'en'}
            isClearable={false}
            isSearchable={false}
            placeholder={'Select Language'}
            onChange={(value: any) => {
              setSelectedLanguage(value)
              localStorage.setItem('tempLanguage', value)
            }}
            items={[
              {
                value: 'en',
                label: 'English',
              },
              {
                value: 'si',
                label: 'සිංහල',
              },
              {
                value: 'ta',
                label: 'தமிழ்',
              },
            ]}
          />

          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                px={4}
                borderRadius="10px"
                transition="all 0.3s"
                onDoubleClick={() => navigate(CITIZEN_VIEW_USER_PROFILE)}
                _focus={{
                  boxShadow: 'md',
                }}
                _active={{
                  boxShadow: 'md',
                  border: 'none',
                }}
                _hover={{
                  boxShadow: 'md',
                }}
              >
                <HStack>
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="2px"
                    ml="2"
                    mr="2"
                  >
                    <a
                      onDoubleClick={() => navigate(CITIZEN_VIEW_USER_PROFILE)}
                    >
                      <Text
                        fontSize="md"
                        align="right"
                        fontWeight="600"
                        color="#37474F"
                      >
                        {userData?.firstName} {userData?.lastName}
                      </Text>
                      <Text fontSize="sm" color="primary.600" align="right">
                        {userData?.email}
                      </Text>
                    </a>
                  </VStack>
                  <Avatar
                    size={'md'}
                    name={`${userData?.firstName} ${userData?.lastName}`}
                    src={avatarUrl}
                  />
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                w={{ sm: '35%', md: '200%' }}
                borderRadius={'5px'}
              >
                <MenuItem as="a" href={CITIZEN_VIEW_USER_PROFILE}>
                  <FormattedMessage
                    id="profile"
                    values={{ locale }}
                  ></FormattedMessage>
                </MenuItem>
                <MenuItem as="a" href={UM_ROUTE} target="_blank">
                  <FormattedMessage
                    id="help"
                    values={{ locale }}
                  ></FormattedMessage>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  color="primary.500"
                  onClick={(e) => {
                    makeLogoutWithWso2()
                  }}
                >
                  <FormattedMessage
                    id="signOut"
                    values={{ locale }}
                  ></FormattedMessage>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </IntlProvider>
  )
}
