import { atom } from 'recoil'

export const LanguageFilter = atom({
  key: 'languageFilter',
  default: 'en',
})

export const ShowFeatureTour = atom({
  key: 'showFeatureTour',
  default: false,
})

export const DraftSubmissionId = atom({
  key: 'draftSubmissionId',
  default: null,
})

export const SignatureRequiredMsge = atom({
  key: 'signatureRequiredMsge',
  default: null,
})

export const SignatureTypeRequiredMsge = atom({
  key: 'signatureTypeRequiredMsge',
  default: null,
})
