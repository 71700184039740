import { z } from 'zod'
import { Opaque } from 'type-fest'

import { UserDto } from './user'
import { PublicUserDto } from './public_user'
import { AgencyDto } from './agency'

export type ConversationId = Opaque<string, 'ConversationId'>
export const ConversationId = z.string() as unknown as z.Schema<ConversationId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const ConversationBase = z.object({
  createdAt: z.date(),
  createdBy: z.object({
    userCategory: z.enum(['PUBLIC', 'AGENCY', 'SYSTEM']),
    userId: UserDto.shape._id,
  }),
  formId: z.string(), // no direct shape._id
  agency: z.object({
    agencyId: AgencyDto.shape._id,
    name: z.string(),
  }),
  submissionId: z.string(), // no direct shape._id
  archived: z.boolean(),
  archivedDate: z.date(),
  conversationType: z.object({
    code: z.enum(['AM', 'CQ']),
    name: z.enum(['Agency Message', 'Citizen Inquiry']),
  }),
  senderPublicUser: z.object({
    senderPublicUserId: PublicUserDto.shape._id,
    name: z.string(),
  }),
  senderAgencyUser: z.object({
    senderAgencyUserId: UserDto.shape._id,
    name: z.string(),
  }),
  recipientPublicUser: z.object({
    recipientPublicUserId: PublicUserDto.shape._id,
    name: z.string(),
  }),
  recipientAgencyUser: z.object({
    recipientAgencyUserId: UserDto.shape._id,
    name: z.string(),
  }),
  message: z.object({
    type: z.string(),
    content: z.string(),
    lastRestoredAt: z.date(),
    deleted: z.boolean(),
    deletedDate: z.date(),
  }),
})
export type ConversationBase = z.infer<typeof ConversationBase>

// Convert to serialized versions.
export const ConversationDto = ConversationBase.extend({
  _id: ConversationId,
})

export type ConversationDto = z.infer<typeof ConversationDto>

export type ConversationWithOptionsDto = {
  _id?: string
  formId?: string
  submissionId?: string
  agency?: {
    agencyId: string
    name: string
  }
  archived: boolean
  conversationType: {
    code: string
    name: string
  }
  senderPublicUser?: {
    senderPublicUserId: string
    name?: string
  }
  senderAgencyUser?: {
    senderAgencyUserId: string
    name?: string
  }
  recipientPublicUser?: {
    recipientPublicUserId: string
    name?: string
  }
  recipientAgencyUser?: {
    recipientAgencyUserId: string
    name?: string
  }
  message: {
    type: string
    content: string
    deleted: boolean
  }
  userImpersonateCode?: string
  formCollaborators?: any
  createdBy?: {
    userCategory: string
    userId: string
  }
  createdAt?: Date | string
}
