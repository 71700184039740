import { isEmpty, isString } from 'lodash'

import { ConversationMessageTypeDto } from '~shared/types'

import { ApiService } from './ApiService'

const MESSAGE_TYPE_ENDPOINT = '/conversation-message'
const PUBLIC_ENDPOINT = '/public'

/**
 * Retrieves a lis of conversation message types
 * @returns when Conversation Message Types are available
 * @throws error on non 2xx response
 */
export const getConversationMessageTypes = (
  conversationTypeCode?: string,
): Promise<ConversationMessageTypeDto[]> => {
  return ApiService.get(
    isString(conversationTypeCode) && !isEmpty(conversationTypeCode)
      ? `${MESSAGE_TYPE_ENDPOINT}?conversationTypeCode=${conversationTypeCode}`
      : `${MESSAGE_TYPE_ENDPOINT}`,
  ).then(({ data }) => data)
}

/**
 * Create a new Conversation Message Type
 * @param conversationMessageType the parameters for new Conversation Message Type
 * @returns when Conversation Message Type is created
 * @throws error on non 2xx response
 */
export const createConversationMessageType = (conversationMessageType: {
  conversationMessageType: string
  conversationTypeCode: string
}): Promise<ConversationMessageTypeDto> => {
  return ApiService.post(`${MESSAGE_TYPE_ENDPOINT}`, {
    conversationMessageType,
  }).then(({ data }) => data)
}

/**
 * Updates a conversation message type by the Id
 * @param conversationMessageType the Conversation Message Type content to be updated
 * @returns when Conversation Message Type is updated
 * @throws error on non 2xx response
 */
export const updateConversationMessageTypeById = (
  conversationMessageTypeId: string,
  conversationMessageType: {
    conversationMessageType: string
    conversationTypeCode: string
  },
): Promise<ConversationMessageTypeDto> => {
  return ApiService.put(
    `${MESSAGE_TYPE_ENDPOINT}/${conversationMessageTypeId}`,
    { conversationMessageType },
  ).then(({ data }) => data)
}

/**
 * Deletes a conversation message type by Id
 * @param conversationMessageTypeId the Conversation Message Type Id to be updated
 * @returns when Conversation Message Type is deleted
 * @throws error on non 2xx response
 */
export const deleteConversationMessageTypeById = (
  conversationMessageTypeId: string,
): Promise<ConversationMessageTypeDto> => {
  return ApiService.delete(
    `${MESSAGE_TYPE_ENDPOINT}/${conversationMessageTypeId}`,
  ).then(({ data }) => data)
}

// For citizen users

/**
 * Retrieves a lis of conversation message types
 * @returns when Conversation Message Types are available
 * @throws error on non 2xx response
 */
export const getConversationMessageTypesForCitizen = (
  conversationTypeCode?: string,
): Promise<ConversationMessageTypeDto[]> => {
  return ApiService.get(
    isString(conversationTypeCode) && !isEmpty(conversationTypeCode)
      ? `${PUBLIC_ENDPOINT}${MESSAGE_TYPE_ENDPOINT}?conversationTypeCode=${conversationTypeCode}`
      : `${PUBLIC_ENDPOINT}${MESSAGE_TYPE_ENDPOINT}`,
  ).then(({ data }) => data)
}
