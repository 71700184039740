import { Dispatch, useState } from 'react'
import { RefetchOptions, RefetchQueryFilters } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Box, SimpleGrid } from '@chakra-ui/react'

import { ADMINFORM_ROUTE, AGENT_MANAGE_FORMS } from '~constants/routes'
import Button from '~components/Button'

import { StatusChangeModal } from './StatusChangeModal'

type StatusChangeButtonsProps = {
  status?: string
  submissionId?: string
  formId?: string
  paymentRefunded?: boolean
  agencyId?: string
  lastPaymentStatus: string
  formName?: string
  submittedAt?: string
  submittedBy?: string
  paymentAmount?: number
  lastPaymentTransactionId?: string
  download?: any
  secretKey?: string
  onStatusChange: any
}

export const StatusChangeButtons = ({
  status,
  submissionId,
  formId,
  paymentRefunded,
  agencyId,
  lastPaymentStatus,
  formName,
  submittedAt,
  submittedBy,
  paymentAmount,
  lastPaymentTransactionId,
  download,
  secretKey,
  onStatusChange,
}: StatusChangeButtonsProps): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [clickedButton, setClickedButton] = useState<string>('')
  const navigate = useNavigate()

  return (
    <Box gridArea="export" justifySelf="flex-end">
      {status ? (
        <SimpleGrid columns={{ sm: 2, md: 5 }} spacing="40px">
          {status === 'UnAttended' ? (
            <>
              <Button
                onClick={() => {
                  setClickedButton('Accept')
                  setModalIsOpen(true)
                }}
              >
                Accept
              </Button>
              <Button
                onClick={() => {
                  setClickedButton('Reject')
                  setModalIsOpen(true)
                }}
              >
                Reject
              </Button>
            </>
          ) : null}
          {status === 'Inprogress' ? (
            <Button
              onClick={() => {
                setClickedButton('Complete')
                setModalIsOpen(true)
              }}
            >
              Complete
            </Button>
          ) : null}
          <Button onClick={download}>Download</Button>
          {/* other conditions as well with submissionPayments API*/}
          {((status === 'Rejected' && lastPaymentStatus === 'Success') ||
            (status === 'PaymentPending' && lastPaymentStatus === 'Pending')) &&
          !paymentRefunded ? (
            <Button
              onClick={() => {
                setClickedButton('Refunded')
                setModalIsOpen(true)
              }}
            >
              Refund
            </Button>
          ) : null}
          <Button
            onClick={() =>
              navigate(`${ADMINFORM_ROUTE}/${formId}/results`, {
                state: { currentSecretKey: secretKey },
              })
            }
          >
            Back
          </Button>
        </SimpleGrid>
      ) : null}
      {modalIsOpen ? (
        <StatusChangeModal
          clickedButton={clickedButton}
          formId={formId}
          submissionId={submissionId}
          isOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          agencyId={agencyId}
          formName={formName}
          submittedAt={submittedAt}
          submittedBy={submittedBy}
          paymentAmount={paymentAmount}
          lastPaymentTransactionId={lastPaymentTransactionId}
          onStatusChange={onStatusChange}
        />
      ) : null}
    </Box>
  )
}
