import validator from 'validator'

import { HttpError } from '~services/ApiService'

export const fromObjectToQueryString = (obj: any): string => {
  let queryString = ''

  Object.keys(obj).forEach((key) => {
    queryString = queryString + `${key}=${obj[key]}&`
  })

  if (queryString.substr(queryString.length - 1) === '&') {
    queryString = queryString.substring(0, queryString.length - 1)
  }
  return queryString
}

export const getMappedErrorMessage = (error: Error): string => {
  // check if error is an instance of HttpError to be able to access status code of error
  if (error instanceof HttpError) {
    const errorMessage = `Error - ${error.message}`
    return errorMessage
  }
  // if error is not of type HttpError return the error message encapsulated in Error object
  return 'Something went wrong!'
}
