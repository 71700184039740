import { useLocation } from 'react-router-dom'

import { EmptyResponses } from '../common/EmptyResponses'
import { ResponsesTabWrapper } from '../common/ResponsesTabWrapper'

import { SecretKeyVerification } from './SecretKeyVerification'
import { useStorageResponsesContext } from './StorageResponsesContext'
import { StorageResponsesProvider } from './StorageResponsesProvider'
import { UnlockedResponses } from './UnlockedResponses'

export const StorageResponsesTab = () => {
  return (
    <StorageResponsesProvider>
      <ProvidedStorageResponsesTab />
    </StorageResponsesProvider>
  )
}

const ProvidedStorageResponsesTab = (): JSX.Element => {
  const { responsesCount, secretKey } = useStorageResponsesContext()
  const location = useLocation()
  const passedSecretKey: string = location.state?.currentSecretKey

  if (responsesCount === 0) {
    return <EmptyResponses />
  }

  console.log('secretKey and passed key', secretKey, passedSecretKey)

  return (
    <ResponsesTabWrapper>
      {secretKey || passedSecretKey ? (
        <UnlockedResponses
          secretKey={secretKey ? secretKey : passedSecretKey}
        />
      ) : (
        <SecretKeyVerification />
      )}
    </ResponsesTabWrapper>
  )
}
