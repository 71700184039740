import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { chakra, Flex, FormControl } from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { FormColorTheme } from '~shared/types/form'

import Button from '~components/Button'
import Rating from '~components/Field/Rating'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Textarea from '~components/Textarea'

import { LanguageFilter } from '../../../../../../atom'

export type FeedbackFormInput = {
  rating: number
  comment?: string
}

export interface FeedbackBlockProps {
  onSubmit: (input: FeedbackFormInput) => void
  colorTheme?: FormColorTheme
}

export const FeedbackBlock = ({
  onSubmit,
  colorTheme = FormColorTheme.Blue,
}: FeedbackBlockProps): JSX.Element => {
  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FeedbackFormInput>()

  const handleFormSubmit = handleSubmit((inputs) => onSubmit(inputs))

  const colorScheme = useMemo(() => {
    return `theme-${colorTheme}` as const
  }, [colorTheme])
  const locale: any = useRecoilValue(LanguageFilter)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <IntlProvider locale={locale}>
      <Flex justify="center">
        <chakra.form
          w="42.5rem"
          maxW="100%"
          noValidate
          onSubmit={handleFormSubmit}
        >
          <FormControl isInvalid={!!errors.rating} id="rating">
            <FormLabel isRequired>
              {locale == 'en'
                ? 'How was your form filling experience today?'
                : locale == 'si'
                ? 'අද ඔබේ පෝරමය පිරවීමේ අත්දැකීම කොහොමද?'
                : 'இன்று உங்கள் படிவத்தை நிரப்பும் அனுபவம் எப்படி இருந்தது?'}
            </FormLabel>
            <Controller
              rules={{
                required: `${
                  locale == 'en'
                    ? 'Please select a rating'
                    : locale == 'si'
                    ? 'කරුණාකර ඇගයීමක් තෝරන්න'
                    : 'மதிப்பீட்டைத் தேர்ந்தெடுக்கவும்'
                }`,
              }}
              control={control}
              name="rating"
              render={({ field }) => (
                <Rating
                  colorScheme={colorScheme}
                  numberOfRatings={5}
                  variant="star"
                  {...field}
                />
              )}
            />
            <FormErrorMessage>{errors.rating?.message}</FormErrorMessage>
          </FormControl>
          <Textarea
            isReadOnly={isSubmitting}
            mt="1rem"
            {...register('comment')}
            aria-label={
              locale == 'en'
                ? 'Tell us more about your experience'
                : locale == 'si'
                ? 'ඔබේ අත්දැකීම් ගැන වැඩි විස්තර අපට කියන්න'
                : 'உங்கள் அனுபவத்தைப் பற்றி மேலும் சொல்லுங்கள்'
            }
            placeholder={
              locale == 'en'
                ? 'Tell us more about your experience'
                : locale == 'si'
                ? 'ඔබේ අත්දැකීම් ගැන වැඩි විස්තර අපට කියන්න'
                : 'உங்கள் அனுபவத்தைப் பற்றி மேலும் சொல்லுங்கள்'
            }
          />
          <Button
            mt="1.5rem"
            variant="outline"
            type="submit"
            colorScheme={colorScheme}
            isLoading={isSubmitting}
          >
            {locale == 'en'
              ? 'Submit feedback'
              : locale == 'si'
              ? 'ප්‍රතිචාර ඉදිරිපත් කරන්න'
              : 'கருத்தைச் சமர்ப்பிக்கவும்'}
          </Button>
        </chakra.form>
      </Flex>
    </IntlProvider>
  )
}
