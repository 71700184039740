import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { isEmpty, isString, template, times, trimStart } from 'lodash'

import { BasicField } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
//import { DateInput } from '~components/DatePicker/DateInput'
import DateInput from '~components/DatePicker'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import Input from '../../../components/Input/index'
import {
  fromObjectToQueryString,
  getMappedErrorMessage,
  tableTemplate,
} from '../utils'

export const UnPublishedReport = (): JSX.Element | null => {
  type agencyTable = {
    rowNumber: number
    formId: string
    formNames: string
    govAgency: string
    createdBy: string
    submitionsCount: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [agencyTableData, setAgencyTableData] = useState<agencyTable[]>([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agencyDropdownData, setAgencyDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [isAgencyIdSet, setIsAgencyIdSet] = useState<boolean>(false)
  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const [formDropdownData, setFormDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [agenciesCount, setAgenciesCount] = useState<number>(0)
  const [allAgencyDropdownData, setAllAgencyTableData] = useState<
    agencyTable[]
  >([])
  const [finalTemplateObj, setFinalTemplateObj] = useState<any>()
  const mapDataToTable = (array: any[]) => {
    const currentAgencyData: agencyTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        formNames: value?.formName.toLowerCase(),
        formNameStr: value?.formName,
        formId: value?.formId,
        govAgency: value?.agencyName.toLowerCase(),
        govAgencyStr: value?.agencyName,
        createdBy: value?.createdBy,
        submitionsCount: value?.submissionCount,
      }
      currentAgencyData.push(obj)
    })
    setAgencyTableData(currentAgencyData)
  }
  const mapDataToTableAll = (array: any[]) => {
    const currentAgencyData: agencyTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * 0 + index + 1,
        formNames: value?.formName,
        formId: value?.formId,
        govAgency: value?.agencyName,
        createdBy: value?.createdBy,
        submitionsCount: value?.submissionCount,
      }
      currentAgencyData.push(obj)
    })
    setAllAgencyTableData(currentAgencyData)

    const columns = [
      'No',
      'Form Name',
      'Government Agency',
      'Created By',
      'Submission Count',
    ]
    const finalAnswerArray: any[] = []
    currentAgencyData.forEach(function (item: any) {
      const tempArray = []
      tempArray.push(item?.rowNumber)
      tempArray.push(item?.formNames)
      tempArray.push(item?.govAgency)
      tempArray.push(item?.createdBy)
      tempArray.push(item?.submitionsCount)
      finalAnswerArray.push(tempArray)
    })

    const finalObj = {
      answerArray: finalAnswerArray,
      columnTitles: columns,
    }

    setFinalTemplateObj(finalObj)
  }
  const dropDown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.fullName,
      }
      currentAgencyData.push(obj)
    })
    setAgencyDropdownData(currentAgencyData)
  }

  const formDropdown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.title,
      }
      currentAgencyData.push(obj)
    })
    setFormDropdownData(currentAgencyData)
  }

  const mainFormValues = useForm({
    defaultValues: {
      formId: '',
      agencyId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const fromDateVal = mainFormValues.watch().createdFrom

  const callApi = (query = '') => {
    ApiService.get(
      `/unpublished-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    ).then((data) => setIsLoading(true))

    ApiService.get(
      `/unpublished-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data.unpublishedReports)) {
            mapDataToTable(data?.data.unpublishedReports)
            setAgenciesCount(data?.data?.meta?.totalItems)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const callApiAll = (query = '') => {
    ApiService.get(
      `/unpublished-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          if (Array.isArray(data?.data?.unpublishedReports)) {
            mapDataToTableAll(data?.data?.unpublishedReports)
          }
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
      }

      if (loggedUserDetails?._doc?.agency) {
        setLoggedUserAgencyId(loggedUserDetails?._doc?.agency)
        setIsAgencyIdSet(true)
      }
    }
  }, [])

  const callDropDown = (query = '') => {
    ApiService.get(`/report/agencies/`).then((data) =>
      console.log('data1112122', data),
    )
    setIsLoading(true)

    ApiService.get(`/report/agencies/`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            dropDown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  const formNameDropDown = (query = '') => {
    //ApiService.get(`/report/allforms/${loggedUserAgencyId}`)
    ApiService.get(
      `/report/allforms?isUnpublished=true&agencyId=${
        mainFormValues.watch().agencyId
      }`,
    )
      //ApiService.get(`/report/allforms/${mainFormValues.watch().agencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            formDropdown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  const formNameDropDownNew = (query = '') => {
    ApiService.get(`/report/allforms?isUnpublished=true`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            formDropdown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    callDropDown()
  }, [])

  useEffect(() => {
    formNameDropDownNew()
  }, [])

  useEffect(() => {
    formNameDropDown()
  }, [mainFormValues.watch().agencyId])

  // useEffect(() => {
  //   if (isAgencyIdSet) {
  //     formNameDropDown()
  //   }
  // }, [mainFormValues.watch().agencyId])

  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
    callApiAll(queryObject)
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    callApiAll(queryObject)
  }, [])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.formId === '') {
      delete data['formId']
    }
    if (data?.createdFrom === '') {
      delete data['createdFrom']
    }
    if (data?.createdTo === '') {
      delete data['createdTo']
    }
    if (data?.agencyId === '') {
      delete data['agencyId']
    }
    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
    callApiAll(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    callApiAll()
    mainFormValues.reset({
      agencyId: '',
      formId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  const exportPdf = async () => {
    const content = document.getElementById('divToPrint')
    if (content !== null) {
      content.style.display = 'block'

      const options = {
        margin: 6,
        filename: `Unpublished_Report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
      }

      await html2pdf(content, options)

      content.style.display = 'none'
    }
  }

  const headers = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Form Name', key: 'formNames' },
    { label: 'Government Agency', key: 'govAgency' },
    { label: 'Created By', key: 'createdBy' },
    { label: 'Submission Count', key: 'submitionsCount' },
  ]

  const csvReport = {
    data: allAgencyDropdownData,
    headers: headers,
    filename: 'Unpublished_Report.csv',
  }

  const compiledTemplate = template(tableTemplate)

  const printResult = finalTemplateObj
    ? compiledTemplate({ finalOuput: finalTemplateObj })
    : ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [agencyTableData])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Unpublished Report
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.agencyId ? true : false
                }
              >
                <FormLabel htmlFor="agencyId" mb="8px">
                  Government Agency
                </FormLabel>
                <Controller
                  name="agencyId"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect {...field} items={agencyDropdownData} />
                  )}
                />
                {mainFormValues.formState.errors.agencyId && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.agencyId.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.formId ? true : false
                }
              >
                <ChakraFormLabel htmlFor="formId" mb="8px">
                  Form Name
                </ChakraFormLabel>
                <Controller
                  name="formId"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect {...field} items={formDropdownData} />
                  )}
                />
                {mainFormValues.formState.errors.formId && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.formId.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.createdFrom ? true : false
                }
              >
                {/* <ChakraFormLabel>Start Date</ChakraFormLabel> */}
                <ChakraFormLabel htmlFor="createdFrom" mb="8px">
                  Form Created From
                </ChakraFormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'createdFrom'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.createdFrom && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.createdFrom.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.createdTo ? true : false
                }
              >
                {/* <ChakraFormLabel>Start Date</ChakraFormLabel> */}
                <ChakraFormLabel htmlFor="createdTo" mb="8px">
                  Form Created To
                </ChakraFormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'createdTo'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        const fromDate = new Date(fromDateVal)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        } else if (dateVal < fromDate) {
                          return 'To date cannot be lesser than From date.'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.createdTo && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.createdTo.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </SimpleGrid>
          <Box height="80px"></Box>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
            <Box height="80px">
              <Center>
                <ButtonGroup variant="outline" spacing="6">
                  <Button type="submit">Search</Button>
                  <Button type="reset" onClick={resetForm}>
                    Reset
                  </Button>
                </ButtonGroup>
              </Center>
            </Box>
          </SimpleGrid>
        </form>

        <Box display="none" id="divToPrint">
          <div dangerouslySetInnerHTML={{ __html: printResult }} />
        </Box>

        <Box
          bg={'white'}
          px={10}
          py={5}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid
            columns={{ sm: 1, md: 1 }}
            spacing="10px"
            overflowX="auto"
          >
            <DataTable
              heading=""
              rowValues={agencyTableData ? agencyTableData : []}
              currentPage={currentPage}
              pageSize={parseInt(rowsPerPage)}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  //accessor: (_row: any, i: number) => i + 1,
                  disableSortBy: true,
                  maxWidth: 300,
                  minWidth: 70,
                },
                {
                  Header: 'Form Name',
                  accessor: 'formNames',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.formNameStr}</div>
                  ),
                  maxWidth: 350,
                  minWidth: 140,
                },
                {
                  Header: 'Gov. Agency',
                  accessor: 'govAgency',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.govAgencyStr}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 160,
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy', //not returned
                  disableSortBy: false,
                  maxWidth: 300,
                  minWidth: 200,
                },
                {
                  Header: 'Submissions Count',
                  accessor: 'submitionsCount', //not returned
                  disableSortBy: false,
                  maxWidth: 400,
                  minWidth: 250,
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {agencyTableData.length === 0 ? (
              <Center>No results found</Center>
            ) : null}
            <br />
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <div>
                Rows per page:
                <SingleSelect
                  value={rowsPerPage}
                  onChange={(value) => {
                    setCurrentPage(1)
                    setRowsPerPage(value)
                  }}
                  name={'rowsPerPage'}
                  isClearable={false}
                  items={[
                    {
                      value: '5',
                      label: '5',
                    },
                    {
                      value: '10',
                      label: '10',
                    },
                    {
                      value: '25',
                      label: '25',
                    },
                    {
                      value: '50',
                      label: '50',
                    },
                    {
                      value: '100',
                      label: '100',
                    },
                  ]}
                />
              </div>

              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={agenciesCount}
                onPageChange={setCurrentPage}
              />
              <Box height="10px"></Box>
              <Flex>
                <Spacer />
                <ButtonGroup variant="outline" spacing="6">
                  <Button>
                    <CSVLink {...csvReport}>Download CSV</CSVLink>
                  </Button>
                  <Button onClick={exportPdf}>Download PDF</Button>
                </ButtonGroup>
              </Flex>
            </SimpleGrid>
            <Box height="1px"></Box>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}
