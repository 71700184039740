import { useQuery, UseQueryResult } from 'react-query'
import { useParams } from 'react-router-dom'

import {
  AgencyReasonDto,
  ConversationDto,
  StorageModeSubmissionDto,
  SubmissionPaymentDto,
} from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'

import { getDecryptedSubmissionById } from '~features/admin-form/responses/AdminSubmissionsService'
import { adminFormResponsesKeys } from '~features/admin-form/responses/queries'
import { useStorageResponsesContext } from '~features/admin-form/responses/ResponsesPage/storage/StorageResponsesContext'

// import { getDecryptedSubmissionById } from '../AdminSubmissionsService'
// import { adminFormResponsesKeys } from '../queries'

/**
 * Returns the data of a single submission of a given storage mode form
 * @param arg.formId The id of the form to query
 * @param arg.submissionId The id of the submission
 * @returns The data of the submission
 */
const getEncryptedPublicSubmissionById = async ({
  formId,
  submissionId,
}: {
  formId: string
  submissionId: string
}): Promise<StorageModeSubmissionDto> => {
  return ApiService.get<StorageModeSubmissionDto>(
    `/public/submission/${formId}/submissions/${submissionId}`,
  ).then(({ data }) => data)
}

export const getDecryptedPublicSubmissionById = async ({
  formId,
  submissionId,
  secretKey,
}: {
  formId: string
  submissionId: string
  secretKey?: string
}) => {
  const { content, version, verified, attachmentMetadata, ...rest } =
    await getEncryptedPublicSubmissionById({ formId, submissionId })

  return {
    ...rest,
    responses: content,
  }
}

/**
 * @precondition Must be wrapped in a Router as `useParam` is used.
 */
export const useIndividualSubmission = () => {
  const toast = useToast({
    status: 'danger',
  })

  const { formId, submissionId } = useParams()
  if (!formId || !submissionId) {
    throw new Error('No formId or submissionId provided')
  }

  return useQuery(
    adminFormResponsesKeys.individual(formId, submissionId),
    () => getDecryptedPublicSubmissionById({ formId, submissionId }), //change this.
    {
      // Will never update once fetched.
      staleTime: Infinity,
      enabled: true,
      onError: (e) => {
        toast({
          description: String(e),
        })
      },
    },
  )
}

export const getAgencyRejectedReasons = async (
  agencyId: string,
): Promise<AgencyReasonDto[]> =>
  ApiService.get(`/agency-reasons?reasonType=FR&${agencyId}`).then(
    ({ data }) => data,
  )

const agencyReasonsListKeys = {
  base: ['agencyReasonsList'],
}

export const useAgencyRejectedReasons = (
  agencyId: string,
): UseQueryResult<AgencyReasonDto[], unknown> => {
  return useQuery<AgencyReasonDto[]>(
    agencyReasonsListKeys.base,
    () => getAgencyRejectedReasons(agencyId),
    {
      // 5 minutes.
      staleTime: 300000,
    },
  )
}

export const getSubmissionPayments = async (
  formId: string,
  submissionId: string,
): Promise<SubmissionPaymentDto[]> =>
  ApiService.get(
    `/public/form-submissions/payments?submissionId=${submissionId}`,
  ).then(({ data }) => data)

const submissionPaymentsKeys = {
  base: ['submissionPaymentsList'],
}

export const useSubmissionPayments = (): UseQueryResult<
  SubmissionPaymentDto[],
  unknown
> => {
  const { formId, submissionId } = useParams()
  if (!formId || !submissionId) {
    throw new Error('No formId or submissionId provided')
  }
  return useQuery<SubmissionPaymentDto[]>(
    submissionPaymentsKeys.base,
    () => getSubmissionPayments(formId, submissionId),
    {
      staleTime: 1,
    },
  )
}

// Conversations for a submission
// NOTE*: Update controller method after security is fixed
export const getSubmissionConversationsForCitizen = async (
  submissionId: string,
): Promise<ConversationDto[]> =>
  ApiService.get(`/public/conversations/submissions/${submissionId}`).then(
    ({ data }) => data,
  )
// .catch((error) => {}) // Not catching, to allow to show the error in console

const submissionConversationsListKeys = {
  base: ['submissionConversationList'],
}

export const useSubmissionConversations = (
  submissionId: string,
): UseQueryResult<ConversationDto[], unknown> => {
  return useQuery<ConversationDto[]>(
    submissionConversationsListKeys.base,
    () => getSubmissionConversationsForCitizen(submissionId),
    {
      // 1 minute.
      staleTime: 6000,
    },
  )
}
