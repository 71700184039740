import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react'
import { find, isArray, isEmpty, isString, map } from 'lodash'

import { ConversationTypeCode } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import { getConversationMessageTypes } from '~services/ConversationMessageTypeService'
import { createConversationBySubmission } from '~services/ConversationsService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import {
  useFormAdmin,
  useIndividualSubmission,
  useSubmissionFormCollaborators,
} from './queries'

type ConversationMessageModelProps = {
  formId: string
  agencyId: string | undefined
  agencyName: string | undefined
  submissionId: string
  // formCollaborators: any
  // submissionStatus: string | undefined
  formAdmin: string | undefined
  isNoConversation?: boolean
}

export const ConversationMessageModel = ({
  formId,
  agencyId,
  agencyName,
  submissionId,
  // formCollaborators,
  formAdmin,
  isNoConversation,
}: ConversationMessageModelProps): JSX.Element => {
  const MAX_MESSAGE_LENGTH = 250
  const [showingModel, setShowingModel] = useState<boolean>(false)
  const [messageTypes, setMessageTypes] = useState<string[]>([])

  type whichShowingMessageTypeForm = {
    messageType: string
    messageContent: string
  }

  const toast = useToast({ duration: 5000, isClosable: true })
  const queryClient = useQueryClient()

  // const { formId, submissionId } = useParams()

  const submissionFormCollaborators = useSubmissionFormCollaborators(formId)

  // Get initial data to populate the table
  const getInitialData = async () => {
    const responseData = await getConversationMessageTypes('AM')
    setMessageTypes(
      isArray(responseData) ? map(responseData, 'conversationMessageType') : [],
    )
  }

  useEffect(() => {
    getInitialData()
  }, [])

  if (localStorage.getItem('refresh') === null) {
    window.location.reload()
    localStorage.setItem('refresh', 'r')
  }

  const { handleSubmit, register, formState, control, reset } =
    useForm<whichShowingMessageTypeForm>()

  // Create a conversation message
  const onSubmitForm = async (inputs: whichShowingMessageTypeForm) => {
    toast.closeAll()
    try {
      // Create record
      const conversationWithOptions = {
        formId: formId,
        submissionId: submissionId,
        agency: {
          agencyId: `${agencyId}`,
          name: `${agencyName}`,
        },
        archived: false,
        conversationType: {
          code: 'AM',
          name: ConversationTypeCode.AM,
        },
        // recipientPublicUser: This is extracted at the backend using submissionId
        message: {
          type: inputs.messageType,
          content: inputs.messageContent,
          deleted: false,
        },
      }

      const newConversation = await createConversationBySubmission(
        conversationWithOptions,
      )

      if (isArray(newConversation)) {
        queryClient.cancelQueries('submissionConversationList')
        queryClient.setQueryData('submissionConversationList', newConversation)

        toast({
          status: 'success',
          description: 'Message Sent Successfully',
        })
      } else {
        toast({
          status: 'danger',
          description: 'Something went wrong',
        })
      }

      setShowingModel(false)
      reset({})
      // Reload the updated data
    } catch (error) {
      toast({
        status: 'danger',
        description:
          error instanceof Error ? error.message : 'Something went wrong',
      })
    }
  }
  // const formAdmin = useFormAdmin(formId)
  const location = useLocation()
  const passedSecretKey: string = location.state?.currentSecretKey
  const { data } = useIndividualSubmission(passedSecretKey)

  // Check the UI render criteria with form status, otherwise return
  if (
    data?.submissionStatus !== 'UnAttended' &&
    data?.submissionStatus !== 'Inprogress'
  ) {
    // console.log(
    //   'aaaaaaaaaaaaaaaaaaaaaaaaa',
    //   'submisssionStatus: ',
    //   data?.submissionStatus,
    // )
    return <></>
  }

  // Check the UI render criteria with form collaborators, otherwise return
  const loggedUser = localStorage.getItem('user')
  let loggedUserDetails = undefined
  let loggedUserPermissions = undefined
  let loggedUserId = undefined
  if (isString(loggedUser) && !isEmpty(loggedUser)) {
    try {
      const jsonUser = JSON.parse(loggedUser)
      if (isString(jsonUser?._doc?.email) && !isEmpty(jsonUser?._doc?.email)) {
        loggedUserDetails = jsonUser?._doc?.email
      }
      if (isArray(jsonUser?.permissions) && !isEmpty(jsonUser?.permissions)) {
        loggedUserPermissions = jsonUser?.permissions
      }
      if (isString(jsonUser?._doc?._id) && !isEmpty(jsonUser?._doc?._id)) {
        loggedUserId = jsonUser?._doc?._id
      }
    } catch (error) {
      // Ignore
    }
  }
  // No logged in user email. Don't show the model
  if (!loggedUserDetails) {
    // console.log('bbbbbbbbbbbbbbbbbbbb')
    return <></>
  }

  const formCollaborators = isArray(submissionFormCollaborators?.data)
    ? submissionFormCollaborators?.data
    : []

  // Email is not in permission list with write permissions. Don't show the model
  const permittedUser = find(formCollaborators, {
    email: loggedUserDetails,
    write: true,
  })

  const p2 = formAdmin && formAdmin === loggedUserId

  // console.log(
  //   '***********************',
  //   'submisssionStatus: ',
  //   data?.submissionStatus,
  // )

  // console.log(
  //   '%%%%%%%%%%%%%%%%%%%%%%%%%%',
  //   permittedUser,
  //   p2,
  //   loggedUserId,
  //   loggedUserDetails,
  // )

  if (!(permittedUser || p2)) {
    // console.log(
    //   'ccccccccccccccccccccccccccccc',
    //   permittedUser,
    //   p2,
    //   loggedUserId,
    //   loggedUserDetails,
    // )
    return <></>
  }

  // Check whether user is authorized to send messages
  if (
    !loggedUserPermissions ||
    !find(loggedUserPermissions, {
      permissionCode: 'MANAGE_FORM',
    })
  ) {
    // console.log('ddddddddddddddddddddddddddddd')
    return <></>
  }

  return (
    <>
      {/* UI button for the parent */}
      {/* {submissionStatus === 'UnAttended' ||
      submissionStatus === 'Inprogress' ? (
        <Button
          isDisabled={isNoConversation ?? false}
          onClick={() => {
            reset({})
            setShowingModel(true)
          }}
        >
          Send Message
        </Button>
      ) : (
        <></>
      )} */}
      <Button
        isDisabled={isNoConversation ?? false}
        onClick={() => {
          reset({})
          setShowingModel(true)
        }}
      >
        Send Message
      </Button>

      {/* Show add or update modal */}
      <Modal
        isOpen={showingModel}
        onClose={() => {
          setShowingModel(false)
          reset({})
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <ModalCloseButton />
            <ModalHeader>Message</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              <FormControl isInvalid={!!formState.errors.messageType} mb="1rem">
                <FormLabel isRequired htmlFor="messageType">
                  Message Type: *
                </FormLabel>
                <Controller
                  name="messageType"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      isClearable={false}
                      {...register('messageType', {
                        required: 'Message Type is required',
                      })}
                      {...field}
                      items={messageTypes}
                    />
                  )}
                />
                {formState.errors.messageType && (
                  <FormErrorMessage>
                    {formState.errors.messageType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors.messageContent}
                mb="1rem"
              >
                <FormLabel isRequired htmlFor="messageContent">
                  Message: *
                </FormLabel>
                <Textarea
                  maxLength={MAX_MESSAGE_LENGTH}
                  inputMode="text"
                  autoComplete="messageContent"
                  autoFocus
                  placeholder="e.g. Message type"
                  {...register('messageContent', {
                    required: 'Message is required',
                    maxLength: {
                      value: MAX_MESSAGE_LENGTH,
                      message: 'Character length should not exceed',
                    },
                    pattern: {
                      value:
                        /^[ A-Za-z0-9.,?$!@#%^&*()_,\u0D80-\u0DFF\u0B80-\u0BFF]*$/,
                      message:
                        'Message should be alphanumeric and/or one of .,?$!@#%^&*()_ characters.',
                    },
                  })}
                />
                {formState.errors.messageContent && (
                  <FormErrorMessage>
                    {formState.errors.messageContent.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button type="submit">Send Message</Button>
                <Button
                  onClick={() => {
                    setShowingModel(false)
                    reset({})
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
