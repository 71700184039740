import { z } from 'zod'
import { Opaque } from 'type-fest'

export type UserId = Opaque<string, 'UserId'>
export const UserId = z.string() as unknown as z.Schema<UserId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const PublicUserBase = z.object({
  username: z.string(),
  title: z.string(),
  gender: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nameInitials: z.string(),
  identificationType: z.enum(['N', 'P', 'D']),
  nicNo: z.string().optional(),
  passportNo: z.string().optional(),
  passportCountry: z.string().optional(),
  drivingLicenseNo: z.string().optional(),
  contact: z.string().optional(),
  email: z.string().email().optional(),
  profPicture: z.string().optional(),
  iamId: z.string().optional(),
  lastAccessed: z.date().optional(),
  previouslyAccessed: z.date().optional(),
  createdAt: z.date().optional(),
  lastModifiedAt: z.date().optional(),
  userCategory: z.object({
    code: z.enum(['AGENCY', 'SYSTEM', 'PUBLIC']),
    name: z.enum(['Agency User', 'System User', 'Public User']),
  }),
  userCategoryId: z.string().optional(),
  userRole: z.object({
    code: z.string(),
    name: z.string(),
  }),
  createdBy: z.object({
    userCategoryCode: z.string(),
    userCategoryName: z.string(),
    userId: z.string(),
    userName: z.string(),
  }),
  lastModifiedBy: z.object({
    userCategoryCode: z.string(),
    userCategoryName: z.string(),
    userId: z.string(),
    userName: z.string(),
  }),
  preferredLanguage: z.string(),
  betaFlags: z
    .object({
      sgid: z.boolean().optional(),
    })
    .optional(),
  permissions: z
    .tuple([
      z.object({
        permissionName: z.string(),
        permissionCode: z.string(),
        description: z.string(),
        rules: z
          .tuple([
            z.object({
              method: z.enum(['GET', 'PUT', 'POST', 'PATCH', 'DELETE']),
              uri: z.string(),
            }),
          ])
          .optional(),
      }),
    ])
    .optional(),
  signupSource: z.enum(['self', 'google', 'facebook']),
  firstTimeLogin: z.boolean()
})
export type PublicUserBase = z.infer<typeof PublicUserBase>

// Convert to serialized versions.
export const PublicUserDto = PublicUserBase.extend({
  _id: UserId,
  createdAt: z.date(),
  lastModifiedAt: z.date(),
  emailVerificationId: z.string(),
  phoneNoVerificationId: z.string()
})

export type PublicUserDto = z.infer<typeof PublicUserDto>

// Equal with user
export enum UserCategoryCode {
  AGENCY = 'AGENCY',
  SYSTEM = 'SYSTEM',
  PUBLIC = 'PUBLIC',
}

export enum SignUpSource {
  SELF = 'self',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export type ForgotPublicUsernameDto = {
  emailOrPhone: string
}

export type ResetPublicPasswordOtpSendDto = {
  username: string
}

export type ResetPublicPasswordOtpVerifyDto = {
  username: string
  otp: string
  password: string
}

export type ValidateContactOtpGenerateDto = {
  userId: string
  newEmail?: string
  phoneNo?: string 
}

export type ValidateContactOtpGenerateOboardingDto = {
  newEmail: string
  phoneNo: string 
}

export type ValidateContactOtpVerifyDto = {
  userId: string
  newEmail?: string
  phoneNo?: string 
  otp: string
}

export type ValidateContactOtpVerifyOnboardingDto = {
  newEmail: string
  phoneNo: string 
  otp: string
}