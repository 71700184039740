import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Container,
  Flex,
  FormControl,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import simplur from 'simplur'

import { ProcessedFeedbackMeta } from '~shared/types'

import Pagination from '~/components/Pagination'

import { useIsMobile } from '~hooks/useIsMobile'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { DateInput } from '~components/DatePicker/DateInput'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

import { ADMIN_FORM_ENDPOINT } from '~features/admin-form/common/AdminViewFormService'

import { useFormFeedback } from '../queries'

import { EmptyFeedback } from './EmptyFeedback'
import { FeedbackDownloadButton } from './FeedbackDownloadButton'
import {
  FeedbackPageSkeleton,
  FeedbackPageSkeletonMobile,
} from './FeedbackSkeleton'
import { FeedbackTable } from './FeedbackTable'

export const FeedbackPage = (): JSX.Element => {
  type FeedbackTableType = {
    rowNumber?: number
    comment?: string
    createdDate?: string
    rating?: number
  }

  const fromObjectToQueryString = (obj: any): string => {
    let queryString = ''

    Object.keys(obj).forEach((key) => {
      queryString = queryString + `${key}=${obj[key]}&`
    })

    if (queryString.substr(queryString.length - 1) === '&') {
      queryString = queryString.substring(0, queryString.length - 1)
    }
    return queryString
  }

  const defaultValues = {
    startDate: '',
    endDate: '',
  }

  const formValues = useForm({
    defaultValues: defaultValues,
  })

  const fromDateVal = formValues.watch('startDate')

  const toast = useToast()
  // const { data: { average, count, feedback } = {}, isLoading } =
  //   useFormFeedback()
  const { formId } = useParams()
  const isMobile = useIsMobile()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState(false)
  const [feedbackTable, setFeedbackTable] = useState<FeedbackTableType[]>()
  // const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('10')
  const [queryObject, setQueryObject] = useState<string>('')
  const [average, setAverage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [feedback, setFeedback] = useState<ProcessedFeedbackMeta[]>()

  const prettifiedAverageScore = useMemo(
    () => (average ? Number(average).toPrecision(2) : '-.--'),
    [average],
  )

  const prettifiedFeedbackCount = useMemo(() => {
    if (!count) return
    return simplur` ${[count]}feedback submission[|s] to date`
  }, [count])

  const mapDataToTable = (array: any[]) => {
    const currentTableData: FeedbackTableType[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        createdDate: value?.date,
        comment: value?.comment,
        rating: value?.rating,
      }
      currentTableData.push(obj)
    })
    setFeedbackTable(currentTableData)
  }

  const callAPI = (query = '') => {
    setIsLoading(true)
    // without pagination to set to the export
    ApiService.get(`${ADMIN_FORM_ENDPOINT}/${formId}/feedback/manage?&${query}`)
      .then((data) => {
        if (data?.status === 200) {
          setAverage(data?.data?.average)
          setCount(data?.data?.count)
          setFeedback(data?.data?.feedback)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })

    ApiService.get(
      `${ADMIN_FORM_ENDPOINT}/${formId}/feedback/manage?page_size=${rowsPerPage}&page=${currentPage}&${query}`,
    )
      .then((data) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.feedback)) {
            mapDataToTable(data?.data?.feedback)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoading(false)
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  useEffect(() => {
    setCurrentPage(currentPage)
    callAPI(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmit = (data: any) => {
    setCurrentPage(1)
    console.log('data', data)
    if (data.startDate !== '' || data.endDate !== '') {
      if (new Date(data.startDate) > new Date(data.endDate)) {
        formValues.setError(
          'endDate',
          {
            type: 'focus',
            message: 'End date should be greater than start Date',
          },
          { shouldFocus: true },
        )
        return
      }
    }
    if (data.startDate !== '' && data.endDate === '') {
      formValues.setError(
        'endDate',
        {
          type: 'focus',
          message: 'End date cannot be empty if start date is entered',
        },
        { shouldFocus: true },
      )
      return
    }
    if (data.endDate !== '' && data.startDate === '') {
      formValues.setError(
        'startDate',
        {
          type: 'focus',
          message: 'Start date cannot be empty if end date is entered',
        },
        { shouldFocus: true },
      )
      return
    }
    if (data?.startDate === '') {
      delete data['startDate']
    }
    if (data?.endDate === '') {
      delete data['endDate']
    }

    const queryData = fromObjectToQueryString(data)
    setQueryObject(queryData)
    callAPI(queryData)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callAPI()
    formValues.reset(defaultValues)
  }

  if (isLoading) {
    return isMobile ? <FeedbackPageSkeletonMobile /> : <FeedbackPageSkeleton />
  }

  // if (count === 0) {
  //   return <EmptyFeedback />
  // }

  return (
    <Container
      overflowY="auto"
      p="1.5rem"
      maxW="69.5rem"
      flex={1}
      display="flex"
      flexDir="column"
    >
      <Grid
        mb="1rem"
        alignItems="end"
        color="secondary.500"
        gridTemplateColumns={{ base: 'auto', md: 'auto auto 1fr' }}
        gridGap={{ base: '0.5rem', md: '1.5rem' }}
        gridTemplateAreas={{
          base: "'submissions submissions' 'score export'",
          md: "'score submissions export'",
        }}
      >
        <Flex gridArea="score" flexDir="column">
          <Text textStyle="caption-2" color="secondary.400">
            Average Score
          </Text>
          <Text textStyle="display-2">{prettifiedAverageScore}</Text>
        </Flex>
        <Box gridArea="submissions">
          <Text textStyle="h4" mb="0.5rem">
            <Text as="span" color="primary.500">
              {count}
            </Text>
            {prettifiedFeedbackCount}
          </Text>
        </Box>
        <Box gridArea="export" justifySelf="flex-end">
          <FeedbackDownloadButton
            isDisabled={isLoading || count === 0}
            formId={formId}
            feedback={feedback}
          />
        </Box>
      </Grid>
      <Box mb="3rem" overflow="auto" flex={1}>
        {/* table here */}
        <form onSubmit={formValues.handleSubmit(onSubmit)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  formValues?.formState?.errors?.startDate ? true : false
                }
              >
                <FormLabel>Submitted from</FormLabel>
                <Controller
                  control={formValues.control}
                  name={'startDate'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {formValues.formState.errors.startDate && (
                  <FormErrorMessage>
                    {formValues.formState.errors.startDate.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  formValues?.formState?.errors?.endDate ? true : false
                }
              >
                <FormLabel>Submitted to</FormLabel>
                <Controller
                  control={formValues.control}
                  name={'endDate'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        const fromDate = new Date(fromDateVal)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        } else if (dateVal < fromDate) {
                          return 'To date cannot be lesser than From date.'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {formValues.formState.errors.endDate && (
                  <FormErrorMessage>
                    {formValues.formState.errors.endDate.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </SimpleGrid>
          <Box height="60px" mt={10} mb={5}>
            <Center>
              <ButtonGroup variant="outline" spacing="6">
                <Button type="submit" disabled={!formValues.formState.isDirty}>
                  Search
                </Button>
                <Button type="reset" onClick={resetForm}>
                  Reset
                </Button>
                {/* <Button>Export</Button> */}
              </ButtonGroup>
            </Center>
          </Box>
        </form>

        <Box bg={'gray.200'} p={5}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
            <Box p={5}></Box>
          </SimpleGrid>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="70px">
            <DataTable
              heading="Feedbacks"
              rowValues={feedbackTable ? feedbackTable : []}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 100,
                },
                {
                  Header: 'Date',
                  accessor: 'createdDate',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Feedback',
                  accessor: 'comment',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Rating',
                  accessor: 'rating',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 170,
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem w="100%" h="10" colSpan={2} pt={2}>
                  Rows per page:
                </GridItem>
                <GridItem w="100%" h="10" colSpan={1}>
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={count}
                onPageChange={setCurrentPage}
              />
            </SimpleGrid>
          </SimpleGrid>
        </Box>

        {/* <FeedbackTable feedbackData={feedback} currentPage={currentPage - 1} /> */}
      </Box>
      {/* <Box display={isLoading || count === 0 ? 'none' : ''}>
        <Pagination
          totalCount={count ?? 0}
          currentPage={currentPage} //1-indexed
          pageSize={10}
          onPageChange={setCurrentPage}
        />
      </Box> */}
    </Container>
  )
}
