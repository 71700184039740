import { useState } from 'react'
import { Navigate, NavigateProps, useLocation } from 'react-router-dom'

import { useAuth } from '~contexts/AuthContext'
import { useUser } from '~contexts/userContext'
import { CITIZEN_LOGIN_ROUTE, LOGIN_ROUTE } from '~constants/routes'
import { CitizenLayout } from '~templates/CitizenLayout'

import NotFoundErrorPage from '~pages/NotFoundError'

interface CitizenElementProps {
  /**
   * Route to redirect to when user is not authenticated. Defaults to
   * `LOGIN_ROUTE` if not provided.
   */
  redirectTo?: NavigateProps['to']
  element: React.ReactElement
  permission: string
  /**
   * Check if this is the onboarding screen
   */
  isOnboarding?: boolean
}

export const CitizenElement = ({
  element,
  //redirectTo = LOGIN_ROUTE,
  redirectTo = CITIZEN_LOGIN_ROUTE,
  isOnboarding = false,
}: CitizenElementProps): React.ReactElement => {
  const location = useLocation()

  const { isAuthenticated } = useAuth()

  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user) : {}

  if (isOnboarding) {
    return isAuthenticated ? (
      element
    ) : (
      <Navigate replace to={redirectTo} state={{ from: location }} />
    )
  } else {
    return isAuthenticated ? (
      userData?._doc.userRole?.code == 'DF_CITIZEN' ? (
        <CitizenLayout>{element}</CitizenLayout>
      ) : (
        <NotFoundErrorPage />
      )
    ) : (
      <Navigate replace to={redirectTo} state={{ from: location }} />
    )
  }
}
