import { z } from 'zod'
import { Opaque } from 'type-fest'

import { UserDto } from './user'

export type ConversationMessageTypeId = Opaque<
  string,
  'ConversationMessageTypeId'
>
export const ConversationMessageTypeId =
  z.string() as unknown as z.Schema<ConversationMessageTypeId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const ConversationMessageTypeBase = z.object({
  conversationMessageType: z.string(),
  conversationTypeCode: z.string(),
  createdAt: z.date(),
  createdBy: z.object({
    userCategory: z.enum(['PUBLIC', 'AGENCY', 'SYSTEM']),
    userId: UserDto.shape._id,
  }),
  lastModifiedAt: z.date(),
  lastModifiedBy: z.object({
    userCategory: z.enum(['PUBLIC', 'AGENCY', 'SYSTEM']),
    userId: UserDto.shape._id,
  }),
})
export type ConversationMessageTypeBase = z.infer<
  typeof ConversationMessageTypeBase
>

// Convert to serialized versions.
export const ConversationMessageTypeDto = ConversationMessageTypeBase.extend({
  _id: ConversationMessageTypeId,
})

export type ConversationMessageTypeDto = z.infer<
  typeof ConversationMessageTypeDto
>

export enum ConversationTypeCode {
  AM = 'Agency Message', // Message from Agency for submitted form
  CQ = 'Citizen Inquiry', // Inquiry from citizen for submission
}
