const PUBLIC_FORM_FIELDS = <const>[
  'admin',
  'authType',
  'endPage',
  'esrvcId',
  'form_fields',
  'form_logics',
  'hasCaptcha',
  'template',
  'templateName',
  'templateDescription',
  'startPage',
  'status',
  'title',
  '_id',
  'responseMode',
  'paymentRequired',
  'paymentCharges',
  'signatureRequired',
  'signatureTypes',
]

export const EMAIL_PUBLIC_FORM_FIELDS = PUBLIC_FORM_FIELDS
export const STORAGE_PUBLIC_FORM_FIELDS = <const>[
  ...PUBLIC_FORM_FIELDS,
  'publicKey',
]

const FORM_SETTINGS_FIELDS = <const>[
  'admin',
  'responseMode',
  'authType',
  'esrvcId',
  'description',
  'hasCaptcha',
  'formLanguage',
  'template',
  'templateName',
  'templateDescription',
  'inactiveMessage',
  'status',
  'submissionLimit',
  'title',
  'webhook',
  'formExpireDate',
  'afterExpier',
  'submissionRetentionPeriod',
  'authPublicUrl',
  'displayCitizenPortal',
  'paymentRequired',
  'paymentCharges',
  'emailNotificationEnable',
  'smsNotificationEnable',
  'signatureRequired',
  'signatureTypes',
]

export const EMAIL_FORM_SETTINGS_FIELDS = <const>[
  ...FORM_SETTINGS_FIELDS,
  'emails',
  'agencyId',
]
export const STORAGE_FORM_SETTINGS_FIELDS = <const>[
  ...FORM_SETTINGS_FIELDS,
  'encryptedKey',
  'publicKey',
  'agencyId',
]

export const ADMIN_FORM_META_FIELDS = <const>[
  'agencyId',
  'admin',
  'title',
  'lastModified',
  'status',
  '_id',
  'responseMode',
  'template',
  'templateName',
  'templateDescription',
  'encryptedKey',
  'form_fields',
  'form_logics',
]

export const WSO2_AUTH_PREFIX = 'FORMWSO2_'
