/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
import { useFormContext } from 'react-hook-form'

import Input from '~components/Input'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { SignatureFieldSchema, SingleAnswerFieldInput } from '../types'

export interface SignatureFieldProps extends BaseFieldProps {
  schema: SignatureFieldSchema
}

export const SignatureField = ({
  schema,
}: SignatureFieldProps): JSX.Element => {
  const { register } = useFormContext<SingleAnswerFieldInput>()

  return (
    <FieldContainer schema={schema}>
      <Input aria-label={schema.title} {...register(schema._id)} />
    </FieldContainer>
  )
}
