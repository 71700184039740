import {
  UserDto,
  Wso2LoginSettingsDto,
  Wso2LogoutSettingsDto,
} from '~shared/types'

import { ApiService } from './ApiService'

const AUTH_ENDPOINT = '/auth'

/**
 * Sends login OTP to given email
 * @param email email to send login OTP to
 * @returns success string if login OTP is sent successfully
 */
export const checkLoginUser = async (email: string): Promise<string> => {
  return ApiService.post<string>(`${AUTH_ENDPOINT}/email/validate`, {
    email: email.toLowerCase(),
  }).then(({ data }) => data)
}

/**
 * Sends login OTP to given email
 * @param email email to send login OTP to
 * @returns success string if login OTP is sent successfully
 */
export const sendLoginOtp = async (email: string): Promise<string> => {
  return ApiService.post<string>(`${AUTH_ENDPOINT}/otp/generate`, {
    email: email.toLowerCase(),
  }).then(({ data }) => data)
}

/**
 * Verifies the login OTP and returns the user if OTP is valid.
 * @param params.email the email to verify
 * @param params.otp the OTP sent to the given email to verify
 * @returns logged in user when successful
 * @throws Error on non 2xx response
 */
export const verifyLoginOtp = async (params: {
  otp: string
  email: string
}): Promise<UserDto> => {
  return ApiService.post<UserDto>(`${AUTH_ENDPOINT}/otp/verify`, params).then(
    ({ data }) => data,
  )
}

/**
 * Verifies the login OTP and returns the user if OTP is valid.
 * @param params.code the unique code returned from the ias server
 * @param params.state the unique stat-id to identify request unique
 * @param params.sessionState session state returned form the ias
 * @returns logged in user when successful
 * @throws Error on non 2xx response
 */
export const verifyWso2UserLogin = async (params: {
  code: string
  state: string
  sessionState: string
}): Promise<any> => {
  return ApiService.post<any>(
    `${AUTH_ENDPOINT}/wso2/users/logins/verifies`,
    params,
  ).then(({ data }) => data)
}

/**
 * Get wso2 login settings
 * @returns when settings are available
 * @throws Error on non 2xx response
 */
export const getWso2LoginSettings = async (): Promise<Wso2LoginSettingsDto> => {
  return ApiService.get(`${AUTH_ENDPOINT}/wso2/settings/logins`).then(
    ({ data }) => data,
  )
}

/**
 * Get wso2 logout settings
 * @returns when settings are available
 * @throws Error on non 2xx response
 */
export const getWso2LogoutSettings =
  async (): Promise<Wso2LogoutSettingsDto> => {
    return ApiService.get(`${AUTH_ENDPOINT}/wso2/settings/logouts`).then(
      ({ data }) => data,
    )
  }

export const logout = async (): Promise<void> => {
  return ApiService.get(`${AUTH_ENDPOINT}/logout`)
}
