export const ROOT_ROUTE_PERMISSION = 'DASHBOARD'
export const AGENCY_DASHBOARD_PERMISSION = 'AGENCY_DASHBOARD'

export const ADMINFORM_ROUTE_PERMISSION = 'ADMIN_FORM'

export const ADMIN_REPORT_PERMISSION = 'ADMIN_REPORT'
export const ADMIN_PROGRESS_REPORT_PERMISSION = 'ADMIN_PROGRESS_REPORT'
export const ADMIN_ARCHIVED_REPORT_PERMISSION = 'ADMIN_ARCHIVED_REPORT'
export const ADMIN_UNPUBLISHED_REPORT_PERMISSION = 'ADMIN_UNPUBLISHED_REPORT'

export const CREATE_USER_ADMIN_PERMISSION = 'MANAGE_USER'

/** Agency pages. */
export const AGENCY_CREATE_PERMISSION = 'MANAGE_AGENCY'
export const AGENCY_VIEW_PERMISSION = 'VIEW_AGENCY'
export const AGENCY_UPDATE_PERMISSION = 'MANAGE_AGENCY'
export const AGENCY_MANAGE_PERMISSION = 'VIEW_AGENCY'
export const SETTINGS_MANAGE_PERMISSION = 'MANAGE_APPLICATION_SETTINGS'
export const MANAGE_TITLE_PERMISSION = 'MANAGE_TITLES'
export const MANAGE_MASTERDATA_PERMISSION = 'MANAGE_MASTER_DATA'
export const VIEW_MASTERDATA_PERMISSION = 'VIEW_MASTER_DATA'
export const VIEW_USER_PROFILE_PERMISSION = 'VIEW_USER_PROFILE'
export const UPDATE_USER_PROFILE_PERMISSION = 'MANAGE_USER_PROFILE'

export const MANAGE_TEMPLATES_PERMISSION = 'VIEW_FORM_TEMPLATE'
export const MANAGE_FORMS_PERMISSION = 'MANAGE_FORMS'

export const MANAGE_REJECT_REASON_PERMISSION = 'VIEW_REJECT_REASON'

export const ACTIVITY_LOG_PERMISSION = 'VIEW_ACTIVITY'

export const AGENCY_REPORT_PERMISSION = 'AGENCY_REPORT'
export const AGENCY_PROGRESS_REPORT_PERMISSION = 'AGENCY_PROGRESS_REPORT'
export const AGENCY_ARCHIVED_REPORT_PERMISSION = 'AGENCY_ARCHIVED_REPORT'
export const AGENCY_UNPUBLISHED_REPORT_PERMISSION = 'AGENCY_UNPUBLISHED_REPORT'
export const RECONCILIATION_REPORT_PERMISSION = 'RECONCILIATION_REPORT'

/** User pages. */
export const USER_CREATE_PERMISSION = 'MANAGE_USER'
export const USER_VIEW_PERMISSION = 'VIEW_USER'
export const USER_UPDATE_PERMISSION = 'MANAGE_USER'
export const USER_MANAGE_PERMISSION = 'MANAGE_USER'

/** Agency User pages. */
export const AGENCY_USER_CREATE_PERMISSION = 'AGENCY_MANAGE_USER'
export const AGENCY_USER_VIEW_PERMISSION = 'AGENCY_VIEW_USER'
export const AGENCY_USER_UPDATE_PERMISSION = 'AGENCY_MANAGE_USER'
export const AGENCY_USER_MANAGE_PERMISSION = 'AGENCY_MANAGE_USER'

export const AGENCY_SETTINGS_PERMISSION = 'AGENCY_SETTINGS'
/** Notification pages. */
export const NOTIFICATION_PERMISSION = 'VIEW_AGENCY_NOTIFICATION_SETTINGS'
export const NOTIFICATION_UPDATE_PERMISSION =
  'MANAGE_AGENCY_NOTIFICATION_SETTINGS'

export const VIEW_SUBMISSION_PERMISSION = 'VIEW_SUBMISSION'
/** Citizen Portal pages */
export const CITIZEN_DASHBOARD_PERMISSION = 'CITIZEN_DASHBOARD'
export const CITIZEN_VIEW_SUBMISSION_PERMISSION = 'CITIZEN_VIEW_SUBMISSION'

/** Conversation routes */
export const CONVERSATION_MESSAGE_TYPE_PERMISSION =
  'MANAGE_CONVERSATION_SETTINGS'

/** Agency Details */
export const VIEW_AGENCY_PERMISSION = 'VIEW_AGENCY_DETAILS'
export const UPDATE_AGENCY_PERMISSION = 'MANAGE_AGENCY_DETAILS'
