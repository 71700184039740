import { Dispatch, SetStateAction } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export interface ProcessingPaymentModalProps {
  text: string
  setModalIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
}

export const ProcessingPaymentModal = ({
  text,
  isOpen,
  setModalIsOpen,
}: ProcessingPaymentModalProps): JSX.Element => {
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => console.log('closed')}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader h={10}></ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>{text}</ModalBody>

          <ModalFooter h={10}></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
