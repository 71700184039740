import { createContext, FC, useContext, useMemo, useState } from 'react'

type UserContextProps = any

const UserContext = createContext<UserContextProps | undefined>(undefined)

export const UserProvider: FC = ({ children }) => {
  const user = useProvide()

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext)
  //   if (!context) {
  //     throw new Error(`useUser must be used within a UserProvider component`)
  //   }

  return context
}

const useProvide = () => {
  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user) : {}

  return userData.permissions
}
