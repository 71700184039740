import { useQuery, UseQueryResult } from 'react-query'

import { TitleDto } from '~shared/types'

import { ApiService } from '~services/ApiService'

export const getTitles = async (): Promise<TitleDto[]> =>
  ApiService.get('/title').then(({ data }) => data?.titles)

const titleUserAdminKeys = {
  base: ['titleUserAdmin'],
}

export const useTitleUserAdmin = (): UseQueryResult<TitleDto[], unknown> => {
  return useQuery<TitleDto[]>(titleUserAdminKeys.base, () => getTitles(), {
    // 5 minutes.
    staleTime: 300000,
  })
}
