import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react'
import { isEmpty, isString, trimStart } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import isEmail from 'validator/lib/isEmail'

import {
  MAX_EMAIL_LENGTH,
  MAX_OTP_LENGTH,
  MAX_PHONE_LENGTH,
} from '~shared/constants'
import { isSLMobilePhoneNumber } from '~shared/utils/phone-num-validation'

import { BxShow, FluentEye, FluentEyeHide } from '~assets/icons'
import DefaultUserImage from '~assets/images/default-user.png'
import { CITIZEN_LOGIN_ROUTE, PASSWORD_POLICY_ROUTE } from '~constants/routes'
import { useDraggable } from '~hooks/useDraggable'
import { useToast } from '~hooks/useToast'
import {
  EMAIL_INVALID,
  EMAIL_VALIDATION_RULES,
  OLD_PASSWORD_VALIDATION_RULES,
  OTP_VALIDATION_RULES,
  PASSWORD_VALIDATION_RULES,
  PASSWORDS_MISMATCH,
  PHONE_INVALID,
  PHONE_VALIDATION_RULES,
  RE_ENTER_PASSWORD_VALIDATION_RULES,
} from '~utils/userValidation'
import { ApiService } from '~services/ApiService'
import {
  updateUser,
  validateUserContactSendOtpCitizen,
  validateUserContactVerifyOtpCitizen,
} from '~services/CitizenUserService'
import {
  validateUserContact,
  validateUserContactSendOtp,
  validateUserContactVerifyOtp,
} from '~services/UserService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormFieldMessage from '~components/FormControl/FormFieldMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input/index'
import Link from '~components/Link'
import ResendOtpButtonWithDelay from '~templates/ResendOtpButtonWithDelay'

import { LoggedIn } from '~pages/AdminForbiddenError/AdminForbiddenErrorPage.stories'
import { useTitleUserPublic } from '~pages/Citizen/onboardingScreen/queries'
import { convertNumberToSriLankaNumber } from '~pages/ManageUser/utils'

import { LanguageFilter } from '../../../../atom'
import {
  getMappedErrorMessage,
  handlePhoneValidation,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
  validateImageSize,
  validateProfilePictureFormat,
} from '../utils'

export type OtpWithPasswordFormInputs = {
  otp: string
  newPassword: string
  oldPassword: string
  reEnterNewPassword: string
  commonError?: string
}

// Page is copied out of admin portal. Update API calls as required.

export const CitizenUpdateUserProfilePage = (): JSX.Element | null => {
  const toast = useToast({ isClosable: false })
  const navigate = useNavigate()
  const { data } = useTitleUserPublic()
  const [profilePicFile, setProfilePicFile] = useState<File | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const imageUploadRef = useRef<HTMLInputElement | null>(null)
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  enum contactType {
    EMAIL = 'emails',
    PHONE = 'contacts',
  }

  type updatePersonalDetailsParams = {
    title: string
    gender: string
    firstName: string
    lastName: string
    nameInitials?: string
    identificationType: string
    nicNo?: string
    passportNo?: string
    passportCountry?: string
    drivingLicenseNo?: string
    preferredLanguage?: string
    profPicture?: string
  }

  type DropdownData = {
    value: string
    label: string
  }

  const { handleSubmit, register, formState, setError, watch } =
    useForm<OtpWithPasswordFormInputs>()

  const [errorNewPhoneNo, setErrorNewPhoneNo] = useState<string | null>(null)
  const [successNewPhoneNo, setSuccessNewPhoneNo] = useState<string | null>(
    null,
  )
  const [validatingNewPhoneNo, setValidatingNewPhoneNo] = useState<
    string | null
  >(null)
  const [isNewPhoneNoVerified, setIsNewPhoneNoVerified] =
    useState<boolean>(false)

  const [errorNewEmail, setErrorNewEmail] = useState<string | null>(null)
  const [successNewEmail, setSuccessNewEmail] = useState<string | null>(null)
  const [validatingNewEmail, setValidatingNewEmail] = useState<string | null>(
    null,
  )
  const [isNewEmailVerified, setIsNewEmailVerified] = useState<boolean>(false)
  const [idType, setIdType] = useState<string>('')
  const [isloggedIdSet, setIsloggedIdSet] = useState<boolean>(false)
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const [resendPhoneNoOtp, setResendPhoneNoOtp] = useState<boolean>(false)
  const [resendEmailOtp, setResendEmailOtp] = useState<boolean>(false)
  const { ref, onMouseDown } = useDraggable<HTMLDivElement>()
  const [pictureFile, setPictureFile] = useState<File | null>(null)
  const [profilePicture, setProfilePicture] = useState<string>('')
  const [pictureFileType, setPictureFileType] = useState<string>('image/png')
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showNewPasswordCopy, setShowNewPasswordCopy] = useState<boolean>(false)
  const locale: any = useRecoilValue(LanguageFilter)
  const [titleDropdownData, setTitleDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [dropdownData, setDropdownData] = useState<DropdownData>()

  const [result, setResult] = useState({
    _id: '',
    username: '',
    title: '',
    gender: '',
    firstName: '',
    lastName: '',
    identificationType: '',
    nicNo: '',
    passportNo: '',
    drivingLicenseNo: '',
    preferredLanguage: '',
    contact: '',
    email: '',
    passportCountry: '',
    signupSource: '',
    profPicture: '',
    nameInitials: '',
    userCategory: {
      code: '',
      name: '',
    },
    agency: {
      emailDomain: [],
      phoneNo: [],
      email: [],
      _id: '',
      shortName: '',
      fullName: '',
      logo: '',
      contactPerson: [],
    },
    userRole: {
      code: '',
      name: '',
    },
    createdBy: {
      userCategoryCode: '',
      userCategoryName: '',
      userId: '',
      userName: '',
    },
    lastModifiedBy: {
      userId: '',
      userName: '',
      userCategoryCode: '',
      userCategoryName: '',
    },
    createdAt: '',
    lastModifiedAt: '',
    _v: 0,
    lastAccessed: '',
  })

  async function getUser() {
    if (isloggedIdSet) {
      ApiService.get(`/public/users/${loggedUserId}`)
        .then((data: any) => {
          if (data?.status === 200) {
            setResult(data?.data.user)
            setIsLoaded(true)
          }
        })
        .catch((error) => {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        })
    }
  }

  useEffect(() => {
    const response = getUser()
  }, [loggedUserId, isloggedIdSet])

  const phoneNoSendOtpFormDefault = {
    newContact: '',
    contactType: contactType.PHONE,
  }
  const phoneNoVerifyOtpFormDefault = {
    otp: '',
    contactType: contactType.PHONE,
  }
  const phoneNoSendOtpFormValues = useForm({
    defaultValues: phoneNoSendOtpFormDefault,
  })
  const phoneNoVerifyOtpFormValues = useForm({
    defaultValues: phoneNoVerifyOtpFormDefault,
  })

  const emailSendOtpFormDefault = {
    newContact: '',
    contactType: contactType.EMAIL,
  }
  const emailVerifyOtpFormDefault = {
    otp: '',
    contactType: contactType.EMAIL,
  }
  const emailSendOtpFormValues = useForm({
    defaultValues: emailSendOtpFormDefault,
  })
  const emailVerifyOtpFormValues = useForm({
    defaultValues: emailVerifyOtpFormDefault,
  })

  useEffect(() => {
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setValidatingNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)
  }, [
    phoneNoVerifyOtpFormValues.formState.errors.otp,
    phoneNoSendOtpFormValues.formState.errors.newContact,
  ])

  useEffect(() => {
    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setValidatingNewEmail(null)
    setIsNewEmailVerified(false)
  }, [
    emailSendOtpFormValues.formState.errors.newContact,
    emailVerifyOtpFormValues.formState.errors.otp,
  ])

  const uploadToPresginedUrl = async (url: string) => {
    const headers = new Headers({ 'Content-Type': '*' })
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: pictureFile,
    })
  }

  const profilePicUpload = async () => {
    const profilePicParams = {
      fileName: pictureFile?.name,
      fileType: pictureFileType,
      userId: loggedUserId,
    }

    const uploadImage = await ApiService.post(
      `public/users/profileImage`,
      profilePicParams,
    )

    if (uploadImage.status === 200) {
      toast({
        title: '',
        description:
          locale == 'en'
            ? 'Profile Picture uploaded'
            : locale == 'si'
            ? 'පැතිකඩ පින්තූරය උඩුගත කරන ලදී'
            : 'சுயவிவரப் படம் பதிவேற்றப்பட்டது',
        duration: 5000,
        isClosable: true,
        status: 'success',
        position: 'top-right',
      })
    } else {
      toast({
        title: '',
        description: 'Something went wrong',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
    }

    if (uploadImage) {
      await uploadToPresginedUrl(uploadImage?.data?.presignedPostUrl)
    }
    console.log('uploadImage', uploadImage?.data)
    return uploadImage?.data?.randomizedImageId
  }

  const onSubmitMain = async (data: any) => {
    let logoKey = ''
    if (profilePicture && pictureFile) {
      logoKey = await profilePicUpload()

      onSubmitPersonalDetails(data, logoKey)
    } else {
      onSubmitPersonalDetails(data)
    }
  }

  const onSubmitPersonalDetails = (data: any, profPicture?: string) => {
    // TODO - Rewrite for citizen portal API
    setIsLoaded(false)

    const updatePersonalDetailsParams: updatePersonalDetailsParams = {
      title: mainFormValues.getValues('title'),
      gender: mainFormValues.getValues('gender'),
      firstName: mainFormValues.getValues('firstName'),
      lastName: mainFormValues.getValues('lastName'),
      nameInitials: mainFormValues.getValues('nameInitials'),
      identificationType: mainFormValues.getValues('identificationType'),
      nicNo: mainFormValues.getValues('idNo'),
      passportCountry: mainFormValues.getValues('passportCountry'),
      preferredLanguage: mainFormValues.getValues('preferredLanguage'),
      passportNo: '',
      drivingLicenseNo: '',
    }

    if (profilePicture && pictureFile) {
      updatePersonalDetailsParams.profPicture = profPicture
    } else if (!profilePicture && !pictureFile) {
      updatePersonalDetailsParams.profPicture = ''
    }

    if (data.identificationType === 'P') {
      updatePersonalDetailsParams['passportNo'] =
        mainFormValues.getValues('idNo')
      updatePersonalDetailsParams['passportCountry'] =
        mainFormValues.getValues('passportCountry')
      delete updatePersonalDetailsParams['nicNo']
      delete updatePersonalDetailsParams['drivingLicenseNo']
    } else if (data.identificationType === 'N') {
      updatePersonalDetailsParams['nicNo'] = mainFormValues.getValues('idNo')
      delete updatePersonalDetailsParams['passportNo']
      updatePersonalDetailsParams['passportCountry'] = ''
      delete updatePersonalDetailsParams['drivingLicenseNo']
    } else if (data.identificationType === 'D') {
      updatePersonalDetailsParams['drivingLicenseNo'] =
        mainFormValues.getValues('idNo')
      delete updatePersonalDetailsParams['nicNo']
      delete updatePersonalDetailsParams['passportNo']
      updatePersonalDetailsParams['passportCountry'] = ''
    }

    const requestBody = {
      user: updatePersonalDetailsParams,
    }

    ApiService.put(`/public/users/${loggedUserId}`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          setIsLoaded(true)
          const UserDetails = {
            _doc: data.data,
          }
          localStorage.setItem('user', JSON.stringify(UserDetails))
          console.log(data.data)
          toast({
            title: '',
            description:
              locale == 'en'
                ? 'User Personal Details Updated Successfully'
                : locale == 'si'
                ? 'පරිශීලක පුද්ගලික තොරතුරු සාර්ථකව යාවත්කාලීන කරන ලදී'
                : 'பயனரின் தனிப்பட்ட விவரங்கள் வெற்றிகரமாக புதுப்பிக்கப்பட்டன',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate('/citizen/view-user-profile')
        } else {
          toast({
            title: '',
            description:
              locale == 'en'
                ? 'Something Went Wrong'
                : locale == 'si'
                ? 'කිසියම් වැරැද්දක් වෙලා'
                : 'ஏதோ தவறு நடந்துவிட்டது',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description:
            locale == 'en'
              ? 'Something Went Wrong'
              : locale == 'si'
              ? 'කිසියම් වැරැද්දක් වෙලා'
              : 'ஏதோ தவறு நடந்துவிட்டது',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })

    // const params = {
    //   fileName: profilePicture,
    //   fileType: pictureFileType,
    // }

    // ApiService.post(`/user/profileImage`, params)
    //   .then((data: any) => {
    //     setIsLoaded(true)
    //     toast({
    //       title: '',
    //       description: 'Profile Picture uploaded',
    //       duration: 5000,
    //       isClosable: true,
    //       status: 'success',
    //       position: 'top-right',
    //     })
    //   })
    //   .catch((error) => {
    //     toast({
    //       title: '',
    //       description: 'Something went wrong',
    //       duration: 5000,
    //       isClosable: true,
    //       status: 'danger',
    //       position: 'top-right',
    //     })
    //   })
  }

  const onSubmitPhoneNoSendOtp = async (data: any) => {
    const { newContact, contactType } = data
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)

    const phoneNumber = convertNumberToSriLankaNumber(newContact)

    try {
      // Check whether telephone no is available to be used
      //const validateResult = await validateUserContact(newContact, contactType)
      // setSuccessNewPhoneNo(validateResult.message)

      // Send the OTP to verify the contact
      const sendOtpResult = await validateUserContactSendOtpCitizen(
        {
          userId: loggedUserId,
          phoneNo: phoneNumber,
        },
        contactType,
      )
      setSuccessNewPhoneNo(sendOtpResult.message)
      setValidatingNewPhoneNo(newContact)
      setTimeout(() => {
        setResendPhoneNoOtp(true)
      }, 10000)
    } catch (error) {
      if (error instanceof Error) setErrorNewPhoneNo(error.message)
    }
  }

  const onSubmitPhoneNoVerifyOtp = async (data: any) => {
    const { otp, contactType } = data
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)

    // Text field validation ensure the value. Just an additional check.
    if (!isString(otp) || isEmpty(otp)) {
      setErrorNewPhoneNo('OTP is required.')
      return
    }

    // Text field validation ensure the value. Just an additional check.
    if (!isString(validatingNewPhoneNo) || isEmpty(validatingNewPhoneNo)) {
      setErrorNewPhoneNo('OTP has not sent to your phone number yet.')
      return
    }

    const phoneNumber = convertNumberToSriLankaNumber(validatingNewPhoneNo)

    setValidatingNewPhoneNo(phoneNumber)

    try {
      const verifyOtpResult = await validateUserContactVerifyOtpCitizen(
        {
          userId: loggedUserId,
          phoneNo: phoneNumber,
          otp: otp,
        },
        contactType,
      )
      // setSuccessNewPhoneNo(verifyOtpResult.message)
      setIsNewPhoneNoVerified(true)
    } catch (error) {
      if (error instanceof Error) setErrorNewPhoneNo(error.message)
    }
  }

  const onSubmitEmailSendOtp = async (data: any) => {
    const { newContact, contactType } = data
    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setIsNewEmailVerified(false)
    try {
      // Check whether telephone no is available to be used
      //const validateResult = await validateUserContact(newContact, contactType)
      // setSuccessNewEmail(validateResult.message)

      // Send the OTP to verify the contact
      const sendOtpResult = await validateUserContactSendOtpCitizen(
        {
          userId: loggedUserId,
          newEmail: newContact,
        },
        contactType,
      )
      setSuccessNewEmail(sendOtpResult.message)
      setValidatingNewEmail(newContact)
      setTimeout(() => {
        setResendEmailOtp(true)
      }, 10000)
    } catch (error) {
      if (error instanceof Error) setErrorNewEmail(error.message)
    }
  }

  const onSubmitEmailVerifyOtp = async (data: any) => {
    emailSendOtpFormValues.clearErrors('newContact')
    const { otp, contactType } = data
    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setIsNewEmailVerified(false)

    // Text field validation ensure the value. Just an additional check.
    if (!isString(otp) || isEmpty(otp)) {
      setErrorNewEmail('OTP is required.')
      return
    }

    // Text field validation ensure the value. Just an additional check.
    if (!isString(validatingNewEmail) || isEmpty(validatingNewEmail)) {
      setErrorNewEmail('OTP has not sent to your email address yet.')
      return
    }

    try {
      const verifyOtpResult = await validateUserContactVerifyOtpCitizen(
        {
          userId: loggedUserId,
          newEmail: validatingNewEmail,
          otp: otp,
        },
        contactType,
      )
      // setSuccessNewEmail(verifyOtpResult.message)
      setIsNewEmailVerified(true)
    } catch (error) {
      if (error instanceof Error) setErrorNewEmail(error.message)
    }
  }

  const handleUserContactUpdateSave = async () => {
    toast.closeAll()
    const user: any = {}
    // alert(user)
    if (isNewPhoneNoVerified) user.contact = validatingNewPhoneNo
    if (isNewEmailVerified) user.email = validatingNewEmail
    if (emailSendOtpFormValues.formState.errors.newContact) return

    try {
      const verifyOtpResult = await updateUser({ user: user }, loggedUserId)
      const UserDetails = {
        _doc: verifyOtpResult,
      }
      localStorage.setItem('user', JSON.stringify(UserDetails))
      console.log(verifyOtpResult)
      toast({
        status: 'success',
        description: 'User Contact Details Updated Successfully',
        position: 'top',
      })
      navigate('/citizen/view-user-profile')
    } catch (error) {
      let errorMessage = undefined
      if (error instanceof Error) {
        errorMessage = error.message
      }
      emailSendOtpFormValues.setError('newContact', {
        type: 'focus',
        message: errorMessage,
      })
    }
  }

  const handleUserUpdateCancel = async () => {
    setShowAlert(true)
  }

  const handleUserUpdateCancelYes = async () => {
    // Close the modal
    setShowAlert(false)

    navigate(-1)
  }

  const validatePhoneNo = useCallback((value: string) => {
    return isSLMobilePhoneNumber(value.trim()) || PHONE_INVALID
  }, [])
  const validateEmail = useCallback((value: string) => {
    return isEmail(value.trim()) || EMAIL_INVALID
  }, [])

  useEffect(() => {
    // Populate the current user details. Taking from the local store.
    handleGetUser()
  }, [])

  const handleGetUser = async () => {
    // Find logged in user
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
        setIsloggedIdSet(true)
      }

      // Set current contact details
      // if (loggedUserDetails?._doc?.contact) {
      //   phoneNoSendOtpFormValues.setValue(
      //     'newContact',
      //     loggedUserDetails?._doc?.contact,
      //   )
      // }
      // if (loggedUserDetails?._doc?.email) {
      //   emailSendOtpFormValues.setValue(
      //     'newContact',
      //     loggedUserDetails?._doc?.email,
      //   )
      // }

      phoneNoVerifyOtpFormValues.setValue('otp', '')
      emailVerifyOtpFormValues.setValue('otp', '')
    } else {
      // TODO: Should not proceed if user is unavailable
      toast.closeAll()
      toast({
        status: 'danger',
        description: 'User details not found',
        position: 'top',
      })
    }
  }

  useEffect(() => {
    if (pictureFile !== null) {
      setProfilePicture(URL.createObjectURL(new Blob([pictureFile])))
    }
  }, [pictureFile])

  useEffect(() => {
    setProfilePicture(result?.profPicture)
  }, [result])

  const removeImage = () => {
    setProfilePicture('')
    setPictureFile(null)
  }

  const validateProfilePicture = (file: File): boolean => {
    const validProfilePictureFormat = validateProfilePictureFormat(file?.name)
    if (!validProfilePictureFormat) {
      toast({
        title: '',
        description: 'Profile Picture can be only of types png, jpg, jpeg',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    const isImgSizeValid = validateImageSize(file, 2000000)
    if (!isImgSizeValid) {
      toast({
        title: '',
        description: 'Image has to be less than 2MB',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    return true
  }

  const mainFormValues = useForm({
    defaultValues: {
      userName: '',
      title: '',
      gender: '',
      firstName: '',
      lastName: '',
      nameInitials: '',
      idNo: '',
      preferredLanguage: '',
      identificationType: '',
      district: '',
      passportCountry: '',
      designation: '',
    },
  })

  const enteredTitle = mainFormValues.watch('title')

  useEffect(() => {
    titlesGenderDropDown()
    let titleArray = []
    for (const i in titlesGenderDropdownData) {
      titleArray = titlesGenderDropdownData[i].value.split('/')
      if (mainFormValues.getValues('title') == titleArray[1]) {
        if (titleArray[0] == 'undefined') {
          mainFormValues.setValue('gender', result?.gender)
        } else {
          mainFormValues.setValue('gender', titleArray[0])
        }
      }
    }

    mainFormValues.clearErrors('title')

    mainFormValues.clearErrors('gender')
  }, [enteredTitle])

  useEffect(() => {
    mainFormValues.setValue(
      'userName',
      result?.username ? result?.username : '',
    )
    mainFormValues.setValue('title', result?.title ? result?.title : '')

    mainFormValues.setValue('gender', result?.gender ? result?.gender : '')
    mainFormValues.setValue(
      'firstName',
      result?.firstName ? result?.firstName : '',
    )
    mainFormValues.setValue(
      'lastName',
      result?.lastName ? result?.lastName : '',
    )
    mainFormValues.setValue(
      'nameInitials',
      result?.nameInitials ? result?.nameInitials : '',
    )
    mainFormValues.setValue(
      'identificationType',
      result?.identificationType
        ? result?.identificationType === 'N'
          ? 'N'
          : result?.identificationType === 'P'
          ? 'P'
          : 'D'
        : '',
    )
    mainFormValues.setValue(
      'idNo',
      result?.identificationType === 'N'
        ? result?.nicNo
          ? result?.nicNo
          : '-'
        : result?.identificationType === 'P'
        ? result?.passportNo
          ? result?.passportNo
          : '-'
        : result?.drivingLicenseNo
        ? result?.drivingLicenseNo
        : '-',
    )
    mainFormValues.setValue(
      'passportCountry',
      result?.passportCountry ? result?.passportCountry : '-',
    )
    mainFormValues.setValue(
      'preferredLanguage',
      result?.preferredLanguage ? result?.preferredLanguage : '',
    )
    mainFormValues.setValue('designation', result?.title ? result?.title : '')
    phoneNoSendOtpFormValues.setValue(
      'newContact',
      result?.contact ? '0' + result.contact.substring(3) : '-',
    )
    emailSendOtpFormValues.setValue(
      'newContact',
      result?.email ? result?.email : '',
    )
  }, [result])

  const userName = mainFormValues.getValues('userName')

  const validateReEnteredNewPassword = useCallback(
    (value: string) => {
      if (watch('newPassword') !== value) {
        return PASSWORDS_MISMATCH
      }
    },
    [watch],
  )

  // password
  const onSubmitNewPassword = async (inputs: OtpWithPasswordFormInputs) => {
    setIsLoaded(false)

    const params = {
      oldPassword: inputs?.oldPassword,
      newPassword: inputs?.reEnterNewPassword,
    }

    ApiService.put(`/public/users/password/update`, params)
      .then((data: any) => {
        if (data.status === 200) {
          setIsLoaded(true)
          toast({
            title: '',
            description: 'User Credentials Updated Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          toast({
            title: '',
            description: 'Logged out. Please log in again',
            duration: 5000,
            isClosable: true,
            status: 'warning',
            position: 'top-right',
          })
          navigate(CITIZEN_LOGIN_ROUTE)
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: error.message,
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const onResendOtp = async () => {
    setIsLoaded(false)

    const params = {
      username: mainFormValues.getValues('userName'),
    }

    ApiService.post(`/user/password/profile/otp/generate`, params)
      .then((data: any) => {
        if (data.status === 200) {
          setIsLoaded(true)
          toast({
            title: '',
            description: 'OTP Sent',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const titlesDropdownData: DropdownData[] = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      titlesDropdownData.push({
        value: `${value?.title}`,
        label: value?.title,
      })
    })
  }

  mapDataToDropDown()

  const titlesGenderDropdownData: DropdownData[] = []
  const titlesGenderDropDown = () => {
    data?.map((value) => {
      titlesGenderDropdownData.push({
        value: `${value?.gender}/${value?.title}`,
        label: value?.title,
      })
    })
  }

  const mapDataToTitleDropdown = (array: any[]) => {
    const titleData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.title,
        gender: value.gender,
      }
      titleData.push(obj)
    })
    setTitleDropdownData(titleData)
    //setTitleArray(titleData)
  }

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    mainFormValues.clearErrors('idNo')
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(5)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      setIdNumberRegex(SL_NIC_REGEX)
    } else {
      setIdType('D')
      setMaxLength(8)
      setMinLength(7)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    }
    // mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  const messages: any = {
    en: {
      viewUserProfile: 'View User Profile',
      personalDetails: 'Personal Details',
      username: 'Username:',
      title: 'Title:*',
      gender: 'Gender:*',
      firstName: 'First Name:*',
      lastName: 'Last Name:*',
      namewithInitials: 'Name with Initials:',
      identificationType: 'Identification Type:*',
      identificationNumber: 'Identification Number:*',
      countryofPassport: 'Country of Passport:*',
      preferredLanguage: 'Preferred Language:*',
      contactDetails: 'Contact Details',
      phoneNumber: 'Phone Number:',
      emailAddress: 'Email Address:',
      otherDetails: 'Other Details',
      signUpvia: 'Sign Up via:',
      lastLoggedat: 'Last Logged at:',
      back: 'Back',
      update: 'Update',
      updateUserProfile: 'Update User Profile ',
      confirm: 'Confirm',
      areyousureyouwanttocancel: 'Are you sure you want to cancel?',
      yes: 'Yes',
      no: 'No',
      credentials: 'Credentials',
      upload: 'Upload',
      remove: 'Remove',
      enterOTP: 'Enter OTP',
      verify: 'Verify',
      verified: 'Verified',
      save: 'Save',
      password: 'Password:',
      oldPassword: 'Old Password*',
      newPassword: 'New Password*',
      reenterNewpassword: 'Re-enter New Password*',
      cancel: 'Cancel',
      resendOTP: 'Resend OTP',
      generateOTP: 'Generate OTP',
      drivingLicenseNumber: 'Driving License Number*',
      passportNumber: 'Passport Number*',
      nicNumber: 'NIC Number*',
      imagineRemoveConfirm: 'Are you sure you want to remove the image?',
      note: 'Note: If you change the Email, you wont be able to login to the system using Google/Facebook again.',
    },
    si: {
      viewUserProfile: 'පරිශීලක පැතිකඩ බලන්න',
      personalDetails: 'පුද්ගලික තොරතුරු',
      username: 'පරිශීලක නාමය:',
      title: 'මාතෘකාව:*',
      gender: 'ස්ත්‍රී පුරුෂ භාවය:*',
      firstName: 'මුල් නම:*',
      lastName: 'අවසන් නම:*',
      namewithInitials: 'මුලකුරු සමඟ නම:*',
      identificationType: 'අනන්‍යතාවයේ වර්ගය:*',
      identificationNumber: 'හඳුනාගැනීමේ අංකය:*',
      countryofPassport: 'විදේශ ගමන් බලපත්‍රය නිකුත් කළ රට:*',
      preferredLanguage: 'කැමති භාෂාව:*',
      contactDetails: 'ඇමතුම් විස්තර',
      phoneNumber: 'දුරකථන අංකය:',
      emailAddress: 'විද්‍යුත් තැපැල් ලිපිනය:',
      otherDetails: 'වෙනත් විස්තර',
      signUpvia: 'හරහා ලියාපදිංචි වන්න:',
      lastLoggedat: 'අවසන් වරට ලොග් විය:',
      back: 'ආපසු',
      update: 'යාවත්කාලීන කරන්න',
      updateUserProfile: 'පරිශීලක පැතිකඩ යාවත්කාලීන කරන්න',
      confirm: 'තහවුරු කරන්න',
      areyousureyouwanttocancel: 'ඔබට අවලංගු කිරීමට අවශ්‍ය බව විශ්වාසද?',
      yes: 'ඔව්',
      no: 'නැත',
      credentials: 'අක්තපත්‍ර',
      upload: 'උඩුගත කරන්න',
      remove: 'ඉවත් කරන්න',
      enterOTP: 'OTP ඇතුලත් කරන්න',
      verify: 'තහවුරු කරන්න',
      verified: 'තහවුරු කර ඇත',
      save: 'සුරකින්න',
      password: 'මුරපදය:',
      oldPassword: 'පැරණි මුරපදය*',
      newPassword: 'නව මුරපදය*',
      reenterNewpassword: 'නව මුරපදය නැවත ඇතුල් කරන්න*',
      cancel: 'අවලංගු කරන්න',
      resendOTP: 'OTP නැවත යවන්න',
      generateOTP: 'OTP ජනනය කරන්න',
      drivingLicenseNumber: 'රියදුරු බලපත්ර අංකය*',
      passportNumber: 'ගමන් බලපත්ර අංකය*',
      nicNumber: 'ජාතික හැඳුනුම්පත් අංකය*',
      imagineRemoveConfirm: 'ඔබට පින්තූරය ඉවත් කිරීමට අවශ්‍ය ද?',
      note: 'සටහන: ඔබ විද්‍යුත් තැපෑල වෙනස් කළහොත්, ඔබට නැවත ගූගල්/ෆේස්බුක් භාවිතයෙන් පද්ධතියට පුරනය වීමට නොහැකි වනු ඇත.',
    },
    ta: {
      viewUserProfile: 'பயனர் சுயவிவரத்தைக் காண்க',
      personalDetails: 'தனிப்பட்ட விவரங்கள்',
      username: 'பயனர் பெயர்:',
      title: 'தலைப்பு:*',
      gender: 'பாலினம்:*',
      firstName: 'முதல் பெயர்:*',
      lastName: 'கடைசி பெயர்:*',
      namewithInitials: 'முதலெழுத்துக்களுடன் பெயர்:*',
      identificationType: 'அடையாள வகை:*',
      identificationNumber: 'அடையாள எண்:*',
      countryofPassport: 'பாஸ்போர்ட் நாடு:*',
      preferredLanguage: 'விருப்பமான மொழி:*',
      contactDetails: 'தொடர்பு விபரங்கள்',
      phoneNumber: 'தொலைபேசி எண்:',
      emailAddress: 'மின்னஞ்சல் முகவரி:',
      otherDetails: 'வேறு தகவல்கள்',
      signUpvia: 'மூலம் பதிவு செய்யவும்:',
      lastLoggedat: 'கடைசியாக உள்நுழைந்தது:',
      back: 'மீண்டும்',
      update: 'புதுப்பிக்கவும்',
      updateUserProfile: 'பயனர் சுயவிவரத்தைப் புதுப்பிக்கவும்',
      confirm: 'உறுதிப்படுத்தவும்',
      areyousureyouwanttocancel: 'நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா?',
      yes: 'ஆம்',
      no: 'இல்லை',
      credentials: 'சான்றுகளை',
      upload: 'பதிவேற்றவும்',
      remove: 'அகற்று',
      enterOTP: 'OTP ஐ உள்ளிடவும்',
      verify: 'சரிபார்க்கவும்',
      verified: 'சரிபார்க்கப்பட்டது',
      save: 'சேமிக்கவும்',
      password: 'கடவுச்சொல்:',
      oldPassword: 'பழைய கடவுச்சொல்*',
      newPassword: 'புதிய கடவுச்சொல்*',
      reenterNewpassword: 'புதிய கடவுச்சொல்லை மீண்டும் உள்ளிடவும்*',
      cancel: 'ரத்து செய்',
      resendOTP: 'OTP ஐ மீண்டும் அனுப்பவும்',
      generateOTP: 'OTP ஐ உருவாக்கவும்',
      drivingLicenseNumber: 'ஓட்டுநர் உரிம எண்*',
      passportNumber: 'கடவுச்சீட்டு எண்*',
      nicNumber: 'தேசிய அடையாள அட்டை எண்*',
      imagineRemoveConfirm: 'இந்தப் படத்தை நிச்சயமாக அகற்ற விரும்புகிறீர்களா?',
      note: 'குறிப்பு: நீங்கள் மின்னஞ்சலை மாற்றினால், மீண்டும் கூகிள்/முகநூல் ஐப் பயன்படுத்தி கணினியில் உள்நுழைய முடியாது.',
    },
  }

  const isDesktopView = useBreakpointValue({ sm: false, md: false, lg: true })
  const isDesktopTabView = useBreakpointValue({
    sm: false,
    md: true,
    lg: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {showAlert && (
          <Modal
            isOpen={showAlert}
            onClose={() => {
              setShowAlert(false)
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <FormattedMessage
                  id="confirm"
                  values={{ locale }}
                ></FormattedMessage>
              </ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <FormattedMessage
                  id="areyousureyouwanttocancel"
                  values={{ locale }}
                ></FormattedMessage>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      handleUserUpdateCancelYes()
                    }}
                  >
                    <FormattedMessage
                      id="yes"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                  <Button
                    onClick={() => {
                      setShowAlert(false)
                    }}
                  >
                    <FormattedMessage
                      id="no"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        {isConfirmOpen && (
          <Modal
            isOpen={isConfirmOpen}
            onClose={() => {
              setIsConfirmOpen(false)
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <FormattedMessage
                  id="confirm"
                  values={{ locale }}
                ></FormattedMessage>
              </ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <FormattedMessage
                  id="imagineRemoveConfirm"
                  values={{ locale }}
                ></FormattedMessage>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      setIsConfirmOpen(false)
                      removeImage()
                    }}
                  >
                    <FormattedMessage
                      id="yes"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                  <Button
                    onClick={() => {
                      setIsConfirmOpen(false)
                    }}
                  >
                    <FormattedMessage
                      id="no"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        <Box bg={'gray.50'} p={10}>
          <Box height="50px">
            <Heading as="h4" size="md">
              <FormattedMessage
                id="updateUserProfile"
                values={{ locale }}
              ></FormattedMessage>
            </Heading>
          </Box>

          <Tabs variant="unstyled">
            <TabList
              ref={ref}
              onMouseDown={onMouseDown}
              pt={{ base: '0.625rem', lg: 0 }}
              px={{ base: '1.25rem', lg: '1rem' }}
              w={{ base: '100%', sm: '100%', lg: 'initial' }}
              gridArea="tabs"
              borderBottom="none"
              justifyContent={{ base: 'flex-start', lg: 'center' }}
              alignSelf="center"
            >
              <Tab
                style={{ fontWeight: 'bold' }}
                _selected={{ bg: 'gray.400' }}
              >
                <FormattedMessage
                  id="personalDetails"
                  values={{ locale }}
                ></FormattedMessage>
              </Tab>
              <Tab
                style={{ fontWeight: 'bold' }}
                _selected={{ bg: 'gray.400' }}
              >
                <FormattedMessage
                  id="contactDetails"
                  values={{ locale }}
                ></FormattedMessage>
              </Tab>
              {result?.signupSource === 'self' ? (
                <Tab
                  style={{ fontWeight: 'bold' }}
                  _selected={{ bg: 'gray.400' }}
                >
                  <FormattedMessage
                    id="credentials"
                    values={{ locale }}
                  ></FormattedMessage>
                </Tab>
              ) : null}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box borderRadius="5" border="1px" borderColor="black.150">
                  <Heading as="h6" size="md" padding="10px">
                    <FormattedMessage
                      id="personalDetails"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Heading>
                  <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="60px">
                      <dl>
                        {result?.signupSource === 'self' ? (
                          <div style={{ display: 'flex' }}>
                            <dd
                              style={{
                                flex: '50%',
                                padding: '10px',
                                fontWeight: 500,
                              }}
                            >
                              <FormattedMessage
                                id="username"
                                values={{ locale }}
                              ></FormattedMessage>
                            </dd>
                            <dt
                              style={{
                                flex: '50%',
                                padding: '10px',
                                maxWidth: '100%',
                              }}
                            >
                              {userName}
                            </dt>
                          </div>
                        ) : null}
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.title
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="title" mb="8px">
                                <FormattedMessage
                                  id="title"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Controller
                                name="title"
                                control={mainFormValues.control}
                                render={({ field }) => (
                                  <SingleSelect
                                    {...field}
                                    items={titlesDropdownData}
                                  />
                                )}
                                rules={{ required: 'Title is required' }}
                              />
                              {mainFormValues.formState.errors.title && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors.title
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.gender
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="gender" mb="8px">
                                <FormattedMessage
                                  id="gender"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Controller
                                name="gender"
                                control={mainFormValues.control}
                                render={({ field }) => (
                                  <SingleSelect
                                    {...field}
                                    items={[
                                      {
                                        value: 'M',
                                        label:
                                          locale === 'en'
                                            ? 'Male'
                                            : locale === 'si'
                                            ? 'පිරිමි'
                                            : 'ஆண்',
                                      },
                                      {
                                        value: 'F',
                                        label:
                                          locale === 'en'
                                            ? 'Female'
                                            : locale === 'si'
                                            ? 'ගැහැණු'
                                            : 'பெண்',
                                      },
                                    ]}
                                  />
                                )}
                                rules={{ required: 'Gender is required' }}
                              />
                              {mainFormValues.formState.errors.gender && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors.gender
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.firstName
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="firstName" mb="8px">
                                <FormattedMessage
                                  id="firstName"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Input
                                placeholder={
                                  locale === 'en'
                                    ? 'First Name'
                                    : locale === 'si'
                                    ? 'මුල් නම'
                                    : 'முதல் பெயர்'
                                }
                                id="firstName"
                                maxLength={100}
                                {...mainFormValues.register('firstName', {
                                  required: 'First Name is required.',
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100',
                                  },
                                })}
                              />
                              {mainFormValues.formState.errors.firstName && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors.firstName
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.lastName
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="lastName" mb="8px">
                                <FormattedMessage
                                  id="lastName"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Input
                                placeholder={
                                  locale === 'en'
                                    ? 'Last Name'
                                    : locale === 'si'
                                    ? 'අවසන් නම'
                                    : 'கடைசி பெயர்'
                                }
                                id="lastName"
                                maxLength={100}
                                {...mainFormValues.register('lastName', {
                                  required: 'Last Name is required.',
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100',
                                  },
                                })}
                              />
                              {mainFormValues.formState.errors.lastName && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors.lastName
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.nameInitials
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="nameInitials" mb="8px">
                                <FormattedMessage
                                  id="namewithInitials"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Input
                                placeholder={
                                  locale === 'en'
                                    ? 'Name with Initials'
                                    : locale === 'si'
                                    ? 'මුලකුරු සමඟ නම'
                                    : 'முதலெழுத்துக்களுடன் பெயர்'
                                }
                                id="nameInitials"
                                maxLength={100}
                                {...mainFormValues.register('nameInitials', {
                                  maxLength: {
                                    value: 100,
                                    message: 'Max length is 100',
                                  },
                                })}
                              />
                              {mainFormValues.formState.errors.nameInitials && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors.nameInitials
                                      .message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors
                                  ?.identificationType
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel
                                htmlFor="identificationType"
                                mb="8px"
                              >
                                <FormattedMessage
                                  id="identificationType"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Controller
                                name="identificationType"
                                control={mainFormValues.control}
                                defaultValue={result?.identificationType}
                                render={({ field }) => (
                                  <SingleSelect
                                    {...field}
                                    items={[
                                      {
                                        value: 'N',
                                        label:
                                          locale === 'en'
                                            ? 'NIC'
                                            : locale === 'si'
                                            ? 'ජාතික හැඳුනුම්පත'
                                            : 'தேசிய அடையாள அட்டை',
                                      },
                                      {
                                        value: 'P',
                                        label:
                                          locale === 'en'
                                            ? 'Passport'
                                            : locale === 'si'
                                            ? 'ගමන් බලපත්‍රය'
                                            : 'கடவுச்சீட்டு',
                                      },
                                      {
                                        value: 'D',
                                        label:
                                          locale === 'en'
                                            ? 'Driving License'
                                            : locale === 'si'
                                            ? 'රියදුරු බලපත්‍රය'
                                            : 'ஓட்டுநர் உரிமம்',
                                      },
                                    ]}
                                  />
                                )}
                                rules={{
                                  required: ' Identification Type is required',
                                }}
                              />
                              {mainFormValues.formState.errors
                                .identificationType && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors
                                      .identificationType.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors?.idNo
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel htmlFor="idNo" mb="8px">
                                {idType === 'N' ? (
                                  <FormattedMessage
                                    id="nicNumber"
                                    values={{ locale }}
                                  />
                                ) : idType === 'D' ? (
                                  <FormattedMessage
                                    id="drivingLicenseNumber"
                                    values={{ locale }}
                                  />
                                ) : idType === 'P' ? (
                                  <FormattedMessage
                                    id="passportNumber"
                                    values={{ locale }}
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="identificationNumber"
                                    values={{ locale }}
                                  />
                                )}
                              </ChakraFormLabel>
                              <Input
                                placeholder={
                                  idType === 'N'
                                    ? locale === 'en'
                                      ? 'NIC Number'
                                      : locale === 'si'
                                      ? 'ජා.හැ.අං'
                                      : 'தே.அ.'
                                    : idType === 'D'
                                    ? locale === 'en'
                                      ? 'Driving License Number'
                                      : locale === 'si'
                                      ? 'රියදුරු බලපත් අංකය'
                                      : 'இயக்க விருப்பு எண்'
                                    : idType === 'P'
                                    ? locale === 'en'
                                      ? 'Passport Number'
                                      : locale === 'si'
                                      ? 'පාස්පෝට් අංකය'
                                      : 'கடவுச்சீட்டு எண்'
                                    : locale === 'en'
                                    ? 'Identification Number'
                                    : locale === 'si'
                                    ? 'හඳුනාගැනීමේ අංකය'
                                    : 'அடையாள எண்'
                                }
                                id="idNo"
                                {...mainFormValues.register('idNo', {
                                  required:
                                    'Identification Number is required.',
                                  maxLength: {
                                    value: maxLength,
                                    message:
                                      idType === 'N'
                                        ? 'Max length is 12'
                                        : idType === 'D'
                                        ? 'Max length is 8'
                                        : 'Max length is 30',
                                  },
                                  minLength: {
                                    value: minLength,
                                    message:
                                      idType === 'N'
                                        ? 'Min length is 10'
                                        : idType === 'D'
                                        ? 'Min length is 7'
                                        : 'Min length is 8',
                                  },
                                  pattern: {
                                    value: idNumberRegex,
                                    message: 'Invalid Identification Number',
                                  },
                                })}
                              />
                              {mainFormValues.formState.errors.idNo && (
                                <FormErrorMessage>
                                  {mainFormValues.formState.errors.idNo.message}
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>

                        {idType === 'P' && (
                          <div style={{ display: 'flex' }}>
                            <Box
                              style={{
                                flex: '50%',
                                padding: '10px',
                                fontWeight: 500,
                              }}
                            >
                              <FormControl
                                isInvalid={
                                  mainFormValues?.formState?.errors
                                    ?.passportCountry
                                    ? true
                                    : false
                                }
                              >
                                <ChakraFormLabel
                                  htmlFor="passportCountry"
                                  mb="8px"
                                >
                                  <FormattedMessage
                                    id="countryofPassport"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </ChakraFormLabel>
                                <Controller
                                  name="passportCountry"
                                  control={mainFormValues.control}
                                  defaultValue={
                                    result?.passportCountry !== '' ||
                                    result?.passportCountry !== undefined
                                      ? result?.passportCountry
                                      : undefined
                                  }
                                  render={({ field }) => (
                                    <SingleSelect
                                      {...field}
                                      items={[
                                        {
                                          value: 'SRI_LANKA',
                                          label:
                                            locale === 'en'
                                              ? 'Sri Lanka'
                                              : locale === 'si'
                                              ? 'ශ්‍රී ලංකාව'
                                              : 'இலங்கை',
                                        },
                                      ]}
                                    />
                                  )}
                                  rules={{
                                    required: 'Country of Passport is required',
                                  }}
                                />
                                {mainFormValues.formState.errors
                                  .passportCountry && (
                                  <FormErrorMessage>
                                    {
                                      mainFormValues.formState.errors
                                        .passportCountry.message
                                    }
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                            </Box>
                          </div>
                        )}
                        <div style={{ display: 'flex' }}>
                          <Box
                            style={{
                              flex: '50%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                mainFormValues?.formState?.errors
                                  ?.preferredLanguage
                                  ? true
                                  : false
                              }
                            >
                              <ChakraFormLabel
                                htmlFor="preferredLanguage"
                                mb="8px"
                              >
                                <FormattedMessage
                                  id="preferredLanguage"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <Controller
                                name="preferredLanguage"
                                control={mainFormValues.control}
                                defaultValue={result?.preferredLanguage}
                                render={({ field }) => (
                                  <SingleSelect
                                    {...field}
                                    items={[
                                      {
                                        value: 'English',
                                        label: 'English',
                                      },
                                      {
                                        value: 'Sinhala',
                                        label: 'සිංහල',
                                      },
                                      {
                                        value: 'Tamil',
                                        label: 'தமிழ்',
                                      },
                                    ]}
                                  />
                                )}
                                rules={{
                                  required: ' Preferred Language is required',
                                }}
                              />
                              {mainFormValues.formState.errors
                                .preferredLanguage && (
                                <FormErrorMessage>
                                  {
                                    mainFormValues.formState.errors
                                      .preferredLanguage.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </div>
                      </dl>

                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                        {isDesktopView ? (
                          <Box h="250" w="250px">
                            <Center>
                              <Box h="200px" w="200px">
                                <Box bg={'#ECEFF1'} borderRadius="50%">
                                  <Image
                                    h="200px"
                                    w="200px"
                                    borderRadius="full"
                                    src={profilePicture}
                                    fallbackSrc={DefaultUserImage}
                                  />
                                </Box>
                              </Box>
                            </Center>
                            {/* <Box bg={'gray.400'} h="250px" w="200px" p={5}>
                            {profilePicture !== '' ? (
                              <Image
                                h="200px"
                                w="150px"
                                m={'2px'}
                                alt="profile picture"
                                src={profilePicture}
                              />
                            ) : null}
                          </Box> */}
                            <Button
                              marginTop="10px"
                              width="250px"
                              onClick={() => imageUploadRef.current?.click()}
                            >
                              <FormattedMessage
                                id="upload"
                                values={{ locale }}
                              ></FormattedMessage>
                            </Button>
                            <Button
                              marginTop="10px"
                              width="250px"
                              onClick={() => setIsConfirmOpen(true)}
                              isDisabled={profilePicture === ''}
                            >
                              <FormattedMessage
                                id="remove"
                                values={{ locale }}
                              ></FormattedMessage>
                            </Button>

                            <Input
                              name="userProfilePic"
                              type="file"
                              ref={imageUploadRef}
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={(e) => {
                                if (!e.target.files) return
                                const validProfilePictureFormat =
                                  validateProfilePicture(e?.target?.files[0])
                                if (!validProfilePictureFormat) {
                                  return
                                }
                                setPictureFile(e?.target?.files[0])
                                setPictureFileType(e?.target?.files[0].type)
                                if (imageUploadRef.current) {
                                  imageUploadRef.current.value = ''
                                }
                              }}
                              display="none"
                            />
                          </Box>
                        ) : (
                          <Center>
                            <Box h="250" w="250px">
                              <Center>
                                <Box h="200px" w="200px">
                                  <Box bg={'#ECEFF1'} borderRadius="50%">
                                    <Image
                                      h="200px"
                                      w="200px"
                                      borderRadius="full"
                                      src={profilePicture}
                                      fallbackSrc={DefaultUserImage}
                                    />
                                  </Box>
                                </Box>
                              </Center>
                              {/* <Box bg={'gray.400'} h="250px" w="200px" p={5}>
                            {profilePicture !== '' ? (
                              <Image
                                h="200px"
                                w="150px"
                                m={'2px'}
                                alt="profile picture"
                                src={profilePicture}
                              />
                            ) : null}
                          </Box> */}
                              <Button
                                marginTop="10px"
                                width="250px"
                                onClick={() => imageUploadRef.current?.click()}
                              >
                                <FormattedMessage
                                  id="upload"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Button>
                              <Button
                                marginTop="10px"
                                width="250px"
                                onClick={() => setIsConfirmOpen(true)}
                                isDisabled={profilePicture === ''}
                              >
                                <FormattedMessage
                                  id="remove"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Button>

                              <Input
                                name="userProfilePic"
                                type="file"
                                ref={imageUploadRef}
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => {
                                  if (!e.target.files) return
                                  const validProfilePictureFormat =
                                    validateProfilePicture(e?.target?.files[0])
                                  if (!validProfilePictureFormat) {
                                    return
                                  }
                                  setPictureFile(e?.target?.files[0])
                                  setPictureFileType(e?.target?.files[0].type)
                                  if (imageUploadRef.current) {
                                    imageUploadRef.current.value = ''
                                  }
                                }}
                                display="none"
                              />
                            </Box>
                          </Center>
                        )}
                      </SimpleGrid>
                    </SimpleGrid>{' '}
                    <Box height="72px"></Box>
                    <Center>
                      <ButtonGroup variant="outline" spacing="3" padding={10}>
                        <Button type="submit">
                          <FormattedMessage
                            id="save"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Button>
                        <Button onClick={handleUserUpdateCancel}>
                          <FormattedMessage
                            id="cancel"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Button>
                      </ButtonGroup>
                    </Center>
                  </form>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box borderRadius="5" border="1px" borderColor="black.150">
                  <Heading as="h5" size="md" padding="10px">
                    <FormattedMessage
                      id="contactDetails"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Heading>

                  {/* Mobile Details */}

                  <SimpleGrid
                    columns={isDesktopView ? 2 : 1}
                    spacing={isDesktopView ? '0px' : '5px'}
                  >
                    <form
                      onSubmit={phoneNoSendOtpFormValues.handleSubmit(
                        onSubmitPhoneNoSendOtp,
                      )}
                    >
                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="2px">
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={
                              phoneNoSendOtpFormValues.formState.errors
                                .newContact
                                ? 20
                                : 12
                            }
                            style={{
                              flex: '25%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                !!phoneNoSendOtpFormValues.formState.errors
                                  .newContact
                              }
                            >
                              <ChakraFormLabel htmlFor="newContact" mb="8px">
                                <FormattedMessage
                                  id="phoneNumber"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <InputGroup size="sm">
                                {/* <InputLeftAddon children="+94" /> */}
                                <Input
                                  placeholder="e.g. 0701234567"
                                  id="newContact"
                                  type="tel"
                                  maxLength={MAX_PHONE_LENGTH - 2} // To allow if the saved value is with different country code
                                  autoComplete="newContact"
                                  {...phoneNoSendOtpFormValues.register(
                                    'newContact',
                                    {
                                      ...PHONE_VALIDATION_RULES,
                                      validate: handlePhoneValidation,
                                    },
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                    // value = `+94${trimStart(value, '+94')}`
                                    return (e.target.value = value)
                                  }}
                                />
                              </InputGroup>
                              {phoneNoSendOtpFormValues.formState.errors
                                .newContact && (
                                <FormErrorMessage>
                                  {
                                    phoneNoSendOtpFormValues.formState.errors
                                      .newContact.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={isDesktopView ? 12 : 24}
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'left',
                            }}
                          >
                            <ButtonGroup
                              variant="outline"
                              spacing="1"
                              size="sm"
                            >
                              <Button type="submit" mt={8}>
                                {resendPhoneNoOtp ? (
                                  <FormattedMessage
                                    id="resendOTP"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                ) : (
                                  <FormattedMessage
                                    id="generateOTP"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                )}
                              </Button>
                            </ButtonGroup>
                          </Box>
                        </SimpleGrid>
                      </SimpleGrid>
                    </form>
                    <form
                      onSubmit={phoneNoVerifyOtpFormValues.handleSubmit(
                        onSubmitPhoneNoVerifyOtp,
                      )}
                    >
                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="2px">
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={18}
                            style={{
                              flex: '25%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                !!phoneNoVerifyOtpFormValues.formState.errors
                                  .otp
                              }
                            >
                              <ChakraFormLabel htmlFor="otp" mb="8px">
                                <FormattedMessage
                                  id="enterOTP"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <InputGroup size="sm">
                                <Input
                                  placeholder="e.g. 123456"
                                  id="otp"
                                  type="text"
                                  maxLength={MAX_OTP_LENGTH}
                                  autoComplete="otp"
                                  {...phoneNoVerifyOtpFormValues.register(
                                    'otp',
                                    OTP_VALIDATION_RULES,
                                  )}
                                />
                                {isDesktopTabView && (
                                  <ButtonGroup
                                    variant="outline"
                                    spacing="1"
                                    size="sm"
                                  >
                                    <Button type="submit" ml={4}>
                                      <FormattedMessage
                                        id="verify"
                                        values={{ locale }}
                                      ></FormattedMessage>
                                    </Button>
                                  </ButtonGroup>
                                )}
                              </InputGroup>
                              {phoneNoVerifyOtpFormValues.formState.errors
                                .otp && (
                                <FormErrorMessage>
                                  {
                                    phoneNoVerifyOtpFormValues.formState.errors
                                      .otp.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid>
                        {/* <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={12}
                            mt={10}
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <FormControl>
                              {isNewPhoneNoVerified && (
                                <FormFieldMessage variant="success">
                                  <FormattedMessage
                                    id="verified"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </FormFieldMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid> */}
                        <SimpleGrid>
                          <Box
                            height={isDesktopView ? 12 : 24}
                            mt={
                              phoneNoVerifyOtpFormValues.formState.errors.otp
                                ? 14
                                : 10
                            }
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'left',
                            }}
                          >
                            {isDesktopTabView ? null : (
                              <ButtonGroup
                                variant="outline"
                                spacing="1"
                                size="sm"
                              >
                                <Button type="submit" mt={4}>
                                  <FormattedMessage
                                    id="verify"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </Button>
                              </ButtonGroup>
                            )}
                          </Box>
                        </SimpleGrid>
                      </SimpleGrid>
                    </form>
                  </SimpleGrid>
                  <Stack direction={['column', 'row']} spacing="26px" p={3}>
                    <Box>
                      <FormControl isInvalid={errorNewPhoneNo !== null}>
                        <FormErrorMessage>{errorNewPhoneNo}</FormErrorMessage>
                        {successNewPhoneNo !== null && (
                          <FormFieldMessage variant="success">
                            {successNewPhoneNo}
                          </FormFieldMessage>
                        )}
                        {isNewPhoneNoVerified && (
                          <FormFieldMessage variant="success">
                            <FormattedMessage
                              id="verified"
                              values={{ locale }}
                            ></FormattedMessage>
                          </FormFieldMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Stack>
                  <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                    <Box height="10px" p={1}>
                      {/* <FormControl>
                              {isNewPhoneNoVerified && (
                                <FormFieldMessage variant="success">
                                  <FormattedMessage
                                    id="verified"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </FormFieldMessage>
                              )}
                            </FormControl> */}
                    </Box>
                  </SimpleGrid>
                  {/* Emails Verify Details */}
                  <SimpleGrid
                    columns={isDesktopView ? 2 : 1}
                    spacing={isDesktopView ? '0px' : '5px'}
                  >
                    <form
                      onSubmit={emailSendOtpFormValues.handleSubmit(
                        onSubmitEmailSendOtp,
                      )}
                    >
                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="2px">
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={
                              emailSendOtpFormValues.formState.errors.newContact
                                ? 20
                                : 12
                            }
                            style={{
                              flex: '25%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                !!emailSendOtpFormValues.formState.errors
                                  .newContact
                              }
                            >
                              <ChakraFormLabel htmlFor="newContact" mb="8px">
                                <FormattedMessage
                                  id="emailAddress"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <InputGroup size="sm">
                                <Input
                                  placeholder="e.g. sample@email.com"
                                  id="newContact"
                                  type="text"
                                  maxLength={MAX_EMAIL_LENGTH}
                                  autoComplete="newContact"
                                  {...emailSendOtpFormValues.register(
                                    'newContact',
                                    {
                                      ...EMAIL_VALIDATION_RULES,
                                      validate: validateEmail,
                                    },
                                  )}
                                />
                              </InputGroup>
                              {emailSendOtpFormValues.formState.errors
                                .newContact && (
                                <FormErrorMessage>
                                  {
                                    emailSendOtpFormValues.formState.errors
                                      .newContact.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid>
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={isDesktopView ? 12 : 24}
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'left',
                            }}
                          >
                            <ButtonGroup
                              variant="outline"
                              spacing="1"
                              size="sm"
                            >
                              <Button type="submit" mt={8}>
                                {resendEmailOtp ? (
                                  <FormattedMessage
                                    id="resendOTP"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                ) : (
                                  <FormattedMessage
                                    id="generateOTP"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                )}
                              </Button>
                            </ButtonGroup>
                          </Box>
                        </SimpleGrid>
                      </SimpleGrid>
                    </form>
                    <form
                      onSubmit={emailVerifyOtpFormValues.handleSubmit(
                        onSubmitEmailVerifyOtp,
                      )}
                    >
                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="2px">
                        <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={18}
                            style={{
                              flex: '25%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormControl
                              isInvalid={
                                !!emailVerifyOtpFormValues.formState.errors.otp
                              }
                            >
                              <ChakraFormLabel htmlFor="otp" mb="8px">
                                <FormattedMessage
                                  id="enterOTP"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </ChakraFormLabel>
                              <InputGroup size="sm">
                                <Input
                                  placeholder="e.g. 123456"
                                  id="otp"
                                  type="text"
                                  maxLength={MAX_OTP_LENGTH}
                                  autoComplete="otp"
                                  {...emailVerifyOtpFormValues.register(
                                    'otp',
                                    OTP_VALIDATION_RULES,
                                  )}
                                />
                                {isDesktopTabView && (
                                  <ButtonGroup
                                    variant="outline"
                                    spacing="1"
                                    size="sm"
                                  >
                                    <Button type="submit" ml={4}>
                                      <FormattedMessage
                                        id="verify"
                                        values={{ locale }}
                                      ></FormattedMessage>
                                    </Button>
                                  </ButtonGroup>
                                )}
                              </InputGroup>
                              {emailVerifyOtpFormValues.formState.errors
                                .otp && (
                                <FormErrorMessage>
                                  {
                                    emailVerifyOtpFormValues.formState.errors
                                      .otp.message
                                  }
                                </FormErrorMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid>
                        {/* <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                          <Box
                            height={12}
                            mt={10}
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <FormControl>
                              {isNewEmailVerified && (
                                <FormFieldMessage variant="success">
                                  <FormattedMessage
                                    id="verified"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </FormFieldMessage>
                              )}
                            </FormControl>
                          </Box>
                        </SimpleGrid> */}
                        <SimpleGrid>
                          <Box
                            height={isDesktopView ? 12 : 24}
                            mt={10}
                            display={{ base: 'block', sm: 'flex' }}
                            style={{
                              flex: '15%',
                              padding: '10px',
                              justifyContent: 'left',
                            }}
                          >
                            {isDesktopTabView ? null : (
                              <ButtonGroup
                                variant="outline"
                                spacing="1"
                                size="sm"
                              >
                                <Button type="submit" mt={4}>
                                  <FormattedMessage
                                    id="verify"
                                    values={{ locale }}
                                  ></FormattedMessage>
                                </Button>
                              </ButtonGroup>
                            )}
                          </Box>
                        </SimpleGrid>
                      </SimpleGrid>
                    </form>
                    <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="1px">
                      <Box>
                        {/* <FormControl>
                          {isNewEmailVerified && (
                            <FormFieldMessage variant="success">
                              <FormattedMessage
                                id="verified"
                                values={{ locale }}
                              ></FormattedMessage>
                            </FormFieldMessage>
                          )}
                        </FormControl> */}
                      </Box>
                    </SimpleGrid>
                  </SimpleGrid>
                  <Stack direction={['column', 'row']} spacing="26px" p={3}>
                    <Box>
                      <FormControl isInvalid={errorNewEmail !== null}>
                        <FormErrorMessage>{errorNewEmail}</FormErrorMessage>
                        {successNewEmail !== null && (
                          <FormFieldMessage variant="success">
                            {successNewEmail}
                          </FormFieldMessage>
                        )}
                        {isNewEmailVerified && (
                          <FormFieldMessage variant="success">
                            <FormattedMessage
                              id="verified"
                              values={{ locale }}
                            ></FormattedMessage>
                          </FormFieldMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Stack>
                  <Box height="10px"></Box>
                  {result?.signupSource === 'self' ? null : (
                    <Stack direction={['column', 'row']} spacing="26px" p={1}>
                      <p>
                        <FormattedMessage
                          id="note"
                          values={{ locale }}
                        ></FormattedMessage>
                      </p>
                    </Stack>
                  )}

                  <Center>
                    <ButtonGroup variant="outline" spacing="3" padding={10}>
                      <Button
                        onClick={handleUserContactUpdateSave}
                        disabled={!(isNewPhoneNoVerified || isNewEmailVerified)}
                      >
                        <FormattedMessage
                          id="save"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Button>
                      <Button onClick={handleUserUpdateCancel}>
                        <FormattedMessage
                          id="cancel"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Button>
                    </ButtonGroup>
                  </Center>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box borderRadius="5" border="1px" borderColor="black.150">
                  <Heading as="h6" size="md" padding="10px">
                    <FormattedMessage
                      id="credentials"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Heading>
                  <Heading size="md" padding="10px" p={3}>
                    <FormattedMessage
                      id="password"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Heading>
                  <form onSubmit={handleSubmit(onSubmitNewPassword)}>
                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                      <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                        <Box height={'70px'} p={3}>
                          <FormControl
                            isInvalid={!!formState.errors.oldPassword}
                            mb="1rem"
                          >
                            <ChakraFormLabel htmlFor="email" mb="8px">
                              <FormattedMessage
                                id="oldPassword"
                                values={{ locale }}
                              ></FormattedMessage>
                            </ChakraFormLabel>
                            <InputGroup>
                              <Input
                                type={showOldPassword ? 'text' : 'password'}
                                inputMode="text"
                                placeholder={
                                  locale === 'en'
                                    ? 'e.g. P@ssw0rd!'
                                    : locale === 'si'
                                    ? 'උ.දා. P@ssw0rd!'
                                    : 'ஈ.ஜி. P@ssw0rd!'
                                }
                                autoComplete="old-password"
                                {...register(
                                  'oldPassword',
                                  OLD_PASSWORD_VALIDATION_RULES,
                                )}
                              />
                              <InputRightElement width="4.5rem">
                                <IconButton
                                  aria-label="Show/Hide old password"
                                  icon={
                                    showOldPassword ? (
                                      <FluentEye />
                                    ) : (
                                      <FluentEyeHide />
                                    )
                                  }
                                  variant="ghost"
                                  colorScheme="gray"
                                  size="lg"
                                  maxHeight="calc(100% - 3px)"
                                  minHeight="calc(100% - 3px)"
                                  borderWidth={0}
                                  onClick={() =>
                                    setShowOldPassword(!showOldPassword)
                                  }
                                />
                              </InputRightElement>
                            </InputGroup>
                            {formState.errors.oldPassword && (
                              <FormErrorMessage>
                                {formState.errors.oldPassword.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>

                        <Box height={'70px'} p={3}>
                          <FormControl
                            isInvalid={!!formState.errors.newPassword}
                            mb="1rem"
                          >
                            <ChakraFormLabel htmlFor="email" mb="8px">
                              <FormattedMessage
                                id="newPassword"
                                values={{ locale }}
                              ></FormattedMessage>
                            </ChakraFormLabel>

                            <InputGroup>
                              <Input
                                type={showNewPassword ? 'text' : 'password'}
                                inputMode="text"
                                placeholder={
                                  locale === 'en'
                                    ? 'e.g. P@ssw0rd!'
                                    : locale === 'si'
                                    ? 'උ.දා. P@ssw0rd!'
                                    : 'ஈ.ஜி. P@ssw0rd!'
                                }
                                autoComplete="new-password"
                                {...register(
                                  'newPassword',
                                  PASSWORD_VALIDATION_RULES,
                                )}
                              />
                              <InputRightElement width="4.5rem">
                                <IconButton
                                  aria-label="Show/Hide new password"
                                  icon={
                                    showNewPassword ? (
                                      <FluentEye />
                                    ) : (
                                      <FluentEyeHide />
                                    )
                                  }
                                  variant="ghost"
                                  colorScheme="gray"
                                  size="lg"
                                  maxHeight="calc(100% - 3px)"
                                  minHeight="calc(100% - 3px)"
                                  borderWidth={0}
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                />
                              </InputRightElement>
                            </InputGroup>
                            {formState.errors.newPassword && (
                              <FormErrorMessage>
                                {formState.errors.newPassword.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>

                        <Box height={'70px'} p={3}>
                          <FormControl
                            isInvalid={!!formState.errors.reEnterNewPassword}
                            mb="2rem"
                          >
                            <ChakraFormLabel htmlFor="email" mb="8px">
                              <FormattedMessage
                                id="reenterNewpassword"
                                values={{ locale }}
                              ></FormattedMessage>
                            </ChakraFormLabel>

                            <InputGroup>
                              <Input
                                type={showNewPasswordCopy ? 'text' : 'password'}
                                inputMode="text"
                                placeholder={
                                  locale === 'en'
                                    ? 'e.g. P@ssw0rd!'
                                    : locale === 'si'
                                    ? 'උ.දා. P@ssw0rd!'
                                    : 'ஈ.ஜி. P@ssw0rd!'
                                }
                                autoComplete="re-enter-new-password"
                                {...register('reEnterNewPassword', {
                                  ...RE_ENTER_PASSWORD_VALIDATION_RULES,
                                  validate: validateReEnteredNewPassword,
                                })}
                              />
                              <InputRightElement width="4.5rem">
                                <IconButton
                                  aria-label="Show/Hide repeated new password"
                                  icon={
                                    showNewPasswordCopy ? (
                                      <FluentEye />
                                    ) : (
                                      <FluentEyeHide />
                                    )
                                  }
                                  variant="ghost"
                                  colorScheme="gray"
                                  size="lg"
                                  maxHeight="calc(100% - 3px)"
                                  minHeight="calc(100% - 3px)"
                                  borderWidth={0}
                                  onClick={() =>
                                    setShowNewPasswordCopy(!showNewPasswordCopy)
                                  }
                                />
                              </InputRightElement>
                            </InputGroup>
                            {formState.errors.reEnterNewPassword && (
                              <FormErrorMessage>
                                {formState.errors.reEnterNewPassword.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl mb="2.5rem">
                            <Link
                              isExternal
                              variant="standalone"
                              href={PASSWORD_POLICY_ROUTE}
                            >
                              Password Policy
                            </Link>
                          </FormControl>
                        </Box>
                        <Box height={'5px'}></Box>
                      </SimpleGrid>
                      <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                        <Box height={'5px'}></Box>
                      </SimpleGrid>
                    </SimpleGrid>

                    <Center>
                      <ButtonGroup variant="outline" spacing="3" padding={3}>
                        <Button type="submit">
                          <FormattedMessage
                            id="save"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Button>
                        <Button onClick={handleUserUpdateCancel}>
                          <FormattedMessage
                            id="cancel"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Button>
                      </ButtonGroup>
                    </Center>
                  </form>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </IntlProvider>
    </>
  )
}
