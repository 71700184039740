import validator from 'validator'

import { HttpError } from '~services/ApiService'

export const arrayToString = (array: string[]): string => {
  let label = ''
  if (Array.isArray(array)) {
    if (array.length > 0) {
      array.map((value) => {
        label = label + `${value}, `
      })
    } else {
      label = ''
    }
  }
  //remove last comma.
  if (label.substr(label.length - 2) === ', ') {
    label = label.substring(0, label.length - 2)
  }
  return label
}
