import { Opaque } from 'type-fest'
import { z } from 'zod'

export type AgencyId = Opaque<string, 'AgencyId'>
export const AgencyId = z.string() as unknown as z.Schema<AgencyId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const AgencyBase = z.object({
  emailDomain: z.array(z.string()),
  fullName: z.string(),
  shortName: z.string(),
  logo: z.string(),
  description: z.string(),
  agencyCategory: z.object({
    code: z.enum(['AGENCY', 'SYSTEM']),
    name: z.enum(['Agency', 'System']),
  }),
  paymentMerchantId: z.string(),
  paymentServiceId: z.string(),
  paymentPublicKey: z.string(),
  paymentPrivateKey: z.string(),
  paymentPassword: z.string(),
  status: z.enum(['Active', 'Inactive']),
  website: z.string(),
  mobile: z.array(z.string()),
  email: z.array(z.string()),
  address: z.object({
    line1: z.string(),
    line2: z.string(),
    line3: z.string(),
    city: z.string(),
  }),
  district: z.string(),
  province: z.string(),
  contactPerson: z.array(
    z.object({
      personName: z.string(),
      designation: z.string(),
      mobile: z.array(z.string()),
      email: z.array(z.string()),
    }),
  ),
  smsServiceEnabled: z.boolean(),
  emailServiceEnabled: z.boolean(),
  note: z.string(),
  createdBy: z.object({
    userCategoryCode: z.string(),
    userId: z.string(),
    userName: z.string(),
    userCategoryName: z.string()
  }),
  lastModifiedBy: z.object({
    userCategoryCode: z.string(),
    userId: z.string(),
    userName: z.string(),
    userCategoryName: z.string()
  }),
  created: z.date(),
  lastModified: z.date(),
})
export type AgencyBase = z.infer<typeof AgencyBase>

export const AgencyDto = AgencyBase.extend({
  _id: AgencyId,
})
export type AgencyDto = z.infer<typeof AgencyDto>

export const PublicAgencyDto = AgencyDto.pick({
  shortName: true,
  fullName: true,
  emailDomain: true,
  logo: true,
  description: true,
  paymentMerchantId: true,
  paymentServiceId: true,
  paymentPublicKey: true,
  paymentPrivateKey: true,
  paymentPassword: true,
  status: true,
  website: true,
  mobile: true,
  email: true,
  address: true,
  district: true,
  province: true,
  contactPerson: true,
  smsServiceEnabled: true,
  emailServiceEnabled: true,
  createdBy: true,
  lastModifiedBy: true,
})
export type PublicAgencyDto = z.infer<typeof PublicAgencyDto>

export enum agencyCategoryType {
  AGENCY = 'Agency',
  SYSTEM = 'System',
}

export type CreateAgencyBody = {
  fullName: string
  shortName: string
  logo?: string
  description?: string
  agencyCategory: {
    code: string
    name: string
  }
  paymentMerchantId?: string
  paymentServiceId?: string
  paymentPublicKey?: string
  paymentPrivateKey?: string
  paymentPassword?: string
  status: string
  website?: string
  mobile: string[]
  email?: string[]
  address: {
    line1?: string
    line2?: string
    line3?: string
    city: string
  }
  district: string
  province: string
  contactPerson?: any[]
  smsServiceEnabled: boolean
  emailServiceEnabled: boolean
  note: string
}

export type GovernmentAgencyDto = {
  agencyId: string
  fullName?: string
  shortName?: string
  description?: string
  addressLine1?: string
  addressLine2?: string
  addressLine3?: string
  city?: string
  district?: string
  province?: string
  website?: string
  mobile?: any
  email: any
  logo?: any
  contacts?: any
}
