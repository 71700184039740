import { encode as encodeBase64 } from '@stablelib/base64'
import { chain, forOwn, isEmpty, keyBy, omit, pick } from 'lodash'

import { BasicField, FormFieldDto } from '~shared/types/field'
import {
  EmailResponse,
  FieldResponse,
  MobileResponse,
} from '~shared/types/response'
import {
  StorageModeAttachment,
  StorageModeAttachmentsMap,
  StorageModeAttachmentsTypeMap,
  StorageModeAttachmentType,
  StorageModeSubmissionContentDraftsDto,
  StorageModeSubmissionContentDto,
} from '~shared/types/submission'

import formsgSdk from '~utils/formSdk'
import { AttachmentFieldSchema, FormFieldValues } from '~templates/Field'

import { transformInputsToOutputs } from './inputTransformation'
import { validateResponses } from './validateResponses'

// The current encrypt version to assign to the encrypted submission.
// This is needed if we ever break backwards compatibility with
// end-to-end encryption
const ENCRYPT_VERSION = 1

/**
 * @returns StorageModeSubmissionContentDto
 * @throw Error if form inputs are invalid.
 */
export const createEncryptedSubmissionData = async (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
  publicKey: string,
  draftId?: string,
): Promise<StorageModeSubmissionContentDto> => {
  const responses = createResponsesArray(formFields, formInputs)
  // const encodedContentBuffer = Buffer.from(JSON.stringify(responses), 'utf-8')
  // const encodedContent = encodedContentBuffer.toString('base64')
  const encryptedContent = formsgSdk.crypto.encrypt(responses, publicKey)
  // Edge case: We still send email/verifiable fields to the server in plaintext
  // even with end-to-end encryption in order to support email autoreplies and
  // signature verification (for when signature has expired).
  const filteredResponses = filterSendableStorageModeResponses(
    formFields,
    responses,
  )
  const attachments = await getEncryptedAttachmentsMap(
    formFields,
    formInputs,
    publicKey,
  )

  const encodedContentBuffer = Buffer.from(JSON.stringify(responses), 'utf-8')
  // const encodedContent = encodedContentBuffer.toString('base64')
  const encodedContent = encodedContentBuffer.toString('base64')

  const attachmentsForEmail = await getEncryptedAttachmentsMapDrafts(
    formFields,
    formInputs,
  )

  let sign: any = ''
  let signType: any = ''
  sign = localStorage.getItem('signature')
  signType = localStorage.getItem('signatureType')
  // localStorage.removeItem('signatureType')
  // localStorage.removeItem('signature')
  const signature = {
    signatureType: signType,
    value: sign,
  }
  return {
    contentForEmail: encodedContent,
    attachmentsForEmail: attachmentsForEmail,
    attachments,
    responses: filteredResponses,
    encryptedContent,
    version: ENCRYPT_VERSION,
    draftId,
    signature: signType === null || sign === null ? undefined : signature,
  }
}

/**
 * @returns formData containing form responses and attachments.
 * @throws Error if form inputs are invalid.
 */
export const createEmailSubmissionFormData = (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
) => {
  const responses = createResponsesArray(formFields, formInputs)
  const attachments = getAttachmentsMap(formFields, formInputs)

  // Convert content to FormData object.
  const formData = new FormData()
  formData.append('body', JSON.stringify({ responses }))

  if (!isEmpty(attachments)) {
    forOwn(attachments, (attachment, fieldId) => {
      if (attachment) {
        formData.append(attachment.name, attachment, fieldId)
      }
    })
  }

  return formData
}

const createResponsesArray = (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
): FieldResponse[] => {
  const transformedResponses = formFields
    .map((ff) => transformInputsToOutputs(ff, formInputs[ff._id]))
    .filter((output): output is FieldResponse => output !== undefined)

  return validateResponses(transformedResponses)
}

const createResponsesArrayDraft = (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
): FieldResponse[] => {
  const transformedResponses = formFields
    .map((ff) => transformInputsToOutputs(ff, formInputs[ff._id]))
    .filter((output): output is FieldResponse => output !== undefined)

  return transformedResponses
}

const getEncryptedAttachmentsMap = async (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
  publicKey: string,
): Promise<StorageModeAttachmentsMap> => {
  const attachmentsMap = getAttachmentsMap(formFields, formInputs)

  const attachmentPromises = Object.keys(attachmentsMap).map((id) =>
    encryptAttachment(attachmentsMap[id], { id, publicKey }),
  )

  return Promise.all(attachmentPromises).then((encryptedAttachmentsMeta) => {
    return (
      chain(encryptedAttachmentsMeta)
        .keyBy('id')
        // Remove id from object.
        .mapValues((v) => omit(v, 'id'))
        .value()
    )
  })
}

const getAttachmentsMap = (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
): Record<string, File> => {
  const attachmentsMap: Record<string, File> = {}
  const attachmentFields = formFields.filter(
    (ff): ff is AttachmentFieldSchema => ff.fieldType === BasicField.Attachment,
  )
  attachmentFields.forEach((af) => {
    const attachmentValue = formInputs[af._id]
    if (!(attachmentValue instanceof File)) return
    attachmentsMap[af._id] = attachmentValue
  })

  return attachmentsMap
}

/**
 * Utility to filter out responses that should be sent to the server. This includes:
 * 1. Email fields that have an autoreply enabled.
 * 2. Verifiable fields to verify its signature on the backend.
 */
const filterSendableStorageModeResponses = (
  formFields: FormFieldDto[],
  responses: FieldResponse[],
) => {
  const mapFieldIdToField = keyBy(formFields, '_id')
  return responses
    .filter((r): r is EmailResponse | MobileResponse => {
      switch (r.fieldType) {
        case BasicField.Email: {
          const field = mapFieldIdToField[r._id]
          if (!field || field.fieldType !== r.fieldType) return false
          // Only filter out fields with auto reply set to true, or if field is verifiable.
          // return field.autoReplyOptions.hasAutoReply || field.isVerifiable
          return true
        }
        case BasicField.Mobile: {
          const field = mapFieldIdToField[r._id]
          if (!field || field.fieldType !== r.fieldType) return false
          // return field.isVerifiable
          return true
        }
        default:
          return false
      }
    })
    .map((r) => pick(r, ['fieldType', '_id', 'answer', 'signature']))
}

const encryptAttachment = async (
  attachment: File,
  { id, publicKey }: { id: string; publicKey: string },
): Promise<StorageModeAttachment & { id: string }> => {
  const fileArrayBuffer = await attachment.arrayBuffer()
  const fileContentsView = new Uint8Array(fileArrayBuffer)

  const encryptedAttachment = await formsgSdk.crypto.encryptFile(
    fileContentsView,
    publicKey,
  )

  const encodedEncryptedAttachment = {
    ...encryptedAttachment,
    binary: encodeBase64(encryptedAttachment.binary),
  }

  return { id, encryptedFile: encodedEncryptedAttachment }
}

/**
 * @returns StorageModeSubmissionContentDto
 * @throw Error if form inputs are invalid.
 * For draft submissions
 */
export const createEncryptedSubmissionDataDrafts = async (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
  draftId?: string,
): Promise<StorageModeSubmissionContentDraftsDto> => {
  const responses = createResponsesArrayDraft(formFields, formInputs)
  // const encodedContentBuffer = Buffer.from(JSON.stringify(responses), 'utf-8')
  console.log('responses', responses)
  const encodedContentBuffer = Buffer.from(JSON.stringify(responses), 'utf-8')
  // const encodedContent = encodedContentBuffer.toString('base64')
  const encodedContent = encodedContentBuffer.toString('base64')
  // Edge case: We still send email/verifiable fields to the server in plaintext
  // even with end-to-end encryption in order to support email autoreplies and
  // signature verification (for when signature has expired).
  const filteredResponses = filterSendableStorageModeResponses(
    formFields,
    responses,
  )
  const attachments = await getEncryptedAttachmentsMapDrafts(
    formFields,
    formInputs,
  )

  return {
    attachments,
    responses: filteredResponses,
    encryptedContent: encodedContent,
    version: ENCRYPT_VERSION,
    draftId: draftId,
  }
}

const getEncryptedAttachmentsMapDrafts = async (
  formFields: FormFieldDto[],
  formInputs: FormFieldValues,
): Promise<StorageModeAttachmentsTypeMap> => {
  const attachmentsMap = getAttachmentsMap(formFields, formInputs)

  const attachmentPromises = Object.keys(attachmentsMap).map((id) =>
    encryptAttachmentDrafts(attachmentsMap[id], { id }),
  )

  return Promise.all(attachmentPromises).then((encryptedAttachmentsMeta) => {
    return (
      chain(encryptedAttachmentsMeta)
        .keyBy('id')
        // Remove id from object.
        .mapValues((v) => omit(v, 'id'))
        .value()
    )
  })
}

const encryptAttachmentDrafts = async (
  attachment: File,
  { id }: { id: string },
): Promise<StorageModeAttachmentType & { id: string }> => {
  const fileArrayBuffer = await attachment.arrayBuffer()
  const fileContentsView = new Uint8Array(fileArrayBuffer)

  const base64File = encodeBase64(fileContentsView)

  return { id, encryptedFile: { file: base64File } }
}
