import { FormAuthType, FormResponseMode } from '~shared/types/form'

export type EmailFormAuthType = FormAuthType
export type StorageFormAuthType =
  | FormAuthType.NIL
  | FormAuthType.SP
  | FormAuthType.CP
  | FormAuthType.WSO2

export const STORAGE_MODE_AUTHTYPES: Record<StorageFormAuthType, string> = {
  [FormAuthType.NIL]: 'None',
  [FormAuthType.SP]: 'Singpass',
  [FormAuthType.CP]: 'Singpass (Corporate)',
  [FormAuthType.WSO2]: 'Identity Server (IS)',
}

// Not using STORAGE_MODE_AUTHTYPES due to wanting a different order.
export const EMAIL_MODE_AUTHTYPES: Record<EmailFormAuthType, string> = {
  [FormAuthType.NIL]: 'None',
  [FormAuthType.SP]: 'Singpass',
  [FormAuthType.SGID]: 'Singpass App-only Login (Free)',
  [FormAuthType.MyInfo]: 'Singpass with MyInfo',
  [FormAuthType.CP]: 'Singpass (Corporate)',
  [FormAuthType.WSO2]: 'Identity Server (IS)',
}

export type StorageFormAuthTypeFormLk = FormAuthType.NIL | FormAuthType.WSO2

export const STORAGE_MODE_AUTHTYPES_FORMLK: Record<
  StorageFormAuthTypeFormLk,
  string
> = {
  [FormAuthType.NIL]: 'No',
  [FormAuthType.WSO2]: 'Yes',
}

export type EmailFormAuthTypeFormLK = FormAuthType.NIL | FormAuthType.WSO2

export const EMAIL_MODE_AUTHTYPES_FORMLK: Record<
  EmailFormAuthTypeFormLK,
  string
> = {
  [FormAuthType.NIL]: 'No',
  [FormAuthType.WSO2]: 'Yes',
}

export const AUTHTYPE_TO_TEXT = {
  [FormResponseMode.Email]: EMAIL_MODE_AUTHTYPES_FORMLK,
  [FormResponseMode.Encrypt]: STORAGE_MODE_AUTHTYPES_FORMLK,
}
