import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  CheckboxGroup,
  FormControl,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Stack,
} from '@chakra-ui/react'
import { get, isEmpty } from 'lodash'

import {
  AfterExpierType,
  AfterExpierTypeCode,
  AfterExpierTypeName,
  EmailNotificationEnableType,
  FormAuthType,
  FormLanguages,
  FormResponseMode,
  PaymentCharges,
  PaymentChargeType,
  SmsNotificationEnableType,
} from '~shared/types/form/form'

import { useToast } from '~hooks/useToast'
import {
  FORM_DESCRIPTION_VALIDATION_RULES,
  FORM_TITLE_VALIDATION_RULES,
} from '~utils/formValidation'
import Checkbox from '~components/Checkbox'
import { DateInput } from '~components/DatePicker/DateInput'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Radio from '~components/Radio'

import { useMutateFormSettings } from '../mutations'
import { useAdminFormSettings } from '../queries'

import { EmailFormSection } from './EmailFormSection'
import { EnableServiceTable } from './EnableServiceTable'

export const FormDetailsSection = (): JSX.Element => {
  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  const showPayment = (): boolean => {
    return settings?.admin?.agency?.paymentMerchantId &&
      settings?.admin?.agency?.paymentPassword &&
      settings?.admin?.agency?.paymentPrivateKey &&
      settings?.admin?.agency?.paymentPublicKey &&
      settings?.admin?.agency?.paymentServiceId
      ? true
      : false
  }

  const showPaymentDetails = showPayment()

  return (
    <Skeleton isLoaded={!isLoadingSettings && !!settings}>
      <Stack spacing="2rem">
        {settings ? (
          <FormTitleInput
            initialTitle={settings.title}
            initialDecription={settings.description}
            initialFormLanguage={settings.formLanguage}
            initialFormExpireDate={
              settings.formExpireDate ? settings.formExpireDate.toString() : ''
            }
            initialSubmissionRetentionPeriod={
              settings.submissionRetentionPeriod
            }
            initAfterExpier={settings.afterExpier}
            initPaymentRequired={
              settings.paymentRequired && settings.authType !== FormAuthType.NIL
            }
            initPaymentAmount={settings?.paymentCharges}
            initAgencyId={settings.agencyId}
            initEmailNotificationEnable={
              settings?.emailNotificationEnable
                ? settings.emailNotificationEnable
                : []
            }
            initSmsNotificationEnable={
              settings?.smsNotificationEnable
                ? settings.smsNotificationEnable
                : []
            }
            showPaymentDetails={showPaymentDetails}
          />
        ) : null}
        {settings?.responseMode === FormResponseMode.Email ? (
          <EmailFormSection settings={settings} />
        ) : null}
      </Stack>
    </Skeleton>
  )
}

type SubmissionRetentionPeriod = {
  years: number
  months: number
  days: number
}

interface FormTitleInputProps {
  initialTitle: string
  initialDecription?: string
  initialFormExpireDate?: string
  initialSubmissionRetentionPeriod?: SubmissionRetentionPeriod
  initialFormLanguage?: FormLanguages[]
  initAfterExpier?: AfterExpierType
  initPaymentRequired: boolean
  initPaymentAmount?: PaymentCharges[]
  initAgencyId: string
  initEmailNotificationEnable: EmailNotificationEnableType[]
  initSmsNotificationEnable: SmsNotificationEnableType[]
  showPaymentDetails: boolean
}
const FormTitleInput = ({
  initialTitle,
  initialDecription,
  initialFormExpireDate,
  initialSubmissionRetentionPeriod,
  initialFormLanguage,
  initAfterExpier,
  initPaymentRequired,
  initPaymentAmount,
  initAgencyId,
  initEmailNotificationEnable,
  initSmsNotificationEnable,
  showPaymentDetails,
}: FormTitleInputProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: initialTitle,
      description: initialDecription,
      formExpireDate: initialFormExpireDate,
      submissionRetentionPeriod: initialSubmissionRetentionPeriod,
      formLanguage: initialFormLanguage,
      afterExpier: initAfterExpier,
      paymentRequired: initPaymentRequired,
      paymentAmount: initPaymentAmount?.[0]?.amount ?? 0,
    },
  })

  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    return day + '/' + month + '/' + year
  }

  const expireDate = watch('formExpireDate')

  const toast = useToast()
  const [englishSelected, setEnglishSelected] = useState<boolean>(
    initialFormLanguage
      ? initialFormLanguage.includes(FormLanguages.English)
      : false,
  )
  const [sinhalaSelected, setSinhalaSelected] = useState<boolean>(
    initialFormLanguage
      ? initialFormLanguage.includes(FormLanguages.Sinhala)
      : false,
  )
  const [tamilSelected, setTamilSelected] = useState<boolean>(
    initialFormLanguage
      ? initialFormLanguage.includes(FormLanguages.Tamil)
      : false,
  )
  const [retentionYears, setRetentionYears] = useState<string>(
    initialSubmissionRetentionPeriod?.years
      ? initialSubmissionRetentionPeriod.years.toString()
      : '',
  )
  const [retentionMonths, setRetentionMonths] = useState<string>(
    initialSubmissionRetentionPeriod?.months
      ? initialSubmissionRetentionPeriod.months.toString()
      : '',
  )
  const [retentionDays, setRetentionDays] = useState<string>(
    initialSubmissionRetentionPeriod?.days
      ? initialSubmissionRetentionPeriod.days.toString()
      : '',
  )
  const [afterExpireCode, setAfterExpireCode] = useState<string | undefined>(
    initAfterExpier?.code,
  )
  const [prevAfterExpireCode, setPrevAfterExpireCode] = useState<
    string | undefined
  >(initAfterExpier?.code)
  const [paymentRequiredSelection, setPaymentRequiredSelection] = useState<
    string | undefined
  >(initPaymentRequired ? 'yes' : 'no')

  const expireDateButton = useRef<HTMLButtonElement | null>(null)

  const {
    mutateFormTitle,
    mutateFormDescription,
    mutateFormLanguages,
    mutateFormExpireDate,
    mutateFormSubmissionRetentionPeriod,
    mutateFormAfterExpire,
    mutateFormPaymentRequired,
    mutateFormPaymentCharges,
  } = useMutateFormSettings()

  const handleBlur = useCallback(() => {
    return handleSubmit(
      ({ title }) => {
        if (title === initialTitle) return

        return mutateFormTitle.mutate(title, { onError: () => reset() })
      },
      (e) => console.log('error', e),
    )()
  }, [handleSubmit, initialTitle, mutateFormTitle, reset])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleBlur()
      }
    },
    [handleBlur],
  )

  const handleBlurDescription = useCallback(() => {
    return handleSubmit(
      ({ description }) => {
        if (description === initialDecription) return

        return mutateFormDescription.mutate(description ? description : '', {
          onError: () => reset(),
        })
      },
      (e) => console.log('error', e),
    )()
  }, [handleSubmit, initialDecription, mutateFormDescription, reset])

  const handleKeyDownDescription: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleBlurDescription()
        }
      },
      [handleBlurDescription],
    )

  const handleOnClickFormLanguage = useCallback(
    (e) => {
      const langaugeArray: FormLanguages[] = []
      // if English selected
      if (e.target.value === FormLanguages.English) {
        if (e.target.checked) {
          langaugeArray.push(FormLanguages.English)
          setEnglishSelected(true)
        } else {
          setEnglishSelected(false)
        }

        if (sinhalaSelected) {
          langaugeArray.push(FormLanguages.Sinhala)
        }
        if (tamilSelected) {
          langaugeArray.push(FormLanguages.Tamil)
        }
      } else if (e.target.value === FormLanguages.Sinhala) {
        // if sinhala selected
        if (e.target.checked) {
          langaugeArray.push(FormLanguages.Sinhala)
          setSinhalaSelected(true)
        } else {
          setSinhalaSelected(false)
        }
        if (englishSelected) {
          langaugeArray.push(FormLanguages.English)
        }
        if (tamilSelected) {
          langaugeArray.push(FormLanguages.Tamil)
        }
      } else if (e.target.value === FormLanguages.Tamil) {
        // if tamil selected
        if (e.target.checked) {
          langaugeArray.push(FormLanguages.Tamil)
          setTamilSelected(true)
        } else {
          setTamilSelected(false)
        }
        if (englishSelected) {
          langaugeArray.push(FormLanguages.English)
        }
        if (sinhalaSelected) {
          langaugeArray.push(FormLanguages.Sinhala)
        }
      }
      if (langaugeArray.length === 0) {
        toast({
          title: '',
          description: 'Please select one language',
          duration: 5000,
          isClosable: true,
          status: 'danger',
        })
        return
      }

      return mutateFormLanguages.mutate(langaugeArray, {
        onError: () => reset(),
      })
    },
    [
      englishSelected,
      sinhalaSelected,
      tamilSelected,
      mutateFormLanguages,
      reset,
      toast,
    ],
  )

  const handleBlurExpireDate = useCallback(() => {
    console.log('here!')
    return handleSubmit(
      ({ formExpireDate }) => {
        if (!formExpireDate) return
        if (formExpireDate === initialFormExpireDate) return

        const newFormExpireDate = new Date(formExpireDate)

        return mutateFormExpireDate.mutate(newFormExpireDate, {
          onError: () => reset(),
        })
      },
      (e) => console.log('error', e),
    )()
  }, [handleSubmit, initialFormExpireDate, mutateFormExpireDate, reset])

  useEffect(() => {
    expireDateButton.current?.click()
  }, [expireDate])

  const handleOnChangeAfterExpire = (selectedValue: string) => {
    try {
      let finalObject: AfterExpierType = initAfterExpier
        ? initAfterExpier
        : {
            code: AfterExpierTypeCode.UPB,
            name: AfterExpierTypeName.UnpublishedAfterExpiry,
          }
      if (selectedValue === AfterExpierTypeCode.UPB) {
        finalObject = {
          code: AfterExpierTypeCode.UPB,
          name: AfterExpierTypeName.UnpublishedAfterExpiry,
        }
      } else {
        finalObject = {
          code: AfterExpierTypeCode.ACH,
          name: AfterExpierTypeName.ArchivedAfterExpiry,
        }
      }
      console.log('selectedValue', selectedValue)
      // if (afterExpireCode === prevAfterExpireCode) {
      //   return
      // }
      return mutateFormAfterExpire.mutate(finalObject, {
        onError: () => reset(),
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleBlurRetentionPeriod = useCallback(() => {
    const intRetentionYears =
      retentionYears !== '' ? parseInt(retentionYears, 10) : 0
    const intRetentionMonths =
      retentionMonths !== '' ? parseInt(retentionMonths, 10) : 0
    const intRetentionDays =
      retentionDays !== '' ? parseInt(retentionDays, 10) : 0
    if (
      initialSubmissionRetentionPeriod?.years === intRetentionYears &&
      initialSubmissionRetentionPeriod?.months === intRetentionMonths &&
      initialSubmissionRetentionPeriod?.days === intRetentionDays
    )
      return

    const newRetentionPeriod = {
      years: intRetentionYears,
      months: intRetentionMonths,
      days: intRetentionDays,
    }

    return mutateFormSubmissionRetentionPeriod.mutate(newRetentionPeriod, {
      onError: () => reset(),
    })
  }, [
    retentionYears,
    retentionMonths,
    retentionDays,
    mutateFormSubmissionRetentionPeriod,
    reset,
    initialSubmissionRetentionPeriod?.years,
    initialSubmissionRetentionPeriod?.months,
    initialSubmissionRetentionPeriod?.days,
  ])

  const handleKeyDownRetentionPeriod: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleBlurDescription()
        }
      },
      [handleBlurDescription],
    )

  const handleOnChangePaymentRequired = (paymentRequired: string) => {
    try {
      const finalObject: boolean = paymentRequired === 'yes' ? true : false
      return mutateFormPaymentRequired.mutate(finalObject, {
        onError: () => reset(),
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleBlurPaymentAmount = useCallback(() => {
    return handleSubmit(
      ({ paymentAmount }) => {
        if (
          !initPaymentAmount ||
          paymentAmount === initPaymentAmount?.[0]?.amount
        )
          return

        let paymentCharge: PaymentCharges[] = []

        if (!initPaymentAmount) {
          paymentCharge = [
            {
              type: PaymentChargeType.FORM_CHARGE,
              name: 'Form Charge',
              amount: paymentAmount,
              required: true,
            },
          ]
        } else {
          paymentCharge = initPaymentAmount

          paymentCharge[0] = {
            type: PaymentChargeType.FORM_CHARGE,
            name: 'Form Charge',
            amount: paymentAmount,
            required: true,
          }
        }

        return mutateFormPaymentCharges.mutate(
          paymentCharge ? paymentCharge : [],
          {
            onError: () => reset(),
          },
        )
      },
      (e) => console.log('error', e),
    )()
  }, [handleSubmit, initPaymentAmount, mutateFormPaymentCharges, reset])

  const handleKeyDownPaymentAmount: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          handleBlurPaymentAmount()
        }
      },
      [handleBlurPaymentAmount],
    )

  const css = `
    div.is-disabled
      {
        pointer-events: none;
        opacity: 0.7;
      }
      `

  return (
    <>
      <style>{css}</style>
      <FormControl isInvalid={!isEmpty(errors?.title)}>
        <FormLabel isRequired>Form Name</FormLabel>

        <Controller
          control={control}
          name="title"
          rules={FORM_TITLE_VALIDATION_RULES}
          render={({ field }) => (
            <Input {...field} onBlur={handleBlur} onKeyDown={handleKeyDown} />
          )}
        />
        <FormErrorMessage>{get(errors, 'title.message')}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!isEmpty(errors?.description)}>
        <FormLabel isRequired>Form Description</FormLabel>

        <Controller
          control={control}
          name="description"
          rules={FORM_DESCRIPTION_VALIDATION_RULES}
          render={({ field }) => (
            <Input
              {...field}
              onBlur={handleBlurDescription}
              onKeyDown={handleKeyDownDescription}
            />
          )}
        />
        <FormErrorMessage>
          {get(errors, 'description.message')}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!isEmpty(errors?.formLanguage)}>
        <FormLabel isRequired>Form Language</FormLabel>

        <CheckboxGroup
          defaultValue={
            initialFormLanguage
              ? Array.isArray(initialFormLanguage)
                ? initialFormLanguage
                : [initialFormLanguage]
              : []
          }
        >
          <Stack spacing={[1, 5]} direction={['column', 'row']}>
            <Controller
              name="formLanguage"
              control={control}
              rules={{ required: 'Form Language is required' }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  value={FormLanguages.English}
                  onChange={handleOnClickFormLanguage}
                >
                  {' '}
                  English
                </Checkbox>
              )}
            />
            <Controller
              name="formLanguage"
              control={control}
              rules={{ required: 'Form Language is required' }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  value={FormLanguages.Sinhala}
                  onChange={handleOnClickFormLanguage}
                >
                  {' '}
                  සිංහල
                </Checkbox>
              )}
            />
            <Controller
              name="formLanguage"
              control={control}
              rules={{ required: 'Form Language is required' }}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  value={FormLanguages.Tamil}
                  onChange={handleOnClickFormLanguage}
                >
                  {' '}
                  தமிழ்
                </Checkbox>
              )}
            />
          </Stack>
        </CheckboxGroup>
        <FormErrorMessage>
          {(errors.formLanguage as any)?.message}
        </FormErrorMessage>
      </FormControl>

      <Box>
        <FormLabel isRequired>Form Expire Date</FormLabel>
        <Stack direction="row" spacing="0.5rem">
          <Box w={200}>
            <Center>
              {expireDate ? formatDate(expireDate) : 'No Expire Date set'}
            </Center>
          </Box>
          <FormControl isInvalid={!isEmpty(errors?.formExpireDate)} mt={50}>
            <Stack direction="row" spacing="0.5rem">
              <Controller
                control={control}
                name="formExpireDate"
                rules={{
                  validate: {
                    // GET IT?
                    validDate: (val) => {
                      if (!val) return
                      const dateVal = new Date(val)
                      if (isNaN(dateVal.getTime())) {
                        return 'Please enter a valid date'
                      }
                      if (dateVal < new Date()) {
                        return 'Please enter a future date'
                      }
                      return true
                    },
                  },
                }}
                render={({ field }) => <DateInput {...field} />}
              />
              <Button
                mt={35}
                colorScheme="secondary"
                variant="outline"
                hidden={true}
                ref={expireDateButton}
                isDisabled={
                  expireDate
                    ? new Date(expireDate) < new Date() ||
                      expireDate === initialFormExpireDate
                    : true
                }
                onClick={handleBlurExpireDate}
              >
                Update
              </Button>
            </Stack>
            <FormErrorMessage>
              {get(errors, 'formExpireDate.message')}
            </FormErrorMessage>
          </FormControl>
        </Stack>
      </Box>

      <Box>
        <FormControl mb="2.5rem">
          <FormLabel>After Expire</FormLabel>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
            <RadioGroup
              onChange={(e) => {
                handleOnChangeAfterExpire(e)
                return setAfterExpireCode(e)
              }}
              value={afterExpireCode}
            >
              <Stack direction="row">
                <Radio value={AfterExpierTypeCode.UPB}>
                  Unpublished After Expiry
                </Radio>
                <Radio value={AfterExpierTypeCode.ACH}>
                  Archived After Expiry
                </Radio>
              </Stack>
            </RadioGroup>
          </SimpleGrid>
        </FormControl>
      </Box>

      <FormControl isInvalid={!isEmpty(errors?.description)}>
        <FormLabel isRequired>Submission Retention Period</FormLabel>

        <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
          <FormLabel isRequired>Years</FormLabel>
          <Input
            value={retentionYears}
            maxLength={2}
            type="number"
            onChange={(event) => {
              if (event.target.value.length < 3) {
                setRetentionYears(event.target.value)
              }
            }}
            onBlur={handleBlurRetentionPeriod}
            onKeyDown={handleKeyDownRetentionPeriod}
          />
          <FormLabel isRequired>Months</FormLabel>
          <Input
            value={retentionMonths}
            maxLength={2}
            type="number"
            onChange={(event) => {
              if (event.target.value.length < 3) {
                setRetentionMonths(event.target.value)
              }
            }}
            onBlur={handleBlurRetentionPeriod}
            onKeyDown={handleKeyDownRetentionPeriod}
          />
          <FormLabel isRequired>Days</FormLabel>
          <Input
            value={retentionDays}
            maxLength={2}
            type="number"
            onChange={(event) => {
              if (event.target.value.length < 3) {
                setRetentionDays(event.target.value)
              }
            }}
            onBlur={handleBlurRetentionPeriod}
            onKeyDown={handleKeyDownRetentionPeriod}
          />
        </SimpleGrid>
      </FormControl>

      <div
        aria-disabled={showPaymentDetails}
        className={showPaymentDetails ? '' : 'is-disabled'}
      >
        <Box>
          <FormControl
            // mb="2.5rem"
            isDisabled={settings?.authType === FormAuthType.NIL}
          >
            <FormLabel>Payment Required</FormLabel>
            {settings?.authType === FormAuthType.NIL ? (
              <Alert status="warning">
                <AlertIcon />
                Forms can only accept payments, when authentication is enabled.
              </Alert>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
              <RadioGroup
                onChange={(e) => {
                  console.log(e)
                  handleOnChangePaymentRequired(e)
                  return setPaymentRequiredSelection(e)
                }}
                value={paymentRequiredSelection}
              >
                <Stack direction="row">
                  <Radio value={'yes'}>Yes</Radio>
                  <Radio value={'no'}>No</Radio>
                </Stack>
              </RadioGroup>
            </SimpleGrid>
          </FormControl>
        </Box>

        <FormControl
          isInvalid={!isEmpty(errors?.paymentAmount)}
          isDisabled={paymentRequiredSelection !== 'yes'}
        >
          <FormLabel isRequired>Payment Amount (LKR)</FormLabel>

          <Controller
            control={control}
            name="paymentAmount"
            rules={{
              required: 'Payment amount is required',
              pattern: {
                value:
                  /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                message: 'Must be a valid amount',
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                onBlur={handleBlurPaymentAmount}
                onKeyDown={handleKeyDownPaymentAmount}
              />
            )}
          />
          <FormErrorMessage>
            {get(errors, 'paymentAmount.message')}
          </FormErrorMessage>
        </FormControl>
      </div>
      {!showPaymentDetails ? (
        <Alert status="warning">
          <AlertIcon />
          Please upload Payment Keys to enable the payment option for your
          Agency Forms.
        </Alert>
      ) : null}

      <EnableServiceTable
        agencyId={initAgencyId}
        emailNotificationEnable={initEmailNotificationEnable}
        smsNotificationEnable={initSmsNotificationEnable}
      />
    </>
  )
}
