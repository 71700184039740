import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiLinkExternal } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Divider,
  FormControl,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react'
import dedent from 'dedent'

import { FormResponseMode } from '~shared/types/form'

import { ADMINFORM_ROUTE, ADMINFORM_SETTINGS_SUBROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import formsgSdk from '~utils/formSdk'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import InlineMessage from '~components/InlineMessage'
import Input from '~components/Input'
import { ModalCloseButton } from '~components/Modal'
import Textarea from '~components/Textarea'
import { CopyButton } from '~templates/CopyButton'

import { useAdminForm } from '~features/admin-form/common/queries'

export interface ShareFormModalProps {
  isOpen: boolean
  onClose: () => void
  /**
   * ID of form to generate share link for. If not provided, modal will be in a
   * loading state
   */
  formId: string | undefined
  isFormPrivate: boolean | undefined
  citizenPortalUrl?: string
}

export const ShareFormModal = ({
  isOpen,
  onClose,
  formId,
  isFormPrivate,
  citizenPortalUrl,
}: ShareFormModalProps): JSX.Element => {
  const navigate = useNavigate()
  const toast = useToast()
  const { data: form, isLoading } = useAdminForm()
  const modalSize = useBreakpointValue({
    base: 'mobile',
    xs: 'mobile',
    md: 'md',
  })

  const [saveAsTemplate, setSaveAsTemplate] = useState<boolean>(false)
  const templateFormValues = useForm({
    defaultValues: {
      templateName: '',
      templateDescription: '',
    },
  })

  const keypair = useMemo(() => formsgSdk.crypto.generate(), [])

  const onSubmitSaveAsTemplate = async (data: any) => {
    const params = {
      title: form?.title,
      responseMode: form?.responseMode,
      publicKey: keypair.publicKey,
      emails: [],
      template: true,
      templateName: data.templateName,
      templateDescription: data.templateDescription,
      isEmailModeOn: false,
    }

    ApiService.post(`/admin/forms/${formId}/template/save`, params)
      .then((data: any) => {
        if (data.status === 200) {
          onClose()
          toast({
            title: '',
            description: 'Form Template saved',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          onClose()
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const shareLink = useMemo(
    () =>
      citizenPortalUrl
        ? `${citizenPortalUrl}/${formId}`
        : `${window.location.origin}/${formId}`,
    [formId, citizenPortalUrl],
  )

  const embeddedHtml = useMemo(() => {
    return dedent(`
      <div
        style="
          font-family: Sans-Serif;
          font-size: 15px;
          color: #000;
          opacity: 0.9;
          padding-top: 5px;
          padding-bottom: 8px;
        "
      >
        If the form below is not loaded, you can also fill it in at
        <a href="${shareLink}">here</a>.
      </div>

      <!-- Change the width and height values to suit you best -->
      <iframe
        id="iframe"
        src="${shareLink}"
        style="width: 100%; height: 500px"
      ></iframe>

      <div
        style="
          font-family: Sans-Serif;
          font-size: 12px;
          color: #999;
          opacity: 0.5;
          padding-top: 5px;
        "
      >
        Powered by <a href="${window.location.origin}" style="color: #999">Form</a>
      </div>
    `)
  }, [shareLink])

  const handleRedirectToSettings = useCallback(() => {
    onClose()
    navigate(`${ADMINFORM_ROUTE}/${formId}/${ADMINFORM_SETTINGS_SUBROUTE}`)
  }, [formId, navigate, onClose])

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader color="secondary.700">Share Form</ModalHeader>
        <ModalBody whiteSpace="pre-line">
          <Stack spacing="1rem" pb="2rem">
            {isFormPrivate ? (
              <InlineMessage variant="warning">
                <Box>
                  This form is currently closed to new responses.{' '}
                  <Button
                    p={0}
                    variant="link"
                    onClick={handleRedirectToSettings}
                  >
                    Activate your form
                  </Button>{' '}
                  to allow new responses.
                </Box>
              </InlineMessage>
            ) : null}
            <FormControl isReadOnly>
              <Skeleton isLoaded={!!formId}>
                <Stack direction="row" align="center">
                  <InputGroup>
                    <Input isReadOnly value={shareLink} />
                    {formId ? (
                      <InputRightElement>
                        <CopyButton
                          colorScheme="secondary"
                          stringToCopy={shareLink}
                          aria-label="Copy respondent form link"
                        />
                      </InputRightElement>
                    ) : null}
                  </InputGroup>
                  <IconButton
                    as="a"
                    icon={<BiLinkExternal fontSize="1.5rem" />}
                    href={shareLink}
                    target="_blank"
                    rel="noopener"
                    aria-label="Open link in new tab"
                  />
                </Stack>
              </Skeleton>
            </FormControl>
            <Divider />
            <Stack>
              <form
                onSubmit={templateFormValues.handleSubmit(
                  onSubmitSaveAsTemplate,
                )}
              >
                <Checkbox
                  isChecked={saveAsTemplate}
                  onChange={(ev) => setSaveAsTemplate(ev.target.checked)}
                >
                  Save as a Template
                </Checkbox>

                {saveAsTemplate ? (
                  <>
                    <Box height="70px" mt="20px">
                      <FormControl
                        isInvalid={
                          templateFormValues?.formState?.errors?.templateName
                            ? true
                            : false
                        }
                      >
                        <FormLabel htmlFor="templateName" mt="10px">
                          Template Name*
                        </FormLabel>
                        <Input
                          placeholder={'Template Name'}
                          id="templateName"
                          maxLength={100}
                          {...templateFormValues.register('templateName', {
                            required: 'Template Name is required',
                            maxLength: {
                              value: 100,
                              message: 'Max length is 100',
                            },
                          })}
                        />
                        {templateFormValues.formState.errors.templateName && (
                          <FormErrorMessage>
                            {
                              templateFormValues.formState.errors.templateName
                                .message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box height="70px" mt="20px">
                      <FormControl
                        isInvalid={
                          templateFormValues?.formState?.errors
                            ?.templateDescription
                            ? true
                            : false
                        }
                      >
                        <FormLabel htmlFor="templateDescription" mt="10px">
                          Template Description*
                        </FormLabel>
                        <Textarea
                          placeholder={'Template Description'}
                          id="templateDescription"
                          maxLength={100}
                          {...templateFormValues.register(
                            'templateDescription',
                            {
                              required: 'Template Description is required',
                              maxLength: {
                                value: 200,
                                message: 'Max length is 200',
                              },
                            },
                          )}
                        />
                        {templateFormValues.formState.errors
                          .templateDescription && (
                          <FormErrorMessage>
                            {
                              templateFormValues.formState.errors
                                .templateDescription.message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Center>
                      <ButtonGroup
                        variant="outline"
                        spacing="2"
                        padding={10}
                        mt={10}
                      >
                        <Button type="submit">Save</Button>
                      </ButtonGroup>
                    </Center>
                  </>
                ) : null}
              </form>
            </Stack>
            <Divider />
            <FormControl isReadOnly>
              <FormLabel isRequired>Embed HTML</FormLabel>
              <Skeleton isLoaded={!!formId}>
                <InputGroup>
                  <Textarea
                    pr="2.75rem"
                    fontFamily="monospace"
                    textStyle="body-1"
                    isReadOnly
                    value={embeddedHtml}
                  />
                  {formId ? (
                    <InputRightElement>
                      <CopyButton
                        bg="white"
                        colorScheme="secondary"
                        stringToCopy={embeddedHtml}
                        aria-label="Copy HTML code for embedding this form"
                      />
                    </InputRightElement>
                  ) : null}
                </InputGroup>
              </Skeleton>
            </FormControl>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
