import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { isEmpty, isString, template } from 'lodash'

//import { times, values } from 'lodash'
import { BasicField, FormId } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
// import DatePicker from '~components/DatePicker'
import { DateInput } from '~components/DatePicker/DateInput'
import { DatePicker } from '~components/DatePicker/DatePicker'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import { ModalCloseButton } from '~components/Modal'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import { tableTemplate } from '~pages/AdminReports/utils'

import Input from '../../../components/Input/index'
import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const AgencyArchivedReportPage = (): JSX.Element | null => {
  type mainTable = {
    rowNumber: number
    formName: string
    archivedSubmissionCount: string
    _formId: string
  }
  type moreTable = {
    rowNumberMore: number
    submissionId: string
    serialNumber: string
    submittedAt: string
    submittedBy: string
    status: string
    statusAt: string
    _formIdMore: string
  }
  type selectedForm = {
    selectedId: string
    selectedFormName: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [agencyDropdownData, setAgencyDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [formsDropdownData, setFormsDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false)
  const [unarchivedId, setUnarchivedId] = useState<string>('')
  const [unarchiveForm, setUnarchiveForm] = useState<boolean>(false)
  const [unarchiveSubmission, setUnarchiveSubmission] = useState<boolean>(false)

  //Main Table
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [archivedTableData, setArchivedTableData] = useState<mainTable[]>([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [archivedReportsCount, setArchivedReportsCount] = useState<number>(0)
  // const [selectedId, setSelectedId] = useState<string>('')
  const [selectedForm, setSelectedForm] = useState<selectedForm>({
    selectedId: '',
    selectedFormName: '',
  })
  const [isAgencyIdSet, setIsAgencyIdSet] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [clickedBtn, setClickedBtn] = useState<string>('')

  //More Table
  const [currentPageMore, setCurrentPageMore] = useState(1)
  const [rowsPerPageMore, setRowsPerPageMore] = useState('25')
  const [archivedTableDataMore, setArchivedTableDataMore] = useState<
    moreTable[]
  >([])
  const [archivedTableDataMoreReport, setArchivedTableDataMoreReport] =
    useState<moreTable[]>([])
  const [queryObjectMore, setQueryObjectMore] = useState<string>('')
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const [archivedReportsCountMore, setArchivedReportsCountMore] =
    useState<number>(0)
  const [allArchivedTableData, setAllArchivedTableData] = useState<mainTable[]>(
    [],
  )
  const [finalTemplateObj, setFinalTemplateObj] = useState<any>()

  const mainFormValues = useForm({
    defaultValues: {
      formId: '',
      //agencyId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })
  const moreFormValues = useForm({
    defaultValues: {
      //formId: '',
      status: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const createdFromDate = mainFormValues.watch().createdFrom
  const submittedFromDate = moreFormValues.watch().submittedFrom

  //Logged user ID and Agency ID
  useEffect(() => {
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
      }

      if (loggedUserDetails?._doc?.agency) {
        setLoggedUserAgencyId(loggedUserDetails?._doc?.agency)
        setIsAgencyIdSet(true)
        setUserLoggedIn(true)
      }
    }
  }, [])

  //Agency Dropdown Options
  const mapDataToAgencyDropdown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.fullName,
      }
      currentAgencyData.push(obj)
    })
    setAgencyDropdownData(currentAgencyData)
  }

  const callApiForAgencyDropdown = (query = '') => {
    ApiService.get(`/report/agencies/`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            mapDataToAgencyDropdown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    callApiForAgencyDropdown()
  }, [])

  useEffect(() => {
    if (isAgencyIdSet) {
      callApi()
    }
  }, [loggedUserAgencyId])

  //Forms Dropdown Options
  const mapDataToFormsDropdown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.title,
      }
      currentAgencyData.push(obj)
    })
    setFormsDropdownData(currentAgencyData)
  }

  const callApiForFormsDropdown = (query = '') => {
    ApiService.get(`/report/allforms?agencyId=${loggedUserAgencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data)) {
            mapDataToFormsDropdown(data?.data)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    if (userLoggedIn) {
      callApiForFormsDropdown()
    }
  }, [loggedUserAgencyId])

  //Main Table API Call
  const mapDataToTable = (array: any[]) => {
    const currentArchivedData: mainTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        formName: value?.formTitle.toLowerCase(),
        formNameStr: value?.formTitle,
        archivedSubmissionCount: value?.archivedSubmissionCount,
        _formId: value?.formId,
      }
      currentArchivedData.push(obj)
    })
    setArchivedTableData(currentArchivedData)
  }

  const callApi = (query = '') => {
    ApiService.get(
      `/archived-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}&agencyId=${loggedUserAgencyId}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.summary)) {
            mapDataToTable(data?.data?.summary)
            setArchivedReportsCount(data?.data?.meta?.totalItems)
          }
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapDataToTableAll = (array: any[]) => {
    const currentArchivedData: mainTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * 0 + index + 1,
        formName: value?.formTitle,
        govAgencyName: value?.agencyName,
        archivedSubmissionCount: value?.archivedSubmissionCount,
        _formId: value?.formId,
        _govAgencyId: value?.agencyId,
      }
      currentArchivedData.push(obj)
    })
    setAllArchivedTableData(currentArchivedData)

    const columns = ['No', 'Form Name', 'Archived Submissions Count']
    const finalAnswerArray: any[] = []
    currentArchivedData.forEach(function (item: any) {
      console.log('item', item)
      const tempArray = []
      tempArray.push(item?.rowNumber)
      tempArray.push(item?.formName)
      tempArray.push(item?.archivedSubmissionCount)
      finalAnswerArray.push(tempArray)
    })

    const finalObj = {
      answerArray: finalAnswerArray,
      columnTitles: columns,
    }

    console.log('finalObj', finalObj)
    setFinalTemplateObj(finalObj)
  }

  const callApiAll = (query = '') => {
    ApiService.get(
      `/archived-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}&agencyId=${loggedUserAgencyId}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.summary)) {
            mapDataToTableAll(data?.data?.summary)
          }
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    setCurrentPage(currentPage)
    if (loggedUserAgencyId) {
      callApi(queryObject)
    }
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    if (!isEmpty(loggedUserAgencyId)) {
      callApiAll(queryObject)
    }
  }, [loggedUserAgencyId, currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.formId === 'ALL' || data?.formId === '') {
      delete data['formId']
    }
    if (data?.createdFrom === '') {
      delete data['createdFrom']
    }
    if (data?.createdTo === '') {
      delete data['createdTo']
    }

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
    callApiAll(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    callApiAll()
    //setSelectedId('')
    setSelectedForm({
      selectedId: '',
      selectedFormName: '',
    })
    mainFormValues.reset({
      formId: '',
      createdFrom: format(new Date(), 'yyyy-MM-dd'),
      createdTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  //More Table API Call
  const mapDataToTableMore = (array: any[]) => {
    const moreArchivedData: moreTable[] = []

    array.map((value, index) => {
      const obj = {
        rowNumberMore:
          parseInt(rowsPerPageMore) * (currentPageMore - 1) + index + 1,
        submissionId: value?.submissionId,
        serialNumber: value?.serialNumber,
        submittedAtStr: value?.submittedAt,
        submittedAt: format(new Date(value?.submittedAt), 'dd/LLL/y h:mm a'),
        submittedBy: value?.submittedBy,
        status: value?.status,
        statusAtStr: value?.statusAt,
        statusAt: format(new Date(value?.statusAt), 'dd/LLL/y h:mm a'),
        _formIdMore: value?.formId,
      }
      moreArchivedData.push(obj)
    })
    setArchivedTableDataMore(moreArchivedData)
  }

  const mapDataToTableMoreReport = (array: any[]) => {
    const moreArchivedData: moreTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumberMore: parseInt(rowsPerPageMore) * 0 + index + 1,
        submissionId: value?.submissionId,
        serialNumber: value?.serialNumber,
        submittedAtStr: value?.submittedAt,
        submittedAt: format(new Date(value?.submittedAt), 'dd/LLL/y h:mm a'),
        submittedBy: value?.submittedBy,
        status: value?.status,
        statusAtStr: value?.statusAt,
        statusAt: format(new Date(value?.statusAt), 'dd/LLL/y h:mm a'),
        _formIdMore: value?.formId,
      }
      moreArchivedData.push(obj)
    })
    setArchivedTableDataMoreReport(moreArchivedData)
  }

  const callApiForMore = (query = '') => {
    ApiService.get(
      `/archived-reports/submissions/${selectedForm.selectedId}?${query}&page_size=${rowsPerPageMore}&page=${currentPageMore}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoadingMore(false)
          if (Array.isArray(data?.data?.submissions)) {
            const submissions = data?.data?.submissions.filter(
              (submission: any) =>
                submission.status !== 'PaymentFailed' &&
                submission.status !== 'PaymentPending',
            )
            mapDataToTableMore(submissions)
            mapDataToTableMoreReport(submissions)
            setArchivedReportsCountMore(data?.data?.meta?.totalItems)
          }
        } else if (data !== 401) {
          setIsLoadingMore(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    setCurrentPageMore(currentPageMore)
    //selectedId ? callApiForMore(queryObject) : null
    if (selectedForm.selectedId) {
      callApiForMore(queryObjectMore)
    }
  }, [currentPageMore, rowsPerPageMore])

  useEffect(() => {
    if (selectedForm.selectedId) {
      callApiForMore(queryObjectMore)
    }
  }, [selectedForm.selectedId])

  const onSubmitMore = (data: any) => {
    setCurrentPageMore(1)
    if (data?.status === 'ALL' || data?.status === '') {
      delete data['status']
    }
    if (data?.submittedFrom === '') {
      delete data['submittedFrom']
    }
    if (data?.submittedTo === '') {
      delete data['submittedTo']
    }

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObjectMore(queryString)
    callApiForMore(queryString)
  }

  const resetFormMore = () => {
    setCurrentPageMore(1)
    setQueryObjectMore('')
    callApiForMore()
    moreFormValues.reset({
      status: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  const headers = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Form Name', key: 'formName' },
    { label: 'Archived Submissions Count', key: 'archivedSubmissionCount' },
  ]
  const headersMore = [
    { label: 'No', key: 'rowNumberMore' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Submitted at', key: 'submittedAt' },
    { label: 'Submitted by', key: 'submittedBy' },
    { label: 'Status', key: 'status' },
    { label: 'Status at', key: 'statusAt' },
  ]

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  const exportPdf = async () => {
    const content = document.getElementById('divToPrint')
    if (content !== null) {
      content.style.display = 'block'

      const options = {
        margin: 6,
        filename: `Archived_Report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
      }

      await html2pdf(content, options)

      content.style.display = 'none'
    }
  }

  const exportPdfMore = () => {
    const doc = new jsPDF('p', 'pt')
    autoTable(doc, {
      body: archivedTableDataMoreReport,
      columns: [
        { header: 'No', dataKey: 'rowNumberMore' },
        { header: 'Serial Number', dataKey: 'serialNumber' },
        { header: 'Submitted at', dataKey: 'submittedAt' },
        { header: 'Submitted by', dataKey: 'submittedBy' },
        { header: 'Status', dataKey: 'status' },
        {
          header: 'Status at',
          dataKey: 'statusAt',
        },
      ],
    })
    doc.save('Archived_More_Report.pdf')
  }

  const csvReport = {
    data: allArchivedTableData,
    headers: headers,
    filename: 'Archived_Report.csv',
  }

  const csvReportMore = {
    data: archivedTableDataMoreReport,
    headers: headersMore,
    filename: 'Archived_More_Report.csv',
  }

  const formUnarchiveFunc = (formId = '') => {
    ApiService.put(`/archived-reports/unarchive?formId=${formId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          onSubmitMain('')
          toast({
            title: '',
            description: 'Form Unarchived Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
    setUnarchiveForm(false)
  }

  const submissionUnarchiveFunc = (submissionId = '') => {
    ApiService.put(`/archived-reports/unarchive?submissionId=${submissionId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          onSubmitMore('')
          onSubmitMain('')
          toast({
            title: '',
            description: 'Submission Unarchived Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
    setUnarchiveSubmission(false)
  }

  const createdFromDateValue = mainFormValues.watch('createdFrom')
  const createdToDateValue = mainFormValues.watch('createdTo')

  const submittedFromDateValue = moreFormValues.watch('submittedFrom')
  const submittedToDateValue = moreFormValues.watch('submittedTo')

  // Manually setting an error obj for the createdTo field becuase validate property in rules seems not working
  const validateToDate: any = () => {
    mainFormValues.clearErrors('createdTo')

    let fromValue: any = ''
    let toValue: any = ''

    if (createdFromDateValue.length != 0) {
      fromValue = format(new Date(createdFromDateValue), 'yyyy-MM-dd')
    }
    if (createdToDateValue.length != 0) {
      toValue = format(new Date(createdToDateValue), 'yyyy-MM-dd')
    }
    if (createdFromDateValue.length != 0 && createdToDateValue.length != 0) {
      if (toValue > fromValue) {
        mainFormValues.clearErrors('createdTo')
      }
      if (toValue < fromValue) {
        mainFormValues.setError('createdTo', {
          type: 'manual',
          message: 'To date cannot be lesser than From date.',
        })
      }
      if (toValue === fromValue) {
        mainFormValues.clearErrors('createdTo')
      }
    }
  }

  const validateSubmittedToDate: any = () => {
    moreFormValues.clearErrors('submittedTo')

    let fromValue: any = ''
    let toValue: any = ''

    if (submittedFromDateValue.length != 0) {
      fromValue = format(new Date(submittedFromDateValue), 'yyyy-MM-dd')
    }
    if (submittedToDateValue.length != 0) {
      toValue = format(new Date(submittedToDateValue), 'yyyy-MM-dd')
    }
    if (
      submittedFromDateValue.length != 0 &&
      submittedToDateValue.length != 0
    ) {
      if (toValue > fromValue) {
        moreFormValues.clearErrors('submittedTo')
      }
      if (toValue < fromValue) {
        moreFormValues.setError('submittedTo', {
          type: 'manual',
          message: 'To date cannot be lesser than From date.',
        })
      }
      if (toValue === fromValue) {
        moreFormValues.clearErrors('submittedTo')
      }
    }
  }

  useEffect(() => {
    validateToDate()
  }, [createdToDateValue, createdFromDateValue])

  useEffect(() => {
    validateSubmittedToDate()
  }, [submittedToDateValue, submittedFromDateValue])

  const compiledTemplate = template(tableTemplate)

  const printResult = finalTemplateObj
    ? compiledTemplate({ finalOuput: finalTemplateObj })
    : ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [archivedTableData])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box>
          <Box height="40px">
            <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
              Archived Report
            </Heading>
          </Box>
          {/* <Box height="40px">
            <Heading as="h4" size="sm">
              Archived Submissions
            </Heading>
          </Box> */}
          <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.formId ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="formId" mb="8px">
                    Form Name
                  </ChakraFormLabel>
                  <Controller
                    name="formId"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect {...field} items={formsDropdownData} />
                    )}
                  />
                  {mainFormValues.formState.errors.formId && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.formId.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px"></Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.createdFrom
                      ? true
                      : false
                  }
                >
                  <ChakraFormLabel htmlFor="createdFrom" mb="8px">
                    Form Created From
                  </ChakraFormLabel>
                  <Controller
                    control={mainFormValues.control}
                    name={'createdFrom'}
                    rules={{
                      validate: {
                        // GET IT?
                        validDate: (val) => {
                          if (!val) return
                          const dateVal = new Date(val)
                          if (isNaN(dateVal.getTime())) {
                            return 'Please enter a valid date'
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field }) => (
                      <DateInput excludeFuture={true} {...field} />
                    )}
                  />
                  {mainFormValues.formState.errors.createdFrom && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.createdFrom.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>

              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.createdTo ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="createdTo" mb="8px">
                    Form Created To
                  </ChakraFormLabel>
                  <Controller
                    control={mainFormValues.control}
                    name={'createdTo'}
                    rules={{
                      validate: {
                        // GET IT?
                        validDate: (val) => {
                          if (!val) return
                          const dateVal = new Date(val)
                          const fromDateVal = new Date(createdFromDate)
                          if (isNaN(dateVal.getTime())) {
                            return 'Please enter a valid date'
                          } else if (dateVal < fromDateVal) {
                            return 'To date cannot be lesser than From date.'
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field }) => (
                      <DateInput excludeFuture={true} {...field} />
                    )}
                  />
                  {mainFormValues.formState.errors.createdTo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.createdTo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="10px"></Box>
              <Box height="10px"></Box>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
              <Box height="80px">
                <Center>
                  <ButtonGroup variant="outline" spacing="6">
                    <Button type="submit">Search</Button>
                    <Button type="reset" onClick={resetForm}>
                      Reset
                    </Button>
                  </ButtonGroup>
                </Center>
              </Box>
            </SimpleGrid>
          </form>

          <Box display="none" id="divToPrint">
            <div dangerouslySetInnerHTML={{ __html: printResult }} />
          </Box>

          <Box
            bg={'white'}
            px={10}
            py={5}
            overflowX="auto"
            maxWidth="100Vw"
            borderRadius="12px"
          >
            <SimpleGrid
              columns={{ sm: 1, md: 1 }}
              spacing="10px"
              overflowX="auto"
            >
              <DataTable
                heading=""
                rowValues={archivedTableData ? archivedTableData : []}
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage)}
                cols={[
                  {
                    //accessor: (_row: any, i: number) => i + 1,
                    Header: 'No',
                    accessor: 'rowNumber',
                    disableSortBy: true,
                    maxWidth: 300,
                    minWidth: 100,
                  },
                  {
                    Header: 'Form Name',
                    accessor: 'formName',
                    disableSortBy: false,
                    Cell: (props: any) => (
                      <div>{props?.row?.original?.formNameStr}</div>
                    ),
                    maxWidth: 350,
                    minWidth: 190,
                  },

                  {
                    Header: 'Archived Submissions Count',
                    accessor: 'archivedSubmissionCount',
                    disableSortBy: false,
                    maxWidth: 400,
                    minWidth: 170,
                  },
                  {
                    Header: () => (
                      <div style={{ textAlign: 'center', width: '100%' }}>
                        Action
                      </div>
                    ),
                    accessor: 'action',

                    Cell: (props: any) => (
                      <Center>
                        <ButtonGroup variant="outline" spacing="1" padding={2}>
                          {/* <Link to="#moreDetails"> */}
                          <Button
                            onClick={() =>
                              setSelectedForm({
                                selectedId: `${props?.row?.original?._formId}`,
                                selectedFormName: `${props?.row?.original?.formName}`,
                              })
                            }
                          >
                            More
                          </Button>
                          {/* <Button
                            onClick={() =>
                              navigate(
                                `/${props?.row?.original?._formId}/preview`,
                              )
                            }
                          >
                            View Form
                          </Button> */}
                          <Button
                            onClick={() => {
                              setIsOpen(true)
                              setClickedBtn('Unarchive')
                              setUnarchivedId(props?.row?.original?._formId)
                              setUnarchiveForm(true)
                            }}
                          >
                            Unarchive
                          </Button>
                        </ButtonGroup>
                      </Center>
                    ),
                  },
                ]}
              />
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : null}
              {archivedTableData.length === 0 ? (
                <Center>No results found</Center>
              ) : null}
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                <div>
                  Rows per page:
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </div>
                <Pagination
                  currentPage={currentPage}
                  pageSize={parseInt(rowsPerPage, 10)}
                  //totalCount={20} //from BE the total number of record should also be returned.
                  totalCount={archivedReportsCount}
                  onPageChange={setCurrentPage}
                />
                <Box height="10px"></Box>
                <Flex>
                  <Spacer />
                  <ButtonGroup variant="outline" spacing="6">
                    <Button>
                      <CSVLink {...csvReport}>Download CSV</CSVLink>
                    </Button>
                    <Button onClick={exportPdf}>Download PDF</Button>
                  </ButtonGroup>
                </Flex>
              </SimpleGrid>
            </SimpleGrid>
          </Box>
        </Box>
        {selectedForm.selectedId ? (
          <Box bg={'gray.50'} p={10}>
            <Box height="40px">
              <Heading
                as="h4"
                size="md"
                id="moreDetails"
                //ref={scrollTo}
              >
                More Details
              </Heading>
            </Box>
            <form onSubmit={moreFormValues.handleSubmit(onSubmitMore)}>
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                <Box height="10px">
                  <HStack>
                    <Text as="b"> Form Name:</Text>
                    <Text>{selectedForm.selectedFormName}</Text>
                  </HStack>
                </Box>
                <Box height="10px" style={{ display: 'flex' }}></Box>

                <Box height="70px">
                  <FormControl
                    isInvalid={
                      moreFormValues?.formState?.errors?.submittedFrom
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="submittedFrom" mb="8px">
                      Submitted From
                    </ChakraFormLabel>
                    <Controller
                      control={moreFormValues.control}
                      name={'submittedFrom'}
                      rules={{
                        validate: {
                          // GET IT?
                          validDate: (val) => {
                            if (!val) return
                            const dateVal = new Date(val)
                            if (isNaN(dateVal.getTime())) {
                              return 'Please enter a valid date'
                            }
                            return true
                          },
                        },
                      }}
                      render={({ field }) => (
                        <DateInput excludeFuture={true} {...field} />
                      )}
                    />
                    {moreFormValues.formState.errors.submittedFrom && (
                      <FormErrorMessage>
                        {moreFormValues.formState.errors.submittedFrom.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>

                <Box height="70px">
                  <FormControl
                    isInvalid={
                      moreFormValues?.formState?.errors?.submittedTo
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="submittedTo" mb="8px">
                      Submitted To
                    </ChakraFormLabel>
                    <Controller
                      control={moreFormValues.control}
                      name={'submittedTo'}
                      rules={{
                        validate: {
                          // GET IT?
                          validDate: (val) => {
                            if (!val) return
                            const dateVal = new Date(val)
                            const fromDateVal = new Date(submittedFromDate)
                            if (isNaN(dateVal.getTime())) {
                              return 'Please enter a valid date'
                            } else if (dateVal < fromDateVal) {
                              return 'To date cannot be lesser than From date.'
                            }
                            return true
                          },
                        },
                      }}
                      render={({ field }) => (
                        <DateInput excludeFuture={true} {...field} />
                      )}
                    />
                    {moreFormValues.formState.errors.submittedTo && (
                      <FormErrorMessage>
                        {moreFormValues.formState.errors.submittedTo.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      moreFormValues?.formState?.errors?.status ? true : false
                    }
                  >
                    <ChakraFormLabel htmlFor="status" mb="8px">
                      Status
                    </ChakraFormLabel>
                    <Controller
                      name="status"
                      control={moreFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'Completed',
                              label: 'Completed',
                            },

                            {
                              value: 'Inprogress',
                              label: 'Inprogress',
                            },
                            {
                              value: 'Rejected',
                              label: 'Rejected',
                            },
                            {
                              value: 'UnAttended',
                              label: 'UnAttended',
                            },
                          ]}
                        />
                      )}
                    />
                    {moreFormValues.formState.errors.status && (
                      <FormErrorMessage>
                        {moreFormValues.formState.errors.status.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="10px"></Box>
                <Box height="10px"></Box>
              </SimpleGrid>
              <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                <Box height="80px">
                  <Center>
                    <ButtonGroup variant="outline" spacing="6">
                      <Button type="submit">Search</Button>
                      <Button type="reset" onClick={resetFormMore}>
                        Reset
                      </Button>
                    </ButtonGroup>
                  </Center>
                </Box>
              </SimpleGrid>
            </form>

            <Box
              bg={'gray.200'}
              pl={5}
              pr={5}
              overflowX="auto"
              maxWidth="100Vw"
              pb={5}
            >
              <SimpleGrid
                columns={{ sm: 1, md: 1 }}
                spacing="10px"
                overflowX="auto"
              >
                <DataTable
                  heading=""
                  rowValues={archivedTableDataMore ? archivedTableDataMore : []}
                  currentPage={currentPageMore}
                  pageSize={parseInt(rowsPerPageMore)}
                  cols={[
                    {
                      Header: 'No',
                      accessor: 'rowNumberMore',
                      disableSortBy: true,
                      maxWidth: 100,
                    },
                    {
                      Header: 'Serial Number',
                      accessor: 'serialNumber',
                      disableSortBy: false,
                      maxWidth: 100,
                    },
                    {
                      Header: 'Submitted at',
                      accessor: 'submittedAtStr',
                      disableSortBy: false,
                      Cell: (props: any) => (
                        <div>{props?.row?.original?.submittedAt}</div>
                      ),
                      maxWidth: 100,
                    },
                    {
                      Header: 'Submitted by',
                      accessor: 'submittedBy',
                      disableSortBy: true,
                      maxWidth: 150,
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      disableSortBy: false,
                      maxWidth: 110,
                    },
                    {
                      Header: 'Status at',
                      accessor: 'statusAtStr', //not returned
                      disableSortBy: false,
                      Cell: (props: any) => (
                        <div>{props?.row?.original?.statusAt}</div>
                      ),
                      maxWidth: 100,
                    },
                    {
                      Header: 'Action',
                      Cell: (props: any) => (
                        <ButtonGroup variant="outline" spacing="1" padding={2}>
                          <Button
                            onClick={() =>
                              navigate(
                                `/report/${selectedForm.selectedId}/submission/${props?.row?.original?.submissionId}`,
                              )
                            }
                          >
                            View Submission
                          </Button>
                          <Button
                            onClick={() => {
                              setIsOpen(true)
                              setClickedBtn('Unarchive')
                              setUnarchivedId(
                                props?.row?.original?.submissionId,
                              )
                              setUnarchiveSubmission(true)
                            }}
                          >
                            Unarchive
                          </Button>
                        </ButtonGroup>
                      ),
                    },
                  ]}
                />
                {isLoadingMore ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : null}
                {archivedTableDataMore.length === 0 ? (
                  <Center>No results found</Center>
                ) : null}
                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                  <div>
                    Rows per page:
                    <SingleSelect
                      value={rowsPerPageMore}
                      onChange={(value) => {
                        setCurrentPageMore(1)
                        setRowsPerPageMore(value)
                      }}
                      name={'rowsPerPageMore'}
                      isClearable={false}
                      items={[
                        {
                          value: '5',
                          label: '5',
                        },
                        {
                          value: '10',
                          label: '10',
                        },
                        {
                          value: '25',
                          label: '25',
                        },
                        {
                          value: '50',
                          label: '50',
                        },
                        {
                          value: '100',
                          label: '100',
                        },
                      ]}
                    />
                  </div>
                  <Pagination
                    currentPage={currentPageMore}
                    pageSize={parseInt(rowsPerPageMore, 10)}
                    //totalCount={20} //from BE the total number of record should also be returned.
                    totalCount={archivedReportsCountMore}
                    onPageChange={setCurrentPageMore}
                  />
                  <Box height="10px"></Box>
                  <Flex>
                    <Spacer />
                    <ButtonGroup variant="outline" spacing="6">
                      <Button>
                        <CSVLink {...csvReportMore}>Download CSV</CSVLink>
                      </Button>
                      <Button onClick={exportPdfMore}>Download PDF</Button>
                    </ButtonGroup>
                  </Flex>
                </SimpleGrid>
              </SimpleGrid>
            </Box>
          </Box>
        ) : null}
        {isOpen ? (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false)
              setClickedBtn('')
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                {
                  //clickedBtn === 'Unarchive'
                  'Are you sure you want to unArchive?'
                }
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      if (clickedBtn === 'Unarchive') {
                        setClickedBtn('')
                        setIsOpen(false)
                        unarchiveForm && formUnarchiveFunc(unarchivedId)
                        unarchiveSubmission &&
                          submissionUnarchiveFunc(unarchivedId)
                      }
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpen(false)
                      setClickedBtn('')
                    }}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ) : null}
      </Box>
    </>
  )
}
