import { z } from 'zod'
import { Opaque } from 'type-fest'

import { UserDto } from './user'
import { AgencyDto } from './agency'
import { PublicUserDto } from './public_user'

export type ActivityId = Opaque<string, 'ActivityId'>
export const ActivityId = z.string() as unknown as z.Schema<ActivityId>

export const ActivityExecuteUser = z.object({
  userId: UserDto.shape._id,
  username: UserDto.shape.username,
  userCategoryId: z.string().optional(), // change to DTO shape
})
export type ActivityExecuteUser = z.infer<typeof ActivityExecuteUser>

export enum ActivityType {
  CREATE_AGENCY = 'Create Agency',
  UPDATE_AGENCY = 'Update Agency',
  CREATE_SYSTEM_USER = 'Create System User',
  UPDATE_SYSTEM_USER = 'Update System User',
  CREATE_AGENCY_USER = 'Create Agency User',
  UPDATE_AGENCY_USER = 'Update Agency User',
  CREATE_PUBLIC_USER = 'Create Public User',
  UPDATE_PUBLIC_USER = 'Update Public User',
  CREATE_FORM = 'Create Form',
  UPDATE_FROM = 'Update Form',
  PUBLISH_FORM = 'Publish Form',
  UNPUBLISH_FORM = 'Unpublish Form',
  SUBMIT_FORM = 'Form Submitted',
  ACCEPT_FORM_SUBMISSION = 'Form Submission Accepted',
  REJECT_FORM_SUBMISSION = 'Form Submission Rejected',
  COMPLET_FORM_SUBMISSION = 'Form Submission Completed',
  PAYMENT_PENIDNG = 'Payment Pending',
  PAYMENT_SUCCESS = 'Payment Success',
  PAYMENT_FAILED = 'Payment Failed',
  PAYMENT_REFUNDED = 'Payment Refunded',
  UPDATE_AGENCY_NOTIFICATION = 'Update Agency Notification',
}

export const ActivityData = z
  .object({
    user: UserDto.pick({
      _id: true,
      userCategory: true,
      username: true,
      memberNo: true,
      identificationType: true,
      nicNo: true,
      passportNo: true,
      drivingLicenseNo: true,
    }),
    agency: AgencyDto.pick({
      _id: true,
      fullName: true,
      agencyCategory: true,
    }),
    form: z.object({
      _id: z.any(),
      title: z.string(),
    }),
    submission: z.object({
      _id: z.any(),
      title: z.string(),
      status: z.string(),
      reason: z.string(),
      note: z.string(),
      serialNumber: z.string(),
    }),
    agencyNotification: z.object({
      _id: z.any(),
      agency: z.any(),
      notificationType: z.any(),
    }),
    payment: z.object({
      paymentType: z.string(),
      paymentAmount: z.number(),
      paymentTxtId: z.string(),
      paymentMerchantId: z.string(),
      refundedAmount: z.number(),
    }),
    publicUser: PublicUserDto.pick({
      _id: true,
      username: true,
      identificationType: true,
      nicNo: true,
      passportNo: true,
      drivingLicenseNo: true,
    }),
  })
  .deepPartial()
// .passthrough()
export type ActivityData = z.infer<typeof ActivityData>

// _id,
//             agency,
//             agencyId,
//             notificationType,

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
// req type
export const ActivityBase = z.object({
  createdAt: z.date(),
  lastModifiedAt: z.date(),
  executedBy: ActivityExecuteUser,
  executedAgencyId: AgencyDto.shape._id,
  activityType: z.nativeEnum(ActivityType),
  data: ActivityData,
  archived: z.boolean(),
  archivedDate: z.date().optional(),
  lastRestoredAt: z.date().optional(),
})
export type ActivityBase = z.infer<typeof ActivityBase>

// Convert to serialized versions.
// res type
export const ActivityDto = ActivityBase.extend({
  _id: ActivityId,
})
export type ActivityDto = z.infer<typeof ActivityDto>
