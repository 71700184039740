import { useEffect, useState } from 'react'
import { Select, SimpleGrid, Stack } from '@chakra-ui/react'

import { NotificationType } from '~shared/types'
import {
  NotificationValueForEmail,
  NotificationValueForSms,
} from '~shared/types/form/form'

import { ApiService } from '~services/ApiService'
import FormLabel from '~components/FormControl/FormLabel'

import { useMutateFormSettings } from '../mutations'

export interface EnableServiceTableProps {
  emailNotificationEnable?: any
  smsNotificationEnable?: any
  agencyId: string
}

export const EnableServiceTable = ({
  emailNotificationEnable,
  smsNotificationEnable,
  agencyId,
}: EnableServiceTableProps): JSX.Element => {
  const checkNotificationTypeSms = (
    notificationType: NotificationType,
  ): string => {
    let returnValue = 'Default'
    smsNotificationEnable?.map((value: any) => {
      if (value.notificationType === 'Form Submission') {
        value.notificationType = 'Submitted'
      } else if (value.notificationType === 'Form Accepted') {
        value.notificationType = 'Accepted'
      } else if (value.notificationType === 'Form Completed') {
        value.notificationType = 'Completed'
      } else if (value.notificationType === 'Form Rejected') {
        value.notificationType = 'Rejected'
      } else {
        value.notificationType = value.notificationType
      }

      if (value.notificationType === notificationType) {
        if (value.value === NotificationValueForSms.Default) {
          returnValue = 'Default'
        } else if (value.value === NotificationValueForSms.Yes) {
          returnValue = 'Send'
        } else if (value.value === NotificationValueForSms.No) {
          returnValue = 'Dont send'
        }
      }
    })
    return returnValue
  }
  const checkNotificationTypeEmail = (
    notificationType: NotificationType,
  ): string => {
    let returnValue = 'Default'
    emailNotificationEnable?.map((value: any) => {
      if (value.notificationType === 'Form Submission') {
        value.notificationType = 'Submitted'
      } else if (value.notificationType === 'Form Accepted') {
        value.notificationType = 'Accepted'
      } else if (value.notificationType === 'Form Completed') {
        value.notificationType = 'Completed'
      } else if (value.notificationType === 'Form Rejected') {
        value.notificationType = 'Rejected'
      } else {
        value.notificationType = value.notificationType
      }

      if (value.notificationType === notificationType) {
        if (value.value === NotificationValueForEmail.Default) {
          returnValue = 'Default'
        } else if (value.value === NotificationValueForEmail.Yes) {
          returnValue = 'Send'
        } else if (value.value === NotificationValueForEmail.No) {
          returnValue = 'Dont send'
        }
      }
    })
    return returnValue
  }

  const ENABLE_SERVICES_DROPDOWN_ITEMS = ['Default', 'Send', 'Dont send']

  const [isSmsEnabled, setIsSmsEnabled] = useState<boolean>(false)
  const [isEmailEnabled, setEmailSmsEnabled] = useState<boolean>(false)

  const [smsSubmitted, setSmsSubmitted] = useState(
    checkNotificationTypeSms(NotificationType.FS),
  )
  const [smsAccepted, setSmsAccepted] = useState(
    checkNotificationTypeSms(NotificationType.FA),
  )
  const [smsRejected, setSmsRejected] = useState(
    checkNotificationTypeSms(NotificationType.FR),
  )
  const [smsCompleted, setSmsCompleted] = useState(
    checkNotificationTypeSms(NotificationType.FC),
  )
  const [smsPaymentSuccess, setSmsPaymentSuccess] = useState(
    checkNotificationTypeSms(NotificationType.PS),
  )
  const [smsPaymentFailed, setSmsPaymentFailed] = useState(
    checkNotificationTypeSms(NotificationType.PF),
  )
  const [emailSubmitted, setEmailSubmitted] = useState(
    checkNotificationTypeEmail(NotificationType.FS),
  )
  const [emailAccepted, setEmailAccepted] = useState(
    checkNotificationTypeEmail(NotificationType.FA),
  )
  const [emailRejected, setEmailRejected] = useState(
    checkNotificationTypeEmail(NotificationType.FR),
  )
  const [emailCompleted, setEmailCompleted] = useState(
    checkNotificationTypeEmail(NotificationType.FC),
  )
  const [emailPaymentSuccess, setEmailPaymentSuccess] = useState(
    checkNotificationTypeEmail(NotificationType.PS),
  )
  const [emailPaymentFailed, setEmailPaymentFailed] = useState(
    checkNotificationTypeEmail(NotificationType.PF),
  )

  const { mutateSmsNotification, mutateEmailNotification } =
    useMutateFormSettings()

  const getAgency = () => {
    ApiService.get(`/agency/${agencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsSmsEnabled(data?.data?.smsServiceEnabled)
          setEmailSmsEnabled(data?.data?.emailServiceEnabled)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getAgency()
  }, [])

  const updateNotification = (
    notificationAction: string,
    notificationTypeClicked: any,
    smsOrEmail: string,
  ) => {
    const smsArray = smsNotificationEnable ? smsNotificationEnable : []
    const emailArray = emailNotificationEnable ? emailNotificationEnable : []
    let valueUpdated = false
    let valueUpdatedEmail = false

    if (smsOrEmail === 'SMS') {
      // if not there
      if (smsArray?.length === 0) {
        valueUpdated = true
        smsArray.push({
          notificationType: notificationTypeClicked,
          value:
            notificationAction == 'Default'
              ? NotificationValueForSms.Default
              : notificationAction == 'Send'
              ? NotificationValueForSms.Yes
              : NotificationValueForSms.No,
        })
      }
      smsArray?.map((value: any) => {
        // if already there, update.
        if (value.notificationType === notificationTypeClicked) {
          valueUpdated = true
          value.value =
            notificationAction == 'Default'
              ? NotificationValueForSms.Default
              : notificationAction == 'Send'
              ? NotificationValueForSms.Yes
              : NotificationValueForSms.No
        }
      })
      if (!valueUpdated) {
        smsArray.push({
          notificationType: notificationTypeClicked,
          value:
            notificationAction == 'Default'
              ? NotificationValueForSms.Default
              : notificationAction == 'Send'
              ? NotificationValueForSms.Yes
              : NotificationValueForSms.No,
        })
      }
      return mutateSmsNotification.mutate(smsArray ? smsArray : [], {
        onError: () => console.log('Error'),
      })
    } else {
      // if not there
      if (emailArray?.length === 0) {
        valueUpdatedEmail = true
        emailArray.push({
          notificationType: notificationTypeClicked,
          value:
            notificationAction == 'Default'
              ? NotificationValueForEmail.Default
              : notificationAction == 'Send'
              ? NotificationValueForEmail.Yes
              : NotificationValueForEmail.No,
        })
      }
      emailArray?.map((value: any) => {
        // if already there, update.
        if (value.notificationType === notificationTypeClicked) {
          valueUpdatedEmail = true
          value.value =
            notificationAction == 'Default'
              ? NotificationValueForEmail.Default
              : notificationAction == 'Send'
              ? NotificationValueForEmail.Yes
              : NotificationValueForEmail.No
        }
      })
      if (!valueUpdatedEmail) {
        emailArray.push({
          notificationType: notificationTypeClicked,
          value:
            notificationAction == 'Default'
              ? NotificationValueForEmail.Default
              : notificationAction == 'Send'
              ? NotificationValueForEmail.Yes
              : NotificationValueForEmail.No,
        })
      }
      return mutateEmailNotification.mutate(emailArray ? emailArray : [], {
        onError: () => console.log('Error'),
      })
    }
    //check the key,
    //take the array
    //see if key's notification already there: if there then update it.
    // if not there: add it with new value.
    // repeat to all 6 keys.
    // repeat for other array.
  }

  return (
    <>
      {isSmsEnabled ? (
        <>
          <FormLabel isRequired>Enable SMS Notification</FormLabel>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Submitted</FormLabel>
              <Select
                value={smsSubmitted}
                onChange={(e) => {
                  setSmsSubmitted(e.target.value)
                  updateNotification(e.target.value, NotificationType.FS, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Accepted</FormLabel>
              <Select
                value={smsAccepted}
                onChange={(e) => {
                  setSmsAccepted(e.target.value)
                  updateNotification(e.target.value, NotificationType.FA, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Rejected</FormLabel>
              <Select
                value={smsRejected}
                onChange={(e) => {
                  setSmsRejected(e.target.value)
                  updateNotification(e.target.value, NotificationType.FR, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>

            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Completed</FormLabel>
              <Select
                value={smsCompleted}
                onChange={(e) => {
                  setSmsCompleted(e.target.value)
                  updateNotification(e.target.value, NotificationType.FC, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Payment Success</FormLabel>
              <Select
                value={smsPaymentSuccess}
                onChange={(e) => {
                  setSmsPaymentSuccess(e.target.value)
                  updateNotification(e.target.value, NotificationType.PS, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Payment Failed</FormLabel>
              <Select
                value={smsPaymentFailed}
                onChange={(e) => {
                  setSmsPaymentFailed(e.target.value)
                  updateNotification(e.target.value, NotificationType.PF, 'SMS')
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
          </SimpleGrid>
        </>
      ) : null}

      {isEmailEnabled ? (
        <>
          <FormLabel isRequired>Enable Email Notification</FormLabel>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Submitted</FormLabel>
              <Select
                value={emailSubmitted}
                onChange={(e) => {
                  setEmailSubmitted(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.FS,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Accepted</FormLabel>
              <Select
                value={emailAccepted}
                onChange={(e) => {
                  setEmailAccepted(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.FA,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Rejected</FormLabel>
              <Select
                value={emailRejected}
                onChange={(e) => {
                  setEmailRejected(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.FR,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>

            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Completed</FormLabel>
              <Select
                value={emailCompleted}
                onChange={(e) => {
                  setEmailCompleted(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.FC,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Payment Success</FormLabel>
              <Select
                value={emailPaymentSuccess}
                onChange={(e) => {
                  setEmailPaymentSuccess(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.PS,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="row" spacing="0.5rem">
              <FormLabel>Payment Failed</FormLabel>
              <Select
                value={emailPaymentFailed}
                onChange={(e) => {
                  setEmailPaymentFailed(e.target.value)
                  updateNotification(
                    e.target.value,
                    NotificationType.PF,
                    'Email',
                  )
                }}
              >
                {ENABLE_SERVICES_DROPDOWN_ITEMS.map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </Stack>
          </SimpleGrid>
        </>
      ) : null}
    </>
  )
}
