import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiCalendarX, BiMessageSquareX, BiUpload } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  CheckboxGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  Image,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { times } from 'lodash'
import { Md5 } from 'ts-md5'
import { v4 as uuidv4 } from 'uuid'

import { AgencyDto, BasicField } from '~shared/types'

import DefaultAgencyImage from '~assets/images/default-agency.png'
import { VIEW_AGENCY_DETAILS } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Badge from '~components/Badge'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormFieldMessage from '~components/FormControl/FormFieldMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import { DataTable } from '~components/Table'
import Textarea from '~components/Textarea'
import { TableField, TableFieldSchema } from '~templates/Field'

import { CreateAgencyBody } from '../../../../../shared/types/agency'
import Input from '../../../components/Input/index'
import {
  arrayToString,
  convertToSriLankaNumber,
  EMAIL_REGEX_WITH_COMMAS,
  getMappedErrorMessage,
  handleEmailValidation,
  handlePhoneValidation,
  PHONE_NUMBER_REGEX_WITH_COMMAS,
  stringToArray,
  validateAgencyLogoFormat,
  validateImageSize,
} from '../commonFunctions'

export const UpdateGovernmentAgency = (): JSX.Element | null => {
  type ContactPersonTable = {
    _id?: string
    id?: number
    personName: string
    designation?: string
    mobile: string[]
    email?: string[]
  }

  type DisplayedContactPersonTable = {
    id?: number
    personName: string
    designation?: string
    mobile: string
    email: string
  }

  type DropdownType = {
    value: string
    label: string
  }

  const navigate = useNavigate()
  const publicKeyUploadRef = useRef<HTMLInputElement | null>(null)
  const privateKeyUploadRef = useRef<HTMLInputElement | null>(null)
  const imageUploadRef = useRef<HTMLInputElement | null>(null)
  // const SECRET_KEY_REGEX = /^[a-zA-Z0-9/+]+={0,2}$/
  const WEBSITE_REGEX =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [agencyLogo, setAgencyLogo] = useState<string>('')
  const [contactDetail, setContactDetail] = useState<ContactPersonTable[]>([])
  const [displayedContactDetail, setDisplayedContactDetail] = useState<
    DisplayedContactPersonTable[]
  >([])
  const [createContactModal, setCreateContactModal] = useState<boolean>(false)
  const [updateContactModal, setUpdateContactModal] = useState<boolean>(false)
  const [deleteContactModal, setDeleteContactModal] = useState<boolean>(false)
  const [deletingContactId, setDeletingContactId] = useState<number | null>(
    null,
  )
  const [updatingContactId, setUpdatingContactId] = useState<number | null>(
    null,
  )
  //ROOT / CONTACT_CREATE /CONTACT_UPDATE
  const [cancelModalState, setCancelModalState] = useState<string>('')
  const [cancelModal, setCancelModal] = useState<boolean>(false)
  const [publicKeyFile, setPublicKeyFile] = useState<File | undefined>()
  const [privateKeyFile, setPrivateKeyFile] = useState<File | undefined>()
  const [masterProvinceList, setMasterProvinceList] = useState([])
  const [masterDistrictList, setMasterDistrictList] = useState([])
  const [masterCityList, setMasterCityList] = useState([])
  const [province, setProvince] = useState<string>('')
  const [district, setDistrict] = useState<string>('')
  const [filteredDistrict, setFilteredDistrict] = useState<DropdownType[]>([])
  const [filteredCity, setFilteredCity] = useState<DropdownType[]>([])
  const toast = useToast()
  const [userData, setUserData] = useState<any>()

  useEffect(() => {
    const user = localStorage.getItem('user')
    const userD = user ? JSON.parse(user) : {}
    setUserData(userD)
  }, [])

  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')

  useEffect(() => {
    if (userData) {
      setLoggedUserAgencyId(userData?._doc?.agency)
    }
  }, [userData])

  const contactFormDefault = {
    personName: '',
    designation: '',
    mobile: '',
    email: '',
  }

  const createContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const updateContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const onSubmitCreateContactPerson = (data: any) => {
    const arrayPhone = convertToSriLankaNumber(stringToArray(data.mobile))
    const arrayEmail = stringToArray(data.email)
    const person = {
      personName: data?.personName,
      designation: data?.designation,
      mobile: arrayPhone,
      email: arrayEmail,
    }
    const currentContactPersons = []
    for (const i of contactDetail) {
      currentContactPersons.push(i)
    }
    currentContactPersons?.unshift(person)
    setContactDetail(currentContactPersons)
    // to display on the table
    const currentContactsDisplayed: DisplayedContactPersonTable[] = []
    const displayedPerson = {
      personName: data?.personName,
      designation: data?.designation,
      mobile: arrayToString(arrayPhone),
      email: arrayToString(arrayEmail),
    }
    for (const i of displayedContactDetail) {
      currentContactsDisplayed.push(i)
    }
    currentContactsDisplayed?.unshift(displayedPerson)
    setDisplayedContactDetail(currentContactsDisplayed)
    //toast
    toast({
      title: '',
      description: 'Contact Person Created Successfully',
      duration: 5000,
      isClosable: true,
      status: 'success',
      position: 'top-right',
    })
    setCreateContactModal(false)
    createContactFormValues.reset({})
  }

  useEffect(() => {
    if (deletingContactId !== null) {
      setDeleteContactModal(true)
    }
  }, [deletingContactId])

  const onSubmitUpdateContactPerson = (data: any) => {
    const newObj = {
      personName: data?.personName,
      designation: data?.designation,
      mobile: convertToSriLankaNumber(stringToArray(data.mobile)),
      email: stringToArray(data.email),
    }
    //to display on the table
    const newDisplayedObj = {
      personName: data?.personName,
      designation: data?.designation,
      mobile: arrayToString(newObj.mobile),
      email: arrayToString(newObj.email),
    }
    if (updatingContactId !== null) {
      contactDetail[updatingContactId] = newObj
    }
    if (updatingContactId !== null) {
      displayedContactDetail[updatingContactId] = newDisplayedObj
    }
    toast({
      title: '',
      description: 'Contact Person Updated Successfully',
      duration: 5000,
      isClosable: true,
      status: 'success',
      position: 'top-right',
    })
    setUpdateContactModal(false)
    updateContactFormValues.reset({})
    setUpdatingContactId(null)
  }

  useEffect(() => {
    if (updatingContactId !== null) {
      setUpdateContactModal(true)
      const obj = contactDetail[updatingContactId]
      const stringPhoneNumber = arrayToString(obj.mobile)
      const stringEmail = arrayToString(obj?.email ? obj?.email : [])
      updateContactFormValues.setValue(
        'personName',
        obj?.personName ? obj?.personName : '',
      )
      updateContactFormValues.setValue(
        'designation',
        obj?.designation ? obj?.designation : '',
      )
      updateContactFormValues.setValue(
        'mobile',
        stringPhoneNumber.replace(/^(\+\d{1,2})/, '0'),
      )
      updateContactFormValues.setValue('email', stringEmail ? stringEmail : '')
    }
  }, [updatingContactId])

  const [result, setResult] = useState<AgencyDto>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState()

  async function getAgency() {
    if (loggedUserAgencyId !== '') {
      ApiService.get(`/agency/${loggedUserAgencyId}`)
        .then((data: any) => {
          if (data?.status === 200) {
            console.log('data.data', data.data)
            setResult(data.data) //result.agency
            setIsLoaded(true)
          } else if (data !== 401) {
            toast({
              title: '',
              description: 'Something went wrong!',
              duration: 5000,
              isClosable: true,
              status: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch((error) => console.log('error', error))
    }
  }

  useEffect(() => {
    const response = getAgency()
  }, [loggedUserAgencyId])

  useEffect(() => {
    const phoneNumberString = arrayToString(result?.mobile ? result.mobile : [])
    const emailString = arrayToString(result?.email ? result.email : [])

    mainFormValues.setValue(
      'governemtAgencyId',
      result?._id ? result?._id : '-',
    )
    mainFormValues.setValue(
      'fullName',
      result?.fullName ? result?.fullName : '',
    )
    mainFormValues.setValue(
      'shortName',
      result?.shortName ? result?.shortName : '',
    )
    mainFormValues.setValue(
      'description',
      result?.description ? result.description : '',
    )
    mainFormValues.setValue(
      'addressLine1',
      result?.address?.line1 ? result?.address?.line1 : '',
    )
    mainFormValues.setValue(
      'addressLine2',
      result?.address?.line2 ? result?.address?.line2 : '',
    )
    mainFormValues.setValue(
      'city',
      result?.address?.city ? result?.address?.city : '',
    )
    mainFormValues.setValue(
      'district',
      result?.district ? result?.district : '',
    )
    mainFormValues.setValue(
      'province',
      result?.province ? result?.province : '',
    )
    mainFormValues.setValue('website', result?.website ? result?.website : '')
    mainFormValues.setValue(
      'merchantId',
      result?.paymentMerchantId ? result?.paymentMerchantId : '',
    )
    mainFormValues.setValue(
      'serviceId',
      result?.paymentServiceId ? result?.paymentServiceId : '',
    )
    mainFormValues.setValue(
      'paymentPublicKey',
      result?.paymentPublicKey ? result?.paymentPublicKey : '',
    )
    mainFormValues.setValue(
      'paymentPrivateKey',
      result?.paymentPrivateKey ? result?.paymentPrivateKey : '',
    )
    mainFormValues.setValue(
      'paymentPassword',
      result?.paymentPassword ? 'InitPassword' : '',
    )
    mainFormValues.setValue(
      'phoneNumber',
      phoneNumberString.replace(/^(\+\d{1,2})/, '0'),
    )
    mainFormValues.setValue('email', emailString)
    mainFormValues.setValue('logo', result?.logo ? result?.logo : '')
    mainFormValues.setValue('status', result?.status ? result?.status : '')
    setContactDetail(result?.contactPerson ? result?.contactPerson : [])
    const tempArray: DisplayedContactPersonTable[] = []
    if (result?.contactPerson) {
      result.contactPerson.map((value) => {
        tempArray.push({
          personName: value.personName,
          designation: value.designation,
          mobile: arrayToString(value.mobile),
          email: arrayToString(value.email),
        })
      })
    }
    setDisplayedContactDetail(tempArray)
    mainFormValues.setValue('note', result?.note ? result?.note : '')
  }, [result])

  const mainFormValues = useForm({
    defaultValues: {
      governemtAgencyId: '',
      fullName: '',
      shortName: '',
      description: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      district: '',
      province: '',
      website: '',
      merchantId: '',
      serviceId: '',
      paymentPublicKey: '',
      paymentPrivateKey: '',
      paymentPassword: '',
      phoneNumber: '',
      email: '',
      enableServices: [],
      logo: '',
      contactPerson: '',
      status: '',
      note: '',
    },
  })

  async function getProvince() {
    ApiService.get(`/master-data/?category=PROVINCE`)
      .then((data: any) => {
        if (data?.status === 200) {
          mapDataToProvince(data?.data.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapDataToProvince = (array: any[]) => {
    const provinceList: any = []

    array.map((value) => {
      const obj = {
        value: value.option,
        label: value.option,
      }
      provinceList.push(obj)
    })

    setMasterProvinceList(provinceList)
  }

  async function getDistrict() {
    ApiService.get(`/master-data/?category=DISTRICT`)
      .then((data: any) => {
        if (data?.status === 200) {
          // mapDataToDistrict(data?.data.data)
          setMasterDistrictList(data?.data.data)
          setFilteredDistrict(mapDataToDistrict(data?.data.data))
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapDataToDistrict = (array: any[]) => {
    const districtList: any = []

    array.map((value) => {
      const obj = {
        value: value.option,
        label: value.option,
      }
      districtList.push(obj)
    })

    return districtList
  }

  const onProvinceChange = (val: any) => {
    setFilteredDistrict(
      mapDataToDistrict(
        masterDistrictList.filter((dist: any) => {
          return dist?.parent?.option === val
        }),
      ),
    )
    mainFormValues.setValue('province', val)
    mainFormValues.resetField('district')
  }

  async function getCity() {
    ApiService.get(`/master-data/?category=CITY`)
      .then((data: any) => {
        if (data?.status === 200) {
          // mapDataToCity(data?.data.data)
          setMasterCityList(data?.data.data)
          setFilteredCity(mapDataToCity(data?.data.data))
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapDataToCity = (array: any[]) => {
    const cityList: any = []

    array.map((value) => {
      const obj = {
        value: value.option,
        label: value.option,
      }
      cityList.push(obj)
    })

    return cityList
  }

  const onDistrictChange = (val: any) => {
    setFilteredCity(
      mapDataToCity(
        masterCityList.filter((city: any) => {
          return city?.parent?.option === val
        }),
      ),
    )
    mainFormValues.setValue('district', val)
    mainFormValues.resetField('city')
  }

  useEffect(() => {
    getProvince()
    getDistrict()
    getCity()
  }, [])

  const uploadPaymentKey = async (keyFile: File): Promise<string> => {
    const formData = new FormData()
    formData.append('keyFile', keyFile)
    // const requestBody = {
    //   keyFile: keyFile,
    // }
    const returndedData = await ApiService.post(
      `/agency/payment/key`,
      formData,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    ).then((data: any): { message: string } => {
      return data.data
    })

    if (returndedData) {
      return returndedData.message
    }
    toast({
      title: '',
      description: 'Error occured during uploading',
      duration: 5000,
      isClosable: true,
      status: 'danger',
      position: 'top-right',
    })
    return 'Error occured'
  }

  const uploadToPresginedUrl = async (url: string) => {
    const headers = new Headers({ 'Content-Type': '*' })
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: logoFile,
    })
  }

  const getPresignedUrlForAgencyLogo = async () => {
    const params = {
      fileName: logoFile?.name,
      fileType: logoFile?.type,
      agencyId: loggedUserAgencyId,
    }

    const returnedData = await ApiService.post(`/agency/logo`, params).then(
      (data: any): { uploadUrl: string; logoKey: string } => {
        return data.data
      },
    )

    if (returnedData) {
      await uploadToPresginedUrl(returnedData?.uploadUrl)
    }

    return returnedData.logoKey
  }

  const onSubmitMain = async (data: any) => {
    let logoKey = ''
    if (logoFile) {
      try {
        logoKey = await getPresignedUrlForAgencyLogo()
      } catch (e) {
        toast({
          title: '',
          description: 'Error occured during uploading image',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
        // if error then stop the flow
        return
      }
    }

    const phoneNumberArray = convertToSriLankaNumber(
      stringToArray(data.phoneNumber),
    )
    const emailArray = stringToArray(data.email)
    const finalContactDetails: any[] = []
    contactDetail?.map((contact) => {
      delete contact['_id']
      finalContactDetails.push(contact)
    })

    // encrypt keys
    const fileName = Md5.hashStr(`${data.merchantId}-${data.serviceId}`)

    // upload keys.
    if (
      data.paymentPublicKey !== result?.paymentPublicKey ||
      data.paymentPrivateKey !== result?.paymentPrivateKey
    ) {
      if (publicKeyFile || privateKeyFile) {
        try {
          if (publicKeyFile) {
            const publicKeyBlob = publicKeyFile.slice(
              0,
              publicKeyFile.size,
              publicKeyFile.type,
            )
            const newFilePublicKey = new File(
              [publicKeyBlob],
              `${fileName}-public.crt`,
              {
                type: publicKeyFile.type,
              },
            )

            if (publicKeyFile) {
              await uploadPaymentKey(newFilePublicKey)
            }
          }

          if (privateKeyFile) {
            const privateKeyBlob = privateKeyFile.slice(
              0,
              privateKeyFile.size,
              privateKeyFile.type,
            )
            const newFilePrivateKey = new File(
              [privateKeyBlob],
              `${fileName}-private.key`,
              {
                type: privateKeyFile.type,
              },
            )

            if (privateKeyFile) {
              await uploadPaymentKey(newFilePrivateKey)
            }
          }
        } catch (e) {
          toast({
            title: '',
            description: 'Error occured during uploading',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
          // if error then stop the flow
          return
        }
      }
    }

    const agencyParams: CreateAgencyBody = {
      fullName: data.fullName,
      shortName: data.shortName,
      logo: logoKey,
      description: data.description,
      agencyCategory: {
        code: 'AGENCY',
        name: 'Agency',
      },
      paymentMerchantId: data.merchantId,
      paymentServiceId: data.serviceId,
      paymentPublicKey: `${fileName}-public.crt`,
      paymentPrivateKey: `${fileName}-private.key`,
      paymentPassword: data.paymentPassword,
      status: data.status,
      website: data.website,
      mobile: phoneNumberArray,
      email: emailArray,
      address: {
        line1: data.addressLine1,
        line2: data.addressLine2,
        line3: '',
        city: data.city,
      },
      district: data.district,
      province: data.province,
      contactPerson: finalContactDetails,
      smsServiceEnabled: data.enableServices?.includes('SMS'),
      emailServiceEnabled: data.enableServices?.includes('Email'),
      note: data.note,
    }

    //if removed
    if (data.paymentPublicKey === '') {
      agencyParams.paymentPublicKey = ''
      agencyParams.paymentPrivateKey = ''
      agencyParams.paymentPassword = ''
    }

    if (data?.paymentPassword === 'InitPassword') {
      delete agencyParams['paymentPassword']
    }

    if (logo === '' && logoFile === null && agencyLogo === '') {
      agencyParams.logo = ''
    } else if (!logoFile) {
      delete agencyParams['logo']
    }

    const requestBody = {
      agency: agencyParams,
    }

    ApiService.put(`/agency/${loggedUserAgencyId}`, requestBody)
      .then((data: any) => {
        if (data?.status === 200) {
          toast({
            title: '',
            description: 'Government Agency Updated Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate(`${VIEW_AGENCY_DETAILS}`)
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const logo = mainFormValues.watch('logo')

  useEffect(() => {
    if (logoFile !== null) {
      setAgencyLogo(URL.createObjectURL(new Blob([logoFile])))
    }
  }, [logoFile])

  const removeImage = () => {
    setAgencyLogo('')
    setLogoFile(null)
    mainFormValues.setValue('logo', '')
  }

  const validateAgencyLogo = (file: File): boolean => {
    const validAgencyLogoFormat = validateAgencyLogoFormat(file?.name)
    if (!validAgencyLogoFormat) {
      toast({
        title: '',
        description: 'Invalid image type. Allowed types: png, jpg, jpeg',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    const isImgSizeValid = validateImageSize(file, 2000000)
    if (!isImgSizeValid) {
      toast({
        title: '',
        description: 'Maximum allowed file size is 2MB.',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    return true
  }

  const encMerchantId = mainFormValues.watch('merchantId')
  const encServiceId = mainFormValues.watch('serviceId')
  const encPublicKey = mainFormValues.watch('paymentPublicKey')
  const encPrivateKey = mainFormValues.watch('paymentPrivateKey')
  const encPassword = mainFormValues.watch('paymentPassword')

  //encryption key
  const handlePublicKeySelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0]
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (publicKeyUploadRef.current) {
        publicKeyUploadRef.current.value = ''
      }

      if (!file) return

      const fileArray = file?.name.split('.')
      if (!fileArray) {
        return
      }
      if (fileArray[fileArray?.length - 1] !== 'crt') {
        toast({
          title: '',
          description: 'Only CRT files allowed.',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
        setPublicKeyFile(undefined)
        mainFormValues.setValue('paymentPublicKey', '')
        return
      }

      setPublicKeyFile(file)

      mainFormValues.setValue('paymentPublicKey', 'Public Key Uploaded', {
        shouldValidate: true,
      })
      if (encServiceId === result?.paymentServiceId) {
        if (encServiceId === '') {
          const uniqueMerchantId = uuidv4()
          mainFormValues.setValue('merchantId', uniqueMerchantId)
        }
        mainFormValues.setValue('merchantId', result?.paymentMerchantId)
      } else {
        const uniqueMerchantId = uuidv4()
        mainFormValues.setValue('merchantId', uniqueMerchantId)
      }
    },

    [
      mainFormValues,
      result?.paymentServiceId,
      encServiceId,
      result?.paymentMerchantId,
    ],
  )

  //encryption key
  const handlePrivateKeySelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0]
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (privateKeyUploadRef.current) {
        privateKeyUploadRef.current.value = ''
      }

      if (!file) return

      const fileArray = file?.name.split('.')
      if (!fileArray) {
        return
      }
      if (fileArray[fileArray?.length - 1] !== 'key') {
        toast({
          title: '',
          description: 'Only KEY files allowed.',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
        setPrivateKeyFile(undefined)
        mainFormValues.setValue('paymentPrivateKey', '')
        return
      }

      setPrivateKeyFile(file)

      mainFormValues.setValue('paymentPrivateKey', 'Private Key Uploaded', {
        shouldValidate: true,
      })
    },
    [mainFormValues, toast],
  )

  // useEffect(() => {
  //   if (result?.paymentServiceId !== '') {
  //     if (encServiceId !== result?.paymentServiceId) {
  //       mainFormValues.setValue('merchantId', '')
  //       mainFormValues.setValue('paymentPublicKey', '')
  //       mainFormValues.setValue('paymentPrivateKey', '')
  //       mainFormValues.setValue('paymentPassword', '')
  //     }
  //   }
  // }, [encServiceId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box bg={'gray.50'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h4" size="md">
            Update Government Agency
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
              <Box height="70px">
                <FormControl>
                  <FormLabel htmlFor="governemtAgencyId" mb="8px">
                    Government Agency ID
                  </FormLabel>
                  <Input
                    placeholder={'Government Agency ID'}
                    id="governemtAgencyId"
                    maxLength={25}
                    isDisabled
                    {...mainFormValues.register('governemtAgencyId')}
                  />
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.fullName ? true : false
                  }
                >
                  <FormLabel htmlFor="fullName" mb="8px">
                    Full Name *
                  </FormLabel>
                  <Input
                    placeholder={'Full Name'}
                    id="fullName"
                    maxLength={100}
                    {...mainFormValues.register('fullName', {
                      required: 'Full Name is required',
                      maxLength: { value: 100, message: 'Max length is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.fullName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.fullName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.shortName ? true : false
                  }
                >
                  <FormLabel htmlFor="shortName" mb="8px">
                    Short Name *
                  </FormLabel>
                  <Input
                    placeholder={'Short Name'}
                    id="shortName"
                    maxLength={100}
                    {...mainFormValues.register('shortName', {
                      required: 'Short Name is required',
                      maxLength: { value: 100, message: 'Max length is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.shortName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.shortName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.description
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="description" mb="8px">
                    Description
                  </FormLabel>
                  <Input
                    placeholder={'Description'}
                    id="description"
                    maxLength={200}
                    {...mainFormValues.register('description', {
                      maxLength: { value: 200, message: 'Max length is 200' },
                    })}
                  />
                  {mainFormValues.formState.errors.description && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.description.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.addressLine1
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="addressLine1" mb="8px">
                    Address Line 1 *
                  </FormLabel>
                  <Input
                    placeholder={'Address Line 1'}
                    id="addressLine1"
                    maxLength={60}
                    {...mainFormValues.register('addressLine1', {
                      required: 'Address Line 1 is required',
                      maxLength: { value: 60, message: 'Max length is 60' },
                    })}
                  />
                  {mainFormValues.formState.errors.addressLine1 && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.addressLine1.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.addressLine2
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="addressLine2" mb="8px">
                    Address Line 2
                  </FormLabel>
                  <Input
                    placeholder={'Address Line 2'}
                    id="addressLine2"
                    maxLength={60}
                    {...mainFormValues.register('addressLine2', {
                      maxLength: { value: 60, message: 'Max length is 60' },
                    })}
                  />
                  {mainFormValues.formState.errors.addressLine2 && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.addressLine2.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.province ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="province" mb="8px">
                    Province *
                  </ChakraFormLabel>
                  <Controller
                    name="province"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={masterProvinceList}
                        onChange={(val) => {
                          onProvinceChange(val)
                        }}
                      />
                    )}
                    rules={{ required: 'Province is required' }}
                  />
                  {mainFormValues.formState.errors.province && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.province.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.district ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="district" mb="8px">
                    District *
                  </ChakraFormLabel>
                  <Controller
                    name="district"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={filteredDistrict}
                        onChange={(val) => {
                          onDistrictChange(val)
                        }}
                      />
                    )}
                    rules={{ required: 'District is required' }}
                  />
                  {mainFormValues.formState.errors.district && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.district.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.city ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="city" mb="8px">
                    Divisional Secretariat *
                  </ChakraFormLabel>
                  <Controller
                    name="city"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect {...field} items={filteredCity} />
                    )}
                    rules={{ required: 'City is required' }}
                  />
                  {mainFormValues.formState.errors.city && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.city.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.website ? true : false
                  }
                >
                  <FormLabel htmlFor="website" mb="8px">
                    Website
                  </FormLabel>
                  <Input
                    placeholder={'Website'}
                    id="website"
                    maxLength={100}
                    {...mainFormValues.register('website', {
                      maxLength: { value: 100, message: 'Max length is 100' },
                      pattern: {
                        value: WEBSITE_REGEX,
                        message: 'Invalid Website',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.website && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.website.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {/* <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.merchantId ? true : false
                  }
                >
                  <FormLabel htmlFor="merchantId" mb="8px">
                    Merchant ID
                  </FormLabel>
                  <Input
                    placeholder={'Merchant ID'}
                    id="merchantId"
                    maxLength={20}
                    {...mainFormValues.register('merchantId', {
                      required:
                        encServiceId === '' &&
                        encPublicKey === '' &&
                        encPrivateKey === '' &&
                        encPassword === ''
                          ? false
                          : 'Merchant ID is required if payment details are entered',
                      maxLength: { value: 20, message: 'Max length is 20' },
                    })}
                  />
                  {mainFormValues.formState.errors.merchantId && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.merchantId.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box> */}
              {/* <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.serviceId ? true : false
                  }
                >
                  <FormLabel htmlFor="serviceId" mb="8px">
                    Service ID
                  </FormLabel>
                  <Controller
                    name="serviceId"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={'Service ID'}
                        maxLength={20}
                      />
                    )}
                    rules={{
                      required:
                        encMerchantId === '' &&
                        encPublicKey === '' &&
                        encPrivateKey === '' &&
                        encPassword === ''
                          ? false
                          : 'Service ID is required if payment details are entered',
                      maxLength: { value: 20, message: 'Max length is 20' },
                    }}
                  />
                  {mainFormValues.formState.errors.serviceId && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.serviceId.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box> */}
              {/* <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.paymentPublicKey
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="paymentPublicKey" mb="8px">
                    Payment Public Key
                  </FormLabel>
                  <Stack direction="row" spacing="0.5rem">
                    <Controller
                      name="paymentPublicKey"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'Payment Public Key'}
                          readOnly={true}
                          maxLength={100}
                        />
                      )}
                      rules={{
                        required:
                          encServiceId === '' &&
                          encMerchantId === '' &&
                          encPrivateKey === '' &&
                          encPassword === ''
                            ? false
                            : 'Public Key is required if payment details are entered',
                        maxLength: {
                          value: 100,
                          message: 'Max length is 100',
                        },
                      }}
                    />
                    <IconButton
                      // isDisabled={isLoading}
                      variant="outline"
                      aria-label="Pass secret key from file"
                      icon={<BiUpload />}
                      onClick={() => publicKeyUploadRef.current?.click()}
                    />
                    <IconButton
                      // isDisabled={isLoading}
                      variant="outline"
                      aria-label="Pass secret key from file"
                      icon={<BiCalendarX />}
                      onClick={() => {
                        mainFormValues.setValue('paymentPublicKey', '')
                        mainFormValues.setValue('merchantId', '')
                      }}
                    />
                  </Stack>
                  {mainFormValues.formState.errors.paymentPublicKey && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.paymentPublicKey.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Input
                  name="publicKey"
                  type="file"
                  ref={publicKeyUploadRef}
                  accept=".crt"
                  onChange={handlePublicKeySelect}
                  display="none"
                />
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.paymentPrivateKey
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="paymentPrivateKey" mb="8px">
                    Payment Private Key
                  </FormLabel>
                  <Stack direction="row" spacing="0.5rem">
                    <Controller
                      name="paymentPrivateKey"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'Payment Private Key'}
                          maxLength={100}
                          readOnly={true}
                        />
                      )}
                      rules={{
                        required:
                          encServiceId === '' &&
                          encMerchantId === '' &&
                          encPublicKey === '' &&
                          encPassword === ''
                            ? false
                            : 'Private Key is required if payment details are entered',
                        maxLength: {
                          value: 100,
                          message: 'Max length is 100',
                        },
                      }}
                    />
                    <IconButton
                      // isDisabled={isLoading}
                      variant="outline"
                      aria-label="Pass secret key from file"
                      icon={<BiUpload />}
                      onClick={() => privateKeyUploadRef.current?.click()}
                    />
                    <IconButton
                      // isDisabled={isLoading}
                      variant="outline"
                      aria-label="Pass secret key from file"
                      icon={<BiCalendarX />}
                      onClick={() =>
                        mainFormValues.setValue('paymentPrivateKey', '')
                      }
                    />
                  </Stack>
                  {mainFormValues.formState.errors.paymentPrivateKey && (
                    <FormErrorMessage>
                      {
                        mainFormValues.formState.errors.paymentPrivateKey
                          .message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Input
                  name="privateKey"
                  type="file"
                  ref={privateKeyUploadRef}
                  accept=".key"
                  onChange={handlePrivateKeySelect}
                  display="none"
                />
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.paymentPassword
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="paymentPassword" mb="8px">
                    Payment Password
                  </FormLabel>
                  <Controller
                    name="paymentPassword"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={'Payment Password'}
                        maxLength={30}
                        type="password"
                      />
                    )}
                    rules={{
                      required:
                        encServiceId === '' &&
                        encMerchantId === '' &&
                        encPublicKey === '' &&
                        encPrivateKey === ''
                          ? false
                          : 'Password is required if payment details are entered',
                      maxLength: { value: 30, message: 'Max length is 30' },
                    }}
                  />
                  {mainFormValues.formState.errors.paymentPassword && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.paymentPassword.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box> */}
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.phoneNumber
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="phoneNumber" mb="8px">
                    Phone Numbers *
                  </FormLabel>
                  <Controller
                    name="phoneNumber"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <Input {...field} placeholder={'Phone Numbers'} />
                    )}
                    rules={{
                      required: 'Phone Number is required',
                      validate: handlePhoneValidation,
                    }}
                  />
                  {mainFormValues.formState.errors.phoneNumber && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.phoneNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.email ? true : false
                  }
                >
                  <FormLabel htmlFor="email" mb="8px">
                    Emails
                  </FormLabel>
                  <Controller
                    name="email"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={'Emails'}
                        maxLength={100}
                      />
                    )}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: 'Max length is 100',
                      },
                      validate: handleEmailValidation,
                    }}
                  />
                  {mainFormValues.formState.errors.email && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.email.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {result ? (
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.enableServices
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="enableServices" mb="8px">
                      Enable Services
                    </FormLabel>
                    <CheckboxGroup
                      defaultValue={
                        result?.smsServiceEnabled && result?.emailServiceEnabled
                          ? ['SMS', 'Email']
                          : result?.smsServiceEnabled
                          ? ['SMS']
                          : result?.emailServiceEnabled
                          ? ['Email']
                          : []
                      }
                    >
                      <Stack spacing={[1, 5]} direction={['column', 'row']}>
                        <Checkbox
                          value="SMS"
                          {...mainFormValues.register('enableServices')}
                        >
                          SMS
                        </Checkbox>
                        <Checkbox
                          value="Email"
                          {...mainFormValues.register('enableServices')}
                        >
                          Email
                        </Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  </FormControl>
                </Box>
              ) : null}
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.status ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="status" mb="8px">
                    Status *
                  </ChakraFormLabel>
                  <Controller
                    name="status"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'Active',
                            label: 'Active',
                          },
                          {
                            value: 'Inactive',
                            label: 'Inactive',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: 'Status is required' }}
                  />
                  {mainFormValues.formState.errors.status && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.status.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </SimpleGrid>

            <Box height="80px">
              <FormControl>
                <FormLabel mb="8px" ml={55}>
                  Agency Logo
                </FormLabel>
                <Stack direction="row" spacing="0.5rem">
                  <IconButton
                    isDisabled={logoFile !== null}
                    variant="outline"
                    aria-label="Agency logo upload"
                    icon={<BiUpload />}
                    mb={5}
                    ml={55}
                    onClick={() => imageUploadRef.current?.click()}
                  />
                  <IconButton
                    isDisabled={agencyLogo === '' && logo === ''}
                    variant="outline"
                    aria-label="Agency logo upload"
                    icon={<BiMessageSquareX />}
                    mb={5}
                    onClick={removeImage}
                  />
                </Stack>
                <Input
                  name="agencyLogo"
                  type="file"
                  ref={imageUploadRef}
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => {
                    if (!e.target.files) return
                    const validAgencyLogo = validateAgencyLogo(
                      e?.target?.files[0],
                    )
                    if (!validAgencyLogo) {
                      return
                    }
                    setLogoFile(e?.target?.files[0])
                    if (imageUploadRef.current) {
                      imageUploadRef.current.value = ''
                    }
                  }}
                  display="none"
                />
                <Box>
                  <Box bg={'white'} h="200px" w="200px">
                    <Image
                      h="200px"
                      w="200px"
                      src={agencyLogo ? agencyLogo : logo}
                      fallbackSrc={DefaultAgencyImage}
                      objectFit="cover"
                      alt="agency.logo"
                    />
                  </Box>
                  <FormFieldMessage>
                    It is advised to upload 300x300 image.
                  </FormFieldMessage>
                </Box>
              </FormControl>
            </Box>
          </SimpleGrid>

          <div style={{ paddingTop: 80 }}>
            <Box bg={'gray.200'} p={5} borderRadius="12px">
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                <Box bg={'gray.200'} p={5}></Box>
                <Button onClick={() => setCreateContactModal(true)}>
                  Add New Contact
                </Button>
              </SimpleGrid>
              <DataTable
                heading="Contact Person Details"
                rowValues={displayedContactDetail ? displayedContactDetail : []}
                cols={[
                  {
                    Header: 'No.',
                    accessor: (_row: any, i: number) => i + 1,
                    disableSortBy: true,
                    minWidth: 80,
                    width: 80,
                    maxWidth: 100,
                  },
                  {
                    Header: 'Contact Person',
                    accessor: 'personName',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                  {
                    Header: 'Designation',
                    accessor: 'designation',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                  {
                    Header: 'Phone Number',
                    accessor: 'mobile',
                    disableSortBy: true,
                    maxWidth: 150,
                    minWidth: 140,
                    Cell: ({ value }: { value: string }) =>
                      `0${value.slice(3)}`,
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                  {
                    Header: 'Action',
                    Cell: (props: any) => (
                      <ButtonGroup variant="outline" spacing="1">
                        <Button
                          onClick={() => setUpdatingContactId(props.row.id)}
                        >
                          Update
                        </Button>
                        <Button
                          onClick={() => setDeletingContactId(props.row.id)}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    ),
                  },
                ]}
              />
              {contactDetail.length === 0 ? (
                <Center>No results found</Center>
              ) : null}
            </Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Box height="70px" mt={10}>
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.note ? true : false
                  }
                >
                  <FormLabel htmlFor="note" mb="8px">
                    Note
                  </FormLabel>
                  <Textarea
                    placeholder={'Note'}
                    id="note"
                    maxLength={250}
                    resize={'none'}
                    maxHeight={'90px'}
                    {...mainFormValues.register('note', {
                      maxLength: { value: 250, message: 'Max length is 250' },
                    })}
                  />
                  {mainFormValues.formState.errors.note && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.note.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </SimpleGrid>
            <Center mt={10}>
              <ButtonGroup variant="outline" spacing="2" padding={10} mt={10}>
                <Button type="submit">Save</Button>
                <Button
                  onClick={() => {
                    setCancelModal(true)
                    setCancelModalState('ROOT')
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Center>
          </div>
        </form>
      </Box>

      {createContactModal ? (
        <Modal
          isOpen={createContactModal}
          onClose={() => {
            setCreateContactModal(false)
            createContactFormValues.reset({})
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={createContactFormValues.handleSubmit(
                onSubmitCreateContactPerson,
              )}
            >
              <ModalCloseButton />
              <ModalHeader>Add Contact Person</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        createContactFormValues?.formState?.errors?.personName
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="personName" mb="8px">
                        Contact Person *
                      </FormLabel>
                      <Input
                        placeholder={'Contact Person'}
                        id="personName"
                        maxLength={100}
                        {...createContactFormValues.register('personName', {
                          required: 'Contact Person is required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {createContactFormValues.formState.errors.personName && (
                        <FormErrorMessage>
                          {
                            createContactFormValues.formState.errors.personName
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        createContactFormValues?.formState?.errors?.designation
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="designation" mb="8px">
                        Designation
                      </FormLabel>
                      <Input
                        placeholder={'Designation'}
                        id="designation"
                        maxLength={100}
                        {...createContactFormValues.register('designation', {
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {createContactFormValues.formState.errors.designation && (
                        <FormErrorMessage>
                          {
                            createContactFormValues.formState.errors.designation
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        createContactFormValues?.formState?.errors?.mobile
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="mobile" mb="8px">
                        Phone Numbers *
                      </FormLabel>
                      <InputGroup>
                        <Input
                          placeholder={'Phone Numbers'}
                          id="mobile"
                          {...createContactFormValues.register('mobile', {
                            required: 'Phone Number is required',
                            validate: handlePhoneValidation,
                          })}
                        />
                      </InputGroup>
                      {createContactFormValues.formState.errors.mobile && (
                        <FormErrorMessage>
                          {
                            createContactFormValues.formState.errors.mobile
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        createContactFormValues?.formState?.errors?.email
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="email" mb="8px">
                        Emails
                      </FormLabel>
                      <Input
                        placeholder={'Emails'}
                        id="email"
                        maxLength={100}
                        {...createContactFormValues.register('email', {
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                          validate: handleEmailValidation,
                        })}
                      />
                      {createContactFormValues.formState.errors.email && (
                        <FormErrorMessage>
                          {
                            createContactFormValues.formState.errors.email
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button type="submit">Add</Button>
                  <Button
                    onClick={() => {
                      setCancelModalState('CONTACT_CREATE')
                      setCancelModal(true)
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      ) : null}
      {deleteContactModal ? (
        <Modal
          isOpen={deleteContactModal}
          onClose={() => {
            setDeleteContactModal(false)
            setDeletingContactId(null)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to delete?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (deletingContactId !== null) {
                      contactDetail.splice(deletingContactId, 1)
                      displayedContactDetail.splice(deletingContactId, 1)
                      setDeletingContactId(null)
                      setDeleteContactModal(false)
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setDeleteContactModal(false)
                    setDeletingContactId(null)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
      {updateContactModal ? (
        <Modal
          isOpen={updateContactModal}
          onClose={() => {
            setUpdateContactModal(false)
            updateContactFormValues.reset({})
            setUpdatingContactId(null)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={updateContactFormValues.handleSubmit(
                onSubmitUpdateContactPerson,
              )}
            >
              <ModalCloseButton />
              <ModalHeader>Update Contact Person</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        updateContactFormValues?.formState?.errors?.personName
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="personName" mb="8px">
                        Contact Person *
                      </FormLabel>
                      <Input
                        placeholder={'Contact Person'}
                        id="personName"
                        maxLength={100}
                        {...updateContactFormValues.register('personName', {
                          required: 'Contact Person is required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {updateContactFormValues.formState.errors.personName && (
                        <FormErrorMessage>
                          {
                            updateContactFormValues.formState.errors.personName
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        updateContactFormValues?.formState?.errors?.designation
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="designation" mb="8px">
                        Designation
                      </FormLabel>
                      <Input
                        placeholder={'Designation'}
                        id="designation"
                        maxLength={100}
                        {...updateContactFormValues.register('designation', {
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {updateContactFormValues.formState.errors.designation && (
                        <FormErrorMessage>
                          {
                            updateContactFormValues.formState.errors.designation
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        updateContactFormValues?.formState?.errors?.mobile
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="mobile" mb="8px">
                        Phone Numbers *
                      </FormLabel>
                      <InputGroup>
                        <Input
                          placeholder={'Phone Numbers'}
                          id="mobile"
                          {...updateContactFormValues.register('mobile', {
                            required: 'Phone Number is required',
                            validate: handlePhoneValidation,
                          })}
                        />
                      </InputGroup>
                      {updateContactFormValues.formState.errors.mobile && (
                        <FormErrorMessage>
                          {
                            updateContactFormValues.formState.errors.mobile
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        updateContactFormValues?.formState?.errors?.email
                          ? true
                          : false
                      }
                    >
                      <FormLabel htmlFor="email" mb="8px">
                        Emails
                      </FormLabel>
                      <Input
                        placeholder={'Emails'}
                        id="email"
                        maxLength={100}
                        {...updateContactFormValues.register('email', {
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                          validate: handleEmailValidation,
                        })}
                      />
                      {updateContactFormValues.formState.errors.email && (
                        <FormErrorMessage>
                          {
                            updateContactFormValues.formState.errors.email
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button type="submit">Add</Button>
                  <Button
                    onClick={() => {
                      setCancelModalState('CONTACT_UPDATE')
                      setCancelModal(true)
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      ) : null}
      {cancelModal ? (
        <Modal
          isOpen={cancelModal}
          onClose={() => {
            setCancelModal(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to cancel?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (cancelModalState === 'ROOT') {
                      setCancelModal(false)
                      navigate('/agent/view-agency')
                    } else if (cancelModalState === 'CONTACT_CREATE') {
                      setCancelModal(false)
                      setCreateContactModal(false)
                      createContactFormValues.reset({})
                    } else if (cancelModalState === 'CONTACT_UPDATE') {
                      setCancelModal(false)
                      setUpdateContactModal(false)
                      updateContactFormValues.reset({})
                      setUpdatingContactId(null)
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setCancelModal(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
