import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Heading,
  SimpleGrid,
  Skeleton,
} from '@chakra-ui/react'
import moment from 'moment-timezone'

import { ActivityDto, ActivityType } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'

export const ActivityDetailsPage = (): JSX.Element | null => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const { activity } = useParams()
  const toast = useToast()

  const [result, setResult] = useState<ActivityDto>()

  async function getActivity() {
    setIsLoading(true)
    ApiService.get(`/activity/${activity}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data?.data)
          setIsLoading(false)
        } else if (data !== 401) {
          setIsError(true)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getActivity()
  }, [])

  const mainFormValues = useForm({
    defaultValues: {
      activityId: '',
      activityAt: '',
      activityBy: '',
      activityCreatedAgency: '',
      activityType: '',
      userId: '',
      userCategory: '',
      userName: '',
      identificationType: '',
      identificationNo: '',
      memberNo: '',
      agencyId: '',
      agencyName: '',
      formId: '',
      formName: '',
      serialNumber: '',
      paymentType: '',
      paymentAmount: '',
      paymentId: '',
      merchantId: '',
      refundedAmount: '',
      reason: '',
      note: '',
    },
  })

  const onSubmitMain = (data: any) => {
    console.log('Form Values', data)
  }

  async function getAgency() {
    ApiService.get(`/agency/${agencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          mainFormValues.setValue(
            'activityCreatedAgency',
            data?.data?.fullName ? data?.data?.fullName : '-',
          )
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getAgency()

    mainFormValues.setValue(
      'activityId',
      result?._id !== undefined ? result?._id : '-',
    )
    mainFormValues.setValue(
      'activityAt',
      result?.createdAt !== undefined
        ? moment(result?.createdAt).format('DD/MM/YYYY')
        : '-',
    )
    mainFormValues.setValue(
      'activityBy',
      result?.executedBy?.username ? result?.executedBy?.username : '-',
    )
    mainFormValues.setValue(
      'activityType',
      result?.activityType !== undefined ? result?.activityType : '-',
    )
    mainFormValues.setValue(
      'userId',
      result?.data?.user?._id ? result?.data?.user?._id : '-',
    )
    mainFormValues.setValue(
      'userCategory',
      result?.data?.user?.userCategory?.name !== undefined
        ? result?.data?.user?.userCategory?.name
        : '-',
    )
    mainFormValues.setValue(
      'userName',
      result?.data?.user?.username ? result?.data?.user?.username : '-',
    )
    mainFormValues.setValue(
      'identificationType',
      result?.data?.user?.identificationType
        ? result?.data?.user?.identificationType
        : '-',
    )
    let idNo
    if (result?.data?.user?.identificationType) {
      idNo =
        result?.data?.user?.identificationType === 'P'
          ? result?.data?.user?.passportNo
          : result?.data?.user?.identificationType === 'D'
          ? result?.data?.user?.drivingLicenseNo
          : result?.data?.user?.nicNo
    }
    mainFormValues.setValue('identificationNo', idNo ?? '')
    mainFormValues.setValue(
      'memberNo',
      result?.data?.user?.memberNo ? result?.data?.user?.memberNo : '-',
    )
    mainFormValues.setValue(
      'agencyId',
      result?.data?.agency?._id ? result?.data?.agency?._id : '-',
    )
    mainFormValues.setValue(
      'agencyName',
      result?.data?.agency?.fullName ? result?.data?.agency?.fullName : '-',
    )
    mainFormValues.setValue(
      'formId',
      result?.data?.form?._id ? result?.data?.form?._id : '-',
    )
    mainFormValues.setValue(
      'formName',
      result?.data?.form?.title ? result?.data?.form?.title : '-',
    )
    mainFormValues.setValue(
      'serialNumber',
      result?.data?.submission?.serialNumber
        ? result?.data?.submission?.serialNumber
        : '-',
    )
    mainFormValues.setValue(
      'paymentType',
      result?.data?.payment?.paymentType
        ? result?.data?.payment?.paymentType
        : '-',
    )
    mainFormValues.setValue(
      'paymentAmount',
      result?.data?.payment?.paymentAmount
        ? result?.data?.payment?.paymentAmount.toString()
        : '-',
    )
    mainFormValues.setValue(
      'paymentId',
      result?.data?.payment?.paymentTxtId
        ? result?.data?.payment?.paymentTxtId
        : '-',
    )
    mainFormValues.setValue(
      'merchantId',
      result?.data?.payment?.paymentMerchantId
        ? result?.data?.payment?.paymentMerchantId
        : '-',
    )
    mainFormValues.setValue(
      'refundedAmount',
      result?.data?.payment?.refundedAmount
        ? result?.data?.payment?.refundedAmount.toString()
        : '-',
    )
    mainFormValues.setValue(
      'reason',
      result?.data?.submission?.reason ? result?.data?.submission?.reason : '-',
    )
  }, [result])

  const activityId = mainFormValues.getValues('activityId')
  const activityAt = mainFormValues.getValues('activityAt')
  const activityBy = mainFormValues.getValues('activityBy')
  const activityCreatedAgency = mainFormValues.getValues(
    'activityCreatedAgency',
  )
  const activityType = mainFormValues.getValues('activityType')
  const userId = mainFormValues.getValues('userId')
  const userCategory = mainFormValues.getValues('userCategory')
  const userName = mainFormValues.getValues('userName')
  const identificationType = mainFormValues.getValues('identificationType')
  const identificationNo = mainFormValues.getValues('identificationNo')
  const memberNo = mainFormValues.getValues('memberNo')
  const agencyId = mainFormValues.getValues('agencyId')
  const agencyName = mainFormValues.getValues('agencyName')
  const formId = mainFormValues.getValues('formId')
  const formName = mainFormValues.getValues('formName')
  const serialNumber = mainFormValues.getValues('serialNumber')
  const paymentType = mainFormValues.getValues('paymentType')
  const paymentAmount = mainFormValues.getValues('paymentAmount')
  const paymentId = mainFormValues.getValues('paymentId')
  const merchantId = mainFormValues.getValues('merchantId')
  const refundedAmount = mainFormValues.getValues('refundedAmount')
  const reason = mainFormValues.getValues('reason')
  const note = mainFormValues.getValues('note')

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Activity Details
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <dl>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '100%', display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Activity ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {activityId}
                    </dt>
                  </Skeleton>
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Activity At
                </dd>

                <Skeleton isLoaded={!isLoading && !isError}>
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {activityAt}
                  </dt>
                </Skeleton>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Activity By
                </dd>
                <Skeleton isLoaded={!isLoading && !isError}>
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {activityBy}
                  </dt>
                </Skeleton>
              </div>

              {activityCreatedAgency !== '-' && activityCreatedAgency !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Activity Created Agency
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {activityCreatedAgency}
                    </dt>
                  </Skeleton>
                </div>
              )}

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Activity Type
                </dd>
                <Skeleton isLoaded={!isLoading && !isError}>
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {activityType}
                  </dt>
                </Skeleton>
              </div>

              {userId !== '-' && userId !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    User ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {userId}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {userCategory !== '-' && userCategory !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    User Category
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {userCategory}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {userName !== '-' && userName !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    User Name
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{
                        flex: '50%',
                        padding: '10px',
                        maxWidth: '100%',
                      }}
                    >
                      {userName}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {identificationType !== '-' && identificationType !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Identification Type
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{
                        flex: '50%',
                        padding: '10px',
                        maxWidth: '100%',
                      }}
                    >
                      {identificationType}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {identificationNo !== '-' && identificationNo !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Identification No.
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{
                        flex: '50%',
                        padding: '10px',
                        maxWidth: '100%',
                      }}
                    >
                      {identificationNo}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {memberNo !== '-' && memberNo !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Member No.
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {memberNo}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {agencyId !== '-' && agencyId !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Government Agency ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {agencyId}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {agencyName !== '-' && agencyName !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Government Agency Name
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {agencyName}
                    </dt>
                  </Skeleton>
                </div>
              )}
            </dl>
          </SimpleGrid>

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <dl>
              {formId !== '-' && formId !== '' && (
                <div style={{ width: '100%', display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Form ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {formId}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {formName !== '-' && formName !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Form Name
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {formName}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {serialNumber !== '-' && serialNumber !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Serial Number
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {serialNumber}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {paymentType !== '-' && paymentType !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Payment Type
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {paymentType}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {paymentAmount !== '-' && paymentAmount !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Payment Amount (LKR)
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {paymentAmount}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {paymentId !== '-' && paymentId !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Payment ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {paymentId}
                    </dt>
                  </Skeleton>
                </div>
              )}

              {/* {merchantId !== '-' && merchantId !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Merchant ID
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {merchantId}
                    </dt>
                  </Skeleton>
                </div>
              )} */}

              {refundedAmount !== '-' && refundedAmount !== '' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Refunded Amount (LKR)
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {refundedAmount}
                    </dt>
                  </Skeleton>
                </div>
              )}
            </dl>
          </SimpleGrid>

          {reason !== '-' && reason !== '' && note !== '-' && note !== '' && (
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <dl>
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Reason
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {reason}
                    </dt>
                  </Skeleton>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Note
                  </dd>
                  <Skeleton isLoaded={!isLoading && !isError}>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {note}
                    </dt>
                  </Skeleton>
                </div>
              </dl>
            </SimpleGrid>
          )}
          <Center>
            <ButtonGroup variant="outline" spacing="2" padding={10}>
              <Button onClick={() => navigate('/activity-log')}>Back</Button>
            </ButtonGroup>
          </Center>
        </form>
      </Box>
    </>
  )
}
