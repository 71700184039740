import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

type CancelModalProps = {
  isOpen: boolean
  onClose: () => void
  onYesClick: () => void
  onNoClick: () => void
  confirmationMessage: JSX.Element
  headerMessage: JSX.Element
  yesButton: JSX.Element
  noButton: JSX.Element
}

export const CancelModal = ({
  isOpen,
  onClose,
  onYesClick,
  onNoClick,
  confirmationMessage,
  headerMessage,
  yesButton,
  noButton,
}: CancelModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{headerMessage}</ModalHeader>
        <ModalBody whiteSpace="pre-line">{confirmationMessage}</ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onYesClick}>{yesButton}</Button>
            <Button onClick={onNoClick}>{noButton}</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
