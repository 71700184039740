import { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as html2pdf from 'html2pdf.js'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { isEmpty, isString, template } from 'lodash'
import moment from 'moment-timezone'

import { BasicField, PaymentStatusTypes, SubmissionStatus } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
//import { DateInput } from '~components/DatePicker/DateInput'
import DateInput from '~components/DatePicker'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import { tableTemplate } from '~pages/AdminReports/utils'
import { StatusChangeModal } from '~features/admin-form/responses/ResponsesPage/storage/UnlockedResponses/viewSubmissions/StatusChangeModal'

import Input from '../../../components/Input/index'
import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const ReconciliationReport = (): JSX.Element | null => {
  type agencyTable = {
    rowNumber: number
    submitionId: string
    formNames: string
    submittedAt: string
    submittedAtStr: string
    submittedBy: string
    status: string
    statusAt: string
    statusAtStr: string
    paymentStatus: string
    amount: string
    paymentTransactionId: string
    refunded: string
    refundedAt: string
    refundedAtStr: string
    serialNumber: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [reconciliationTableData, setReconciliationTableData] = useState<
    agencyTable[]
  >([])
  const [reconciliationTableDataToReport, setReconciliationTableDataToReport] =
    useState<agencyTable[]>([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agencyDropdownData, setAgencyDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [isloggedIdSet, setIsloggedIdSet] = useState<boolean>(false)
  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')
  const [agenciesCount, setAgenciesCount] = useState<number>(0)
  const [loggedUserId, setLoggedUserId] = useState<string>('')

  const [totalAmounts, setTotalAmounts] = useState('')
  const [refundedAmount, setRefundedAmount] = useState('')
  const [netAmount, setNetAmount] = useState('')
  const [refundModalIsOpen, setRefundModalIsOpen] = useState<boolean>(false)
  const [refundDetails, setRefundedDetails] = useState<any>()
  const [finalTemplateObj, setFinalTemplateObj] = useState<any>()

  const mapDataToTable = (array: any[]) => {
    const currentAgencyData: agencyTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        submitionId: value?.submissionId,
        formNames: value?.formName.toLowerCase(),
        formNameStr: value?.formName,
        formId: value?.formId,
        submittedAt: moment(value?.submittedAt).format('DD/MMM/YYYY HH: MM'),
        submittedAtStr: value?.submittedAt,
        submittedBy: value?.submittedBy,
        status: value?.status,
        statusAt: moment(value?.statusAt).format('DD/MMM/YYYY HH: MM'),
        statusAtStr: value?.statusAt,
        paymentStatus: value?.paymentStatus,
        amount: parseFloat(value?.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        paymentTransactionId: value?.paymentTxnId,
        refunded: value?.refunded == true ? 'Yes' : 'No',
        refundedAt: moment(value?.refundedAt).format('DD/MMM/YYYY HH: MM'),
        refundedAtStr: value?.refundedAt,
        serialNumber: value?.serialNumber,
      }
      currentAgencyData.push(obj)
    })
    setReconciliationTableData(currentAgencyData)
  }

  const mapDataToTableReport = (array: any[]) => {
    const currentAgencyData: agencyTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * 0 + index + 1,
        submitionId: value?.submissionId,
        formNames: value?.formName.toLowerCase(),
        formNameStr: value?.formName,
        formId: value?.formId,
        submittedAt: moment(value?.submittedAt).format('DD/MMM/YYYY HH: MM'),
        submittedAtStr: value?.submittedAt,
        submittedBy: value?.submittedBy,
        status: value?.status,
        statusAt: moment(value?.statusAt).format('DD/MMM/YYYY HH: MM'),
        statusAtStr: value?.statusAt,
        paymentStatus: value?.paymentStatus,
        amount: parseFloat(value?.amount).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        paymentTransactionId: value?.paymentTxnId,
        refunded: value?.refunded == true ? 'Yes' : 'No',
        refundedAt: moment(value?.refundedAt).format('DD/MMM/YYYY HH: MM'),
        refundedAtStr: value?.refundedAt,
        serialNumber: value?.serialNumber,
      }
      currentAgencyData.push(obj)
    })
    setReconciliationTableDataToReport(currentAgencyData)

    const columns = [
      'No',
      'Serial Number',
      'Form Name',
      'Submitted At',
      'Submitted By',
      'Status',
      'Status At',
      // 'Payment Status',
      'Amount (LKR)',
      'Payment Transaction Id',
      'Refunded',
      'Refunded At',
    ]
    const finalAnswerArray: any[] = []
    currentAgencyData.forEach(function (item: any) {
      console.log('item', item)
      const tempArray = []
      tempArray.push(item?.rowNumber)
      tempArray.push(item?.serialNumber)
      tempArray.push(item?.formNames)
      tempArray.push(item?.submittedAt)
      tempArray.push(item?.submittedBy)
      tempArray.push(item?.status)
      tempArray.push(item?.statusAt)
      // tempArray.push(item?.paymentStatus)
      tempArray.push(item?.amount)
      tempArray.push(item?.paymentTransactionId)
      tempArray.push(item?.refunded)
      tempArray.push(item?.refundedAt)
      finalAnswerArray.push(tempArray)
    })

    const finalObj = {
      answerArray: finalAnswerArray,
      columnTitles: columns,
      columnWidths: [
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '70px',
        '50px',
        '70px',
      ],
    }

    console.log('finalObj', finalObj)
    setFinalTemplateObj(finalObj)
  }

  const dropDown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.title,
      }
      currentAgencyData.push(obj)
    })
    setAgencyDropdownData(currentAgencyData)
  }

  useEffect(() => {
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
        setIsloggedIdSet(true)
      }

      if (loggedUserDetails?._doc?.agency) {
        setLoggedUserAgencyId(loggedUserDetails?._doc?.agency)
      }
    }
  }, [])

  const mainFormValues = useForm({
    defaultValues: {
      formId: '',
      refunded: '',
      // paymentStatus: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    },
  })

  const fromDateVal = mainFormValues.watch().submittedFrom

  const callApi = (query = '') => {
    ApiService.get(
      `reconciliation-reports/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.resultList)) {
            mapDataToTable(data?.data?.resultList)
            mapDataToTableReport(data?.data?.resultList)
            setAgenciesCount(data?.data?.metaData?.totalItems)
            setTotalAmounts(`${data?.data?.metaData?.totalAmount}`)
            setRefundedAmount(`${data?.data?.metaData?.refundedAmount}`)
            setNetAmount(`${data?.data?.metaData?.netAmount}`)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const callDropDown = (query = '') => {
    if (isloggedIdSet) {
      ApiService.get(`/report/allforms?agencyId=${loggedUserAgencyId}`)
        .then((data: any) => {
          if (data?.status === 200) {
            setIsLoading(false)
            if (Array.isArray(data?.data)) {
              dropDown(data?.data)
            }
          } else {
            setIsLoading(false)
          }
        })
        .catch((error) => console.log('error', error))
    }
  }

  useEffect(() => {
    callDropDown()
  }, [loggedUserAgencyId, isloggedIdSet])

  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.formId === '') {
      delete data['formId']
    }
    if (data?.refunded === '') {
      delete data['refunded']
    }
    // if (data?.paymentStatus === '') {
    //   delete data['paymentStatus']
    // }
    if (data?.submittedFrom === '') {
      delete data['submittedFrom']
    }
    if (data?.submittedTo === '') {
      delete data['submittedTo']
    }
    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    mainFormValues.reset({
      formId: '',
      refunded: '',
      // paymentStatus: '',
      submittedFrom: format(new Date(), 'yyyy-MM-dd'),
      submittedTo: format(new Date(), 'yyyy-MM-dd'),
    })
  }

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  const exportPdf = async () => {
    const content = document.getElementById('divToPrint')
    if (content !== null) {
      content.style.display = 'block'

      const options = {
        margin: 6,
        filename: `Reconciliation_Report.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
      }

      await html2pdf(content, options)

      content.style.display = 'none'
    }
  }

  const headers = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Form Name', key: 'formNames' },
    { label: 'Submitted At', key: 'submittedAt' },
    { label: 'Submitted By', key: 'submittedBy' },
    { label: 'Status', key: 'status' },
    { label: 'Status At', key: 'statusAt' },
    // { label: 'Payment Status', key: 'paymentStatus' },
    { label: 'Amount (LKR)', key: 'amount' },
    { label: 'Payment Transaction Id', key: 'paymentTransactionId' },
    { label: 'Refunded', key: 'refunded' },
    { label: 'Refunded At', key: 'refundedAt' },
  ]

  const csvReport = {
    data: reconciliationTableDataToReport,
    headers: headers,
    filename: 'Reconciliation_Report.csv',
  }

  const compiledTemplate = template(tableTemplate)

  const printResult = finalTemplateObj
    ? compiledTemplate({ finalOuput: finalTemplateObj })
    : ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [reconciliationTableData])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Reconciliation Report
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.formId ? true : false
                }
              >
                {/* <ChakraFormLabel>Start Date</ChakraFormLabel> */}
                <ChakraFormLabel htmlFor="submittedFrom" mb="8px">
                  Submitted From
                </ChakraFormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'submittedFrom'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.submittedFrom && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.submittedFrom.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.submittedTo ? true : false
                }
              >
                {/* <ChakraFormLabel>Start Date</ChakraFormLabel> */}
                <ChakraFormLabel htmlFor="submittedTo" mb="8px">
                  Submitted To
                </ChakraFormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'submittedTo'}
                  rules={{
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        const fromDate = new Date(fromDateVal)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        } else if (dateVal < fromDate) {
                          return 'To date cannot be lesser than From date.'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.submittedTo && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.submittedTo.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.formId ? true : false
                }
              >
                <ChakraFormLabel htmlFor="formId" mb="8px">
                  Form Name
                </ChakraFormLabel>
                <Controller
                  name="formId"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect {...field} items={agencyDropdownData} />
                  )}
                />
                {mainFormValues.formState.errors.formId && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.formId.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.formId ? true : false
                }
              >
                <ChakraFormLabel htmlFor="refunded" mb="8px">
                  Refunded
                </ChakraFormLabel>
                <Controller
                  name="refunded"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'true',
                          label: 'Refunded',
                        },
                        {
                          value: 'false',
                          label: 'Not Refunded',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.refunded && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.refunded.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            {/* <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.formId ? true : false
                }
              >
                <ChakraFormLabel htmlFor="paymentStatus" mb="8px">
                  Payment Status
                </ChakraFormLabel>
                <Controller
                  name="paymentStatus"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'Pending',
                          label: 'Pending',
                        },
                        {
                          value: 'Success',
                          label: 'Success',
                        },
                        {
                          value: 'Failed',
                          label: 'Failed',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.paymentStatus && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.paymentStatus.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box> */}
          </SimpleGrid>
          <Box height="80px"></Box>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
            <Box height="80px">
              <Center>
                <ButtonGroup variant="outline" spacing="6">
                  <Button
                    type="submit"
                    // disabled={!mainFormValues.formState.isDirty}
                  >
                    Search
                  </Button>
                  <Button type="reset" onClick={resetForm}>
                    Reset
                  </Button>
                </ButtonGroup>
              </Center>
            </Box>
          </SimpleGrid>
        </form>

        <Box display="none" id="divToPrint">
          <div dangerouslySetInnerHTML={{ __html: printResult }} />
        </Box>

        <Box
          bg={'white'}
          px={10}
          py={5}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid
            columns={{ sm: 1, md: 1 }}
            spacing="10px"
            overflowX="auto"
          >
            <DataTable
              heading=""
              rowValues={reconciliationTableData ? reconciliationTableData : []}
              currentPage={currentPage}
              pageSize={parseInt(rowsPerPage)}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 100,
                },
                {
                  Header: 'Serial Number',
                  accessor: 'serialNumber',
                  disableSortBy: false,
                  maxWidth: 400,
                  minWidth: 200,
                },
                {
                  Header: 'Form Name',
                  accessor: 'formNames',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.formNameStr}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 250,
                },
                {
                  Header: 'Submitted at',
                  accessor: 'submittedAtStr',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.submittedAt}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 250,
                },
                {
                  Header: 'Submitted by',
                  accessor: 'submittedBy',
                  disableSortBy: false,
                  maxWidth: 400,
                  minWidth: 250,
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  disableSortBy: false,
                  maxWidth: 300,
                  minWidth: 200,
                },
                {
                  Header: 'Status at',
                  accessor: 'statusAtStr',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.statusAt}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 250,
                },
                // {
                //   Header: 'Payment Status',
                //   accessor: 'paymentStatus',
                //   disableSortBy: false,
                //   maxWidth: 400,
                //   minWidth: 250,
                // },
                {
                  Header: 'Amount (LKR)',
                  accessor: 'amount',
                  disableSortBy: false,
                  maxWidth: 400,
                  minWidth: 250,
                },
                {
                  Header: 'Payment Transaction ID',
                  accessor: 'paymentTransactionId',
                  disableSortBy: false,
                  maxWidth: 350,
                  minWidth: 250,
                },
                {
                  Header: 'Refunded',
                  accessor: 'refunded',
                  disableSortBy: false,
                  maxWidth: 350,
                  minWidth: 250,
                },
                {
                  Header: 'Refunded at',
                  accessor: 'refundedAtStr',
                  disableSortBy: false,
                  Cell: (props: any) => (
                    <div>{props?.row?.original?.refundedAt}</div>
                  ),
                  maxWidth: 400,
                  minWidth: 300,
                },
                {
                  Header: 'Action',
                  maxWidth: 400,
                  minWidth: 300,
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1" padding={2}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/report/${props?.row?.original?.formId}/submission/${props?.row?.original?.submitionId}`,
                          )
                        }
                      >
                        View Submission
                      </Button>
                      <Button
                        onClick={() => {
                          setRefundedDetails(props?.row?.original)
                          setRefundModalIsOpen(true)
                        }}
                        disabled={
                          props?.row?.original?.status === 'Rejected' &&
                          props?.row?.original?.paymentStatus === 'Success' &&
                          props?.row?.original?.refunded === 'No'
                            ? false
                            : true
                        }
                      >
                        Refund
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />

            {console.log('refundDetails', refundDetails)}

            {refundModalIsOpen ? (
              <StatusChangeModal
                clickedButton={'Refunded'}
                formId={refundDetails?.formId}
                submissionId={refundDetails?.submitionId}
                isOpen={refundModalIsOpen}
                setModalIsOpen={setRefundModalIsOpen}
                agencyId={refundDetails?.agencyId}
                formName={refundDetails?.formNames}
                submittedAt={refundDetails?.submittedAt}
                submittedBy={refundDetails?.submittedBy}
                paymentAmount={refundDetails?.amount}
                lastPaymentTransactionId={refundDetails?.paymentTransactionId}
                onStatusChange={() => void 0}
              />
            ) : null}
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {reconciliationTableData.length === 0 ? (
              <Center>No results found</Center>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <div>
                Rows per page:
                <SingleSelect
                  value={rowsPerPage}
                  onChange={(value) => {
                    setCurrentPage(1)
                    setRowsPerPage(value)
                  }}
                  name={'rowsPerPage'}
                  isClearable={false}
                  items={[
                    {
                      value: '5',
                      label: '5',
                    },
                    {
                      value: '10',
                      label: '10',
                    },
                    {
                      value: '25',
                      label: '25',
                    },
                    {
                      value: '50',
                      label: '50',
                    },
                    {
                      value: '100',
                      label: '100',
                    },
                  ]}
                />
              </div>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={agenciesCount}
                onPageChange={setCurrentPage}
              />
              <Box height="10px"></Box>
              <Flex>
                <Spacer />
                <ButtonGroup variant="outline" spacing="6">
                  <Button>
                    <CSVLink {...csvReport}>Download CSV</CSVLink>
                  </Button>
                  <Button onClick={exportPdf}>Download PDF</Button>
                </ButtonGroup>
              </Flex>
            </SimpleGrid>
            <Box height="1px"></Box>
          </SimpleGrid>
        </Box>
        <Box my={10}>
          <div style={{ display: 'flex' }}>
            <dd style={{ flex: '60%', padding: '10px', fontWeight: 500 }}>
              Total Amount (LKR):
            </dd>
            <dt
              style={{
                flex: '50%',
                padding: '10px',
                maxWidth: '100%',
                textAlign: 'right',
              }}
            >
              {totalAmounts
                ? parseFloat(totalAmounts).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '-'}
            </dt>
          </div>
          <div style={{ display: 'flex' }}>
            <dd style={{ flex: '60%', padding: '10px', fontWeight: 500 }}>
              Total refunded Amount (LKR):
            </dd>
            <dt
              style={{
                flex: '50%',
                padding: '10px',
                maxWidth: '100%',
                textAlign: 'right',
              }}
            >
              {refundedAmount
                ? parseFloat(refundedAmount).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '-'}
            </dt>
          </div>
          <div style={{ display: 'flex' }}>
            <dd style={{ flex: '60%', padding: '10px', fontWeight: 500 }}>
              Net Amount (LKR):
            </dd>
            <dt
              style={{
                flex: '50%',
                padding: '10px',
                maxWidth: '100%',
                textAlign: 'right',
              }}
            >
              {netAmount
                ? parseFloat(netAmount).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '-'}
            </dt>
          </div>
        </Box>
      </Box>
    </>
  )
}
