import { useCallback, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Container, Flex, Stack, StackDivider } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { FormDto } from '~shared/types'

import { CITIZEN_ROOT_ROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'

import { getMappedErrorMessage } from '~pages/Agency/utils'
import { SubmissionData } from '~features/public-form/PublicFormContext'
import { submitFormFeedback } from '~features/public-form/PublicFormService'

import { LanguageFilter } from '../../../../../atom'
import {
  FeedbackBlock,
  FeedbackFormInput,
} from '../FormEndPage/components/FeedbackBlock'
import { ProcessingPaymentModal } from '../FormEndPage/components/ProcessingPaymentModal'
import { ThankYouSvgr } from '../FormEndPage/components/ThankYouSvgr'

export const PaymentProcessingPage = (): JSX.Element => {
  type PaymentSucessFeedback = {
    formTitle?: string
    formId: string
    endPage?: FormDto['endPage']
    submissionData?: SubmissionData
  }

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(true)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false)
  const [sucessResult, setSucessResult] = useState<PaymentSucessFeedback>()
  const toast = useToast()
  const navigate = useNavigate()
  const userTempLanguage = localStorage.getItem('tempLanguage')
  const locale: any = useRecoilValue(LanguageFilter)

  useEffect(() => {
    const searchParamsString = window.location.search
    const searchParams = new URLSearchParams(searchParamsString)

    const requestBody = {
      paymentRequestId: searchParams.get('request_reference'),
      lgpsPaymentResponse: searchParams.get('lgpsPaymentResponse'),
    }
    ApiService.post(`/public/payment/process-payment`, requestBody)
      .then((data) => {
        if (data.status === 200) {
          if (data?.data?.submissionPayment?.paymentStatus === 'Failed') {
            toast({
              title: '',
              description: `${
                userTempLanguage == 'en'
                  ? 'Payment failed'
                  : userTempLanguage == 'si'
                  ? 'ගෙවීම අසාර්ථක විය'
                  : 'பணம் செலுத்த முடியவில்லை'
              }`,
              duration: 5000,
              isClosable: true,
              status: 'danger',
              position: 'top-right',
            })
            navigate(
              `/${data?.data?.submissionPayment?.formId}/submission/${data?.data?.submissionPayment?.submissionId}/pay-again`,
            )
          } else {
            toast({
              title: '',
              description: `${
                userTempLanguage == 'en'
                  ? 'Payment success'
                  : userTempLanguage == 'si'
                  ? 'ගෙවීම සාර්ථක විය'
                  : 'பணம் செலுத்துவதில் வெற்றி'
              }`,
              duration: 5000,
              isClosable: true,
              status: 'success',
              position: 'top-right',
            })
            setPaymentSuccess(true)
            const resultData = {
              formId: data?.data?.submissionPayment?.formId,
            }
            setSucessResult(resultData)
            // navigate(
            //   `/citizen/${data?.data?.submissionPayment?.formId}/submission/${data?.data?.submissionPayment?.submissionId}`,
            // )
          }
          setIsPaymentModalOpen(false)
        } else {
          toast({
            title: '',
            description: `${
              userTempLanguage == 'en'
                ? 'Something went wrong'
                : userTempLanguage == 'si'
                ? 'මොකක්හරි වැරැද්දක් වෙලා'
                : 'ஏதோ தவறு நடந்துவிட்டது'
            }`,
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
    setIsPaymentModalOpen(true)
  }, [])

  const handleSubmitFeedback = useCallback(
    async (inputs: FeedbackFormInput) => {
      if (sucessResult !== undefined) {
        const submittedFeedback = await submitFormFeedback(
          sucessResult.formId,
          inputs,
        )
        setIsFeedbackSubmitted(true)
        toast({
          description: `${
            userTempLanguage == 'en'
              ? 'Thank you for submitting your feedback!'
              : userTempLanguage == 'si'
              ? 'ඔබේ ප්‍රතිපෝෂණය ඉදිරිපත් කිරීම ගැන ඔබට ස්තුතියි!'
              : 'உங்கள் கருத்தைச் சமர்ப்பித்ததற்கு நன்றி!'
          }`,
          status: 'success',
          isClosable: true,
        })
      } else {
        toast({
          description: `${
            userTempLanguage == 'en'
              ? 'Unable to submit feedback'
              : userTempLanguage == 'si'
              ? 'ප්‍රතිපෝෂණ ඉදිරිපත් කළ නොහැක'
              : 'கருத்தைச் சமர்ப்பிக்க முடியவில்லை'
          }`,
          status: 'danger',
          isClosable: true,
        })
      }
    },
    [sucessResult, toast],
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sucessResult])

  return (
    <IntlProvider locale={locale}>
      <Container w="42.5rem" maxW="100%" p={0}>
        <ProcessingPaymentModal
          text={`${
            userTempLanguage === 'en'
              ? 'Payment Processing... Please do not close this tab'
              : userTempLanguage === 'si'
              ? 'ගෙවීම් සකසමින්... කරුණාකර මෙම ටැබය වසන්න එපා'
              : 'கட்டணச் செயலாக்கம்... இந்த தாவலை மூட வேண்டாம்'
          }`}
          isOpen={isPaymentModalOpen}
          setModalIsOpen={setIsPaymentModalOpen}
        />
        {paymentSuccess && sucessResult ? (
          <>
            <Flex flexDir="column" align="center">
              <ThankYouSvgr maxW="100%" />
              <Stack
                spacing={{ base: '1.5rem', md: '3rem' }}
                py={{ base: '1.5rem', md: '3rem' }}
                px={{ base: '1.5rem', md: '4rem' }}
                bg="white"
                w="100%"
                divider={<StackDivider />}
              >
                <Button
                  as="a"
                  onClick={() => {
                    navigate(`/${sucessResult.formId}`, {
                      state: { tempLanguage: userTempLanguage },
                    })
                  }}
                  variant="solid"
                >
                  {userTempLanguage == 'en'
                    ? 'Submit another form'
                    : userTempLanguage == 'si'
                    ? 'තවත් පෝරමයක් ඉදිරිපත් කරන්න'
                    : 'மற்றொரு படிவத்தை சமர்ப்பிக்கவும்'}
                </Button>
                <Button as="a" href={`${CITIZEN_ROOT_ROUTE}`} variant="solid">
                  {userTempLanguage == 'en'
                    ? 'Return to dashboard'
                    : userTempLanguage == 'si'
                    ? 'උපකරණ පුවරුව වෙත ආපසු යන්න'
                    : 'டாஷ்போர்டுக்குத் திரும்பு'}
                </Button>
                {isFeedbackSubmitted ? null : (
                  <FeedbackBlock onSubmit={handleSubmitFeedback} />
                )}
              </Stack>
            </Flex>
          </>
        ) : null}
      </Container>
    </IntlProvider>
  )
}
