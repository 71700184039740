import { KeyboardEventHandler, useCallback, useMemo, useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { BiX } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/hooks'
import {
  Flex,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useFormControlProps,
} from '@chakra-ui/react'
import { ComponentWithAs, forwardRef } from '@chakra-ui/system'
import { format } from 'date-fns'
import moment from 'moment-timezone'

import { BxCalendar } from '~assets/icons'
import IconButton from '~components/IconButton'

import Input, { InputProps } from '../Input'

import { DatePicker, DatePickerProps } from './DatePicker'

export interface DateInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'colorScheme'>,
    Pick<DatePickerProps, 'isDateUnavailable' | 'colorScheme'> {
  name: string
  value?: string
  excludeFuture?: boolean
  onChange?: (val: string) => void
}

type DateInputWithSubcomponents = ComponentWithAs<'input', DateInputProps> & {
  DatePicker: typeof DatePicker
}

export const DateInput = forwardRef<DateInputProps, 'input'>(
  (
    {
      onChange,
      value = '',
      isDateUnavailable,
      excludeFuture = false,
      isDisabled: isDisabledProp,
      isReadOnly: isReadOnlyProp,
      isRequired: isRequiredProp,
      isInvalid: isInvalidProp,
      colorScheme,
      ...props
    },
    ref,
  ) => {
    const fcProps = useFormControlProps({
      isInvalid: isInvalidProp,
      isDisabled: isDisabledProp,
      isReadOnly: isReadOnlyProp,
      isRequired: isRequiredProp,
    })

    const { onClose } = useDisclosure()
    const initialFocusRef = useRef<HTMLInputElement>(null)
    const currentDate = useMemo(() => new Date(), [])

    const handleDatepickerSelection = useCallback(
      (onClose) => (d: Date) => {
        onChange?.(format(d, 'yyyy-MM-dd'))
        onClose()
      },
      [onChange, onClose],
    )

    /**
     * Extra function to act as a middleman between existing isDateUnavailable function and optional future-date exclusion function
     */
    const checkIsDateUnavailable = useCallback(
      (date: Date) => {
        if (!date) return false

        const isDateFuture = excludeFuture ? date > currentDate : false
        const definedDateUnavailable =
          isDateUnavailable !== undefined ? isDateUnavailable(date) : false

        // Returns true in all cases isDateUnavailable is true, as well as all cases where excludeDate is set true AND isDateFuture is true
        return !(!excludeFuture || !isDateFuture) || definedDateUnavailable
      },
      [currentDate, excludeFuture, isDateUnavailable],
    )

    const datePickerDate = useMemo(() => {
      const dateFromValue = new Date(value)
      return isNaN(dateFromValue.getTime()) ? undefined : dateFromValue
    }, [value])

    const calendarButtonAria = useMemo(() => {
      let ariaLabel = 'Choose date. '
      if (value.length === 1) {
        ariaLabel += `Selected date is ${new Date(value[0]).toDateString()}.`
      }
      return ariaLabel
    }, [value])

    /**
     * Disable spacebar from opening native calendar
     */
    const handlePreventOpenNativeCalendar: KeyboardEventHandler<HTMLInputElement> =
      useCallback((e) => {
        if (e.key === ' ') {
          e.preventDefault()
        }
      }, [])

    return (
      <Flex>
        <Popover
          placement="bottom-start"
          // onClose={onClose}
          initialFocusRef={initialFocusRef}
          isLazy
        >
          {({ isOpen, onClose }) => (
            <>
              <PopoverAnchor>
                <InputGroup>
                  <Input
                    zIndex={1}
                    type="text"
                    onKeyDown={handlePreventOpenNativeCalendar}
                    sx={{
                      borderRadius: '4px 0 0 4px',
                      // Chrome displays a default calendar icon, which we want to hide
                      // so all browsers display our icon consistently.
                      '::-webkit-calendar-picker-indicator': {
                        display: 'none',
                      },
                      cursor: 'auto',
                    }}
                    onChange={(e) => onChange?.(e.target.value)}
                    ref={ref}
                    value={value ? moment(value).format('DD/MMM/YYYY') : ''}
                    {...props}
                    {...fcProps}
                    placeholder={'DD/MMM/YYYY'}
                    readOnly
                  />
                  {value !== '' && value ? (
                    <InputRightElement>
                      <IconButton
                        colorScheme={colorScheme}
                        aria-label="Reset"
                        icon={<BiX />}
                        variant="clear"
                        borderRadius={0}
                        onClick={() => onChange?.('')}
                      />
                    </InputRightElement>
                  ) : null}
                </InputGroup>
              </PopoverAnchor>
              <PopoverTrigger>
                <IconButton
                  colorScheme={colorScheme}
                  aria-label={calendarButtonAria}
                  icon={<BxCalendar />}
                  variant="inputAttached"
                  borderRadius={0}
                  isActive={isOpen}
                  isDisabled={fcProps.isDisabled || fcProps.isReadOnly}
                />
              </PopoverTrigger>
              <PopoverContent
                borderRadius="4px"
                w="unset"
                maxW="100vw"
                bg="white"
              >
                <FocusLock returnFocus>
                  <PopoverHeader p={0}>
                    <Flex
                      h="3.5rem"
                      mx={{ base: '1rem', md: '1.5rem' }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text textStyle="subhead-2" color="secondary.500">
                        Select a date
                      </Text>
                      <PopoverCloseButton
                        position="static"
                        variant="clear"
                        colorScheme="secondary"
                      />
                    </Flex>
                  </PopoverHeader>
                  <PopoverBody p={0}>
                    <DateInput.DatePicker
                      colorScheme={colorScheme}
                      date={datePickerDate}
                      isDateUnavailable={checkIsDateUnavailable}
                      onSelectDate={handleDatepickerSelection(onClose)}
                      ref={initialFocusRef}
                    />
                  </PopoverBody>
                </FocusLock>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>
    )
  },
) as DateInputWithSubcomponents

DateInput.DatePicker = DatePicker
