import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiMessageSquareX, BiUpload } from 'react-icons/bi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel as ChakraFormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { times } from 'lodash'
import { isJsxOpeningElement } from 'typescript'

import { MAX_USERNAME_LENGTH } from '~shared/constants'
import { BasicField } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Badge from '~components/Badge'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import { ModalCloseButton } from '~components/Modal'
import Textarea from '~components/Textarea'
import { TableField, TableFieldSchema } from '~templates/Field'

import {
  arrayToString,
  convertToSriLankaNumber,
  getMappedErrorMessage,
  NO_SPECIAL_CHARACTER_REGEX,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
  SL_PHONE_NUMBER_REGEX,
  stringToArray,
} from '~pages/Agency/utils'

import Input from '../../../../components/Input/index'

import { AdminUserRequestBody, DropdownData } from './types/adminUser.types'
import { useTitleUserAdmin } from './queries'

export const CreateAdminUserPage = (): JSX.Element | null => {
  const { data } = useTitleUserAdmin()
  const titlesDropdownData: DropdownData[] = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      titlesDropdownData.push({
        value: `${value?._id}/${value?.gender}/${value?.title}`,
        label: value?.title,
      })
    })
  }

  mapDataToDropDown()

  const [profilePicture, setProfilePicture] = useState<string>('')
  // const [showCountryOfPassport, setShowCountryOfPassport] =
  //   useState<boolean>(false)
  const { agencyId } = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const createdAgencyName = location?.state?.agencyName
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const toast = useToast()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [clickedBtn, setClickedBtn] = useState<string>('')

  const mainFormValues = useForm({
    defaultValues: {
      userCategory: 'Government Agency',
      governmentAgency: createdAgencyName ? createdAgencyName : '-',
      userRole: 'Agency Administrator',
      userName: '',
      title: '',
      gender: '',
      firstName: '',
      lastName: '',
      nameInitials: '',
      identificationType: 'N',
      identificationNumber: '',
      designation: '',
      phoneNumber: '',
      email: '',
      countryOfPassport: 'SRI_LANKA',
      profPicture: '',
    },
  })

  const userCategory = mainFormValues.getValues('userCategory')
  const governmentAgency = mainFormValues.getValues('governmentAgency')
  const userRole = mainFormValues.getValues('userRole')

  const enteredTitle = mainFormValues.watch('title')

  useEffect(() => {
    const titleIdArray = enteredTitle?.split('/')
    mainFormValues.setValue('gender', titleIdArray[1])
    mainFormValues.clearErrors('gender')
  }, [enteredTitle])

  const onSubmitMain = async (data: any) => {
    submitData(data)
  }

  const submitData = (data: any) => {
    const titleIdArray = enteredTitle?.split('/')

    if (idType !== 'P') {
      delete data['countryOfPassport']
    }

    const contactWithCode: any = arrayToString(
      convertToSriLankaNumber(stringToArray(data.phoneNumber)),
    )

    //connect API
    const adminUserParams: AdminUserRequestBody = {
      username: data.userName,
      title: titleIdArray[2],
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      nameInitials: data.nameInitials,
      identificationType: data.identificationType,
      nicNo: '',
      passportNo: '',
      passportCountry: '',
      drivingLicenseNo: '',
      contact: contactWithCode,
      email: data.email,
      iamId: '8b9b9cb0-642e-4088-b8d6-eb66d3297456', //ASK
      userCategory: {
        code: 'AGENCY',
        name: 'Agency User',
      },
      agency: agencyId ? agencyId : '', //Agency id created from prev screen.
      memberNo: '', //ASK
      designation: data.designation,
      userRole: {
        code: 'DF_AGENCY_ADMIN',
        name: 'Agency Administrator', //ASK
      },
      status: 'Active',
      needContactVerification: false, // ASK
      needEmailVerification: false, // ASK
    }

    if (data.identificationType === 'P') {
      adminUserParams['passportNo'] = data.identificationNumber
      adminUserParams['passportCountry'] = data.countryOfPassport
      delete adminUserParams['nicNo']
      delete adminUserParams['drivingLicenseNo']
    } else if (data.identificationType === 'N') {
      adminUserParams['nicNo'] = data.identificationNumber
      delete adminUserParams['passportNo']
      delete adminUserParams['passportCountry']
      delete adminUserParams['drivingLicenseNo']
    } else if (data.identificationType === 'D') {
      adminUserParams['drivingLicenseNo'] = data.identificationNumber
      delete adminUserParams['nicNo']
      delete adminUserParams['passportNo']
      delete adminUserParams['passportCountry']
    }

    const requestBody = {
      user: adminUserParams,
    }

    ApiService.post(`/user`, requestBody)
      .then((data: any) => {
        if (data?.status === 200) {
          toast({
            title: '',
            description: 'Agency Admin User Created Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate('/agency')
          window.location.reload()
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          title: '',
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    mainFormValues.clearErrors('identificationNumber')
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(8)
      setIdNumberRegex(PASSPORT_REGEX)
      mainFormValues.setValue('countryOfPassport', 'SRI_LANKA')
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      setIdNumberRegex(SL_NIC_REGEX)
      mainFormValues.setValue('countryOfPassport', '')
    } else if (identificationType === 'D') {
      setIdType('D')
      setMaxLength(8)
      setMinLength(7)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
      mainFormValues.setValue('countryOfPassport', '')
    } else {
      setIdType('')
      setMaxLength(30)
      setMinLength(0)
      // mainFormValues.setValue('identificationNo', '')
    }
    mainFormValues.setValue('identificationNumber', '')
  }, [identificationType])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Create Admin User
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <Grid
            h="170px"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(5, 1fr)"
            gap={1}
          >
            <GridItem colSpan={1}>
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                User Category
              </dd>
            </GridItem>
            <GridItem colSpan={4}>
              <dt style={{ flex: '50%', padding: '10px' }}>{userCategory}</dt>
            </GridItem>

            <GridItem colSpan={1}>
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Government Agency
              </dd>
            </GridItem>
            <GridItem colSpan={4}>
              <dt style={{ flex: '50%', padding: '10px' }}>
                {governmentAgency}
              </dt>
            </GridItem>

            <GridItem colSpan={1}>
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                User Role
              </dd>
            </GridItem>
            <GridItem colSpan={4}>
              <dt style={{ flex: '50%', padding: '10px' }}>{userRole}</dt>
            </GridItem>
          </Grid>
          <Box bg={'gray.100'} p={10} mt={5}>
            <Box height="40px">
              <Heading as="h5" size="md">
                Personal Details
              </Heading>
            </Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.userName ? true : false
                    }
                  >
                    <FormLabel htmlFor="userName" mb="8px">
                      Username*
                    </FormLabel>
                    <Input
                      placeholder={'Username'}
                      id="userName"
                      maxLength={MAX_USERNAME_LENGTH}
                      {...mainFormValues.register('userName', {
                        required: 'Username is required',
                        maxLength: { value: 20, message: 'Max length is 20' },
                        pattern: {
                          value: NO_SPECIAL_CHARACTER_REGEX,
                          message: 'Invalid Username',
                        },
                      })}
                    />
                    {mainFormValues.formState.errors.userName && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.userName.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.title ? true : false
                    }
                  >
                    <ChakraFormLabel htmlFor="title" mb="8px">
                      Title*
                    </ChakraFormLabel>
                    <Controller
                      name="title"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect {...field} items={titlesDropdownData} />
                      )}
                      rules={{ required: 'Title is required' }}
                    />
                    {mainFormValues.formState.errors.title && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.title.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.gender ? true : false
                    }
                  >
                    <ChakraFormLabel htmlFor="gender" mb="8px">
                      Gender
                    </ChakraFormLabel>
                    <Controller
                      name="gender"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'M',
                              label: 'Male',
                            },
                            {
                              value: 'F',
                              label: 'Female',
                            },
                          ]}
                        />
                      )}
                    />
                    {mainFormValues.formState.errors.gender && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.gender.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.firstName
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="firstName" mb="8px">
                      First Name*
                    </FormLabel>
                    <Controller
                      name="firstName"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'First Name'}
                          maxLength={100}
                        />
                      )}
                      rules={{
                        required: 'First Name is required',
                        maxLength: { value: 100, message: 'Max length is 100' },
                      }}
                    />
                    {mainFormValues.formState.errors.firstName && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.firstName.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.lastName ? true : false
                    }
                  >
                    <FormLabel htmlFor="lastName" mb="8px">
                      Last Name*
                    </FormLabel>
                    <Controller
                      name="lastName"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'Last Name'}
                          maxLength={100}
                        />
                      )}
                      rules={{
                        required: 'Last Name is required',
                        maxLength: { value: 100, message: 'Max length is 100' },
                      }}
                    />
                    {mainFormValues.formState.errors.lastName && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.lastName.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.nameInitials
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="nameInitials" mb="8px">
                      Name with Initials
                    </FormLabel>
                    <Input
                      placeholder={'Name with Initials'}
                      id="nameInitials"
                      maxLength={100}
                      {...mainFormValues.register('nameInitials', {
                        maxLength: { value: 100, message: 'Max length is 100' },
                      })}
                    />
                    {mainFormValues.formState.errors.nameInitials && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.nameInitials.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.identificationType
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="identificationType" mb="8px">
                      Identification Type*
                    </ChakraFormLabel>
                    <Controller
                      name="identificationType"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'N',
                              label: 'NIC',
                            },
                            {
                              value: 'P',
                              label: 'Passport',
                            },
                            {
                              value: 'D',
                              label: 'Driving License',
                            },
                          ]}
                        />
                      )}
                      rules={{ required: 'Identification Type is required' }}
                    />
                    {mainFormValues.formState.errors.identificationType && (
                      <FormErrorMessage>
                        {
                          mainFormValues.formState.errors.identificationType
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.identificationNumber
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="nicNo" mb="8px">
                      {idType === 'N'
                        ? 'NIC Number*'
                        : idType === 'D'
                        ? 'Driving License Number*'
                        : idType === 'P'
                        ? 'Passport Number*'
                        : 'Identification Number*'}
                    </FormLabel>
                    <Controller
                      name="identificationNumber"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={
                            idType === 'N'
                              ? 'NIC Number'
                              : idType === 'D'
                              ? 'Driving License Number'
                              : idType === 'P'
                              ? 'Passport Number'
                              : 'Identification Number'
                          }
                          id="identificationNumber"
                        />
                      )}
                      rules={{
                        required: 'Identification Number is required',
                        maxLength: {
                          value: maxLength,
                          message: `Max length is ${maxLength}`,
                        },
                        minLength: {
                          value: minLength,
                          message: `Min length is ${minLength}`,
                        },
                        pattern: {
                          value: idNumberRegex,
                          message: 'Invalid Identification Number',
                        },
                      }}
                    />
                    {mainFormValues.formState.errors.identificationNumber && (
                      <FormErrorMessage>
                        {
                          mainFormValues.formState.errors.identificationNumber
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                {idType === 'P' && (
                  <Box height="70px">
                    <FormControl
                      isInvalid={
                        mainFormValues?.formState?.errors?.countryOfPassport
                          ? true
                          : false
                      }
                    >
                      <ChakraFormLabel htmlFor="countryOfPassport" mb="8px">
                        Country of Passport*
                      </ChakraFormLabel>
                      <Controller
                        name="countryOfPassport"
                        control={mainFormValues.control}
                        render={({ field }) => (
                          <SingleSelect
                            {...field}
                            items={[
                              {
                                value: 'SRI_LANKA',
                                label: 'Sri Lanka',
                              },
                            ]}
                          />
                        )}
                        rules={{ required: 'Country of Passport is required' }}
                      />
                      {mainFormValues.formState.errors.countryOfPassport && (
                        <FormErrorMessage>
                          {
                            mainFormValues.formState.errors.countryOfPassport
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                )}
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.designation
                        ? true
                        : false
                    }
                  >
                    <FormLabel htmlFor="designation" mb="8px">
                      Designation
                    </FormLabel>
                    <Input
                      placeholder={'Designation'}
                      id="designation"
                      maxLength={100}
                      {...mainFormValues.register('designation', {
                        maxLength: { value: 100, message: 'Max length is 100' },
                      })}
                    />
                    {mainFormValues.formState.errors.designation && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.designation.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              </SimpleGrid>
            </SimpleGrid>
          </Box>

          <Box bg={'gray.200'} p={5} mt={10} pb={20}>
            <Box height="70px">
              <Heading as="h5" size="md">
                Contact Details
              </Heading>
            </Box>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.phoneNumber
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="phoneNumber" mb="8px">
                    Phone Number*
                  </FormLabel>
                  <InputGroup>
                    <Controller
                      name="phoneNumber"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={'Phone Number'}
                          type="number"
                        />
                      )}
                      rules={{
                        required: 'Phone Number is required',
                        maxLength: { value: 10, message: 'Max length is 10' },
                        pattern: {
                          value: SL_PHONE_NUMBER_REGEX,
                          message: 'Invalid Phone number',
                        },
                      }}
                    />
                  </InputGroup>
                  {mainFormValues.formState.errors.phoneNumber && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.phoneNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.email ? true : false
                  }
                >
                  <FormLabel htmlFor="email" mb="8px">
                    Email Address*
                  </FormLabel>
                  <Controller
                    name="email"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={'Email Address'}
                        maxLength={100}
                      />
                    )}
                    rules={{
                      required: 'Email Address is required',
                      maxLength: { value: 100, message: 'Max length is 100' },
                    }}
                  />
                  {mainFormValues.formState.errors.email && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.email.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </SimpleGrid>
          </Box>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
            <Center>
              <ButtonGroup variant="outline" spacing="2" padding={10}>
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    setClickedBtn('Back')
                  }}
                >
                  Back
                </Button>
                <Button type="submit">Save</Button>
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    setClickedBtn('Cancel')
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Center>
          </SimpleGrid>
        </form>
      </Box>
      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
            setClickedBtn('')
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              {clickedBtn === 'Back'
                ? 'Agency was already created. Are you sure you want to continue without creating an agency admin user?'
                : 'Are you sure you want to cancel? An agency is already created'}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (clickedBtn === 'Back') {
                      setClickedBtn('')
                      setIsOpen(false)
                      navigate('/agency/create')
                      window.scrollTo(0, 0)
                    } else if (clickedBtn === 'Cancel') {
                      setClickedBtn('')
                      setIsOpen(false)
                      window.location.href = '/agency/'
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setIsOpen(false)
                    setClickedBtn('')
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
