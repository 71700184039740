import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { times } from 'lodash'

import { BasicField } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import Input from '../../../components/Input/index'
import { fromObjectToQueryString } from '../utils'

export const ManageNotificationPage = (): JSX.Element | null => {
  type notificationTable = {
    rowNumber: number
    notificationType: string
    _id: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [notificationTableData, setNotificationTableData] = useState<
    notificationTable[]
  >([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const mapDataToTable = (array: any[]) => {
    const currentAgencyData: notificationTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        notificationType: value?.notificationType?.name,
        _id: value?._id,
      }
      currentAgencyData.push(obj)
    })
    const order = [
      'Submitted',
      'Accepted',
      'Rejected',
      'Completed',
      'Payment Success',
      'Payment Failed',
    ]
    currentAgencyData.sort((a, b) => {
      return (
        order.indexOf(a.notificationType) - order.indexOf(b.notificationType)
      )
    })

    const updatedData = currentAgencyData.map((value, index) => {
      const updatedObj = {
        ...value,
        rowNumber: index + 1,
      }
      return updatedObj
    })

    setNotificationTableData(updatedData)
  }

  const callApi = (query = '') => {
    ApiService.get(`agency-notifications`)
    setIsLoading(true)

    ApiService.get(
      `agency-notifications?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          console.log('data12312', data?.data.agencyNotifications)
          setIsLoading(false)
          if (Array.isArray(data?.data.agencyNotifications)) {
            mapDataToTable(data?.data.agencyNotifications)
          }
          setTotalCount(data?.data?.meta?.totalItems)
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  // useEffect(() => {
  //   callApi()
  // }, [])
  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.status === 'ALL' || data?.status === '') {
      delete data['status']
    }
    if (data?.agencyId === '') {
      delete data['agencyId']
    }
    if (data?.fullName === '') {
      delete data['fullName']
    }

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Manage Notification
          </Heading>
        </Box>

        <Box bg={'white'} p={10} borderRadius="12px" my={5}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
            <Box bg={'gray.200'}></Box>
          </SimpleGrid>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="30px" mb={10}>
            <DataTable
              heading="Notification Types"
              rowValues={notificationTableData ? notificationTableData : []}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 300,
                  minWidth: 100,
                },
                {
                  Header: 'Notification Type',
                  accessor: 'notificationType',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Action',
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1" padding={2}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/notification/update/${props?.row?.original?._id}`,
                          )
                        }
                      >
                        Update
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {notificationTableData.length === 0 ? (
              <Center mt={5}>No results found</Center>
            ) : null}
            {/* <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem w="100%" h="10" colSpan={2} pt={2}>
                  Rows per page:
                </GridItem>
                <GridItem w="100%" h="10" colSpan={1}>
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={totalCount} //from BE the total number of record should also be returned.
                onPageChange={setCurrentPage}
              />
            </SimpleGrid> */}
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}
