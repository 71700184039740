import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  Box,
  ButtonGroup,
  Divider,
  FormControl,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { times } from 'lodash'

import { BasicField } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import Input from '~components/Input/index'
import { ModalCloseButton } from '~components/Modal'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import { CollaboratorFlowStates } from '~features/admin-form/common/components/CollaboratorModal/CollaboratorWizardContext'

import { useUsersForDropdown } from './queries'

interface AddCollaboratorsModalProps {
  isOpen: boolean
  onClose: () => void
  formAdmin: any
}

export const AddCollaboratorsModal = ({
  isOpen,
  onClose,
  formAdmin,
}: AddCollaboratorsModalProps): JSX.Element | null => {
  type Collaborator = {
    write: boolean
    _id: string
    email: string
  }
  type DropdownType = {
    value: string
    label: string
  }
  const toast = useToast()
  const modalSize = useBreakpointValue({
    base: 'mobile',
    xs: 'mobile',
    md: 'md',
  })
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [indexToDelete, setIndexToDelete] = useState<number>(0)
  const [submitButtonText, setButtonText] = useState('Add Collaborators')
  const [ownerDisplayName, setOwnerDisplayName] = useState<string>('')

  const { formId } = useParams()
  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user) : {}

  const loggedInUsersAgencyId = userData?._doc.agency

  const { data } = useUsersForDropdown(loggedInUsersAgencyId)

  const userDropdownData: DropdownType[] = []

  useEffect(() => {
    setOwnerDisplayName(formAdmin?.email)
  }, [formAdmin?.email])

  const mapDataToDropDown = () => {
    data?.map((value) => {
      userDropdownData.push({
        value: value?.email,
        label: value?.email,
      })
    })
  }

  mapDataToDropDown()

  const mainFormValues = useForm({
    defaultValues: {
      email: '',
      role: '',
    },
  })

  const roleDropdownData = [
    {
      value: 'editor',
      label: 'Editor',
    },
    {
      value: 'readOnly',
      label: 'Read Only',
    },
  ]
  if (formAdmin?._id === userData?._doc?._id) {
    roleDropdownData.push({
      value: 'owner',
      label: 'Owner',
    })
  }

  const onSubmitMain = (data: any) => {
    setIsLoading(true)

    let isUserCollaborator = false

    if (formAdmin?.email === data?.email) {
      toast({
        description: 'User already a collaborator',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      isUserCollaborator = true
    }

    if (isUserCollaborator) {
      setIsLoading(false)
      return
    }

    collaboratorList?.map((collaborator) => {
      if (collaborator?.email === data?.email) {
        toast({
          description: 'User already a collaborator',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
        isUserCollaborator = true
      }
    })

    if (isUserCollaborator) {
      setIsLoading(false)
      return
    }

    if (data.email && data.role === 'owner') {
      const newOwner = {
        email: data.email,
      }
      const finalEmail = data.email

      ApiService.post(
        `/admin/forms/${formId}/collaborators/transfer-owner`,
        newOwner,
      )
        .then((data: any) => {
          if (data.status === 200) {
            setIsLoading(false)
            setOwnerDisplayName(finalEmail)
            getCollaborators()
            toast({
              title: '',
              description: 'Form ownership transfered',
              duration: 5000,
              isClosable: true,
              status: 'success',
              position: 'top-right',
            })
          } else {
            toast({
              title: '',
              description: 'Something went wrong',
              duration: 5000,
              isClosable: true,
              status: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch((error) => {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        })
    } else {
      const params = {
        _id: data._id,
        email: data.email,
        write: data.role === 'editor' ? true : false,
      }

      collaboratorList.push(params)

      const requestBody = collaboratorList

      ApiService.put(`/admin/forms/${formId}/collaborators`, requestBody)
        .then((data: any) => {
          if (data.status === 200) {
            setIsLoading(false)
            getCollaborators()
            mainFormValues.reset({})
            toast({
              title: '',
              description: 'Collaborator added',
              duration: 5000,
              isClosable: true,
              status: 'success',
              position: 'top-right',
            })
          } else {
            toast({
              title: '',
              description: 'Something went wrong',
              duration: 5000,
              isClosable: true,
              status: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch((error) => {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        })
    }
  }

  const onChangeRoleDropdown = (role: string) => {
    if (role === 'owner') {
      setButtonText('Transfer Ownership')
    }
  }

  const onChangeCollaboratorRole = (index: number, role: string) => {
    const params = collaboratorList

    params[index].write = role !== 'readOnly'

    const requestBody = params

    ApiService.put(`/admin/forms/${formId}/collaborators`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          setIsLoading(false)
          getCollaborators()
          toast({
            title: '',
            description: 'Collaborator Updated',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const onClickRemoveCollaborator = (index: number) => {
    setIndexToDelete(index)
    setIsConfirmOpen(true)
  }

  const onRemoveCollaborator = (index: number) => {
    const params = collaboratorList

    params.splice(index, 1)

    const requestBody = params

    ApiService.put(`/admin/forms/${formId}/collaborators`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          setIsLoading(false)
          getCollaborators()
          toast({
            title: '',
            description: 'Collaborator Deleted',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  async function getCollaborators() {
    ApiService.get(`/admin/forms/${formId}/collaborators`)
      .then((data: any) => {
        if (data?.status === 200) {
          setCollaboratorList(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getCollaborators()
  }, [])

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        // Prevent motion box content from escaping modal
        overflowX="hidden"
      >
        <ModalCloseButton />
        {isOpen && (
          <Box bg={'gray.50'} p={10}>
            <Box height="40px">
              <Heading as="h4" size="md">
                Manage Collaborators
              </Heading>
            </Box>
            <Box style={{ paddingBottom: '1.5rem' }}>
              <Text fontSize="md" fontWeight="500">
                Add Collaborators or transfer form ownership
              </Text>
              <Text fontSize="sm">
                Share your secret key with collaborators who need to access
                submissions data.
              </Text>
            </Box>
            <form
              onSubmit={mainFormValues.handleSubmit(onSubmitMain)}
              style={{ paddingBottom: '1.5rem' }}
            >
              <HStack spacing="10px">
                <Box height="70px" w="80%">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.email ? true : false
                    }
                  >
                    <Controller
                      name="email"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={userDropdownData ? userDropdownData : []}
                          isClearable={false}
                        />
                      )}
                      rules={{ required: 'Email is required' }}
                    />
                    {mainFormValues.formState.errors.email && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.email.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
                <Box height="70px" maxWidth={{ sm: '100%', md: '200px' }}>
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.role ? true : false
                    }
                  >
                    <Controller
                      name="role"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={roleDropdownData}
                          isClearable={false}
                          // onChange={(role) => {
                          //   onChangeRoleDropdown(role)
                          // }}
                        />
                      )}
                      rules={{ required: 'Role is required' }}
                    />
                    {mainFormValues.formState.errors.role && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.role.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              </HStack>
              <ButtonGroup spacing="6">
                <Button type="submit">{submitButtonText}</Button>
              </ButtonGroup>
            </form>
            <Divider></Divider>
            <Box py={4}>
              <HStack spacing="10px">
                <Text w="80%">{ownerDisplayName}</Text>
                <Tag variant="subtle" colorScheme="orange">
                  <TagLabel>Owner</TagLabel>
                </Tag>
              </HStack>
            </Box>
            <Divider></Divider>
            <VStack my={4} spacing={2} align="stretch">
              {collaboratorList?.length === 0 ? (
                <Text>No collaborators added</Text>
              ) : null}
              {collaboratorList?.map((collaborator, index) => {
                return (
                  <HStack spacing="10px" key={index}>
                    <Text w="80%">{collaborator.email}</Text>
                    <SingleSelect
                      name={`collaborator${index}-role`}
                      value={collaborator.write ? 'editor' : 'readOnly'}
                      isClearable={false}
                      onChange={(role) => {
                        onChangeCollaboratorRole(index, role)
                      }}
                      items={[
                        {
                          value: 'editor',
                          label: 'Editor',
                        },
                        {
                          value: 'readOnly',
                          label: 'Read Only',
                        },
                      ]}
                    />
                    <IconButton
                      variant="clear"
                      colorScheme="danger"
                      aria-label="remove collaborator"
                      icon={<BiTrash />}
                      onClick={(idx) => {
                        onClickRemoveCollaborator(index)
                      }}
                    />
                  </HStack>
                )
              })}
            </VStack>
          </Box>
        )}
        {isConfirmOpen ? (
          <Modal
            isOpen={isConfirmOpen}
            onClose={() => {
              setIsConfirmOpen(false)
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>Confirm</ModalHeader>
              <ModalBody whiteSpace="pre-line">
                Are you sure you want to delete the collaborator?
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      setIsConfirmOpen(false)
                      onRemoveCollaborator(indexToDelete)
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="clear"
                    colorScheme="secondary"
                    onClick={() => {
                      setIsConfirmOpen(false)
                    }}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ) : null}
      </ModalContent>
    </Modal>
  )
}
