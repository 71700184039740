import { Merge, Opaque } from 'type-fest'
import { z } from 'zod'
import { AgencyDto } from './agency'

export type AgencyStatisticId = Opaque<string, 'AgencyStatisticId'>
export const AgencyStatisticId =
  z.string() as unknown as z.Schema<AgencyStatisticId>

export enum StatisticType {
  AA = 'Age analysis',
}

export enum StatisticAgeAnalysisRange {
  '1_14' = '1_14',
  '15_28' = '15_28',
  '29_PLUS' = '29_PLUS',
}

export type AgencyStatisticStatisticValue = {
  range: StatisticAgeAnalysisRange
  value: string
}

export type AgencyStatisticAgency = {
  agencyId: AgencyDto['_id']
  agencyName: string
}

export type AgencyStatisticStatisticType = {
  code: StatisticType
  name: string
}

/**
 * Typing for individual agency statistic
 */
export type AgencyStatisticBase = {
  // _id: AgencyStatisticId
  agencyId: AgencyDto['_id']
  agency: AgencyStatisticAgency
  statisticType: AgencyStatisticStatisticType
  statisticValue: {} //AgencyStatisticStatisticValue[]
  created?: Date
  lastModified?: Date
}

export type AgencyStatisticDto = Merge<
  AgencyStatisticBase,
  { created?: Date; lastModified?: Date }
>
