import { Opaque } from 'type-fest'
import { z } from 'zod'
import { AgencyId } from './agency'
import { FormId } from './form'
import { SubmissionId } from './submission'

export type SubmissionPaymentId = Opaque<string, 'SubmissionPaymentId'>
export const SubmissionPaymentId =
  z.string() as unknown as z.Schema<SubmissionPaymentId>

export enum PaymentStatusTypes {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export enum PaymentStatusCode {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export type PaymentType = {
  paymentTypeId?: string
  paymentTypeName: string
}

export type SubmissionPaymentBase = {
  // _id: SubmissionPaymentId
  submissionId: SubmissionId
  formId: FormId
  agencyId: AgencyId
  paymentStatus: PaymentStatusTypes
  paymentType?: PaymentType
  paymentAmount: number
  paymentTxnId: string
  paymentTxnRequest?: string
  paymentTxnResponse?: string
  paymentTxnResponseId?: string
  paymentTxnResponseBankId?: string
  paymentMerchantId?: string
  paymentServiceId?: string
  archived: boolean
  archivedDate?: Date
  toBeArchivedAt?: Date
  lastRestoredAt?: Date
  deleted: boolean
  deletedDate?: Date
  created?: Date
  lastModified?: Date
}

export type SubmissionPaymentDto = {
  _id: SubmissionPaymentId
  submissionId: SubmissionId
  formId: FormId
  agencyId: AgencyId
  paymentStatus: PaymentStatusTypes
  paymentType?: PaymentType
  paymentAmount: number
  paymentTxnId: string
  paymentTxnRequest?: string
  paymentTxnResponse?: string
  paymentMerchantId?: string
  paymentServiceId?: string
  archived: boolean
  archivedDate?: Date
  toBeArchivedAt: Date
  lastRestoredAt?: Date
  deleted: boolean
  deletedDate?: Date
  created?: Date
  lastModified?: Date
}

export const SubmissionPaymentRequestDto = z.object({
  submissionId: SubmissionId,
  paymentType: z.enum(['lgps']),
  paymentAmount: z.number(),
})
export type SubmissionPaymentRequestDto = z.infer<
  typeof SubmissionPaymentRequestDto
>

export type ProcessPaymentRequestDto = {
  method: string
  payment_merchant_id?: string
  payment_service_id?: string
  password: any | null
  return_url: string
  payment_id: string
  request_reference: string
  reference: string
  total: number
  response: any
}
export type CreatePaymentRequestDto = {
  method?: string
  payment_merchant_id?: string
  payment_service_id?: string
  password: string | null
  return_url: string
  payment_id: string
  request_reference: string
  reference: string
  total: number
}

export type ProcessPaymentResponseDto = {
  responseStr: string
  response: string
  status: string
  submissionId: string
  submissionPaymentId: string
  agencyName: string
  bankId: string
  responseId: string
  paymentMerchantId: any
}

export type DecodePaymentResponseDto = {
  method?: string
  payment_merchant_id?: string
  payment_service_id?: string
  password: string | null
  return_url: string
  payment_id: string
  request_reference: string
  reference: string
  total: number
  response: {
    lgpsPaymentResponse: string
    conf: any
    PAID: any
    BID: any
    PRN: any
  }
}

export type CreatePaymentResponseDto = {
  requestStr: string
  url: string
  cipheredRequest: string
}

export type CreateSubmissionPaymentDto = {
  paymentSubmissionDetails: SubmissionPaymentDto
  requestUrl: CreatePaymentResponseDto
}
