import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { isEmpty, isString, times, trimStart } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import { BasicField } from '~shared/types'

import DefaultAgencyImage from '~assets/images/default-agency.png'
import { CITIZEN_DASHBOARD_ROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
//import { DateInput } from '~components/DatePicker/DateInput'
import DateInput from '~components/DatePicker'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import { LanguageFilter } from '../../../../atom'
import Input from '../../../components/Input/index'

import { arrayToString } from './commonFunctions'

// import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const ViewGovernmentAgency = (): JSX.Element | null => {
  type contactTable = {
    rowNumber: number
    contactPerson: string
    designation: string
    phoneNumber: string
    email: any
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()
  const { agencyId } = useParams()
  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const [contactTableData, setContactTableData] = useState<contactTable[]>([])
  const [result, setResult] = useState({
    agencyId: '',
    description: '',
    logo: '',
    fullName: '',
    shortName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
    },
    district: '',
    province: '',
    website: '',
    mobile: [],
    email: [],
  })

  async function getViewAgency() {
    ApiService.get(`/public/agency/${agencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data?.data)
          setIsLoading(true)
          if (Array.isArray(data?.data?.contactPerson)) {
            mapFormDataToTable(data?.data?.contactPerson)
          }
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const mapFormDataToTable = (array: any[]) => {
    const currentFormsData: contactTable[] = []
    array.map((value, index) => {
      const obj: any = {
        rowNumber: index + 1,
        contactPerson: value?.personName,
        designation: value?.designation,
        phoneNumber: arrayToString(value.mobile),
        email: value?.email,
      }
      currentFormsData.push(obj)
    })
    setContactTableData(currentFormsData)
  }

  useEffect(() => {
    getViewAgency()
  }, [])

  useEffect(() => {
    const response = getViewAgency()
  }, [])

  const mainFormValues = useForm({
    defaultValues: {
      description: '-',
      logo: '-',
      fullName: '-',
      shortName: '-',
      addressLine1: '-',
      addressLine2: '-',
      city: '-',
      district: '-',
      province: '-',
      website: '-',
      mobile: '-',
      email: '-',
    },
  })

  useEffect(() => {
    const phoneNumberString = arrayToString(result?.mobile)
    const emailString = arrayToString(result?.email)
    mainFormValues.setValue(
      'description',
      result?.description ? result?.description : '-',
    )
    mainFormValues.setValue('logo', result?.logo ? result?.logo : '-')
    mainFormValues.setValue(
      'fullName',
      result?.fullName ? result?.fullName : '-',
    )
    mainFormValues.setValue(
      'shortName',
      result?.shortName ? result?.shortName : '-',
    )
    mainFormValues.setValue(
      'addressLine1',
      result?.address?.line1 ? result?.address?.line1 : '-',
    )
    mainFormValues.setValue(
      'addressLine2',
      result?.address?.line2 ? result?.address?.line2 : '-',
    )
    mainFormValues.setValue(
      'city',
      result?.address?.city ? result?.address?.city : '-',
    )
    mainFormValues.setValue(
      'district',
      result?.district ? result?.district : '-',
    )
    mainFormValues.setValue(
      'province',
      result?.province ? result?.province : '-',
    )
    mainFormValues.setValue('website', result?.website ? result?.website : '-')
    mainFormValues.setValue('mobile', phoneNumberString)
    mainFormValues.setValue('email', emailString)
  }, [result])

  // const agencyId = mainFormValues.getValues('agencyId')
  const description = mainFormValues.getValues('description')
  const logo = mainFormValues.getValues('logo')
  const fullName = mainFormValues.getValues('fullName')
  const shortName = mainFormValues.getValues('shortName')
  const addressLine1 = mainFormValues.getValues('addressLine1')
  const addressLine2 = mainFormValues.getValues('addressLine2')
  const district = mainFormValues.getValues('district')
  const city = mainFormValues.getValues('city')
  const province = mainFormValues.getValues('province')
  const mobile = mainFormValues.getValues('mobile')
  const website = mainFormValues.getValues('website')
  const email = mainFormValues.getValues('email')
  const locale: any = useRecoilValue(LanguageFilter)

  const messages: any = {
    en: {
      viewGovermentAgency: 'View Government Agency',
      govermentAgencyName: 'Government Agency Name:',
      shortName: 'Short Name:',
      Description: 'Description:',
      addressLine1: 'Address Line 1:',
      addressLine2: 'Address Line 2:',
      DivisionalSecretariat: 'Divisional Secretariat:',
      District: 'District:',
      Province: 'Province:',
      Website: 'Website:',
      mobile: 'Phone Number:',
      emailAddress: 'Email Address:',
      back: 'Back',
      noLogoUploaded: 'No Logo Uploaded',
      contactDetails: 'Contact Person Details',
    },
    si: {
      viewGovermentAgency: 'රජයේ නියෝජිතායතනය බලන්න',
      govermentAgencyName: 'රජයේ නියෝජිතායතනයේ නම:',
      shortName: 'කෙටි නම:',
      Description: 'විස්තර:',
      addressLine1: 'ලිපින පේළි 1:',
      addressLine2: 'ලිපින පේළි 2:',
      DivisionalSecretariat: 'ප්‍රාදේශීය ලේකම් කාර්යාලය:',
      District: 'දිසා:',
      Province: 'පළාත:',
      Website: 'වෙබ් අඩවිය:',
      mobile: 'දුරකථන අංකය:',
      emailAddress: 'විද්‍යුත් තැපැල් ලිපිනය:',
      back: 'ආපසු',
      noLogoUploaded: 'ලාංඡනය උඩුගත කර නැත',
      contactDetails: 'සම්බන්ධතා පුද්ගල විස්තර',
    },
    ta: {
      viewGovermentAgency: 'அரசாங்க முகமையைப் பார்க்கவும்',
      govermentAgencyName: 'அரசு நிறுவனத்தின் பெயர்:',
      shortName: 'குறுகிய பெயர்:',
      Description: 'விளக்கம்:',
      addressLine1: 'முகவரி வரி 1:',
      addressLine2: 'முகவரி வரி 2:',
      DivisionalSecretariat: 'பிரதேச செயலகம்:',
      District: 'மாவட்டம்:',
      Province: 'மாகாணம்:',
      Website: 'இணையதளம்:',
      mobile: 'தொலைபேசி எண்:',
      emailAddress: 'மின்னஞ்சல் முகவரி:',
      back: 'மீண்டும்',
      noLogoUploaded: 'லோகோ பதிவேற்றப்படவில்லை',
      contactDetails: 'தொடர்பு நபர் விவரங்கள்',
    },
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <Box>
            <Heading as="h4" size="md" padding="10px">
              <FormattedMessage
                id="viewGovermentAgency"
                values={{ locale }}
              ></FormattedMessage>
            </Heading>
            <SimpleGrid columns={{ xs: 0, md: 2 }} spacing="60px">
              <dl>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="govermentAgencyName"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </Box>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {fullName}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="shortName"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {shortName}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="mobile"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </Box>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt style={{ flex: '50%', padding: '10px' }}>
                      {mobile === ''
                        ? '-'
                        : mobile.replace(/^(\+\d{1,2})/, '0')}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="emailAddress"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {email}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Website"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {website}
                    </dt>
                  </Box>
                </SimpleGrid>
                <Box>
                  <br></br>
                </Box>
                <Box
                  w={['60%', '100%', '140%']}
                  my={5}
                  bg={'gray.200'}
                  overflowX="auto"
                >
                  <Heading as="h4" size="md" padding="10px">
                    <FormattedMessage
                      id="contactDetails"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Heading>
                  <SimpleGrid
                    columns={{ xs: 1, md: 1 }}
                    spacing="10px"
                    mx={3}
                    mt={-10}
                    bg={'gray.200'}
                  >
                    <DataTable
                      heading=""
                      rowValues={contactTableData ? contactTableData : []}
                      cols={[
                        {
                          Header:
                            locale === 'en'
                              ? 'No.'
                              : locale === 'si'
                              ? 'අංකය'
                              : 'எண்',
                          accessor: 'rowNumber',
                          disableSortBy: true,
                          maxWidth: 100,
                          minWidth: 80,
                          width: 80,
                        },
                        {
                          Header:
                            locale === 'en'
                              ? 'Contact Person'
                              : locale === 'si'
                              ? 'අදාළ පුද්ගලයා'
                              : 'தொடர்பு நபர்',
                          accessor: 'contactPerson',
                          disableSortBy: false,
                          maxWidth: 400,
                          minWidth: 190,
                        },
                        {
                          Header:
                            locale === 'en'
                              ? 'Designation'
                              : locale === 'si'
                              ? 'තනතුරු'
                              : 'பதவி',
                          accessor: 'designation',
                          disableSortBy: true,
                          maxWidth: 300,
                          minWidth: 170,
                        },
                        {
                          Header:
                            locale === 'en'
                              ? 'Phone Number'
                              : locale === 'si'
                              ? 'දුරකථන අංක'
                              : 'தொலைபேசி எண்கள்',
                          accessor: 'phoneNumber',
                          disableSortBy: true,
                          maxWidth: 300,
                          minWidth: 170,
                          Cell: ({ value }: { value: string }) =>
                            `0${value.slice(3)}`,
                        },
                        {
                          Header:
                            locale === 'en'
                              ? 'Email'
                              : locale === 'si'
                              ? 'ඊමේල්'
                              : 'மின்னஞ்சல்கள்',
                          accessor: 'email',
                          disableSortBy: true,
                          maxWidth: 300,
                          minWidth: 170,
                        },
                      ]}
                    />
                  </SimpleGrid>
                </Box>
                <Box>
                  <br></br>
                </Box>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="addressLine1"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {addressLine1}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="addressLine2"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {addressLine2}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="DivisionalSecretariat"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {city}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 0, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="District"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {district}
                    </dt>
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Province"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {province}
                    </dt>
                  </Box>
                </SimpleGrid>
                <br></br>
                <SimpleGrid columns={{ xs: 1, md: 2 }} spacing="0px">
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '60%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="Description"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                  </div>
                  <Box w={['60%', '100%', '100%']} overflowX="auto">
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {description}
                    </dt>
                  </Box>
                </SimpleGrid>
              </dl>

              <Box h="400px">
                <Box bg={'white'} h="200px" w="200px">
                  {logo !== '-' ? (
                    <>
                      <Image
                        h="200px"
                        w="200px"
                        src={logo}
                        fallbackSrc={DefaultAgencyImage}
                        objectFit="cover"
                        alt="agency.logo"
                      />
                    </>
                  ) : (
                    <Center>
                      <FormattedMessage
                        id="noLogoUploaded"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Center>
                  )}
                </Box>
              </Box>
            </SimpleGrid>
            <Center>
              <ButtonGroup variant="outline" spacing="1" padding={10}>
                <Button as={Link} to={CITIZEN_DASHBOARD_ROUTE}>
                  <FormattedMessage
                    id="back"
                    values={{ locale }}
                  ></FormattedMessage>
                </Button>
              </ButtonGroup>
            </Center>
          </Box>
        </IntlProvider>
      </Box>
    </>
  )
}
