import { useQuery, UseQueryResult } from 'react-query'

import {
  AgencyDto,
  ConversationDto,
  ConversationWithOptionsDto,
} from '~shared/types'

import { ApiService } from '~services/ApiService'

export const getAgencies = async (): Promise<AgencyDto[]> =>
  ApiService.get(
    '/agency/dashboard/dropdown?status=Active&agencyCategory.code=AGENCY',
  ).then(({ data }) => {
    // Sort data alphabetically by fullName
    return data.sort((a: AgencyDto, b: AgencyDto) =>
      a.fullName.localeCompare(b.fullName),
    )
  })

const dashBoardAgencyKeys = {
  base: ['dashboardAgency'],
}

export const useAgencyDashboard = (): UseQueryResult<AgencyDto[], unknown> => {
  return useQuery<AgencyDto[]>(dashBoardAgencyKeys.base, () => getAgencies(), {
    // 5 minutes.
    staleTime: 300000,
  })
}

// Get notifications for the user
export const getNotificationDashboard = async (): Promise<any> =>
  ApiService.get(`/notifications/push-notifications?notificationType.code=CQ`)
    .then(({ data }) => data)
    .catch((error) => console.error(error))

const notificationDashboardListKeys = {
  base: ['dashboardConversationList'],
}

export const useNotificationDashboard = (): UseQueryResult<any, unknown> => {
  return useQuery<any>(
    notificationDashboardListKeys.base,
    () => getNotificationDashboard(),
    {
      // 1 minute.
      staleTime: 60000,
    },
  )
}
