import { SuccessMessageDto } from '~shared/types'
import {
  ForgotUsernameDto,
  ResetPasswordOtpSendDto,
  ResetPasswordOtpVerifyDto,
  SendUserContactOtpDto,
  UserDto,
  ValidateContactOtpSendDto,
  ValidateContactOtpVerifyDto,
  VerifyUserContactOtpDto,
} from '~shared/types/user'

import { ApiService } from './ApiService'

const USER_ENDPOINT = '/user'

/**
 * Fetches the user from the server using the current session cookie.
 *
 * @returns the logged in user if session is valid, will throw 401 error if not.
 */
export const fetchUser = async (): Promise<UserDto> => {
  return ApiService.get<UserDto>(USER_ENDPOINT).then(({ data }) => data)
}

export const generateUserContactOtp = (
  params: SendUserContactOtpDto,
): Promise<void> => {
  return ApiService.post(`${USER_ENDPOINT}/contact/otp/generate`, params)
}

export const verifyUserContactOtp = (
  params: VerifyUserContactOtpDto,
): Promise<UserDto> => {
  return ApiService.post<UserDto>(
    `${USER_ENDPOINT}/contact/otp/verify`,
    params,
  ).then(({ data }) => data)
}

/**
 * Verifies that a user is existing for the provided contact and category.
 * Send the username to the contact.
 * @param params.emailOrPhone the provided email or phone no
 * @returns when username sent to the contact successfully
 * @throws error on non 2xx response
 */
export const forgotUsernameNotify = (
  params: ForgotUsernameDto,
): Promise<SuccessMessageDto> => {
  return ApiService.post(`${USER_ENDPOINT}/username/forgot`, params).then(
    ({ data }) => data,
  )
}

/**
 * Verifies that a forgot password user is existing for the provided username and user-category.
 * Send the OTP to all available contacts.
 * @param params.username the provided email or phone no
 * @returns when OTP sent successfully to at least one contact
 * @throws error on non 2xx response
 */
export const forgotPasswordOtpSend = (
  params: ResetPasswordOtpSendDto,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/password/forgot/otp/generate`,
    params,
  ).then(({ data }) => data)
}

/**
 * Update the forgot password with WSO2 IAS if the OTP is valid
 * @param params.username the provided email or phone no
 * @param params.otp the TOP value
 * @param params.password the new password
 * @returns when password is updated
 * @throws error on non 2xx response
 */
export const forgotPasswordOtpVerify = (
  params: ResetPasswordOtpVerifyDto,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/password/forgot/otp/verify`,
    params,
  ).then(({ data }) => data)
}

/**
 * Verifies that a first time user is existing for the provided username and user-category.
 * Send the OTP to all available contacts.
 * @param params.username the provided email or phone no
 * @returns when OTP sent successfully to at least one contact
 * @throws error on non 2xx response
 */
export const createPasswordOtpSend = (
  params: ResetPasswordOtpSendDto,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/password/create/otp/generate`,
    params,
  ).then(({ data }) => data)
}

/**
 * Update the new password for the first time user with WSO2 IAS if the OTP is valid
 * @param params.username the provided email or phone no
 * @param params.otp the TOP value
 * @param params.password the new password
 * @returns when password is updated
 * @throws error on non 2xx response
 */
export const createPasswordOtpVerify = (
  params: ResetPasswordOtpVerifyDto,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/password/create/otp/verify`,
    params,
  ).then(({ data }) => data)
}

/**
 * Validate the contact to be used with a user. Contact should be unique and available to be used.
 * @returns when contact is allowed to be used
 * @throws error on non 2xx response
 */
export const validateUserContact = (
  contact: string,
  contactType: string,
): Promise<SuccessMessageDto> => {
  return ApiService.get(
    `${USER_ENDPOINT}/${contactType}/validate/${contact}`,
  ).then(({ data }) => data)
}

/**
 * Send OTP to validate the user contact.
 * @returns when OTP is sent to the user contact
 * @throws error on non 2xx response
 */
export const validateUserContactSendOtp = (
  params: ValidateContactOtpSendDto,
  contactType: string,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/${contactType}/validate/otp/generate`,
    params,
  ).then(({ data }) => data)
}

/**
 * Send OTP to validate the user contact.
 * @returns when OTP is sent to the user contact
 * @throws error on non 2xx response
 */
export const validateUserContactVerifyOtp = (
  params: ValidateContactOtpVerifyDto,
  contactType: string,
): Promise<SuccessMessageDto> => {
  return ApiService.post(
    `${USER_ENDPOINT}/${contactType}/validate/otp/verify`,
    params,
  ).then(({ data }) => data)
}

/**
 * Update the user
 * @returns when the update is success
 * @throws error on non 2xx response
 */
export const updateUser = (
  params: any,
  userId: string,
): Promise<SuccessMessageDto> => {
  return ApiService.put(`${USER_ENDPOINT}/${userId}`, params).then(
    ({ data }) => data,
  )
}
