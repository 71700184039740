import React from 'react'
import { useSortBy, useTable } from 'react-table'
// import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  forwardRef,
  Heading,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { BxsChevronDown } from '~assets/icons/BxsChevronDown'
import { BxsChevronUp } from '~assets/icons/BxsChevronUp'

export interface TableProps {
  heading: string
  rowValues: any
  cols: any
  currentPage?: any
  pageSize?: any
}

export const DataTable = forwardRef<TableProps, 'div'>((props, ref) => {
  const data = React.useMemo(() => props.rowValues, [props.rowValues])

  const columns = React.useMemo(() => props.cols, [props.cols])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
    )
  console.log(data)
  return (
    <>
      <Box height="30px">
        <Heading as="h4" size="md">
          {props.heading}
        </Heading>
      </Box>
      <Table variant="solid" colorScheme="primary" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>
                  <Flex {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {column.isSorted ? (
                      <Icon
                        fontSize="1rem"
                        as={
                          column.isSorted && column.isSortedDesc
                            ? BxsChevronDown
                            : BxsChevronUp
                        }
                      />
                    ) : null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                      },
                    })}
                  >
                    {(cell.column.id == 'rowNumber' ||
                      cell.column.id == 'rowNumberMore') &&
                    props.pageSize
                      ? props.pageSize * (props.currentPage - 1) + i + 1
                      : cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
})
