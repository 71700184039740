import { useCallback, useMemo, useState } from 'react'
import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { LogicDto, LogicType } from '~shared/types/form'

import Button from '~components/Button'
import { ModalCloseButton } from '~components/Modal'

import { BASICFIELD_TO_DRAWER_META } from '../../constants'
import {
  setToInactiveSelector,
  useAdminLogicStore,
} from '../../logic/adminLogicStore'
import { useLogicMutations } from '../../logic/mutations'
import { useBuilderAndDesignContext } from '../BuilderAndDesignContext'
import { useDeleteFormField } from '../mutations/useDeleteFormField'
import {
  BuildFieldState,
  stateDataSelector,
  useBuilderAndDesignStore,
} from '../useBuilderAndDesignStore'

interface DeleteFieldModalProps {
  logicSet: LogicDto[] | undefined
}

export const DeleteFieldModal = ({
  logicSet,
}: DeleteFieldModalProps): JSX.Element => {
  const stateData = useBuilderAndDesignStore(stateDataSelector)
  const setToInactive = useAdminLogicStore(setToInactiveSelector)
  const {
    deleteFieldModalDisclosure: { isOpen, onClose },
  } = useBuilderAndDesignContext()
  const { deleteLogicMutation } = useLogicMutations()

  const [connectedLogicId, setConnectedLogicId] = useState<string>('')
  const [fieldsToDelete, setFieldsToDelete] = useState<string[]>([])

  const doesFieldHaveLogic = useMemo(() => {
    if (!logicSet) return false

    if (stateData.state === BuildFieldState.EditingField) {
      const fieldId = stateData.field._id
      const fieldsForDeletion: string[] = []

      return logicSet.some((log) => {
        if (
          (log.logicType === LogicType.ShowFields &&
            (log.conditions.some((e) => e.field === fieldId) ||
              log.show.includes(fieldId))) ||
          (log.logicType === LogicType.PreventSubmit &&
            log.conditions.some((e) => e.field === fieldId))
        ) {
          if (log.logicType === LogicType.ShowFields)
            fieldsForDeletion.concat(log.show)

          fieldsForDeletion.concat(log.conditions.map((el) => el.field))

          setConnectedLogicId(log._id)
          console.log('detected fields for deletion are ', fieldsForDeletion)
          setFieldsToDelete(fieldsForDeletion)
          return true
        } else return false
      })
    } else return false
  }, [stateData, logicSet])

  const fieldTypeLabel = useMemo(() => {
    if (stateData.state === BuildFieldState.EditingField) {
      return BASICFIELD_TO_DRAWER_META[stateData.field.fieldType].label
    }
    return null
  }, [stateData])

  const { deleteFieldMutation } = useDeleteFormField()

  const handleDeleteConfirmation = useCallback(() => {
    if (stateData.state === BuildFieldState.EditingField) {
      if (doesFieldHaveLogic && connectedLogicId) {
        deleteLogicMutation.mutate(connectedLogicId)

        console.log('new fields to delete are ', fieldsToDelete)
        deleteFieldMutation.mutate(stateData.field._id, {
          onSuccess: onClose,
        })
        // for (const id in fieldsToDelete) {
        //   deleteFieldMutation.mutate(id)
        // }
      } else {
        deleteFieldMutation.mutate(stateData.field._id, {
          onSuccess: onClose,
        })
      }
    }
    onClose()
  }, [
    connectedLogicId,
    deleteFieldMutation,
    deleteLogicMutation,
    doesFieldHaveLogic,
    fieldsToDelete,
    stateData,
    onClose,
  ])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete {fieldTypeLabel} field</ModalHeader>
        <ModalBody>
          {doesFieldHaveLogic
            ? 'This field is currently involved in a set conditional logic within this form. Deleting this field will also remove its associated conditional logic. Are you sure you want to proceed?'
            : 'This field will be deleted permanently. Are you sure you want to proceed?'}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="clear" colorScheme="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="danger"
              onClick={handleDeleteConfirmation}
              isLoading={deleteFieldMutation.isLoading}
            >
              Yes, delete field
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
