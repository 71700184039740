import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  CheckboxGroup,
  FormControl,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { get, isEmpty, truncate } from 'lodash'

import { FormSettings, SignatureType } from '~shared/types'

import { useToast } from '~hooks/useToast'
import Checkbox from '~components/Checkbox'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Toggle from '~components/Toggle'
import { Switch } from '~components/Toggle/Switch'

import { useMutateFormSettings } from '../mutations'
import { useAdminFormSettings } from '../queries'

type FormSignatureType = {
  settings: FormSettings
  isLoadingSettings: boolean
}

export const FormSignatureToggle = (): JSX.Element => {
  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  return (
    <>
      {settings ? (
        <FormSignatureToggleComponent
          settings={settings}
          isLoadingSettings={isLoadingSettings}
        />
      ) : null}
    </>
  )
}

const FormSignatureToggleComponent = ({
  settings,
  isLoadingSettings,
}: FormSignatureType): JSX.Element => {
  // const initialFormSignature = settings.formSignature
  const [initialFormSignatureTypes, setInitialFormSignatureTypes] = useState<
    SignatureType[]
  >(settings?.signatureTypes ? settings.signatureTypes : [])

  const toast = useToast()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      formSignature: initialFormSignatureTypes,
    },
  })

  const [signatureDisabledFullName, setSignatureDisabledFullName] =
    useState<boolean>(false)
  const [signatureDisabledImage, setSignatureDisabledImage] =
    useState<boolean>(false)
  const [signatureDisabledTouchPad, setSignatureDisabledTouchPad] =
    useState<boolean>(false)
  const fullNameRef = useRef<HTMLInputElement | null>(null)
  const imageRef = useRef<HTMLInputElement | null>(null)
  const touchpadRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (settings?.signatureTypes) {
      setInitialFormSignatureTypes(settings.signatureTypes)
    }
    if (settings?.signatureTypes?.length === 1) {
      if (fullNameRef.current?.checked) {
        setSignatureDisabledFullName(true)
      } else if (imageRef.current?.checked) {
        setSignatureDisabledImage(true)
      } else if (touchpadRef.current?.checked) {
        setSignatureDisabledTouchPad(true)
      }
    } else {
      setSignatureDisabledFullName(false)
      setSignatureDisabledImage(false)
      setSignatureDisabledTouchPad(false)
    }
  }, [settings])

  const signatureRequired = useMemo(
    () => settings && settings?.signatureRequired,
    [settings],
  )

  const { mutateFormSignature, mutateFormSignatureTypes } =
    useMutateFormSettings()

  const handleToggleSignature = useCallback(() => {
    if (!settings || isLoadingSettings || mutateFormSignature.isLoading) return
    const nextSignatureRequired = !settings.signatureRequired
    return mutateFormSignature.mutate(nextSignatureRequired)
  }, [isLoadingSettings, mutateFormSignature, settings])

  const handleOnClickFormSignature = useCallback(
    (e) => {
      const methodsArray: SignatureType[] = []
      if (e.target.value === SignatureType.FullName) {
        if (e.target.checked) {
          methodsArray.push(SignatureType.FullName)
        }

        if (touchpadRef.current?.checked) {
          methodsArray.push(SignatureType.Touchpad)
        }
        if (imageRef.current?.checked) {
          methodsArray.push(SignatureType.Image)
        }
      } else if (e.target.value === SignatureType.Touchpad) {
        if (e.target.checked) {
          methodsArray.push(SignatureType.Touchpad)
        }
        if (fullNameRef.current?.checked) {
          methodsArray.push(SignatureType.FullName)
        }
        if (imageRef.current?.checked) {
          methodsArray.push(SignatureType.Image)
        }
      } else if (e.target.value === SignatureType.Image) {
        if (e.target.checked) {
          methodsArray.push(SignatureType.Image)
        }
        if (fullNameRef.current?.checked) {
          methodsArray.push(SignatureType.FullName)
        }
        if (touchpadRef.current?.checked) {
          methodsArray.push(SignatureType.Touchpad)
        }
      }
      if (methodsArray.length === 1) {
        if (fullNameRef.current?.checked) {
          setSignatureDisabledFullName(true)
        } else if (imageRef.current?.checked) {
          setSignatureDisabledImage(true)
        } else if (touchpadRef.current?.checked) {
          setSignatureDisabledTouchPad(true)
        }
      } else {
        setSignatureDisabledFullName(false)
        setSignatureDisabledImage(false)
        setSignatureDisabledTouchPad(false)
      }
      if (methodsArray.length === 0) {
        toast({
          title: '',
          description: 'Please select a Signature Type',
          duration: 5000,
          isClosable: true,
          status: 'danger',
        })
        return
      }

      return mutateFormSignatureTypes.mutate(methodsArray, {
        onError: () => reset(),
      })
    },
    [mutateFormSignatureTypes, reset, toast],
  )

  return (
    <Skeleton isLoaded={!isLoadingSettings && !!settings}>
      <Box my={10}>
        {/* <Toggle
          isLoading={mutateFormSignature.isLoading}
          isChecked={signatureRequired}
          label="Enable Signature"
          onChange={handleToggleSignature}
        /> */}
        <Stack>
          <Grid templateColumns="repeat(5, 1fr)" gap={2}>
            <GridItem colSpan={4}>
              <Text textStyle="subhead-1">Enable Signature</Text>
            </GridItem>
            <GridItem colSpan={1} h="10">
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                <GridItem />
                <GridItem />
                <GridItem>
                  <Checkbox
                    isChecked={signatureRequired}
                    value={'signatureRequired'}
                    onChange={handleToggleSignature}
                  />
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Stack>
        {signatureRequired && (
          <Stack spacing="2rem" my={4}>
            <FormControl isInvalid={!isEmpty(errors?.formSignature)}>
              <CheckboxGroup
                defaultValue={
                  initialFormSignatureTypes
                    ? Array.isArray(initialFormSignatureTypes)
                      ? initialFormSignatureTypes
                      : [initialFormSignatureTypes]
                    : []
                }
              >
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  <Controller
                    name="formSignature"
                    control={control}
                    rules={{ required: 'Form Signature Method is required' }}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value={SignatureType.FullName}
                        onChange={handleOnClickFormSignature}
                        isDisabled={signatureDisabledFullName}
                        ref={fullNameRef}
                      >
                        {' '}
                        Type Full Name for the Signature
                      </Checkbox>
                    )}
                  />
                  <Controller
                    name="formSignature"
                    control={control}
                    rules={{ required: 'Form Signature Method is required' }}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value={SignatureType.Touchpad}
                        onChange={handleOnClickFormSignature}
                        isDisabled={signatureDisabledTouchPad}
                        ref={touchpadRef}
                      >
                        {' '}
                        Sign by using a touch screen or mouse
                      </Checkbox>
                    )}
                  />
                  <Controller
                    name="formSignature"
                    control={control}
                    rules={{ required: 'Form Signature Method is required' }}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value={SignatureType.Image}
                        onChange={handleOnClickFormSignature}
                        isDisabled={signatureDisabledImage}
                        ref={imageRef}
                      >
                        {' '}
                        Insert an image with Signature
                      </Checkbox>
                    )}
                  />
                </Stack>
              </CheckboxGroup>
              <FormErrorMessage>
                {(errors.formSignature as any)?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        )}
      </Box>
    </Skeleton>
  )
}
