import { Merge, Opaque } from 'type-fest'
import { z } from 'zod'
import { AgencyDto } from './agency'
import { UserDto } from './user'

export type AgencyReasonId = Opaque<string, 'AgencyReasonId'>
export const AgencyReasonId = z.string() as unknown as z.Schema<AgencyReasonId>

export enum ReasonType {
  FR = 'Form Reject',
}

export type AgencyReasonAgency = {
  agencyId: AgencyDto['_id']
  agencyName: string
}

export type AgencyReasonReasonType = {
  code: ReasonType
  name: string
}

export type AgencyReasonLastModifiedBy = {
  userCategoryCode: string
  userCategoryName: string
  userId: UserDto['_id']
  userName: string
}

/**
 * Typing for individual agency reason
 */
export type AgencyReasonBase = {
  // _id: AgencyReasonId
  agencyId: AgencyDto['_id']
  agency: AgencyReasonAgency
  reasonType: AgencyReasonReasonType
  reason: string
  created?: Date
  lastModified?: Date
  lastModifiedBy: AgencyReasonLastModifiedBy
}

export type AgencyReasonDto = Merge<
  AgencyReasonBase,
  { created?: Date; lastModified?: Date }
>
