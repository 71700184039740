import { Opaque } from 'type-fest'
import { z } from 'zod'
import { UserDto } from './user'

export type TitleId = Opaque<string, 'TitleId'>
export const TitleId = z.string() as unknown as z.Schema<TitleId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
export const TitleBase = z.object({
  title: z.string(),
  gender: z.string(),
  createdAt: z.date(),
  lastModifiedAt: z.date(),
  createdBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(), // change to DTO shape
  }),
  lastModifiedBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(),
  }),
})
export type TitleBase = z.infer<typeof TitleBase>

export const TitleDto = TitleBase.extend({
  _id: TitleId,
  createdBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
  lastModifiedBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
})
export type TitleDto = z.infer<typeof TitleDto>
