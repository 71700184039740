import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { isEmpty, isString, times, trimStart } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import { BasicField } from '~shared/types'

import DefaultAgencyImage from '~assets/images/default-agency.png'
import {
  CITIZEN_DASHBOARD_ROUTE,
  UPDATE_AGENCY_DETAILS,
} from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import Input from '~components/Input/index'
import { DataTable } from '~components/Table'

import { LanguageFilter } from '../../../atom'

import { arrayToString } from './commonFunctions'

// import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const ViewGovernmentAgency = (): JSX.Element | null => {
  type ContactPerson = {
    personName: string
    designation: string
    mobile: string[]
    email: string[]
    _id: string
  }

  type ContactPersonTable = {
    personName: string
    designation: string
    mobile: string
    email: string
  }

  const formatDate = (value: string): string => {
    if (value === '-') {
      return 'N/A'
    }
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>()
  const [contactDetail, setContactDetail] = useState<ContactPersonTable[]>([])
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    const user = localStorage.getItem('user')
    const userD = user ? JSON.parse(user) : {}
    setUserData(userD)
  }, [])

  const [loggedUserAgencyId, setLoggedUserAgencyId] = useState<string>('')

  useEffect(() => {
    if (userData) {
      setLoggedUserAgencyId(userData?._doc?.agency)
    }
  }, [userData])

  const mapContactDetails = () => {
    const array: ContactPersonTable[] = []

    if (result?.contactPerson?.length !== 0) {
      result?.contactPerson?.map((value: ContactPerson) => {
        array.push({
          personName: value.personName,
          designation: value.designation === '' ? '-' : value.designation,
          mobile: arrayToString(value.mobile),
          email: value.email?.length === 0 ? '-' : arrayToString(value.email),
        })
      })
    }

    setContactDetail(array)
  }

  const [result, setResult] = useState({
    agencyId: '',
    description: '',
    logo: '',
    fullName: '',
    shortName: '',
    address: { line1: '', line2: '', city: '' },
    city: '',
    district: '',
    province: '',
    website: '',
    contactPerson: [
      {
        mobile: [],
        email: [],
        _id: '',
        personName: '',
        designation: '',
      },
    ],
    mobile: [],
    email: [],
    note: '',
    created: '',
    lastModified: '',
    createdBy: {
      userName: '',
    },
    lastModifiedBy: {
      userName: '',
    },
    status: '',
    smsServiceEnabled: false,
    emailServiceEnabled: false,
  })

  async function getViewAgency() {
    ApiService.get(`/agency/${loggedUserAgencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data?.data)
          setIsLoading(true)
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  useEffect(() => {
    if (loggedUserAgencyId) {
      getViewAgency()
    }
  }, [loggedUserAgencyId])

  const mainFormValues = useForm({
    defaultValues: {
      description: '-',
      logo: '-',
      fullName: '',
      shortName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      district: '',
      province: '',
      website: '',
      mobile: '',
      email: '',
      note: '',
      createdAt: '-',
      createdBy: '-',
      updatedAt: '-',
      updatedBy: '-',
      status: 'Inactive',
      smsServiceEnable: false,
      emailServiceEnable: false,
    },
  })

  useEffect(() => {
    const phoneNumberString = arrayToString(result?.mobile)
    const emailString = arrayToString(result?.email)

    mapContactDetails()

    mainFormValues.setValue(
      'description',
      result?.description ? result?.description : '',
    )
    mainFormValues.setValue('logo', result?.logo ? result?.logo : '')
    mainFormValues.setValue(
      'fullName',
      result?.fullName ? result?.fullName : '',
    )
    mainFormValues.setValue(
      'shortName',
      result?.shortName ? result?.shortName : '',
    )
    mainFormValues.setValue('addressLine1', result?.address?.line1 ?? '')
    mainFormValues.setValue('addressLine2', result?.address?.line2 ?? '')
    mainFormValues.setValue('city', result?.address?.city ?? '')
    mainFormValues.setValue(
      'district',
      result?.district ? result?.district : '',
    )
    mainFormValues.setValue(
      'province',
      result?.province ? result?.province : '',
    )
    mainFormValues.setValue('website', result?.website ? result?.website : '')
    mainFormValues.setValue('mobile', phoneNumberString ?? '')
    mainFormValues.setValue('email', emailString ?? '')
    mainFormValues.setValue('note', result?.note ?? '')
    mainFormValues.setValue(
      'createdAt',
      result?.created ? result?.created : '-',
    )
    mainFormValues.setValue(
      'updatedAt',
      result?.lastModified ? result?.lastModified : '-',
    )
    mainFormValues.setValue(
      'createdBy',
      result?.createdBy ? result?.createdBy?.userName : '-',
    )
    mainFormValues.setValue(
      'updatedBy',
      result?.lastModifiedBy ? result?.lastModifiedBy?.userName : '-',
    )
    mainFormValues.setValue('status', result?.status ? result?.status : '-')
    mainFormValues.setValue(
      'smsServiceEnable',
      result?.smsServiceEnabled ? result?.smsServiceEnabled : false,
    )
    mainFormValues.setValue(
      'emailServiceEnable',
      result?.emailServiceEnabled ? result?.emailServiceEnabled : false,
    )
  }, [result])

  // const agencyId = mainFormValues.getValues('agencyId')
  const description = mainFormValues.getValues('description')
  const logo = mainFormValues.getValues('logo')
  const fullName = mainFormValues.getValues('fullName')
  const shortName = mainFormValues.getValues('shortName')
  const addressLine1 = mainFormValues.getValues('addressLine1')
  const addressLine2 = mainFormValues.getValues('addressLine2')
  const district = mainFormValues.getValues('district')
  const city = mainFormValues.getValues('city')
  const province = mainFormValues.getValues('province')
  const mobile = mainFormValues.getValues('mobile')
  const website = mainFormValues.getValues('website')
  const email = mainFormValues.getValues('email')
  const note = mainFormValues.getValues('note')
  const createdAt = mainFormValues.getValues('createdAt')
  const createdBy = mainFormValues.getValues('createdBy')
  const updatedAt = mainFormValues.getValues('updatedAt')
  const updatedBy = mainFormValues.getValues('updatedBy')
  const status = mainFormValues.getValues('status')
  const smsServiceEnable = mainFormValues.getValues('smsServiceEnable')
  const emailServiceEnable = mainFormValues.getValues('emailServiceEnable')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Flex mb={5}>
          <Box>
            <Box height="40px" margin="10px">
              <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
                View Agency
              </Heading>
            </Box>
          </Box>
          <Spacer />
          <Box>
            <ButtonGroup>
              <Button
                onClick={() => navigate(`${UPDATE_AGENCY_DETAILS}`)}
                isDisabled={userData?._doc?.userRole?.code === 'DF_AGENCY_USER'}
              >
                Update
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
        <Box
          bg={'white'}
          p={10}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <Box height="40px">
            <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
              Agency Details
            </Heading>
          </Box>
          <SimpleGrid columns={2} spacing="40px">
            <Box>
              <dl>
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Agency Name:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {fullName}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Short Name:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {shortName}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Description:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {description}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Address Line 1:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {addressLine1}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Address Line 2:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {addressLine2}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Divisional Secretariat:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {city}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    District:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {district}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Province:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {province}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Website:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {website}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Phone Numbers:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {mobile.replace(/^(\+\d{1,2})/, '0')}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '30%', padding: '10px', fontWeight: 500 }}>
                    Emails:
                  </dd>
                  <dt
                    style={{ flex: '70%', padding: '10px', maxWidth: '100%' }}
                  >
                    {email}
                  </dt>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Enable Services
                  </dd>
                  {/* <CheckboxGroup> */}
                  <Stack spacing={[1, 5]} direction={['column', 'row']}>
                    <Checkbox
                      value="SMS"
                      isDisabled
                      isChecked={smsServiceEnable}
                    >
                      SMS
                    </Checkbox>
                    <Checkbox
                      value="Email"
                      isDisabled
                      isChecked={emailServiceEnable}
                    >
                      Email
                    </Checkbox>
                  </Stack>
                </div>
              </dl>
            </Box>
            <Box h="250px" w="250px">
              {status === 'Active' ? (
                <Center>
                  <Tag
                    size="lg"
                    variant="subtle"
                    colorScheme="cyan"
                    w="100%"
                    p={3}
                    justifyContent="center"
                  >
                    ACTIVE
                  </Tag>
                </Center>
              ) : (
                <Center>
                  <Tag
                    size="lg"
                    variant="subtle"
                    colorScheme="red"
                    w="100%"
                    p={3}
                    justifyContent="center"
                  >
                    INACTIVE
                  </Tag>
                </Center>
              )}
              <Center>
                <Box bg={'white'} h="200px" w="200px">
                  <Image
                    h="200px"
                    w="200px"
                    src={logo}
                    fallbackSrc={DefaultAgencyImage}
                    objectFit="cover"
                    alt="agency.logo"
                  />
                </Box>
              </Center>
            </Box>
          </SimpleGrid>

          <Box bg={'#ECEFF1'} p={5} mt={10} borderRadius="12px">
            <DataTable
              heading="Contact Person Details"
              rowValues={contactDetail ? contactDetail : []}
              cols={[
                {
                  Header: 'No.',
                  accessor: (_row: any, i: number) => i + 1,
                  disableSortBy: true,
                  minWidth: 80,
                  width: 80,
                  maxWidth: 100,
                },
                {
                  Header: 'Contact Person',
                  accessor: 'personName',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 140,
                },
                {
                  Header: 'Designation',
                  accessor: 'designation',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 140,
                },
                {
                  Header: 'Phone Number',
                  accessor: 'mobile',
                  disableSortBy: true,
                  maxWidth: 150,
                  minWidth: 140,
                  Cell: ({ value }: { value: string }) => `0${value.slice(3)}`,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 140,
                },
              ]}
            />
            {contactDetail.length === 0 ? (
              <Center mt={5}>No results found</Center>
            ) : null}
          </Box>

          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px" mt={10}>
            <Grid h="100px" templateColumns="repeat(4, 1fr)" gap={10}>
              <GridItem colSpan={1}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Note:
                </dd>
              </GridItem>
              <GridItem colSpan={3}>
                {' '}
                <dt
                  style={{
                    flex: '50%',
                    padding: '10px',
                    maxWidth: '100%',
                  }}
                >
                  {note}
                </dt>
              </GridItem>
            </Grid>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px"></SimpleGrid>
          </SimpleGrid>

          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Created at:
              </dd>
              <dt style={{ flex: '50%', padding: '10px' }}>
                {formatDate(createdAt)}
              </dt>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Updated at:
              </dd>
              <dt style={{ flex: '50%', padding: '10px' }}>
                {formatDate(updatedAt)}
              </dt>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Created by:
              </dd>
              <dt style={{ flex: '50%', padding: '10px' }}>{createdBy}</dt>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Updated by:
              </dd>
              <dt style={{ flex: '50%', padding: '10px' }}>{updatedBy}</dt>
            </SimpleGrid>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}
