const DR_FORMAT = /^([a-zA-Z0-9]{7,8})$/

/**
 * Validates whether a provided string value adheres to the old format
 * as provided on the Sri Lankan Government's National Identity Card.
 * @param value The value to be validated
 */
export const isDRValid = (value: string): boolean => {
  return !!value?.toUpperCase().match(DR_FORMAT)
}
