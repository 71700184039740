import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { FormColorTheme, FormDto } from '~shared/types/form'

import { CITIZEN_DASHBOARD_ROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'

import { getMappedErrorMessage } from '~pages/Agency/utils'
import { SubmissionData } from '~features/public-form/PublicFormContext'

import { DraftSubmissionId, LanguageFilter } from '../../../../../atom'
import { ProcessingPaymentModal } from '../FormEndPage/components/ProcessingPaymentModal'

export interface PayNowPageProps {
  /** Form title of submission for display */
  submissionData: SubmissionData
  colorTheme: FormColorTheme
  paymentAmount?: number
  paymentRequired?: boolean
  buttonText?: string
  navigateToDashboard?: boolean
}

export const PayNowPage = ({
  colorTheme,
  submissionData,
  paymentAmount,
  paymentRequired,
  buttonText,
  navigateToDashboard,
}: PayNowPageProps): JSX.Element => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false)
  const toast = useToast()
  const navigate = useNavigate()
  const [redirectData, setRedirectData] = useState<any>()
  const formElement = useRef<HTMLFormElement | null>(null)

  const handleClick = useCallback(() => {
    setIsPaymentModalOpen(true)
    const requestBody = {
      submissionPayment: {
        submissionId: submissionData.id,
        paymentType: 'lgps',
        paymentAmount: submissionData.paymentAmount,
      },
    }
    ApiService.post(`/public/payment/request`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          setRedirectData(data?.data)
          setIsPaymentModalOpen(false)
        } else {
          toast({
            title: '',
            description: `${
              locale === 'en'
                ? 'Something went wrong'
                : locale === 'si'
                ? 'මොකක්හරි වැරැද්දක් වෙලා'
                : 'ஏதோ தவறு நடந்துவிட்டது'
            }`,
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
    setIsPaymentModalOpen(true)
  }, [])

  useEffect(() => {
    if (redirectData) {
      handleRedirect()
    }
  }, [redirectData])

  const handleRedirect = () => {
    if (formElement.current !== null) {
      formElement.current.submit()
    } else {
      console.log('NULL')
    }
  }
  const locale: any = useRecoilValue(LanguageFilter)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <IntlProvider locale={locale}>
      <Container w="42.5rem" maxW="100%" p={0}>
        <Flex flexDir="column" align="center">
          <Stack
            spacing={{ base: '1.5rem', md: '3rem' }}
            py={{ base: '1.5rem', md: '3rem' }}
            px={{ base: '1.5rem', md: '4rem' }}
            bg="white"
            w="100%"
            divider={<StackDivider />}
          >
            <Button
              as="a"
              variant="solid"
              colorScheme={`theme-${colorTheme}`}
              onClick={handleClick}
            >
              {buttonText
                ? buttonText
                : `${
                    locale === 'en'
                      ? 'Pay Now'
                      : locale === 'si'
                      ? 'දැන් ගෙවන්න'
                      : 'இப்போது செலுத்த'
                  }`}
            </Button>

            {navigateToDashboard ? (
              <Button
                as="a"
                variant="solid"
                colorScheme={`theme-${colorTheme}`}
                onClick={() => {
                  navigate(`${CITIZEN_DASHBOARD_ROUTE}`)
                }}
              >
                {locale === 'en'
                  ? 'Return to Dashboard'
                  : locale === 'si'
                  ? 'උපකරණ පුවරුව වෙත ආපසු යන්න'
                  : 'டாஷ்போர்டுக்குத் திரும்பு'}
              </Button>
            ) : null}

            {submissionData?.paymentCharges ? (
              <>
                <Heading as="h4" size="md" pt={5}>
                  {locale === 'en'
                    ? 'Payment Details'
                    : locale === 'si'
                    ? 'ගෙවීම් තොරතුරු'
                    : 'கட்டண விவரங்கள்'}
                </Heading>
                <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px" pt={5}>
                  {submissionData?.paymentCharges.map((data, index) => {
                    return (
                      <SimpleGrid columns={{ sm: 1, md: 2 }} key={index}>
                        <Stack>
                          <Text as="span" textStyle="subhead-1">
                            {index + 1}.
                            {locale === 'en'
                              ? 'Payment Charge name :'
                              : locale === 'si'
                              ? 'ගෙවීම් ගාස්තු නම :'
                              : 'கட்டணம் பெயர் :'}
                          </Text>
                          <Text>
                            {`${
                              data.name == 'Form Charge' && locale === 'en'
                                ? 'Form Charge'
                                : data.name == 'Form Charge' && locale === 'si'
                                ? 'පෝරමය ගාස්තුව'
                                : data.name == 'Form Charge' && locale === 'ta'
                                ? 'படிவம் கட்டணம்'
                                : data.name
                            }`}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text as="span" textStyle="subhead-1">
                            {locale === 'en'
                              ? 'Payment Charge amount :'
                              : locale === 'si'
                              ? 'ගෙවීම් ගාස්තු මුදල :'
                              : 'செலுத்தும் தொகை :'}
                          </Text>
                          <Text>{data.amount}</Text>
                        </Stack>
                      </SimpleGrid>
                    )
                  })}
                </SimpleGrid>
              </>
            ) : null}
            <Stack
              spacing={{ base: '0', md: '0.5rem' }}
              direction={{ base: 'column', md: 'row' }}
              pt={10}
            >
              <Text as="span" textStyle="subhead-1">
                {locale === 'en'
                  ? 'Payment Amount :'
                  : locale === 'si'
                  ? 'ගෙවීම් මුදල :'
                  : 'பணம் செலுத்தும் தொகை :'}
              </Text>
              <Text>{submissionData.paymentAmount}</Text>
            </Stack>
          </Stack>
        </Flex>
        <ProcessingPaymentModal
          text={`${
            locale === 'en'
              ? 'Redirecting... Please do not close this tab'
              : locale === 'si'
              ? 'යළි හරවා යවමින්... කරුණාකර මෙම ටැබය වසන්න එපා'
              : 'திசைதிருப்புகிறது... இந்த தாவலை மூட வேண்டாம்'
          }`}
          isOpen={isPaymentModalOpen}
          setModalIsOpen={setIsPaymentModalOpen}
        />
        {redirectData ? (
          <div style={{ display: 'none' }}>
            <form
              ref={formElement}
              action={redirectData?.requestUrl?.url}
              method="get"
            >
              <b>Sending to LGPS </b>
              <br />
              <input
                type="hidden"
                name="clientPaymentRequest"
                value={redirectData?.requestUrl?.cipheredRequest}
              />
              <input type="submit" value="Send" />
            </form>
          </div>
        ) : null}
      </Container>
    </IntlProvider>
  )
}
