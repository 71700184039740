import { useState } from 'react'
import { Navigate, NavigateProps, useLocation } from 'react-router-dom'

import { useAuth } from '~contexts/AuthContext'
import { useUser } from '~contexts/userContext'
import { LOGIN_ROUTE } from '~constants/routes'
import { AdminLayout } from '~templates/AdminLayout'

import NotFoundErrorPage from '~pages/NotFoundError'

interface PrivateElementProps {
  /**
   * Route to redirect to when user is not authenticated. Defaults to
   * `LOGIN_ROUTE` if not provided.
   */
  redirectTo?: NavigateProps['to']
  element: React.ReactElement
  permission: string
}

export const PrivateElement = ({
  element,
  permission,
  redirectTo = LOGIN_ROUTE,
}: PrivateElementProps): React.ReactElement => {
  const location = useLocation()

  const { isAuthenticated } = useAuth()

  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user) : {}
  const userPermission = userData.permissions

  return isAuthenticated ? (
    userPermission?.filter(function (el: any) {
      return el.permissionCode === permission
    }).length > 0 ? (
      <AdminLayout>{element}</AdminLayout>
    ) : userPermission ? (
      <NotFoundErrorPage />
    ) : (
      <></>
    )
  ) : (
    <Navigate replace to={redirectTo} state={{ from: location }} />
  )
}
