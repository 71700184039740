import { useQuery, UseQueryResult } from 'react-query'

import { UserDto } from '~shared/types'

import { ApiService } from '~services/ApiService'

export const getUsers = async (agencyId: string): Promise<UserDto[]> =>
  ApiService.get(`/user/?agency=${agencyId}`).then(({ data }) => data)

const userDropdownKeys = {
  base: ['userDropdown'],
}

export const useUsersForDropdown = (
  agencyId: string,
): UseQueryResult<UserDto[], unknown> => {
  return useQuery<UserDto[]>(userDropdownKeys.base, () => getUsers(agencyId), {
    // 5 minutes.
    staleTime: 300000,
  })
}
