import { useQuery, UseQueryResult } from 'react-query'
import { useParams } from 'react-router-dom'
import { result } from 'lodash'

import {
  ActivityDto,
  AgencyReasonDto,
  ConversationDto,
  SubmissionPaymentDto,
} from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'

import { getDecryptedSubmissionById } from '~features/admin-form/responses/AdminSubmissionsService'
import { adminFormResponsesKeys } from '~features/admin-form/responses/queries'

import { useStorageResponsesContext } from '../../StorageResponsesContext'

// import { getDecryptedSubmissionById } from '../AdminSubmissionsService'
// import { adminFormResponsesKeys } from '../queries'

/**
 * @precondition Must be wrapped in a Router as `useParam` is used.
 */
export const useIndividualSubmission = (passedSecretKey?: string) => {
  const toast = useToast({
    status: 'danger',
  })

  const { formId, submissionId } = useParams()
  if (!formId || !submissionId) {
    throw new Error('No formId or submissionId provided')
  }

  const { secretKey } = useStorageResponsesContext()

  const key = passedSecretKey ? passedSecretKey : secretKey

  return useQuery(
    adminFormResponsesKeys.individual(formId, submissionId),
    () => getDecryptedSubmissionById({ formId, submissionId, secretKey: key }),
    {
      // Will never update once fetched.
      staleTime: Infinity,
      enabled: !!key,
      onError: (e) => {
        toast({
          description: String(e),
        })
      },
    },
  )
}

export const getAgencyRejectedReasons = async (
  agencyId: string,
): Promise<AgencyReasonDto[]> =>
  ApiService.get(`/agency-reasons?reasonType=FR&${agencyId}`).then(
    ({ data }) => data,
  )

const agencyReasonsListKeys = {
  base: ['agencyReasonsList'],
}

export const useAgencyRejectedReasons = (
  agencyId: string,
): UseQueryResult<AgencyReasonDto[], unknown> => {
  return useQuery<AgencyReasonDto[]>(
    agencyReasonsListKeys.base,
    () => getAgencyRejectedReasons(agencyId),
    {
      // 5 minutes.
      staleTime: 300000,
    },
  )
}

export const getSubmissionPayments = async (
  formId: string,
  submissionId: string,
): Promise<SubmissionPaymentDto[]> =>
  ApiService.get(
    `/admin/forms/submissions/payments?submissionId=${submissionId}`,
  ).then(({ data }) => data)

const submissionPaymentsKeys = {
  base: ['submissionPaymentsList'],
}

export const useSubmissionPayments = (): UseQueryResult<
  SubmissionPaymentDto[]
> => {
  const { formId, submissionId } = useParams()
  if (!formId || !submissionId) {
    throw new Error('No formId or submissionId provided')
  }
  return useQuery<SubmissionPaymentDto[]>(
    submissionPaymentsKeys.base,
    () => getSubmissionPayments(formId, submissionId),
    {
      // 5 minutes.
      staleTime: 300000,
    },
  )
}

// Conversations for a submission
export const getSubmissionConversations = async (
  submissionId: string,
): Promise<ConversationDto[]> =>
  ApiService.get(`/conversations/submissions/${submissionId}`)
    .then(({ data }) => data)
    .catch((error) => console.error(error))

const submissionConversationsListKeys = {
  base: ['submissionConversationList'],
}

export const useSubmissionConversations = (
  submissionId: string,
): UseQueryResult<ConversationDto[], unknown> => {
  return useQuery<ConversationDto[]>(
    submissionConversationsListKeys.base,
    () => getSubmissionConversations(submissionId),
    {
      // 1 minute.
      staleTime: 60000,
    },
  )
}

// Get the collaborator details
export const getSubmissionFormCollaborators = async (
  formId: string,
): Promise<ConversationDto[]> =>
  ApiService.get(`/admin/forms/${formId}/collaborators`)
    .then(({ data }) => data)
    .catch((error) => console.error(error))

const submissionFormCollaboratorsListKeys = {
  base: ['formCollaboratorList'],
}

export const useSubmissionFormCollaborators = (
  formId: string,
): UseQueryResult<ConversationDto[], unknown> => {
  return useQuery<ConversationDto[]>(
    submissionFormCollaboratorsListKeys.base,
    () => getSubmissionFormCollaborators(formId),
    {
      // 5 minutes.
      staleTime: 300000,
    },
  )
}

export const useFormAdmin = (formId: string) => {
  const toast = useToast()
  return useQuery(
    'adminForm',
    () =>
      ApiService.get(`/admin/forms/${formId}`)
        .then(({ data }) => data)
        .catch((error) => console.error(error)),
    {
      // Will never update once fetched.
      staleTime: Infinity,
      onError: (e) => {
        toast({
          description: String(e),
        })
      },
    },
  )
}

export const getSubmissionActivities = (
  submissionId: string,
): Promise<ActivityDto[]> =>
  ApiService.get(`/activity/?data.submission._id=$${submissionId}`).then(
    ({ data }) => data,
  )
