import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  ACTIVITY_LOG_PERMISSION,
  ADMIN_ARCHIVED_REPORT_PERMISSION,
  ADMIN_PROGRESS_REPORT_PERMISSION,
  ADMIN_UNPUBLISHED_REPORT_PERMISSION,
  ADMINFORM_ROUTE_PERMISSION,
  AGENCY_ARCHIVED_REPORT_PERMISSION,
  AGENCY_CREATE_PERMISSION,
  AGENCY_DASHBOARD_PERMISSION,
  AGENCY_MANAGE_PERMISSION,
  AGENCY_PROGRESS_REPORT_PERMISSION,
  AGENCY_UNPUBLISHED_REPORT_PERMISSION,
  AGENCY_UPDATE_PERMISSION,
  AGENCY_USER_CREATE_PERMISSION,
  AGENCY_USER_MANAGE_PERMISSION,
  AGENCY_USER_UPDATE_PERMISSION,
  AGENCY_USER_VIEW_PERMISSION,
  AGENCY_VIEW_PERMISSION,
  CITIZEN_DASHBOARD_PERMISSION,
  CITIZEN_VIEW_SUBMISSION_PERMISSION,
  CONVERSATION_MESSAGE_TYPE_PERMISSION,
  CREATE_USER_ADMIN_PERMISSION,
  MANAGE_FORMS_PERMISSION,
  MANAGE_MASTERDATA_PERMISSION,
  MANAGE_REJECT_REASON_PERMISSION,
  MANAGE_TEMPLATES_PERMISSION,
  MANAGE_TITLE_PERMISSION,
  NOTIFICATION_PERMISSION,
  NOTIFICATION_UPDATE_PERMISSION,
  RECONCILIATION_REPORT_PERMISSION,
  ROOT_ROUTE_PERMISSION,
  SETTINGS_MANAGE_PERMISSION,
  UPDATE_AGENCY_PERMISSION,
  UPDATE_USER_PROFILE_PERMISSION,
  USER_CREATE_PERMISSION,
  USER_MANAGE_PERMISSION,
  USER_UPDATE_PERMISSION,
  USER_VIEW_PERMISSION,
  VIEW_AGENCY_PERMISSION,
  VIEW_MASTERDATA_PERMISSION,
  VIEW_SUBMISSION_PERMISSION,
  VIEW_USER_PROFILE_PERMISSION,
} from '~constants/permission'
import {
  ACTIVITY_LOG,
  ADMIN_ARCHIVED_REPORT,
  ADMIN_PROGRESS_REPORT,
  ADMIN_UNPUBLISHED_REPORT,
  ADMINFORM_RESULTS_SUBROUTE,
  ADMINFORM_ROUTE,
  ADMINFORM_SETTINGS_SUBROUTE,
  ADMINFORM_SUBMISSIONS_SUBROUTE,
  AGENCY_ARCHIVED_REPORT,
  AGENCY_CREATE,
  AGENCY_DASHBOARD_ROUTE,
  AGENCY_MANAGE,
  AGENCY_PROGRESS_REPORT,
  AGENCY_RECONCILlIATION_REPORT,
  AGENCY_UNPUBLISHED_REPORT,
  AGENCY_UPDATE,
  AGENCY_USER_CREATE,
  AGENCY_USER_MANAGE,
  AGENCY_USER_UPDATE,
  AGENCY_VIEW,
  AGENCY_VIEW_USER,
  AGENT_MANAGE_FORMS,
  CITIZEN_DASHBOARD_ROUTE,
  CITIZEN_FORGOT_USERNAME_PASSWORD,
  CITIZEN_IAM_GROUP_ROUTE_WSO2,
  CITIZEN_LOGIN_ROUTE,
  CITIZEN_ONBOARDING_SCREEN,
  CITIZEN_PAYMENT_PAY_AGAIN_PAGE,
  CITIZEN_PAYMENT_PROCESSING_PAGE,
  CITIZEN_UPDATE_USER_PROFILE,
  CITIZEN_USER_ONBOARD,
  CITIZEN_VIEW_GOVERMENT_AGENCY,
  CITIZEN_VIEW_USER_PROFILE,
  CONVERSATION_MESSAGE_TYPES,
  CREATE_USER_ADMIN,
  DASHBOARD_ROUTE,
  FORGOT_USERNAME_PASSWORD,
  IAM_GROUP_ROUTE_WSO2,
  ITCA_FORM_MASTER_DATA,
  ITCA_FORM_MASTER_DATA_ADD_UPDATE,
  ITCA_SETTINGS,
  ITCA_TITLE_SETTINGS,
  LANDING_ROUTE,
  LOGIN_FIRST_TIME,
  LOGIN_ROUTE,
  LOGIN_ROUTE_IAS_WSO2,
  LOGOUT_ROUTE_IAS_WSO2,
  MANAGE_REJECT_REASONS,
  MANAGE_TEMPLATES,
  NOTIFICATION,
  PASSWORD_POLICY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PUBLICFORM_ROUTE,
  RESULTS_FEEDBACK_SUBROUTE,
  ROOT_ROUTE,
  TOC_ROUTE,
  TOU_ROUTE,
  UM_ROUTE,
  UPDATE_AGENCY_DETAILS,
  UPDATE_NOTIFICAION,
  UPDATE_USER_PROFILE,
  USER_CREATE,
  USER_MANAGE,
  USER_UPDATE,
  VIEW_AGENCY_DETAILS,
  VIEW_CITIZEN,
  VIEW_FORM_ROUTE,
  VIEW_FORMS_PREVIEW,
  VIEW_SUBMISSION,
  VIEW_SUBMISSION_AGENCY,
  VIEW_SUBMISSION_CITIZEN,
  VIEW_TEMPLATES,
  VIEW_USER,
  VIEW_USER_PROFILE,
} from '~constants/routes'

import { ActivityDetailsPage } from '~pages/ActivityLog/activityDetails/ActivityDetailsPage'
import { ActivityLogPage } from '~pages/ActivityLog/ActivityLogPage'
import { ArchivedReportPage } from '~pages/AdminReports/archivedReport/ArchivedReportPage'
import { ProgressReport } from '~pages/AdminReports/progressReport/ProgressReport'
import { UnPublishedReport } from '~pages/AdminReports/unPublishedReport/UnPublishedReport'
import { CreateAgencyPage } from '~pages/Agency/createAgency/CreateAgencyPage'
import { CreateAdminUserPage } from '~pages/Agency/createAgency/include/CreateAdminUserPage'
import { ManageAgencyPage } from '~pages/Agency/manageAgency/ManageAgencyPage'
import { ViewSubmissionMeta } from '~pages/Agency/Submissions/ViewSubmissions'
import { UpdateAgencyPage } from '~pages/Agency/updateAgency/UpdateAgencyPage'
import { ViewAgencyPage } from '~pages/Agency/viewAgency/ViewAgencyPage'
import { AgencyProgressReport } from '~pages/AgencyReports/agencyProgressReport/AgencyProgressReport'
import { AgencyUnpublishedReport } from '~pages/AgencyReports/agencyUnpublishedReport/AgencyUnpublishedReport'
import { AgencyArchivedReportPage } from '~pages/AgencyReports/archivedReport/ArchivedReportPage'
import { ReconciliationReport } from '~pages/AgencyReports/reconciliationReport/ReconciliationReport'
import { OnboardingScreenPage } from '~pages/Citizen/onboardingScreen/OnboardingScreen'
import { IndividualResponseCitizenPage } from '~pages/Citizen/Submissions/ViewSubmissions'
import { CitizenUpdateUserProfilePage } from '~pages/Citizen/updateUserProfilePage/CitizenUpdateUserProfilePage'
import { ViewGovernmentAgency } from '~pages/Citizen/viewGovernmentAgency/ViewGovernmentAgency'
import { CitizenViewUserProfile } from '~pages/Citizen/viewUserProfile/CitizenViewUserProfile'
import { AgencyDashboardPage } from '~pages/Dashboard/AgencyDashboardPage'
import { AgencyCreateUserPage } from '~pages/ManageUser/Agency/createUser/CreateUserPage'
import { AgencyManageUserPage } from '~pages/ManageUser/Agency/manageUser/ManageUserPage'
import { AgencyUpdateUserPage } from '~pages/ManageUser/Agency/updateUser/UpdateUserPage'
import { CreateUserPage } from '~pages/ManageUser/createUser/CreateUserPage'
import { ManageUserPage } from '~pages/ManageUser/manageUser/ManageUserPage'
import { UpdateUserPage } from '~pages/ManageUser/updateUser/UpdateUserPage'
import NotFoundErrorPage from '~pages/NotFoundError'
import { ManageNotificationPage } from '~pages/Notification/manageNotification/NotificationPage'
import { UpdateNotificationPage } from '~pages/Notification/updateNotification/UpdateNotificationPage'
import { AddUpdateFormMasterDataPage } from '~pages/Settings/MasterData/AddUpdateFormMasterDataPage'
import { UpdateGovernmentAgency } from '~pages/ViewGovernmentAgency/includes/UpdateGovernmentAgency'
import { ViewGovernmentAgency as ViewAgencyDetails } from '~pages/ViewGovernmentAgency/ViewGovernmentAgency'
import { AdminFormLayout } from '~features/admin-form/common/AdminFormLayout'
import { CreatePage } from '~features/admin-form/create/CreatePage'
import {
  FeedbackPage,
  FormResultsLayout,
  ResponsesPage,
} from '~features/admin-form/responses'
import { IndividualResponsePage } from '~features/admin-form/responses/ResponsesPage/storage/UnlockedResponses/viewSubmissions'
import { SettingsPage } from '~features/admin-form/settings/SettingsPage'
import { ViewCitizenProfilePage } from '~features/citizen/viewCitizenProfile/ViewCitizenProfilePage'
import { ConversationMessageTypePage } from '~features/conversation-message-type/ConversationMessageTypePage'
import { PayAgainPage } from '~features/public-form/components/PayNowPage/PayAgainPage'
import { PaymentProcessingPage } from '~features/public-form/components/PayNowPage/PaymentProcessingPage'
import { ManageRejectReasonsPage } from '~features/reject-reasons/ManageRejectReasonsPage'

import { CitizenElement } from './CitizenElement'
import { HashRouterElement } from './HashRouterElement'
import { PrivateElement } from './PrivateElement'
import { PublicElement } from './PublicElement'

// const IndividualResponsePage = lazy(
//   () =>
//     import(
//       '~features/admin-form/responses/ResponsesPage/storage/UnlockedResponses/viewSubmissions/IndividualResponsePage'
//     ),
// )

const PublicFormPage = lazy(
  () => import('~features/public-form/PublicFormPage'),
)
const ViewFormPage = lazy(
  () => import('~features/public-form/view-form/ViewFormPage'),
)
const ExportSubmissionsPage = lazy(
  () => import('~features/admin-form/responses/ExportSubmissionsPage'),
)
const DashboardPage = lazy(() => import('~pages/Dashboard'))
const WorkspacePage = lazy(() => import('~features/workspace'))
const LandingPage = lazy(() => import('~pages/Landing'))
const LoginPage = lazy(() => import('~features/login'))
const LoginPageByIas = lazy(() => import('~features/login-ias'))
const LogoutPage = lazy(() => import('~features/logout'))
const PrivacyPolicyPage = lazy(() => import('~pages/PrivacyPolicy'))
const TermsOfUsePage = lazy(() => import('~pages/TermsOfUse'))
const TermsAndConditionsPage = lazy(() => import('~pages/TermsAndConditions'))
const PasswordPolicyPage = lazy(() => import('~pages/PasswordPolicy'))
const UserManualPage = lazy(() => import('~pages/UserManual'))

const ICTASettingsPage = lazy(() => import('~pages/Settings'))
const ICTASettingsTitlePage = lazy(() => import('~pages/Settings/Titles'))
const FormMasterDataPage = lazy(() => import('~pages/Settings/MasterData'))
const LoginFirstTimePage = lazy(() => import('~features/login-first-time'))
const ForgotUsernamePasswordPage = lazy(
  () => import('~features/login-forgot-user-pass'),
)
const ViewUserProfilePage = lazy(() => import('~features/user/viewUserProfile'))
const UpdateUserProfilePage = lazy(
  () => import('~features/user/updateUserProfile'),
)

const CitizenLoginPage = lazy(() => import('~features/citizen/login'))
const CitizenLoginPageByIas = lazy(() => import('~features/citizen/login-ias'))
const CitizenLogoutPage = lazy(() => import('~features/citizen/logout'))
const CitizenForgotUsernamePasswordPage = lazy(
  () => import('~features/citizen/login-forgot-user-pass'),
)
const CitizenDashboard = lazy(() => import('~pages/Citizen/Dashboard'))

const TemplatesPage = lazy(() => import('~pages/Templates'))
const ViewTemplatePage = lazy(
  () => import('~pages/Templates/view/ViewTemplatePage'),
)
const ViewUserPage = lazy(() => import('~pages/ManageUser/viewUser'))
const AgencyViewUserPage = lazy(
  () => import('~pages/ManageUser/Agency/viewUser'),
)

const WithSuspense = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <Routes>
        <Route
          path={LANDING_ROUTE}
          element={<HashRouterElement element={<LandingPage />} />}
        />
        <Route
          path={`${IAM_GROUP_ROUTE_WSO2}/${LOGIN_ROUTE_IAS_WSO2}`}
          element={<PublicElement element={<LoginPageByIas />} />}
        />
        <Route
          path={`${IAM_GROUP_ROUTE_WSO2}/${LOGOUT_ROUTE_IAS_WSO2}`}
          element={<PublicElement element={<LogoutPage />} />}
        />
        <Route
          path={`${IAM_GROUP_ROUTE_WSO2}/${LOGIN_FIRST_TIME}`}
          element={<PublicElement element={<LoginFirstTimePage />} />}
        />
        <Route
          path={`${IAM_GROUP_ROUTE_WSO2}/${FORGOT_USERNAME_PASSWORD}`}
          element={<PublicElement element={<ForgotUsernamePasswordPage />} />}
        />
        <Route
          path={`${CITIZEN_IAM_GROUP_ROUTE_WSO2}/${LOGIN_ROUTE_IAS_WSO2}`}
          element={<PublicElement element={<CitizenLoginPageByIas />} />}
        />
        <Route
          path={`${CITIZEN_IAM_GROUP_ROUTE_WSO2}/${LOGOUT_ROUTE_IAS_WSO2}`}
          element={<PublicElement element={<CitizenLogoutPage />} />}
        />
        <Route
          path={`${CITIZEN_IAM_GROUP_ROUTE_WSO2}/${CITIZEN_FORGOT_USERNAME_PASSWORD}`}
          element={
            <PublicElement element={<CitizenForgotUsernamePasswordPage />} />
          }
        />
        <Route
          path={ROOT_ROUTE}
          element={
            <PrivateElement
              element={<WorkspacePage />}
              permission={ROOT_ROUTE_PERMISSION}
            />
          }
        ></Route>
        <Route
          path={DASHBOARD_ROUTE}
          element={
            <PrivateElement
              element={<DashboardPage />}
              permission={ROOT_ROUTE_PERMISSION}
            />
          }
        />
        <Route
          path={LOGIN_ROUTE}
          element={<PublicElement element={<LoginPage />} />}
        />
        <Route
          path={CITIZEN_LOGIN_ROUTE}
          element={<PublicElement element={<CitizenLoginPage />} />}
        />
        <Route
          path={PRIVACY_POLICY_ROUTE}
          element={<PublicElement element={<PrivacyPolicyPage />} />}
        />
        <Route
          path={UM_ROUTE}
          element={<PublicElement element={<UserManualPage />} />}
        />
        <Route
          path={TOU_ROUTE}
          element={<PublicElement element={<TermsOfUsePage />} />}
        />
        <Route
          path={TOC_ROUTE}
          element={<PublicElement element={<TermsAndConditionsPage />} />}
        />
        <Route
          path={PASSWORD_POLICY_ROUTE}
          element={<PublicElement element={<PasswordPolicyPage />} />}
        />
        <Route
          path={PUBLICFORM_ROUTE}
          element={<PublicElement element={<PublicFormPage />} />}
        />
        <Route
          path={VIEW_FORM_ROUTE}
          element={<PublicElement element={<ViewFormPage />} />}
        />
        <Route
          path={`${ADMINFORM_ROUTE}/:formId`}
          element={
            <PrivateElement
              element={<AdminFormLayout />}
              permission={ADMINFORM_ROUTE_PERMISSION}
            />
          }
        >
          <Route index element={<CreatePage />} />
          <Route
            path={ADMINFORM_SETTINGS_SUBROUTE}
            element={<SettingsPage />}
          />
          <Route
            path={ADMINFORM_RESULTS_SUBROUTE}
            element={<FormResultsLayout />}
          >
            <Route index element={<ResponsesPage />} />
            <Route
              path={RESULTS_FEEDBACK_SUBROUTE}
              element={<FeedbackPage />}
            />
          </Route>
          <Route
            path={ADMINFORM_SUBMISSIONS_SUBROUTE}
            element={<ExportSubmissionsPage />}
          />
        </Route>
        <Route
          path={ITCA_SETTINGS}
          element={
            <PrivateElement
              element={<ICTASettingsPage />}
              permission={SETTINGS_MANAGE_PERMISSION}
            />
          }
        />
        <Route
          path={ITCA_FORM_MASTER_DATA}
          element={
            <PrivateElement
              element={<FormMasterDataPage />}
              permission={VIEW_MASTERDATA_PERMISSION}
            />
          }
        />
        <Route
          path={ITCA_FORM_MASTER_DATA_ADD_UPDATE}
          element={
            <PrivateElement
              element={<AddUpdateFormMasterDataPage />}
              permission={MANAGE_MASTERDATA_PERMISSION}
            />
          }
        />
        <Route
          path={ITCA_TITLE_SETTINGS}
          element={
            <PrivateElement
              element={<ICTASettingsTitlePage />}
              permission={MANAGE_TITLE_PERMISSION}
            />
          }
        />
        <Route
          path={`${AGENCY_VIEW}/:agencyId`}
          element={
            <PrivateElement
              element={<ViewAgencyPage />}
              permission={AGENCY_VIEW_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_CREATE}
          element={
            <PrivateElement
              element={<CreateAgencyPage />}
              permission={AGENCY_CREATE_PERMISSION}
            />
          }
        />
        <Route
          path={`${CREATE_USER_ADMIN}/:agencyId`}
          element={
            <PrivateElement
              element={<CreateAdminUserPage />}
              permission={CREATE_USER_ADMIN_PERMISSION}
            />
          }
        />
        <Route
          path={`${AGENCY_UPDATE}/:agencyId`}
          element={
            <PrivateElement
              element={<UpdateAgencyPage />}
              permission={AGENCY_UPDATE_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_MANAGE}
          element={
            <PrivateElement
              element={<ManageAgencyPage />}
              permission={AGENCY_MANAGE_PERMISSION}
            />
          }
        />
        <Route
          path={VIEW_USER_PROFILE}
          element={
            <PrivateElement
              element={<ViewUserProfilePage />}
              permission={VIEW_USER_PROFILE_PERMISSION}
            />
          }
        />
        <Route
          path={MANAGE_REJECT_REASONS}
          element={
            <PrivateElement
              element={<ManageRejectReasonsPage />}
              permission={MANAGE_REJECT_REASON_PERMISSION}
            />
          }
        />
        <Route
          path={VIEW_SUBMISSION}
          element={
            <PrivateElement
              element={<IndividualResponsePage />}
              permission={VIEW_SUBMISSION_PERMISSION}
            />
          }
        />
        <Route
          path={VIEW_SUBMISSION_AGENCY}
          element={
            <PrivateElement
              element={<ViewSubmissionMeta />}
              permission={VIEW_SUBMISSION_PERMISSION}
            />
          }
        />
        <Route
          path={`${VIEW_USER}/:userId`}
          element={
            <PrivateElement
              element={<ViewUserPage />}
              permission={USER_VIEW_PERMISSION}
            />
          }
        />
        <Route
          path={UPDATE_USER_PROFILE}
          element={
            <PrivateElement
              element={<UpdateUserProfilePage />}
              permission={UPDATE_USER_PROFILE_PERMISSION}
            />
          }
        />
        <Route
          path={USER_MANAGE}
          element={
            <PrivateElement
              element={<ManageUserPage />}
              permission={USER_MANAGE_PERMISSION}
            />
          }
        />
        <Route
          path={MANAGE_TEMPLATES}
          element={
            <PrivateElement
              element={<TemplatesPage />}
              permission={MANAGE_TEMPLATES_PERMISSION}
            />
          }
        />
        <Route
          path={USER_CREATE}
          element={
            <PrivateElement
              element={<CreateUserPage />}
              permission={USER_CREATE_PERMISSION}
            />
          }
        />
        <Route
          path={`${VIEW_TEMPLATES}/:formId`}
          element={
            <PrivateElement
              element={<ViewTemplatePage />}
              permission={MANAGE_TEMPLATES_PERMISSION}
            />
          }
        />
        <Route
          path={`${VIEW_FORMS_PREVIEW}/:formId`}
          element={<PublicElement element={<ViewTemplatePage />} />}
        />
        <Route
          path={AGENT_MANAGE_FORMS}
          element={
            <PrivateElement
              element={<WorkspacePage />}
              permission={MANAGE_FORMS_PERMISSION}
            />
          }
        />
        <Route
          path={NOTIFICATION}
          element={
            <PrivateElement
              element={<ManageNotificationPage />}
              permission={NOTIFICATION_PERMISSION}
            />
          }
        />
        <Route
          path={`${UPDATE_NOTIFICAION}/:notificationId`}
          element={
            <PrivateElement
              element={<UpdateNotificationPage />}
              permission={NOTIFICATION_UPDATE_PERMISSION}
            />
          }
        />
        <Route
          path={`${VIEW_CITIZEN}/:citizenId`}
          element={<PublicElement element={<ViewCitizenProfilePage />} />}
        />
        <Route
          path={`${USER_UPDATE}/:userId`}
          element={
            <PrivateElement
              element={<UpdateUserPage />}
              permission={USER_UPDATE_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_USER_MANAGE}
          element={
            <PrivateElement
              element={<AgencyManageUserPage />}
              permission={AGENCY_USER_MANAGE_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_USER_CREATE}
          element={
            <PrivateElement
              element={<AgencyCreateUserPage />}
              permission={AGENCY_USER_CREATE_PERMISSION}
            />
          }
        />
        <Route
          path={`${AGENCY_USER_UPDATE}/:userId`}
          element={
            <PrivateElement
              element={<AgencyUpdateUserPage />}
              permission={AGENCY_USER_UPDATE_PERMISSION}
            />
          }
        />
        <Route
          path={`${AGENCY_VIEW_USER}/:userId`}
          element={
            <PrivateElement
              element={<AgencyViewUserPage />}
              permission={AGENCY_USER_VIEW_PERMISSION}
            />
          }
        />
        <Route
          path={ACTIVITY_LOG}
          element={
            <PrivateElement
              element={<ActivityLogPage />}
              permission={ACTIVITY_LOG_PERMISSION}
            />
          }
        />
        <Route
          path={`${ACTIVITY_LOG}/:activity`}
          element={
            <PrivateElement
              element={<ActivityDetailsPage />}
              permission={ACTIVITY_LOG_PERMISSION}
            />
          }
        />
        <Route
          path={ADMIN_PROGRESS_REPORT}
          element={
            <PrivateElement
              element={<ProgressReport />}
              permission={ADMIN_PROGRESS_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={ADMIN_UNPUBLISHED_REPORT}
          element={
            <PrivateElement
              element={<UnPublishedReport />}
              permission={ADMIN_UNPUBLISHED_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={ADMIN_ARCHIVED_REPORT}
          element={
            <PrivateElement
              element={<ArchivedReportPage />}
              permission={ADMIN_ARCHIVED_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_ARCHIVED_REPORT}
          element={
            <PrivateElement
              element={<AgencyArchivedReportPage />}
              permission={AGENCY_ARCHIVED_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_PROGRESS_REPORT}
          element={
            <PrivateElement
              element={<AgencyProgressReport />}
              permission={AGENCY_PROGRESS_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_UNPUBLISHED_REPORT}
          element={
            <PrivateElement
              element={<AgencyUnpublishedReport />}
              permission={AGENCY_UNPUBLISHED_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_RECONCILlIATION_REPORT}
          element={
            <PrivateElement
              element={<ReconciliationReport />}
              permission={RECONCILIATION_REPORT_PERMISSION}
            />
          }
        />
        <Route
          path={AGENCY_DASHBOARD_ROUTE}
          element={
            <PrivateElement
              element={<AgencyDashboardPage />}
              permission={AGENCY_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={`${CITIZEN_DASHBOARD_ROUTE}`}
          element={
            <CitizenElement
              element={<CitizenDashboard />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={CITIZEN_VIEW_USER_PROFILE}
          element={
            <CitizenElement
              element={<CitizenViewUserProfile />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={`${CITIZEN_IAM_GROUP_ROUTE_WSO2}/${CITIZEN_USER_ONBOARD}`}
          element={
            <CitizenElement
              element={<OnboardingScreenPage />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
              isOnboarding={true}
            />
          }
        />
        <Route
          path={CITIZEN_ONBOARDING_SCREEN}
          element={
            <CitizenElement
              element={<OnboardingScreenPage />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={`${CITIZEN_VIEW_GOVERMENT_AGENCY}/:agencyId`}
          element={
            <CitizenElement
              element={<ViewGovernmentAgency />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={`${VIEW_AGENCY_DETAILS}`}
          element={
            <PrivateElement
              element={<ViewAgencyDetails />}
              permission={VIEW_AGENCY_PERMISSION}
            />
          }
        />
        <Route
          path={`${UPDATE_AGENCY_DETAILS}`}
          element={
            <PrivateElement
              element={<UpdateGovernmentAgency />}
              permission={UPDATE_AGENCY_PERMISSION}
            />
          }
        />
        <Route
          path={CONVERSATION_MESSAGE_TYPES}
          element={
            <PrivateElement
              element={<ConversationMessageTypePage />}
              permission={CONVERSATION_MESSAGE_TYPE_PERMISSION}
            />
          }
        />
        <Route
          path={VIEW_SUBMISSION_CITIZEN}
          element={
            <CitizenElement
              element={<IndividualResponseCitizenPage />}
              permission={CITIZEN_VIEW_SUBMISSION_PERMISSION}
            />
          }
        />
        <Route
          path={CITIZEN_UPDATE_USER_PROFILE}
          element={
            <CitizenElement
              element={<CitizenUpdateUserProfilePage />}
              permission={ACTIVITY_LOG_PERMISSION}
            />
          }
        />
        <Route
          path={CITIZEN_PAYMENT_PROCESSING_PAGE}
          element={
            <CitizenElement
              element={<PaymentProcessingPage />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route
          path={CITIZEN_PAYMENT_PAY_AGAIN_PAGE}
          element={
            <CitizenElement
              element={<PayAgainPage />}
              permission={CITIZEN_DASHBOARD_PERMISSION}
            />
          }
        />
        <Route path="*" element={<NotFoundErrorPage />} />
      </Routes>
    </WithSuspense>
  )
}
