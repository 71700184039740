import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const ThankYouSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    width={463}
    viewBox="0 0 463 223"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={263}
      y={70.101}
      width={12.099}
      height={12.099}
      rx={6.049}
      transform="rotate(-90 263 70.1)"
      fill="#05CC9A"
    />
    <rect
      x={285}
      y={90.654}
      width={4.653}
      height={4.653}
      rx={2.327}
      transform="rotate(-90 285 90.654)"
      fill="#05CC9A"
    />
    <path
      d="M292.723 68.172v-.976a.94.94 0 0 0-.916-.915.938.938 0 0 0-.915.915v.976a.939.939 0 0 1-.916.916h-.915a.938.938 0 0 0-.915.915c0 .489.427.916.915.916h.976c.489 0 .916.427.916.915v.977c0 .488.427.915.915.915a.94.94 0 0 0 .916-.915v-.977c0-.488.427-.915.915-.915h.977a.938.938 0 0 0 .915-.916.938.938 0 0 0-.915-.915h-.977c-.488 0-.915-.366-.976-.916Z"
      fill="#05CC9A"
    />
    <rect
      x={76}
      y={186}
      width={15}
      height={120}
      rx={7.063}
      transform="rotate(-90 76 186)"
      fill="#E4E7F6"
    />
    <rect
      x={235}
      y={219}
      width={15}
      height={128}
      rx={7.063}
      transform="rotate(-90 235 219)"
      fill="#E4E7F6"
    />
    <rect
      x={308}
      y={166}
      width={15}
      height={155}
      rx={7.063}
      transform="rotate(-90 308 166)"
      fill="#E4E7F6"
    />
    <rect
      y={139}
      width={15}
      height={164}
      rx={7.063}
      transform="rotate(-90 0 139)"
      fill="#E4E7F6"
    />
    <path
      d="M321.581 93.756c1.496-5.564-1.869-4.636-3.739-3.477-.997.493-2.077 2.523-2.493 3.477l-10.594 20.282 13.087-10.431-1.246-5.216 2.493-2.318 2.492-2.317Z"
      fill="#fff"
    />
    <path
      d="m319.089 82.744-6.232 8.693-4.985 11.011-3.739 12.749 11.84-21.442 6.855-8.693c2.992-6.027-1.454-4.057-3.739-2.318Z"
      fill="#fff"
    />
    <path
      d="M319.712 120.412c-4.985-.927-12.879-3.863-16.203-5.215l18.696-24.34c1.496-.464 2.285.966 2.493 1.738l-.624 4.057c2.493 0 2.701 2.318 2.493 3.477l-1.869 8.113-4.363 5.796-.623 6.374Z"
      fill="#fff"
    />
    <path
      d="M220.12 112.302c-1.994-3.245-5.817-4.829-7.478-5.216v7.534l-46.74 55.634 3.739 10.432 20.565 41.146 63.566-1.159 2.493-17.386 3.739-33.033 4.986 12.17 8.725 9.273 6.855 5.795 4.985 2.318 4.363.58 4.362-1.739 8.102-5.216 5.608-8.693 4.363-8.113 4.985-15.068 1.247-10.431 1.246-30.715-8.725-1.738-7.478-2.898-9.348 21.443-6.855 21.442-8.725-17.965-7.478-11.591-6.232-4.636-7.478-3.477-14.957-5.216-14.334-2.897-8.101-.58Z"
      fill="#fff"
    />
    <path
      d="M150.383 123.312c4.986-3.709 22.02-8.114 29.914-9.852l28.605 1.159 7.478-1.159-51.04 57.373-3.178 17.965 9.972-9.852 7.478-5.216 6.855 5.795 8.725 5.216-11.218 23.761-9.971 12.749h-32.406c-8.725-21.442-6.794-64.327-5.547-77.076 1.246-12.75 8.101-16.227 14.333-20.863Z"
      fill="#fff"
    />
    <path
      d="M185.567 80.53c-13.887-42.142 41.289-40.094 41.289-15.174.623 27.817-6.855 31.876-14.334 35.351v13.908l-26.797-1.159-.158-32.927Z"
      fill="#fff"
    />
    <path
      d="M225.01 59.847c1.221 8.769 1.881 17.89-.208 26.59-.658 3.206-2 6.257-3.945 8.974-2.185 2.907-5.642 4.766-9.442 5.077-4.595.298-9.112-1.215-12.447-4.169-.574-.5-1.441.307-.867.807 5.961 5.157 15.964 6.452 22.072.772 2.513-2.544 4.302-5.633 5.203-8.984a47.925 47.925 0 0 0 1.93-12.495 95.172 95.172 0 0 0-1.075-16.56c-.098-.717-1.319-.728-1.221 0v-.012ZM204.721 73.602c-.27.129-.482.342-.599.602a1.35 1.35 0 0 0 0 .875c.044.14.102.277.171.409.091.156.226.285.391.374.289.152.651.11.892-.102a.7.7 0 0 0 .219-.545 3.786 3.786 0 0 0-.268-.818.667.667 0 0 0-.281-.34.695.695 0 0 0-.477 0 .608.608 0 0 0-.366.26.54.54 0 0 0 0 .432l.134.364c.055.1.092.207.11.318v-.375l.098-.114.122-.09.147-.057h.33l.146.057.122.09-.073-.068.098.114a1.049 1.049 0 0 1-.122-.193l.061.136a1.046 1.046 0 0 1-.074-.261v.147a.721.721 0 0 1 0-.227v.16a.789.789 0 0 1 0-.182l-.061.136a.65.65 0 0 1 .098-.148l-.098.114.122-.125-.122.09.122-.067a.575.575 0 0 0 .281-.341.534.534 0 0 0-.061-.432.605.605 0 0 0-.366-.261.717.717 0 0 0-.464.057l-.232.011Z"
      fill="#000"
    />
    <path
      d="M219.304 75.497c-.27.13-.479.348-.586.613a1.342 1.342 0 0 0-.061.875c.039.138.096.272.171.397a.92.92 0 0 0 .391.375c.282.156.641.12.879-.09a.632.632 0 0 0 .22-.546 2.99 2.99 0 0 0-.268-.83.602.602 0 0 0-.281-.34.653.653 0 0 0-.465-.057.643.643 0 0 0-.366.262.52.52 0 0 0 0 .443l.134.363c.048.099.085.201.11.307v-.262l.061-.136.098-.113.122-.091.147-.057h.232l.146.057.135.09-.086-.068.098.114a.992.992 0 0 1-.11-.193v.136a.924.924 0 0 1-.073-.261v.159a1.435 1.435 0 0 1 0-.227v.147a.504.504 0 0 1 0-.181v.136a.956.956 0 0 1 .085-.148l-.097.125c.041-.044.086-.086.134-.125l-.122.091a.26.26 0 0 1 .122-.068.598.598 0 0 0 .281-.34.534.534 0 0 0-.055-.436.61.61 0 0 0-.373-.269.77.77 0 0 0-.476.057l-.147.09ZM190.783 77.497a9.612 9.612 0 0 1-4.104-2.647c-1.219-1.238-1.879-2.865-1.844-4.543.038-.804.455-1.551 1.141-2.042a3.101 3.101 0 0 1 2.413-.503c1.741.343 3.107 1.6 3.494 3.215.091.313.438.499.775.414.338-.084.537-.407.446-.721-.431-1.527-1.535-2.814-3.041-3.544a5.035 5.035 0 0 0-4.739.16c-3.115 2.055-1.527 6.212.513 8.416a10.953 10.953 0 0 0 4.592 2.896c.745.239 1.063-.852.33-1.135l.024.034Z"
      fill="#000"
    />
    <path
      d="M187.29 70.568c.363-.25.859-.25 1.221 0 .375.284.672.646.868 1.057.503.906.814 1.893.916 2.908 0 .181.317.193.305 0a7.774 7.774 0 0 0-1.148-3.408 2.367 2.367 0 0 0-1.087-1 1.296 1.296 0 0 0-1.307.16c-.147.124.061.318.208.204l.024.08Z"
      fill="#000"
    />
    <path
      d="M188.732 72.33h.061a.656.656 0 0 1 .33 0c.259.093.469.276.586.51.098.16.366 0 .269-.147a1.206 1.206 0 0 0-.831-.648.74.74 0 0 0-.843.353c-.023.078.026.16.11.181.086 0 .147 0 .183-.102l.135-.148ZM187.73 77.29a127.8 127.8 0 0 1-1.332 32.44c-.122.716 1.063 1.022 1.222.295a128.418 128.418 0 0 0 1.356-32.735c-.074-.727-1.295-.727-1.222 0h-.024ZM212.379 101.419a82.69 82.69 0 0 0-.952 12.869c0 .727 1.221.727 1.221 0-.018-4.305.3-8.606.953-12.869.097-.716-1.124-.716-1.222 0ZM212.343 74.407a33.242 33.242 0 0 1-.147 7.224c0 .313.273.568.611.568.337 0 .611-.255.611-.568.311-2.397.36-4.818.146-7.224 0-.727-1.221-.739-1.221 0ZM204.599 68.908h.097l.159-.056a2.78 2.78 0 0 1 .367-.103l.366-.08h.11c.061 0 .073 0 0 0h.147a4.35 4.35 0 0 1 .769 0h.281l.391.069c.337.081.682-.107.77-.42.087-.314-.115-.634-.452-.716a5.997 5.997 0 0 0-3.665.34.557.557 0 0 0-.22.785c.183.26.548.348.843.204l.037-.023ZM218.377 70.853h.244l.367-.113.378-.08h.245c.26-.017.521-.017.781 0h.281c.129.012.255.035.379.068.337.085.685-.1.776-.414.091-.314-.109-.637-.446-.722a6.01 6.01 0 0 0-3.665.353.562.562 0 0 0-.285.343.519.519 0 0 0 .066.429c.169.268.54.36.83.204l.049-.068ZM181.098 112.584c-9.002 2.567-18.2 4.714-26.799 8.349a40.832 40.832 0 0 0-10.859 6.349c-3.181 2.714-5.54 6.157-6.84 9.984a45.1 45.1 0 0 0-1.832 12.552c-.22 4.691-.489 9.382-.648 14.084a238.596 238.596 0 0 0 .745 27.863 113.916 113.916 0 0 0 5.118 26.682 84.554 84.554 0 0 0 1.148 3.169c.257.681 1.442.386 1.222-.307a102.94 102.94 0 0 1-5.863-26.034 220.127 220.127 0 0 1-1.222-27.056c.074-4.737.294-9.473.538-14.21.06-4.237.403-8.467 1.026-12.665.66-4.058 2.468-7.88 5.24-11.075 2.794-2.947 6.19-5.349 9.992-7.065 8.232-3.918 17.247-6.167 26.078-8.598l3.298-.932c.757-.215.427-1.306-.318-1.136l-.024.046Z"
      fill="#000"
    />
    <path
      d="M181.843 113.357c.968-2.441 2.699-4.558 4.971-6.077a.545.545 0 0 0 .22-.772.596.596 0 0 0-.369-.266.637.637 0 0 0-.461.062c-2.537 1.68-4.467 4.037-5.534 6.758-.087.314.115.634.452.716.338.081.682-.107.77-.421h-.049ZM211.855 106.88c1.545.581 3.026 1.3 4.421 2.147 1.388.704 2.507 1.791 3.201 3.112.268.682 1.453.387 1.221-.295-.744-1.541-1.986-2.828-3.554-3.68a27.104 27.104 0 0 0-4.886-2.374c-.327-.08-.663.096-.757.397-.086.301.104.61.427.693h-.073Z"
      fill="#000"
    />
    <path
      d="M220.6 112.322a161.964 161.964 0 0 1 34.628 8.042c4.886 1.704 9.772 3.737 13.632 6.963 4.3 3.532 6.596 8.394 9.063 13.096l9.332 17.754c.342.659 1.393.091 1.051-.568l-8.551-16.243c-2.504-4.759-4.739-9.916-8.794-13.744-3.847-3.347-8.385-5.932-13.338-7.599a151.296 151.296 0 0 0-16.967-5.191 164.694 164.694 0 0 0-20.056-3.646c-.782-.091-.782 1.045 0 1.136Z"
      fill="#000"
    />
    <path
      d="M288.44 159.132a43.085 43.085 0 0 1 2.577-11.358c1.527-4.544 3.347-8.94 5.24-13.324 1.893-4.384 3.787-8.621 5.619-12.96a86.855 86.855 0 0 0 4.312-11.995c1.478-5.679 3.139-12.96 9.771-15.243.733-.25.416-1.352-.317-1.136-5.619 1.942-8.233 7.315-9.662 12.347-.757 2.658-1.356 5.35-2.26 7.951a89.34 89.34 0 0 1-2.113 5.52c-3.664 8.973-7.976 17.72-11.213 26.841a47.596 47.596 0 0 0-3.188 13.289c0 .739 1.222.739 1.222 0l.012.068ZM314.017 91.24c1.315-2.927 3.275-5.566 5.753-7.746.574-.5-.293-1.306-.855-.795-2.636 2.314-4.717 5.12-6.107 8.235-.086.304.103.616.427.704.326.074.658-.1.758-.397h.024ZM320.76 90.158l.122.068c-.061 0 0 0 0 0s.134.114.183.17l.085.08c.062.057-.073-.102 0 0l.123.193c.045.07.086.142.122.216a.652.652 0 0 0 0 .125c.005.072.005.144 0 .216v.193c0-.08 0 0 0 0v.113a.872.872 0 0 1 0 .25v.114c0 .045 0-.114 0 0 .006.08.006.159 0 .238-.023.082-.052.161-.086.239l-.073.113a1.158 1.158 0 0 1-.122.194s-.11.124 0 0l-.098.102a.538.538 0 0 0-.18.397c0 .15.065.293.18.398a.636.636 0 0 0 .434.168c.163 0 .319-.06.433-.168.617-.662.9-1.536.779-2.404-.122-.868-.636-1.647-1.414-2.14a.663.663 0 0 0-.843.205.567.567 0 0 0 .22.784l.135.136ZM324.045 92.31c.542 1.239.317 2.653-.587 3.692a.56.56 0 0 0 0 .807.636.636 0 0 0 .434.168c.163 0 .319-.06.434-.168 1.209-1.33 1.55-3.164.891-4.794-.089-.3-.421-.477-.745-.397-.322.083-.513.392-.427.693ZM318.989 95.354l-2.15 1.738a3.367 3.367 0 0 0-.806.772 1.068 1.068 0 0 0 0 1.136 8.56 8.56 0 0 1 1.075 4.543c0 .739 1.222.727 1.222 0a9.262 9.262 0 0 0-.807-4.236c-.085-.16-.158-.33-.244-.489-.085-.159-.232-.306-.22-.431 0 .102 0 .09.074 0l.207-.17.489-.398 1.966-1.59c.587-.478-.281-1.284-.867-.807l.061-.068Z"
      fill="#000"
    />
    <path
      d="M326.207 100.032c-.097 2.556-.219 5.214-1.563 7.497a13.266 13.266 0 0 1-2.699 3.169 9.177 9.177 0 0 0-1.454 1.42 4.317 4.317 0 0 0-.769 2.271c-.379 3.612-.428 7.293-.538 10.927-.11 3.635-.183 7.349-.293 11.018-.318 11.257-.782 22.774-4.458 33.599-2.59 7.61-6.181 15.312-11.519 21.582-2.244 2.874-5.169 5.23-8.55 6.883a13.183 13.183 0 0 1-10.138.193c-3.542-1.374-6.523-3.998-9.296-6.418-3.847-3.339-7.402-6.985-10.871-10.654a214.347 214.347 0 0 1-3.664-3.976c-.513-.556-1.38.25-.867.807 4.055 4.407 8.171 8.78 12.605 12.869a53.098 53.098 0 0 0 9.112 7.179c3.194 1.974 7.104 2.678 10.859 1.954 3.611-.958 6.855-2.849 9.357-5.452a46.001 46.001 0 0 0 7.561-9.939 79.774 79.774 0 0 0 5.374-11.404 69.183 69.183 0 0 0 3.102-9.894 123.566 123.566 0 0 0 2.382-20.854c.355-7.577.367-15.153.721-22.718.085-1.817.159-3.634.318-5.452.018-.84.361-1.646.965-2.271.586-.591 1.221-1.136 1.832-1.682 1.821-1.825 3.001-4.123 3.383-6.588.189-1.333.3-2.676.33-4.021 0-.727-1.222-.727-1.222 0v-.045ZM200.873 98.465s3.262 8.996 10.993 10.7l.99-6.543s1.295-2.271-1.222-1.204c-2.516 1.068-10.761-2.953-10.761-2.953Z"
      fill="#000"
    />
    <path
      d="M191.944 69.626s14.523-2.76 22.683-14.767c0 0 1.221 6.35-1.405 10.836a.75.75 0 0 0 .093.88c.219.248.578.35.909.256 2.626-.727 6.656-2.93 5.24-9.575 0 0 2.907 7.871 8.269 10.166a2.599 2.599 0 0 0 2.491-.16c.737-.474 1.135-1.284 1.039-2.112-.501-4.169-2.858-11.756-13.18-15.788a69.744 69.744 0 0 1-3.811-1.59c-2.32-1.046-7.744-2.704-17.32-1.386-9.576 1.317-21.229 6.997-18.64 31.713 2.59 24.716-6.241 37.847-6.241 37.847s16.49-3.214 16.319-3.896c-.171-.681.818-11.166.965-17.924.051-4.554-.206-9.106-.77-13.63 0 0 0-2.999-1.918-4.226-1.917-1.226-3.176-5.497-1.991-7.28.872-1.142 2.332-1.765 3.832-1.634 1.499.131 2.809.996 3.436 2.27Z"
      fill="#000"
    />
    <path
      d="M174.086 111.994s-9.405 18.242-6.107 25.648c3.298 7.405 18.798 4.997 19.946-7.304.696-7.846.945-15.721.745-23.592M205.087 84.472c.781 1.376 2.265 2.284 3.933 2.408.335-.006.605-.257.611-.568 0-.314-.273-.568-.611-.568h-.122c-.085 0 .098 0 0 0h-.244a3.403 3.403 0 0 1-.489-.125h-.146c-.061 0 0 0 0 0l-.232-.102a3.38 3.38 0 0 1-.452-.25l-.098-.068s-.134-.08 0 0l-.098-.08a4.557 4.557 0 0 1-.354-.318l-.171-.182s0 .069 0 0l-.073-.102a4.859 4.859 0 0 1-.269-.42.637.637 0 0 0-.831-.204.557.557 0 0 0-.219.783l-.135-.204Z"
      fill="#000"
    />
    <circle cx={339.029} cy={29.669} r={28.971} fill="#05CC9A" stroke="#000" />
    <path
      transform="rotate(42.529 122.875 431.565) skewX(.036)"
      stroke="#000"
      d="M0-.5h13.582"
    />
    <path
      transform="rotate(-45.018 214.865 -383.231)"
      stroke="#000"
      d="M0-.5h28.32"
    />
    <path
      d="M253.787 146.963c3.669 8.962 5.52 18.478 5.46 28.067a128.45 128.45 0 0 1-3.445 28.84c-1.221 5.543-3.017 11.086-3.005 16.788 0 .314.274.568.611.568.337 0 .611-.254.611-.568 0-5.18 1.515-10.223 2.675-15.232a168.099 168.099 0 0 0 2.663-14.312 97.288 97.288 0 0 0 0-28.862 66.534 66.534 0 0 0-4.459-15.584c-.281-.682-1.465-.387-1.221.295h.11ZM150.451 204.497c7.355-11.885 17.348-22.178 29.315-30.191a38.715 38.715 0 0 0 5.814-4.146 12.622 12.622 0 0 0 2.919-4.862c1.368-3.782 1.625-8.428 5.118-11.165.599-.477-.269-1.284-.867-.807-3.078 2.42-3.811 6.27-4.8 9.712a16.49 16.49 0 0 1-2.199 5.1c-1.392 1.988-3.664 3.237-5.668 4.544-12.533 8.201-22.988 18.85-30.634 31.202a.546.546 0 0 0 .219.769.638.638 0 0 0 .832-.19l-.049.034ZM195.67 158.952a43.614 43.614 0 0 1 10.455-5.135c.733-.238.428-1.34-.317-1.135a44.908 44.908 0 0 0-10.761 5.236c-.636.42 0 1.408.623.988v.046ZM204.257 158.121a17.782 17.782 0 0 0-2.919 1.761.549.549 0 0 0 0 .806.668.668 0 0 0 .867 0l.159-.124c-.11.09 0 0 .085-.057.106-.078.216-.15.33-.216.22-.148.44-.295.672-.432.464-.272.928-.522 1.417-.761a.543.543 0 0 0 .168-.745.635.635 0 0 0-.779-.232ZM206.333 162.128a6.92 6.92 0 0 1-.513.5l-.269.216-.134.114s-.11.079 0 0c-.381.269-.79.501-1.222.693a.575.575 0 0 0-.281.34.49.49 0 0 0 0 .432.58.58 0 0 0 .354.261.69.69 0 0 0 .477-.057 7.04 7.04 0 0 0 2.516-1.749.54.54 0 0 0 0-.795.638.638 0 0 0-.434-.168.636.636 0 0 0-.433.168l-.061.045ZM209.521 166.284c-1.551 3.328-5.105 5.168-7.878 7.496-3.261 2.738-5.179 6.395-7.048 10.019a1647.828 1647.828 0 0 0-6.584 12.903c-1.991 3.93-3.921 7.895-6.107 11.756a66.87 66.87 0 0 1-8.391 12.2c-.501.556.354 1.363.855.806 5.899-6.599 9.771-14.437 13.631-22.149 2.182-4.347 4.393-8.69 6.633-13.029 1.954-3.805 3.762-7.951 7.011-10.983 2.992-2.783 7.145-4.68 8.904-8.451.306-.659-.745-1.238-1.062-.568h.036Z"
      fill="#000"
    />
    <path
      d="m198.687 156.952-1.368-10.086-.342-2.522a16.448 16.448 0 0 1-.318-2.419c0-.909.904-1.056 1.686-1.318.782-.261 1.6-.534 2.443-.772a61.76 61.76 0 0 1 10.077-2.079c1.686-.193 3.383-.341 5.081-.386.929 0 1.527.125 1.832 1 .225.746.4 1.505.526 2.271a290.941 290.941 0 0 1 3.591 19.696c.488 3.34.896 6.687 1.221 10.041.183 1.659.342 3.317.501 4.975 0 .761.269 1.568-.574 1.988a20.09 20.09 0 0 1-2.443.92 72.364 72.364 0 0 1-13.802 3.237l-1.54.194c-.622.068-.964.102-1.05-.603-.232-1.851-.391-3.725-.574-5.588-.086-.727-1.307-.738-1.222 0 .196 1.908.355 3.817.587 5.679a1.775 1.775 0 0 0 .696 1.432c.684.431 1.661.159 2.443.068a74.454 74.454 0 0 0 11.286-2.272 81.31 81.31 0 0 0 5.375-1.761 3.866 3.866 0 0 0 1.759-.999 2.694 2.694 0 0 0 .366-2.056c-.33-3.783-.757-7.554-1.221-11.359a279.666 279.666 0 0 0-3.775-22.07l-.586-2.714a6.733 6.733 0 0 0-.709-2.272c-.916-1.386-2.858-1.045-4.348-.954a62.915 62.915 0 0 0-11.189 1.726 50.81 50.81 0 0 0-5.985 1.818c-.754.199-1.342.75-1.551 1.454a8.129 8.129 0 0 0 .22 2.76l1.759 12.88c.098.716 1.319.727 1.221 0l-.073.091Z"
      fill="#000"
    />
    <path
      d="M200.799 145.091c.338 0 .611-.254.611-.568 0-.313-.273-.568-.611-.568-.337 0-.611.255-.611.568 0 .314.274.568.611.568ZM203.854 146.296c.337 0 .611-.255.611-.568 0-.314-.274-.568-.611-.568-.337 0-.611.254-.611.568 0 .313.274.568.611.568ZM202.12 148.417c.337 0 .61-.254.61-.568 0-.313-.273-.568-.61-.568-.338 0-.611.255-.611.568 0 .314.273.568.611.568ZM187.73 113.834c9.369 1.238 19.214 2.589 28.57.67.77-.159.44-1.25-.317-1.136a54.08 54.08 0 0 1-13.876.761c-4.703-.261-9.393-.818-14.047-1.431-.77-.102-1.099.988-.33 1.136ZM221.101 117.567v-.931a.55.55 0 0 0-.171-.398.66.66 0 0 0-.663-.116.585.585 0 0 0-.388.514v.931a.57.57 0 0 0 .381.529.643.643 0 0 0 .67-.131.574.574 0 0 0 .171-.398ZM179.07 173.876a31.312 31.312 0 0 0 7.671 6.645c1.49.977 2.968 1.953 4.519 2.851.782.454 1.588.897 2.443 1.295.55.261 1.112.511 1.625 0a.54.54 0 0 0 .098-.693v-.068c-.465-.591-1.527 0-1.063.568v.079l.098-.693c.183-.17-.465-.375-.648-.466l-1.099-.579a39.964 39.964 0 0 1-1.893-1.136c-1.32-.806-2.614-1.669-3.909-2.521a27.772 27.772 0 0 1-6.767-5.941c-.464-.579-1.527 0-1.063.579l-.012.08ZM303.977 116.355a27.853 27.853 0 0 0 7.17 2.874c2.602.772 5.302 1.806 8.086 1.624l-.158-1.136-.257.057c-.757.159-.44 1.261.33 1.136h.244c.66-.148.538-1.136-.159-1.136-2.675.17-5.264-.841-7.768-1.59a26.453 26.453 0 0 1-6.865-2.749c-.647-.421-1.221.568-.623.977v-.057ZM163.191 162.311a174.471 174.471 0 0 0 5.253 18.764c.232.693 1.416.398 1.221-.306a176.787 176.787 0 0 1-5.252-18.765c-.147-.716-1.332-.409-1.222.307ZM176.957 216.829c.043.727.043 1.455 0 2.181v1.068a3.994 3.994 0 0 1-.134.977l.159-.25.427-.159.306.079.22.205v.511a.58.58 0 0 0-.22-.784.65.65 0 0 0-.843.204c-.182.308-.062.694.269.864a.724.724 0 0 0 .541.035.667.667 0 0 0 .399-.342c.097-.255.147-.524.147-.795l.073-.772a21.03 21.03 0 0 0 0-3.033c0-.314-.273-.568-.611-.568-.334.006-.604.257-.611.568l-.122.011Z"
      fill="#000"
    />
    <path
      d="M221.04 178.667s1.649 8.189.952 9.303c-.696 1.113-18.053 4.997-20.215 4.441 0 0-9.442 26.125-11.726 29.044h-14.658s-.977-.875.636-2.749c1.612-1.874 6.217-9.462 6.217-9.462l8.318-16.129c.721-1.42 6.999-13.505 7.646-14.448.648-.943 5.473-5.679 5.473-5.679l.354 9.155a58.828 58.828 0 0 0 8.758-1.193c4.14-.988 8.245-2.283 8.245-2.283ZM165.084 167.615s-3.664 21.32-5.838 23.955c0 0 6.803-7.122 7.658-7.894.855-.773 2.187-.511 1.478-4.714M259.857 165.243c.379.375 5.204 12.495 5.118 18.174l-3.31-3.044s-2.577-4.544-1.808-5.997"
      fill="#000"
    />
  </svg>
))
