import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react'
import { find, isArray, isEmpty, isObject, slice } from 'lodash'

import { ConversationMessageTypeDto } from '~shared/types'

import { useToast } from '~hooks/useToast'
import {
  createConversationMessageType,
  deleteConversationMessageTypeById,
  getConversationMessageTypes,
  updateConversationMessageTypeById,
} from '~services/ConversationMessageTypeService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input/index'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

export const ConversationMessageTypePage = (): JSX.Element | null => {
  const MAX_MESSAGE_TYPE_LENGTH = 50
  const DUPLICATE_CONVERSATION_MESSAGE_TYPE =
    'DUPLICATE_CONVERSATION_MESSAGE_TYPE' // Note: This error message is sending from backend for duplicate entries
  const rowsPerPageOptions = ['5', '10', '25', '50', '100']
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showCancelMessage, setShowCancelMessage] = useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = useState<string>('25')
  const [currentPage, setCurrentPage] = useState(1)
  const [conversationMessageTypes, setConversationMessageTypes] = useState<
    ConversationMessageTypeDto[]
  >([])
  const [currentPageMessageTypes, setCurrentPageMessageTypes] = useState<
    ConversationMessageTypeDto[]
  >([])
  const [whichShowingModel, setWhichShowingModel] = useState<string | null>(
    null,
  )
  const [whichShowingMessageType, setWhichShowingMessageType] = useState<
    ConversationMessageTypeDto | null | undefined
  >(null)

  type whichShowingMessageTypeForm = {
    conversationMessageType: string
    conversationTypeCode: string
  }
  enum showingModel {
    create = 'CREATE',
    update = 'UPDATE',
    delete = 'DELETE',
  }
  const toast = useToast({ duration: 5000, isClosable: true })
  const navigate = useNavigate()

  // Get initial data to populate the table
  const getInitialData = async () => {
    setIsLoading(true)
    const responseData = await getConversationMessageTypes()

    // Use direct properties since the object structure is fairly simple
    // Otherwise may use _.pick _.keys in lodash to simplify the collection
    setConversationMessageTypes(isArray(responseData) ? responseData : [])
    setIsLoading(false)
  }
  useEffect(() => {
    getInitialData()
  }, [])

  // Updating the display values according to the pencil file
  const getSendFromValue = (value: any) => {
    // NOTE: values are ConversationTypeCode options.
    if (value === 'AM') return 'Gov. Agency'
    if (value === 'CQ') return 'Citizen'
    return ''
  }

  // Update the page size and count
  useEffect(() => {
    const rowsPerCurrentPage = parseInt(rowsPerPage, 10)

    // Set the viewing content of the data table
    const updatedArray = slice(
      conversationMessageTypes,
      (currentPage - 1) * rowsPerCurrentPage,
      currentPage * rowsPerCurrentPage,
    )
    setCurrentPageMessageTypes(updatedArray)
  }, [rowsPerPage, currentPage, conversationMessageTypes])

  // Delete a conversation message type
  const onSubmitDeleteMessageType = async () => {
    toast.closeAll()
    try {
      if (
        isObject(whichShowingMessageType) &&
        !isEmpty(whichShowingMessageType)
      ) {
        await deleteConversationMessageTypeById(whichShowingMessageType?._id)
      }

      toast({
        status: 'success',
        description: 'Message Type Deleted',
        position: 'top-right',
      })

      setWhichShowingMessageType(null)
      setWhichShowingModel(null)
      // Reload the updated data
      getInitialData()
    } catch (error) {
      // Delete failed. Ignore the error.
      toast({
        status: 'danger',
        description: 'Something went wrong',
        position: 'top-right',
      })
    }
  }

  const { handleSubmit, register, formState, control, reset } =
    useForm<whichShowingMessageTypeForm>()

  // Create or update a conversation message type
  const onSubmitForm = async (inputs: whichShowingMessageTypeForm) => {
    toast.closeAll()
    try {
      if (
        isObject(whichShowingMessageType) &&
        !isEmpty(whichShowingMessageType)
      ) {
        // Update record
        await updateConversationMessageTypeById(
          whichShowingMessageType?._id,
          inputs,
        )
        toast({
          status: 'success',
          description: 'Message Type Updated Successfully',
          position: 'top-right',
        })
      } else {
        // Create record
        await createConversationMessageType(inputs)
        toast({
          status: 'success',
          description: 'Message Type Added Successfully',
          position: 'top-right',
        })
      }

      setWhichShowingMessageType(null)
      setWhichShowingModel(null)
      // Reload the updated data
      getInitialData()
    } catch (error) {
      // Show a custom error for a duplicate entry
      if (
        error instanceof Error &&
        error.message === DUPLICATE_CONVERSATION_MESSAGE_TYPE
      ) {
        return toast({
          status: 'danger',
          description: 'Duplicate Form Submission Message Type',
          position: 'top-right',
        })
      }

      // Add or update failed. Ignore the error.
      toast({
        status: 'danger',
        description: 'Something went wrong',
        position: 'top-right',
      })
    }
  }

  const loggedUser = localStorage.getItem('user')
  const loggedUserDetails = loggedUser ? JSON.parse(loggedUser) : null

  return (
    <>
      {/* Main table content */}
      <Box bg={'gray.50'} p={10}>
        <Box bg={'gray.50'} p={5}>
          <Box height="40px" mb={15}>
            <Heading as="h4" size="md">
              Manage Form Submission Message Type
            </Heading>
            <ButtonGroup
              variant="outline"
              paddingTop={10}
              float="right"
              marginTop="-30px"
              marginLeft="-120px"
            >
              <Button
                isDisabled={
                  loggedUserDetails?._doc?.userRole?.code === 'DF_SYS_ADMIN'
                }
                onClick={() => {
                  setWhichShowingMessageType(null)
                  reset({})
                  setWhichShowingModel(showingModel.create)
                }}
              >
                Add Message Type
              </Button>
            </ButtonGroup>
          </Box>

          <Box overflowX="auto" pt="0" style={{ width: '100%' }}>
            <DataTable
              heading=""
              rowValues={currentPageMessageTypes}
              cols={[
                {
                  Header: 'No.',
                  accessor: (_row: any, i: number) =>
                    i + 1 + (currentPage - 1) * parseInt(rowsPerPage, 10),
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 80,
                  width: 80,
                },
                {
                  Header: 'Send From',
                  accessor: 'conversationTypeCode',
                  disableSortBy: true,
                  maxWidth: 400,
                  minWidth: 140,
                  Cell: (props: any) => getSendFromValue(props?.cell?.value),
                },
                {
                  Header: 'Message Type',
                  accessor: 'conversationMessageType',
                  disableSortBy: true,
                  minWidth: 140,
                },
                {
                  Header: 'Action',
                  accessor: '_id',
                  disableSortBy: true,
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1" padding={0}>
                      <Button
                        isDisabled={
                          loggedUserDetails?._doc?.userRole?.code ===
                          'DF_SYS_ADMIN'
                        }
                        onClick={() => {
                          const messageType = find(currentPageMessageTypes, {
                            _id: props?.cell?.value,
                          })
                          setWhichShowingMessageType(messageType)
                          reset({
                            conversationMessageType:
                              messageType?.conversationMessageType,
                            conversationTypeCode:
                              messageType?.conversationTypeCode,
                          })
                          setWhichShowingModel(showingModel.update)
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        isDisabled={
                          loggedUserDetails?._doc?.userRole?.code ===
                          'DF_SYS_ADMIN'
                        }
                        onClick={() => {
                          const messageType = find(currentPageMessageTypes, {
                            _id: props?.cell?.value,
                          })
                          setWhichShowingMessageType(messageType)
                          setWhichShowingModel(showingModel.delete)
                        }}
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
          </Box>
        </Box>
        {/* Pagination with options */}
        <Box bg={'gray.50'} p={5}>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : null}
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
            <HStack spacing="24px">
              <Box>Rows per page:</Box>
              <Box w="100px">
                <SingleSelect
                  value={rowsPerPage}
                  onChange={(value) => {
                    setCurrentPage(1)
                    setRowsPerPage(value)
                  }}
                  name={'rowsPerPage'}
                  isClearable={false}
                  items={rowsPerPageOptions}
                />
              </Box>
            </HStack>
            <Pagination
              currentPage={currentPage}
              pageSize={parseInt(rowsPerPage, 10)}
              totalCount={conversationMessageTypes.length}
              onPageChange={(pageNumber) => {
                setCurrentPage(pageNumber)
              }}
            />
          </SimpleGrid>
          <Center>
            <ButtonGroup spacing="1rem" padding={10}>
              <Button type="submit" onClick={() => navigate(-1)}>
                Back
              </Button>
            </ButtonGroup>
          </Center>
        </Box>
      </Box>

      {/* Show add or update modal */}
      <Modal
        isOpen={
          whichShowingModel === showingModel.create ||
          whichShowingModel === showingModel.update
        }
        onClose={() => {
          setWhichShowingModel(null)
          setWhichShowingMessageType(null)
          reset({})
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <ModalCloseButton />
            <ModalHeader>
              {whichShowingModel === showingModel.create
                ? 'Add Form Submission Message Type'
                : 'Update Form Submission Message Type'}
            </ModalHeader>
            <ModalBody whiteSpace="pre-line">
              <FormControl
                isInvalid={!!formState.errors.conversationTypeCode}
                mb="1rem"
              >
                <FormLabel isRequired htmlFor="conversationTypeCode">
                  Send From: *
                </FormLabel>
                <Controller
                  name="conversationTypeCode"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      isClearable={false}
                      {...register('conversationTypeCode', {
                        required: 'Send From is required',
                      })}
                      {...field}
                      items={[
                        {
                          value: 'AM',
                          label: 'Gov. Agency',
                        },
                        {
                          value: 'CQ',
                          label: 'Citizen',
                        },
                      ]}
                    />
                  )}
                />
                {formState.errors.conversationTypeCode && (
                  <FormErrorMessage>
                    {formState.errors.conversationTypeCode.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors.conversationMessageType}
                mb="1rem"
              >
                <FormLabel isRequired htmlFor="conversationMessageType">
                  Message Type: *
                </FormLabel>
                <Input
                  type="text"
                  maxLength={MAX_MESSAGE_TYPE_LENGTH}
                  inputMode="text"
                  autoComplete="conversationMessageType"
                  autoFocus
                  placeholder="e.g. Message type"
                  {...register('conversationMessageType', {
                    required: 'Message Type is required',
                    maxLength: {
                      value: MAX_MESSAGE_TYPE_LENGTH,
                      message: 'Character length exceeded',
                    },
                    pattern: {
                      value: /^[ A-Za-z0-9_@./!#&+\-,%^*()?]*$/,
                      message:
                        'Message Type should be alphanumeric and/or one of _@./!#&+-, characters.',
                    },
                  })}
                />
                {formState.errors.conversationMessageType && (
                  <FormErrorMessage>
                    {formState.errors.conversationMessageType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button type="submit">Save</Button>
                <Button
                  onClick={() => {
                    setShowCancelMessage(true)
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* Show delete modal */}
      <Modal
        isOpen={whichShowingModel === showingModel.delete}
        onClose={() => {
          setWhichShowingModel(null)
          setWhichShowingMessageType(null)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            Are you sure you want to delete?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={() => onSubmitDeleteMessageType()}>Yes</Button>
              <Button
                onClick={() => {
                  setWhichShowingModel(null)
                  setWhichShowingMessageType(null)
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Show cancel confirmation modal */}
      <Modal
        isOpen={showCancelMessage}
        onClose={() => {
          setShowCancelMessage(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            Are you sure you want to cancel?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                onClick={() => {
                  setWhichShowingModel(null)
                  setWhichShowingMessageType(null)
                  reset({})
                  setShowCancelMessage(false)
                }}
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setShowCancelMessage(false)
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
